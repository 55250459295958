import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import Select from "react-select";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as AppConfig from "../../../helpers/AppConfig";
import * as Message from "../../../helpers/Messages";
import * as Tooltip from "../../../helpers/Tooltip";

const ListAcquisitions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [listAcquisition, setListAcquisition] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listAcquisitions();
  }, [activePage, searched]);

  const listAcquisitions = (hasSearch) => {
    let request = ApiUrl.listAcquisitions;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: searchText,
        type: type,
        status: status,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: searchText,
        type: type,
        status: status,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setListAcquisition(response.data.acquisitions.data);
        setLoading(false);
        setError({});
        setPagination(response.data.acquisitions);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const deleteAcquision = (e, id, title) => {
    ConfirmPopup.deletePopup(title).then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteAcquisition + id).then(
          (response) => {
            if (response.status === 200) {
              listAcquisitions();
              Message.successMsg(response.data.success);
            } else if (response.status === 404) {
              Message.errorMsg(response.data.error);
            }
          }
        );
      }
    });
  };

  const approveAcquision = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(ApiUrl.approveAcquisition, data).then((response) => {
      if (response.status === 200) {
        navigate(Path.createBiblio);
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const cancelAcquision = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(ApiUrl.cancelAcquisition, data).then((response) => {
      if (response.status === 200) {
        listAcquisitions();
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("list_acquisition_list")}</p>
      <div className="input-items">
        <form onSubmit={(e) => listAcquisitions(e)} id="request">
          <div className="row mb-4">
            <div className="form-block col-12 col-sm-6 col-md-3">
              <input
                placeholder={t("list_acquisition_search")}
                className={
                  "form-input" + (error.search_text ? " input-error" : "")
                }
                name="search_text"
                type="text"
                onChange={(e) => setSearchText(e.target.value)}
              />
              {error.search_text && (
                <ValidationMessage message={error.search_text[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-3 pb-4">
              <Select
                className={
                  "form-react-select" + (error.type ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="type"
                isClearable={true}
                onChange={(e) => (e ? setType(e.value) : setType(""))}
                isSearchable={false}
                placeholder={t("list_acquisition_choose")}
                options={[
                  { value: "title", label: t("title") },
                  { value: "author", label: t("author") },
                ]}
              />
              {error.type && <ValidationMessage message={error.type[0]} />}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-3 pb-4">
              <Select
                className="form-react-select"
                classNamePrefix="select_input"
                name="status"
                isClearable={true}
                onChange={(e) => (e ? setStatus(e.value) : setStatus(""))}
                isSearchable={false}
                placeholder={t("list_acquisition_choose")}
                options={[
                  { value: "registered", label: t("status_registred") },
                  { value: "approved", label: t("status_approved") },
                  { value: "cancelled", label: t("status_cancelled") },
                ]}
              />
              {error.status && <ValidationMessage message={error.status[0]} />}
            </div>
            <div className="col-12 col-sm-6 col-md-3 pb-4">
              {btnLoading === true ? (
                <button className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("list_acquisition_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("list_acquisition_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list-bg mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" width="20%" className="pr-3 text-left">
                {t("list_acquisition_title")}
              </th>
              <th role="columnheader" width="20%" className="pr-3 text-center">
                {t("list_acquisition_author")}
              </th>
              <th role="columnheader" width="20%" className="pr-3 text-center">
                {t("list_acquisition_number_of_copies")}
              </th>
              <th role="columnheader" width="20%" className="pr-3 text-center">
                {t("list_acquisition_date_expected_to_arrive")}
              </th>
              <th role="columnheader" width="20%" className="pr-3 text-center">
                {t("list_acquisition_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loading />
                </td>
              </tr>
            ) : (
              listAcquisition.map((acquisitions, index) => (
                <tr
                  key={index}
                  role="row"
                  onClick={() =>
                    navigate(Path.showAcquisition + acquisitions.id)
                  }
                >
                  <td data-label={t("list_acquisition_title")}>
                    {Tooltip.tooltipDisabledMobile(
                      "title" + index,
                      acquisitions.title
                    )}
                  </td>
                  <td
                    data-label={t("list_acquisition_author")}
                    className=" text-center "
                  >
                    {acquisitions.author}
                  </td>
                  <td
                    data-label={t("list_acquisition_copies")}
                    className=" text-center "
                  >
                    {acquisitions.copies}
                  </td>
                  <td
                    data-label={t("list_acquisition_date_expected_to_arrive")}
                    className=" text-center to-right"
                  >
                    {acquisitions.expected_on !== null &&
                      moment(acquisitions.expected_on).format("DD MMM, YYYY")}
                  </td>
                  <td
                    className=" text-center"
                    data-label={t("list_acquisition_actions")}
                  >
                    <div className="row justify-content-around mr-auto ml-auto action-icons">
                      <div
                        className="col-3 aprovo acq-tooltip"
                        data-toggle="tooltip"
                        title=""
                        data-original-title={t("list_acquisition_approve")}
                      >
                        {acquisitions.status !== t("approved") &&
                          acquisitions.status !== t("cancelled") && (
                            <button
                              type="submit"
                              className="button-unstyled-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                approveAcquision(e, acquisitions.id);
                              }}
                            >
                              <i className="icon approve-icon approve-acquisition" />
                              {/* {acquisitions.status} */}
                            </button>
                          )}
                      </div>
                      <div
                        className="col-3 anullo acq-tooltip"
                        data-toggle="tooltip"
                        title=""
                        data-original-title={t("list_acquisition_cancel")}
                      >
                        {acquisitions.status !== t("cancelled") &&
                          acquisitions.status !== t("approved") && (
                            <button
                              type="submit"
                              className="button-unstyled-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                cancelAcquision(e, acquisitions.id);
                              }}
                            >
                              <i className="icon close-icon acquisition-icon" />
                            </button>
                          )}
                      </div>
                      <div className="col-3 edit">
                        <button
                          className="button-unstyled-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(Path.editAcquisition + acquisitions.id);
                          }}
                        >
                          <i className="icon edit-icon" />
                        </button>
                      </div>
                      <div className="col-3 delete">
                        <button
                          type="submit"
                          className="button-unstyled-icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteAcquision(
                              e,
                              acquisitions.id,
                              acquisitions.title
                            );
                          }}
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(error).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};
export default ListAcquisitions;
