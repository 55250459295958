import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import InviteMember from "../../../components/panel/members/InviteMember";

const CreateMemberView = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>{t("invite_member_views")}</title>
      </Helmet>
      <Sidebar>
        <InviteMember />
      </Sidebar>
    </Fragment>
  );
};

export default CreateMemberView;
