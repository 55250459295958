import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authRole } from "../../../../../helpers/AppConfig";
import ReactDatePicker from "react-datepicker";
import {
  CheckBorrowingType,
  CheckSelectedDate,
} from "../../../../../helpers/CheckValue";
import { Items } from "../../../../../store/Catalog/CreateBibliography/CreateItems";

import ValidationMessage from "../../../../../helpers/ValidationMessage";
import moment from "moment/moment";
import ReactSelect from "react-select";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api//ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as Path from "../../../../../routes/GeneralPaths";

const CreateItems = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const { id } = useParams();

  const resetItemsData = Items((state) => state.reset);
  const itemsData = Items((state) => state.itemsData);
  const addItemsUpdate = Items((state) => state.addItemsUpdate);

  const [index, setIndex] = useState(2);
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => resetItemsData(), []);

  const addNewItem = () => {
    var items = itemsData?.items;
    var newItemData = {
      borrowing_type: "",
      inventory_number: "",
      place_number: "",
      damaged: 0,
      damaged_date: "",
      renewal_date: "",
      id: index,
    };
    items.push(newItemData);

    addItemsUpdate({ ...itemsData, items });
    setIndex(index + 1);
  };

  const removeItem = (id) => {
    var items = itemsData?.items;
    items.forEach((item, index) => {
      if (item.id === id) {
        items.splice(index, 1);
      }
    });

    addItemsUpdate({ ...itemsData, items });
  };

  const handleChangeSelect = (e, name, i) => {
    var items = itemsData?.items;
    items[i][name] = e ? e.value : "";
    addItemsUpdate({ ...itemsData, items });
  };

  const handleChange = (e, name, i) => {
    var items = itemsData?.items;
    items[i][name] = e.target.value;
    addItemsUpdate({ ...itemsData, items });
  };

  const handleChangeDate = (e, name, i) => {
    var items = itemsData?.items;
    items[i][name] = e ? moment(e).format("yyyy-MM-DD") : null;
    addItemsUpdate({ ...itemsData, items });
  };

  const createItems = (e, nav) => {
    e.preventDefault();
    setBtnLoading(true);

    var request = {
      items: { ...itemsData.items },
      [nav]: 1,
      biblioitem_id: id,
    };

    Request.postRequest(ApiUrl.storeItems, request).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        setErrorList([]);
        if (nav === "forward") {
          navigate(Path.listBiblioitems);
          Message.successMsg(response.data.success);
        } else {
          navigate(
            Path.editBiblioitemonly +
              response.data.biblio_id +
              "/" +
              response.data.biblioitem_id
          );
          Message.successMsg(response.data.success);
        }
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
      } else if (response.status === 422) {
        setErrorList(response.data);
      }
    });
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between gap-2 mb-5">
        <p className="partial-title">
          {t("create_bibliography_create_items_title")}
        </p>
        <div className="navigation-bibliography">
          <span
            className="left-arrow"
            onClick={(e) =>
              !btnLoading ? createItems(e, "back") : e.preventDefault()
            }
          >
            <i className="icon left-arrow-icon" />
          </span>
          <span className="current-step">3/3</span>
          <span
            className="right-arrow"
            onClick={(e) =>
              !btnLoading ? createItems(e, "forward") : e.preventDefault()
            }
          >
            <i className="icon right-arrow-icon" />
          </span>
        </div>
      </div>
      {itemsData?.items.map((item, index) => (
        <Fragment key={item.id}>
          <div className="row card-item-bg mb-4 ">
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_items_borrow_type_label")}
              </label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList[`items.${index}.borrowing_type`]
                    ? " input-error"
                    : "")
                }
                classNamePrefix="select_input"
                name={"items[" + item.id + "][borrowing_type]"}
                placeholder={t(
                  "create_bibliography_create_items_borrow_type_placeholder"
                )}
                defaultValue={
                  item.borrowing_type
                    ? CheckBorrowingType(item.borrowing_type)
                    : null
                }
                isSearchable={false}
                isClearable={true}
                menuPlacement="auto"
                options={[
                  {
                    value: "library",
                    label: t("check_borrowing_type_library"),
                  },
                  { value: "normal", label: t("check_borrowing_type_normal") },
                  {
                    value: "unavailable",
                    label: t("check_borrowing_type_unavailable"),
                  },
                ]}
                onChange={(e) => handleChangeSelect(e, `borrowing_type`, index)}
              />
              {errorList[`items.${index}.borrowing_type`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.borrowing_type`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_items_codification_label")}
              </label>
              <input
                placeholder={t(
                  "create_bibliography_create_items_codification_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`items.${index}.inventory_number`]
                    ? " input-error"
                    : "")
                }
                name={"items[" + item.id + "][inventory_number]"}
                onChange={(e) => handleChange(e, `inventory_number`, index)}
                type="text"
                defaultValue={
                  item.inventory_number ? item.inventory_number : ""
                }
              />
              {errorList[`items.${index}.inventory_number`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.inventory_number`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_items_place_number_label")}
              </label>
              <input
                placeholder={t(
                  "create_bibliography_create_items_place_number_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`items.${index}.place_number`]
                    ? " input-error"
                    : "")
                }
                name={"items[" + item.id + "][place_number]"}
                onChange={(e) => handleChange(e, `place_number`, index)}
                type="text"
                defaultValue={item.place_number ? item.place_number : ""}
              />
              {errorList[`items.${index}.place_number`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.place_number`]}
                />
              )}
            </div>
            {authRole() === "administrator" && (
              <Fragment>
                <div className="form-block col-12 col-md-4">
                  <label>
                    {t("create_bibliography_create_items_damaged_label")}
                  </label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button me-4">
                      <label htmlFor={`damaged_${index}`}>
                        {t(
                          "create_bibliography_create_items_damaged_yes_label"
                        )}
                      </label>
                      <input
                        className="form-check-input"
                        name={"items[" + item.id + "][damaged]"}
                        onChange={(e) => handleChange(e, `damaged`, index)}
                        type="radio"
                        value="1"
                        id={`damaged_${index}`}
                        defaultChecked={item.damaged === 1}
                      />
                    </div>
                    <div className="radio-button ms-4">
                      <label htmlFor={`notdamaged_${index}`}>
                        {t("create_bibliography_create_items_notdamaged_label")}
                      </label>
                      <input
                        className="form-check-input"
                        id={`notdamaged_${index}`}
                        name={"items[" + item.id + "][damaged]"}
                        onChange={(e) => handleChange(e, `damaged`, index)}
                        type="radio"
                        value="0"
                        defaultChecked={item.damaged === 0}
                      />
                    </div>
                  </div>
                  {errorList[`items.${index}.damaged`] && (
                    <ValidationMessage
                      message={errorList[`items.${index}.damaged`]}
                    />
                  )}
                </div>
                {(item.damaged === 1 || item.damaged === "1") && (
                  <Fragment>
                    <div className="form-block col-12 col-md-4">
                      <label>
                        {t(
                          "create_bibliography_create_items_damaged_date_label"
                        )}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "create_bibliography_create_items_damaged_date_placeholder"
                        )}
                        name={"items[" + item.id + "][damaged_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.damaged_date
                            ? CheckSelectedDate(item.damaged_date)
                            : null
                        }
                        onChange={(e) =>
                          handleChangeDate(e, `damaged_date`, index)
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`items.${index}.damaged_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`items.${index}.damaged_date`] && (
                        <ValidationMessage
                          message={errorList[`items.${index}.damaged_date`]}
                        />
                      )}
                    </div>
                    <div className="form-block col-12 col-md-4">
                      <label>
                        {t(
                          "create_bibliography_create_items_renovation_date_label"
                        )}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "create_bibliography_create_items_renovation_date_placeholder"
                        )}
                        name={"items[" + item.id + "][renewal_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.renewal_date
                            ? CheckSelectedDate(item.renewal_date)
                            : null
                        }
                        onChange={(e) =>
                          handleChangeDate(e, `renewal_date`, index)
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`items.${index}.renewal_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`items.${index}.renewal_date`] && (
                        <ValidationMessage
                          message={errorList[`items.${index}.renewal_date`]}
                        />
                      )}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
            <div className="add-remove-buttons">
              {index + 1 === itemsData?.items.length && (
                <button
                  className="button-blue-border-item"
                  type="button"
                  onClick={() => addNewItem()}
                >
                  +
                </button>
              )}
              {itemsData?.items.length !== 1 && (
                <button
                  type="button"
                  className="button-blue-item"
                  onClick={() => removeItem(item.id)}
                >
                  -
                </button>
              )}
            </div>
          </div>
        </Fragment>
      ))}

      {itemsData?.items.length === 0 && itemsData?.newItems.length === 0 && (
        <button
          className="button-blue-border-item"
          type="button"
          onClick={() => addNewItem()}
        >
          +
        </button>
      )}
      <div className="d-flex justify-content-end">
        {btnLoading ? (
          <button className="button-blue has-icon" type="button">
            <i className="icon spinner-icon me-2" />
            {t("create_bibliography_create_items_saving_button")}
          </button>
        ) : (
          <button
            className="button-blue"
            onClick={(e) => createItems(e, "forward")}
          >
            {t("create_bibliography_create_items_save_button")}
          </button>
        )}
      </div>
    </Fragment>
  );
};

export default CreateItems;
