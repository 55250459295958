import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import CreateSerial from "../../../components/panel/serial/CreateSerial";

const CreateSerialView = () => {
  const { t } = useTranslation("");
  return (
    <Fragment>
      <Helmet>
        <title>{t("serial_create_serial_tab")}</title>
      </Helmet>
      <Sidebar>
        <CreateSerial />
      </Sidebar>
    </Fragment>
  );
};

export default CreateSerialView;
