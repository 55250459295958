import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authRole } from "../../../../../helpers/AppConfig";
import ReactDatePicker from "react-datepicker";
import {
  CheckBorrowingType,
  CheckSelectedDate,
} from "../../../../../helpers/CheckValue";
import { Items } from "../../../../../store/Catalog/CreateBibliography/CreateItems";

import ValidationMessage from "../../../../../helpers/ValidationMessage";
import moment from "moment/moment";
import ReactSelect from "react-select";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api//ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as Path from "../../../../../routes/GeneralPaths";

const EditItems = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const { id } = useParams();

  const resetItemsData = Items((state) => state.reset);
  const itemsData = Items((state) => state.itemsData);
  const addItemsUpdate = Items((state) => state.addItemsUpdate);
  const addItemsData = Items((state) => state.addItemsData);

  const [index, setIndex] = useState(0);
  const [errorList, setErrorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    resetItemsData();
    getItems();
  }, []);

  const getItems = () => {
    Request.getRequest(ApiUrl.editItems + id).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        addItemsData(response.data);
      } else if (response.status === 404) {
        navigate(-1);
        Message.errorMsg(response.data.error);
      }
    });
  };

  const addNewItem = () => {
    var newItems = itemsData?.newItems;
    var newItemData = {
      borrowing_type: "",
      inventory_number: "",
      place_number: "",
      damaged: 0,
      damaged_date: "",
      renewal_date: "",
      id: index,
    };
    newItems.push(newItemData);
    addItemsUpdate({ ...itemsData, newItems });
    setIndex(index + 1);
  };

  const removeItem = (id) => {
    var newItems = itemsData?.newItems;
    newItems.forEach((item, index) => {
      if (item.id === id) {
        newItems.splice(index, 1);
      }
    });
    addItemsUpdate({ ...itemsData, newItems });
  };

  const deleteItem = (id) => {
    var items = itemsData?.items;
    items.forEach((item) => {
      if (item.id === id) {
        items.splice(item, 1);
      }
    });
    addItemsUpdate({ ...itemsData, items });
    var deletedItems = itemsData?.deletedItems;
    deletedItems.push(id);
    addItemsUpdate({ ...itemsData, deletedItems });
  };

  const handleChangeSelect = (e, name, i, status) => {
    if (status === "item") {
      var items = itemsData?.items;
      items[i][name] = e ? e.value : "";
      addItemsUpdate({ ...itemsData, items });
    } else if (status === "newItem") {
      var newItem = itemsData?.newItems;
      newItem[i][name] = e ? e.value : "";
      addItemsUpdate({ ...itemsData, newItem });
    }
  };

  const handleChange = (e, name, i, status) => {
    if (status === "item") {
      var items = itemsData?.items;
      items[i][name] = e.target.value;
      addItemsUpdate({ ...itemsData, items });
    } else if (status === "newItem") {
      var newItem = itemsData?.newItems;
      newItem[i][name] = e.target.value;
      addItemsUpdate({ ...itemsData, newItem });
    }
  };

  const handleChangeDate = (e, name, i, status) => {
    if (status === "item") {
      var items = itemsData?.items;
      items[i][name] = e ? moment(e).format("yyyy-MM-DD") : null;
      addItemsUpdate({ ...itemsData, items });
    } else if (status === "newItem") {
      var newItems = itemsData?.newItems;
      newItems[i][name] = e ? moment(e).format("yyyy-MM-DD") : null;
      addItemsUpdate({ ...itemsData, newItems });
    }
  };

  const updateItems = (e, nav) => {
    e.preventDefault();
    setBtnLoading(true);

    var request = {
      items: { ...itemsData.items },
      newitems: { ...itemsData.newItems },
      deleteditems: { ...itemsData.deletedItems },
      [nav]: 1,
      biblioitem_id: id,
    };

    Request.patchRequest(ApiUrl.updateItems + id, request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setErrorList([]);
        if (nav === "forward") {
          navigate(Path.listBiblioitems);
          Message.successMsg(response.data.success);
        } else {
          navigate(
            Path.editBiblioitemonly +
              response.data.biblio_id +
              "/" +
              response.data.biblioitem_id
          );
          Message.successMsg(response.data.success);
        }
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
      } else if (response.status === 422) {
        setErrorList(response.data);
      }
    });
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between gap-2 mb-5">
        <p className="partial-title">
          {t("create_bibliography_edit_items_title")}
        </p>
        <div className="navigation-bibliography">
          <span
            className="left-arrow"
            onClick={(e) =>
              !btnLoading ? updateItems(e, "back") : e.preventDefault()
            }
          >
            <i className="icon left-arrow-icon" />
          </span>
          <span className="current-step">3/3</span>
          <span
            className="right-arrow"
            onClick={(e) =>
              !btnLoading ? updateItems(e, "forward") : e.preventDefault()
            }
          >
            <i className="icon right-arrow-icon" />
          </span>
        </div>
      </div>
      <Fragment>
        {itemsData?.items.map((item, index) => (
          <div key={item.id} className="row card-item-bg mb-4 ">
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_items_borrow_type_label")}
              </label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList[`items.${index}.borrowing_type`]
                    ? " input-error"
                    : "")
                }
                classNamePrefix="select_input"
                name={"items[" + item.id + "][borrowing_type]"}
                placeholder={t(
                  "create_bibliography_edit_items_borrow_type_placeholder"
                )}
                defaultValue={
                  item.borrowing_type
                    ? CheckBorrowingType(item.borrowing_type)
                    : null
                }
                isSearchable={false}
                isClearable={true}
                menuPlacement="auto"
                options={[
                  {
                    value: "library",
                    label: t("check_borrowing_type_library"),
                  },
                  {
                    value: "normal",
                    label: t("check_borrowing_type_normal"),
                  },
                  {
                    value: "unavailable",
                    label: t("check_borrowing_type_unavailable"),
                  },
                ]}
                onChange={(e) =>
                  handleChangeSelect(e, `borrowing_type`, index, "item")
                }
              />
              {errorList[`items.${index}.borrowing_type`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.borrowing_type`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_items_codification_label")}
              </label>
              <input
                placeholder={t(
                  "create_bibliography_edit_items_codification_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`items.${index}.inventory_number`]
                    ? " input-error"
                    : "")
                }
                name={"items[" + item.id + "][inventory_number]"}
                onChange={(e) =>
                  handleChange(e, `inventory_number`, index, "item")
                }
                type="text"
                defaultValue={
                  item.inventory_number ? item.inventory_number : ""
                }
              />
              {errorList[`items.${index}.inventory_number`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.inventory_number`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_items_place_number_label")}
              </label>
              <input
                placeholder={t(
                  "create_bibliography_edit_items_place_number_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`items.${index}.place_number`]
                    ? " input-error"
                    : "")
                }
                name={"items[" + item.id + "][place_number]"}
                onChange={(e) => handleChange(e, `place_number`, index, "item")}
                type="text"
                defaultValue={item.place_number ? item.place_number : ""}
              />
              {errorList[`items.${index}.place_number`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.place_number`]}
                />
              )}
            </div>
            <div className="row col-12 m-0 p-0">
              <div className="form-block col-12 col-md-4">
                <label>
                  {t("create_bibliography_edit_items_inventory_number_label")}
                </label>
                <input
                  placeholder={t(
                    "create_bibliography_edit_items_inventory_number_placeholder"
                  )}
                  className="form-input"
                  type="text"
                  defaultValue={item.id}
                  disabled
                />
              </div>
            </div>
            {authRole() === "administrator" && (
              <Fragment>
                <div className="form-block col-12 col-md-4">
                  <label>
                    {t("create_bibliography_edit_items_damaged_label")}
                  </label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button me-4">
                      <label htmlFor={`damaged_${index}`}>
                        {t("create_bibliography_edit_items_damaged_yes_label")}
                      </label>
                      <input
                        className="form-check-input"
                        name={"items[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          handleChange(e, `damaged`, index, "item")
                        }
                        type="radio"
                        value="1"
                        id={`damaged_${index}`}
                        defaultChecked={item.damaged === 1}
                      />
                    </div>
                    <div className="radio-button ms-4">
                      <label htmlFor={`notdamaged_${index}`}>
                        {t("create_bibliography_edit_items_notdamaged_label")}
                      </label>
                      <input
                        className="form-check-input"
                        id={`notdamaged_${index}`}
                        name={"items[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          handleChange(e, `damaged`, index, "item")
                        }
                        type="radio"
                        value="0"
                        defaultChecked={item.damaged === 0}
                      />
                    </div>
                  </div>
                  {errorList[`items.${index}.damaged`] && (
                    <ValidationMessage
                      message={errorList[`items.${index}.damaged`]}
                    />
                  )}
                </div>
                {(item.damaged === 1 || item.damaged === "1") && (
                  <Fragment>
                    <div className="form-block col-12 col-md-4">
                      <label>
                        {t("create_bibliography_edit_items_damaged_date_label")}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "create_bibliography_edit_items_damaged_date_placeholder"
                        )}
                        name={"items[" + item.id + "][damaged_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.damaged_date
                            ? CheckSelectedDate(item.damaged_date)
                            : null
                        }
                        onChange={(e) =>
                          handleChangeDate(e, `damaged_date`, index, "item")
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`items.${index}.damaged_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`items.${index}.damaged_date`] && (
                        <ValidationMessage
                          message={errorList[`items.${index}.damaged_date`]}
                        />
                      )}
                    </div>
                    <div className="form-block col-12 col-md-4">
                      <label>
                        {t(
                          "create_bibliography_edit_items_renovation_date_label"
                        )}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "create_bibliography_edit_items_renovation_date_placeholder"
                        )}
                        name={"items[" + item.id + "][renewal_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.renewal_date
                            ? CheckSelectedDate(item.renewal_date)
                            : null
                        }
                        onChange={(e) =>
                          handleChangeDate(e, `renewal_date`, index, "item")
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`items.${index}.renewal_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`items.${index}.renewal_date`] && (
                        <ValidationMessage
                          message={errorList[`items.${index}.renewal_date`]}
                        />
                      )}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
            <div className="add-remove-buttons">
              {index + 1 === itemsData?.items.length &&
                itemsData?.newItems.length === 0 && (
                  <button
                    className="button-blue-border-item"
                    type="button"
                    onClick={() => addNewItem()}
                  >
                    +
                  </button>
                )}
              <button
                type="button"
                className="button-blue-item"
                onClick={() => deleteItem(item.id)}
              >
                -
              </button>
            </div>
          </div>
        ))}
        {itemsData?.newItems.map((item, index_new) => (
          <div key={item.id} className="row card-item mb-4">
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_items_borrow_type_label")}
              </label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList[`newitems.${index_new}.borrowing_type`]
                    ? " input-error"
                    : "")
                }
                classNamePrefix="select_input"
                name={"newitems[" + item.id + "][borrowing_type]"}
                placeholder={t(
                  "create_bibliography_edit_items_borrow_type_placeholder"
                )}
                isSearchable={false}
                isClearable={true}
                menuPlacement="auto"
                options={[
                  {
                    value: "library",
                    label: t("check_borrowing_type_library"),
                  },
                  {
                    value: "normal",
                    label: t("check_borrowing_type_normal"),
                  },
                  {
                    value: "unavailable",
                    label: t("check_borrowing_type_unavailable"),
                  },
                ]}
                onChange={(e) =>
                  handleChangeSelect(e, `borrowing_type`, index_new, "newItem")
                }
              />
              {errorList[`newitems.${index_new}.borrowing_type`] && (
                <ValidationMessage
                  message={errorList[`newitems.${index_new}.borrowing_type`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>{t("edit_bibliography_items_codification_label")}</label>
              <input
                placeholder={t(
                  "create_bibliography_edit_items_codification_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`newitems.${index_new}.inventory_number`]
                    ? " input-error"
                    : "")
                }
                name={"newitems[" + item.id + "][inventory_number]"}
                onChange={(e) =>
                  handleChange(e, `inventory_number`, index_new, "newItem")
                }
                defaultValue={item.inventory_number}
                type="text"
              />
              {errorList[`newitems.${index_new}.inventory_number`] && (
                <ValidationMessage
                  message={errorList[`newitems.${index_new}.inventory_number`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_items_place_number_label")}
              </label>
              <input
                placeholder={t(
                  "create_bibliography_edit_items_place_number_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`newitems.${index_new}.place_number`]
                    ? " input-error"
                    : "")
                }
                name={"newitems[" + item.id + "][place_number]"}
                onChange={(e) =>
                  handleChange(e, `place_number`, index_new, "newItem")
                }
                type="text"
                defaultValue={item.place_number}
              />
              {errorList[`newitems.${index_new}.place_number`] && (
                <ValidationMessage
                  message={errorList[`newitems.${index_new}.place_number`]}
                />
              )}
            </div>
            {authRole() === "administrator" && (
              <Fragment>
                <div className="form-block col-12 col-md-4">
                  <label>
                    {t("create_bibliography_edit_items_damaged_label")}
                  </label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button me-4">
                      <label htmlFor={`damaged-${index_new}`}>
                        {t("create_bibliography_edit_items_damaged_yes_label")}
                      </label>
                      <input
                        className="form-check-input"
                        id={`damaged-${index_new}`}
                        name={"newitems[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          handleChange(e, `damaged`, index_new, "newItem")
                        }
                        type="radio"
                        defaultValue="1"
                        defaultChecked={item.damaged === 1}
                      />
                    </div>
                    <div className="radio-button ms-4">
                      <label htmlFor={`notdamaged-${index_new}`}>
                        {t("create_bibliography_edit_items_notdamaged_label")}
                      </label>
                      <input
                        className="form-check-input"
                        data-target="removable-field"
                        id={`notdamaged-${index_new}`}
                        name={"newitems[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          handleChange(e, `damaged`, index_new, "newItem")
                        }
                        type="radio"
                        defaultValue="0"
                        defaultChecked={item.damaged === 0}
                      />
                    </div>
                  </div>
                  {errorList[`newitems.${index_new}.damaged`] && (
                    <ValidationMessage
                      message={errorList[`newitems.${index_new}.damaged`]}
                    />
                  )}
                </div>
                {(item.damaged === 1 || item.damaged === "1") && (
                  <Fragment>
                    <div className="form-block col-12 col-md-4">
                      <label>
                        {t("edit_bibliography_items_damaged_date_label")}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "edit_bibliography_items_damaged_date_placeholder"
                        )}
                        name={"newitems[" + item.id + "][damaged_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={CheckSelectedDate(item.damaged_date)}
                        onChange={(e) =>
                          handleChangeDate(
                            e,
                            `damaged_date`,
                            index_new,
                            "newItem"
                          )
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`newitems.${index_new}.damaged_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`newitems.${index_new}.damaged_date`] && (
                        <ValidationMessage
                          message={
                            errorList[`newitems.${index_new}.damaged_date`]
                          }
                        />
                      )}
                    </div>
                    <div className="form-block col-12 col-md-4">
                      <label>
                        {t("edit_bibliography_items_renovation_date_label")}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "edit_bibliography_items_renovation_date_placeholder"
                        )}
                        name={"newitems[" + item.id + "][renewal_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={CheckSelectedDate(item.renewal_date)}
                        onChange={(e) =>
                          handleChangeDate(
                            e,
                            `renewal_date`,
                            index_new,
                            "newItem"
                          )
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`newitems.${index_new}.renewal_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`newitems.${index_new}.renewal_date`] && (
                        <ValidationMessage
                          message={
                            errorList[`newitems.${index_new}.renewal_date`]
                          }
                        />
                      )}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
            <div className="add-remove-buttons">
              {index_new + 1 === itemsData?.newItems.length && (
                <button
                  className="button-blue-border-item"
                  type="button"
                  onClick={() => addNewItem()}
                >
                  +
                </button>
              )}
              <button
                type="button"
                className="button-blue-item"
                onClick={() => removeItem(item.id)}
              >
                -
              </button>
            </div>
          </div>
        ))}
        {itemsData?.items.length === 0 && itemsData?.newItems.length === 0 && (
          <button
            className="button-blue-border-item"
            type="button"
            onClick={() => addNewItem()}
          >
            +
          </button>
        )}
      </Fragment>
      <div className="d-flex justify-content-end mt-4">
        {!loading &&
          (btnLoading ? (
            <button className="button-blue has-icon" type="button">
              <i className="icon spinner-icon me-2" />
              {t("create_bibliography_edit_items_saving_button")}
            </button>
          ) : (
            <button
              className="button-blue"
              onClick={(e) => updateItems(e, "forward")}
              type="submit"
            >
              {t("create_bibliography_edit_items_save_button")}
            </button>
          ))}
      </div>
    </Fragment>
  );
};

export default EditItems;
