import { useTranslation } from "react-i18next";
import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as AppConfig from "../../../../helpers/AppConfig";

import CheckCover from "../../../../helpers/CheckCover";

const Isbd = () => {
  const { t } = useTranslation();
  const param = useParams();
  const [biblioItem, setBiblioItem] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    showIsbd();
  }, []);

  const showIsbd = () => {
    const serialId = param.id;
    let request = ApiUrl.showSerial + serialId;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setBiblioItem(response.data.serial.biblioitem);
        setLoading(false);
      }
    });
  };

  return (
    <div className="row show-material">
      <div className="col-12 col-sm-4 d-none d-md-block image-holder">
        <img
          src={CheckCover(
            biblioItem.cover,
            biblioItem?.biblio?.materialtype?.id
          )}
          alt="Material cover"
        />
      </div>
      <div className="col-12 col-md-8 description-holder">
        <div>
          <div className="row m-0 p-0 name-item">
            {`${biblioItem?.biblio?.title ? biblioItem.biblio.title : ""}
                ${
                  biblioItem?.biblio?.subtitle
                    ? " : " + biblioItem?.biblio?.subtitle
                    : ""
                }
                ${
                  biblioItem?.biblio?.authors?.length > 0 &&
                  " / " +
                    biblioItem.biblio.authors
                      .map((author) =>
                        author.name.split(",").reverse().join(" ")
                      )
                      .join("; ")
                    ? biblioItem?.biblio?.authors?.length > 0 &&
                      " / " +
                        biblioItem.biblio.authors
                          .map((author) =>
                            author.name.split(",").reverse().join(" ")
                          )
                          .join("; ")
                    : ""
                }
                ${
                  biblioItem?.contributors?.length > 0 &&
                  biblioItem?.biblio?.authors.length < 1
                    ? " / " +
                      biblioItem.contributors
                        .map((contributor) =>
                          contributor.name.split("; ").reverse().join(" ")
                        )
                        .join("; ")
                    : biblioItem?.contributors?.length > 0 &&
                      biblioItem?.biblio?.authors?.length > 0 &&
                      "; " +
                        biblioItem.contributors
                          .map((contributor) =>
                            contributor.name.split("; ").reverse().join(" ")
                          )
                          .join("; ")
                    ? biblioItem?.contributors?.length > 0 &&
                      biblioItem?.biblio?.authors.length < 1
                      ? " / " +
                        biblioItem.contributors
                          .map((contributor) =>
                            contributor.name.split("; ").reverse().join(" ")
                          )
                          .join("; ")
                      : biblioItem?.contributors?.length > 0 &&
                        biblioItem?.biblio?.authors?.length > 0 &&
                        "; " +
                          biblioItem.contributors
                            .map((contributor) =>
                              contributor.name.split("; ").reverse().join(" ")
                            )
                            .join("; ")
                    : ""
                }
                ${
                  biblioItem?.biblio?.part_number
                    ? " / " + biblioItem.biblio.part_number
                    : ""
                }
                ${
                  biblioItem?.biblio?.part_name
                    ? " / " + biblioItem.biblio.part_name
                    : ""
                }
                ${
                  biblioItem?.publication_number
                    ? ".- " + biblioItem?.publication_number
                    : ""
                }
                ${biblioItem?.place ? ".- " + biblioItem.place : ""}
                ${
                  biblioItem?.publisher?.name
                    ? " : " + biblioItem.publisher.name
                    : ""
                }
                ${
                  biblioItem?.publication_year
                    ? ", " + biblioItem.publication_year
                    : ""
                }
                ${biblioItem?.pages ? ".- " + biblioItem.pages : ""}
                ${
                  biblioItem?.physical_details
                    ? "; " + biblioItem.physical_details
                    : ""
                }
                ${
                  biblioItem?.dimensions
                    ? "; " + biblioItem.dimensions + "."
                    : ""
                }`}
          </div>
          {biblioItem?.biblio?.notes && (
            <div className="row m-0 p-0 name-item">
              {biblioItem?.biblio?.notes ? biblioItem?.biblio?.notes : ""}
            </div>
          )}
          {biblioItem?.issn && (
            <div className="row m-0 p-0 name-item">
              {t("show_serial_isbd_issn")}: {biblioItem.issn}
            </div>
          )}
          {biblioItem?.isbn && (
            <div className="row m-0 p-0 name-item">
              {t("show_serial_isbd_isbn")}: {biblioItem.isbn}
            </div>
          )}
          {biblioItem?.biblio?.keywords?.length > 0 && (
            <div className="row m-0 p-0 mt-3 mb-3">
              <div className="col-12 ps-0">
                <span className="name-desc">
                  {t("show_serial_isbd_keywords")}
                </span>
              </div>
              <div className="col-12 ps-0 name-item">
                {biblioItem.biblio.keywords.map((keyword, index) => (
                  <span key={index} className="tag">
                    {keyword.name}
                  </span>
                ))}
              </div>
            </div>
          )}

          {biblioItem?.biblio?.geographicalterms?.length > 0 && (
            <div className="row m-0 p-0 mt-3 mb-3">
              <div className="col-12 ps-0">
                <span className="name-desc">
                  {t("show_serial_isbd_geographical_terms")}
                </span>
              </div>
              <div className="col-12 ps-0 name-item">
                {biblioItem.biblio.geographicalterms.map((terms, index) => (
                  <span key={index} className="tag">
                    {terms.name}
                  </span>
                ))}
              </div>
            </div>
          )}

          {biblioItem?.kdu && (
            <Fragment>
              <div className="row m-0 p-0">
                <span className="name-desc text-start p-0">
                  {t("show_serial_isbd_kdu")}
                </span>
              </div>
              <div className="row m-0 p-0 name-item">
                {biblioItem.kdu && biblioItem.kdu}
              </div>
            </Fragment>
          )}
          <br />
          <a
            href={`${
              AppConfig.apiBaseUrl +
              "catalog/biblio/generate-isbd/" +
              biblioItem.id
            }`}
            className="button-unstyled-icon"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon print-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Isbd;
