import create from "zustand";

const authors = [];
const keywords = [];
const geoterms = [];

export const Author = create((set) => ({
  authors,
  addAuthors: (data) => set(() => ({ authors: data })),
  reset: () => set(() => ({ authors })),
}));

export const Keyword = create((set) => ({
  keywords,
  addKeywords: (data) => set(() => ({ keywords: data })),
  reset: () => set(() => ({ keywords })),
}));

export const Geoterm = create((set) => ({
  geoterms,
  addGeoterms: (data) => set(() => ({ geoterms: data })),
  reset: () => set(() => ({ geoterms })),
}));
