import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { authorTypes } from "../../HelpersList";
import { Bibliography } from "../../../../../../store/Catalog/CreateBibliography/CreateBibliography";
import { Biblio } from "../../../../../../store/Catalog/CreateBibliography/CreateBiblio";
import Downshift from "downshift";
import ReactSelect from "react-select";
import ValidationMessage from "../../../../../../helpers/ValidationMessage";

import * as Request from "../../../../../../api/Request";
import * as ApiUrl from "../../../../../../api/ApiUrl";
import * as Message from "../../../../../../helpers/Messages";

const AuthorModal = ({ onShowModal, setAuthorVisible, selectedAuthors }) => {
  const { t } = useTranslation("");

  const bibliographyData = Bibliography((state) => state.bibliographyData);
  const addBibliographyUpdate = Bibliography(
    (state) => state.addBibliographyUpdate
  );
  const addBiblioDataUpdate = Biblio((state) => state.addBiblioDataUpdate);
  const biblioData = Biblio((state) => state.biblioData);

  const [nameAuthor, setNameAuthor] = useState("");
  const [idAuthor, setIdAuthor] = useState("");
  const [typeAuthor, setTypeAuthor] = useState("");
  const [error, setErrors] = useState("");
  const [listAuthors, setListAuthors] = useState([]);

  //The function that closes the modal
  const onClose = () => {
    setAuthorVisible(false);
    setErrors([]);
  };

  useEffect(() => {
    authorsList();
  }, [selectedAuthors]);

  const authorsList = () => {
    setListAuthors(
      bibliographyData.authors.filter(
        (x) => !selectedAuthors.filter((y) => y.id === x.id).length
      )
    );
  };

  const addAuthor = (e) => {
    e.preventDefault();

    var authorData = {
      name_author: nameAuthor,
      type_author: typeAuthor,
    };

    if (
      selectedAuthors.some((el) => el.name === nameAuthor) ||
      !bibliographyData.authors.some((el) => el.name === nameAuthor)
    ) {
      Request.postRequest(ApiUrl.createAuthorBiblio, authorData).then(
        (response) => {
          if (response.status === 201) {
            setNameAuthor("");
            setTypeAuthor("");

            var newAuthorsArr = bibliographyData.authors;

            newAuthorsArr.push({
              id: response?.data?.author?.id,
              name: response?.data?.author?.name,
            });

            addBibliographyUpdate({
              ...bibliographyData,
              authors: newAuthorsArr,
            });

            var newAuthorsSelectedArr = selectedAuthors;
            newAuthorsSelectedArr.push({
              id: response?.data?.author?.id,
              name: response?.data?.author?.name,
              pivot: { type: typeAuthor },
            });

            addBiblioDataUpdate({
              ...biblioData,
              authors: newAuthorsSelectedArr,
            });

            onClose();
            Message.successMsg(response?.data?.success);
          } else if (response.status === 405) {
            Message.infoMsg(response?.data?.info);
          } else if (response.status === 422) {
            setErrors(response.data);
          } else {
            Message.errorMsg(response?.data?.error);
          }
        }
      );
    } else {
      if (typeAuthor.length <= 0) {
        setErrors({
          type_author: t("edit_bibliography_add_authors_modal_validation"),
        });
      } else {
        setErrors([]);
        var newAuthorsArr = selectedAuthors;

        newAuthorsArr.push({
          id: idAuthor,
          name: nameAuthor,
          pivot: { type: typeAuthor },
        });

        addBiblioDataUpdate({
          ...biblioData,
          authors: newAuthorsArr,
        });

        setNameAuthor("");
        setTypeAuthor("");
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("edit_bibliography_add_authors_modal")}
      </ModalHeader>
      <form onSubmit={(e) => addAuthor(e)}>
        <ModalBody>
          <Downshift
            onChange={(e) => {
              setNameAuthor(e.name);
              setIdAuthor(e.id);
            }}
            itemToString={(item) => (item ? item.name : "")}
          >
            {({
              selectedItem,
              getInputProps,
              getItemProps,
              highlightedIndex,
            }) => (
              <div>
                <div className="form-block row mb-0">
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      placeholder={
                        t("edit_bibliography_add_authors_modal_author_input") +
                        " (100/A)"
                      }
                      {...getInputProps({
                        onChange: (e) => setNameAuthor(e.target.value),
                      })}
                      value={nameAuthor}
                      className={
                        "form-input" +
                        (error?.name_author ? " input-error" : "")
                      }
                      name="name_author"
                    />
                    {error?.name_author && (
                      <ValidationMessage message={error?.name_author} />
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <ReactSelect
                      className={
                        "form-react-select" +
                        (error?.type_author ? " input-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="type_author"
                      placeholder={
                        t("edit_bibliography_add_authors_modal_type_select") +
                        " (100/E)"
                      }
                      isClearable={true}
                      onChange={(e) => setTypeAuthor(e ? e.value : "")}
                      isSearchable={false}
                      options={authorTypes.map((item) => ({
                        value: item.value,
                        label: t(item.label),
                      }))}
                    />
                    {error?.type_author && (
                      <ValidationMessage message={error?.type_author} />
                    )}
                  </div>
                </div>
                <ul className="data-list list-unstyled">
                  {listAuthors
                    .filter(
                      (item) =>
                        !nameAuthor ||
                        item.name
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toLowerCase()
                          .includes(nameAuthor.toLowerCase())
                    )
                    .slice(0)
                    .map((item, index) => (
                      <li
                        className={`item_${item.id}`}
                        {...getItemProps({
                          key: index,
                          item,
                        })}
                        style={{
                          backgroundColor:
                            (selectedItem === item ||
                              highlightedIndex === index) &&
                            "#EBECF0",
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </Downshift>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              <button
                type="butom"
                className="button-blue w-100"
                onClick={(e) => addAuthor(e)}
              >
                {t("edit_bibliography_add_authors_modal_add_button")}
              </button>
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("edit_bibliography_add_authors_modal_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AuthorModal;
