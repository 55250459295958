import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as ApiUrl from "../../../../api/ApiUrl";
import * as Request from "../../../../api/Request";
import * as Message from "../../../../helpers/Messages";

const EditPublisher = ({
  onShowModal,
  setPublisherVisible,
  setModified,
  publisherId,
  publisherName,
}) => {
  const { t } = useTranslation("");

  const [namePublisher, setNamePublisher] = useState(publisherName);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState([]);

  //The function that closes the modal
  const onClose = () => {
    setPublisherVisible(false);
    setError([]);
  };

  const updatePublisher = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    Request.patchRequest(ApiUrl.updatePublisher + publisherId, {
      name: namePublisher,
    }).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        onClose();
        setModified(true);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      }
    });
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("publishers_list_edit_publisher_popup_title")}
      </ModalHeader>
      <form onSubmit={(e) => updatePublisher(e)} className="text-center">
        <ModalBody>
          <div className="form-block row mb-0">
            <div className="col-12 mb-3">
              <input
                type="text"
                name="name"
                className={"form-input" + (error?.name ? " input-error" : "")}
                onChange={(e) => setNamePublisher(e.target.value)}
                value={namePublisher}
                placeholder={t(
                  "publishers_list_edit_publisher_popup_placeholder"
                )}
              />
              {error?.name && <ValidationMessage message={error?.name} />}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button className="button-blue w-100 has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("publishers_list_edit_publisher_editing_button")}
                </button>
              ) : (
                <button type="submit" className="button-blue w-100">
                  {t("publishers_list_edit_publisher_edit_button")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("publishers_list_edit_publisher_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditPublisher;
