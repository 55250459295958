import { t } from "i18next";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import ValidationMessage from "../../../helpers/ValidationMessage";

const ChangePassword = () => {
  const { t } = useTranslation("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const changePasswordFunc = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var formData = {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: confirmNewPassword,
    };

    Request.postRequest(ApiUrl.changeUserPassword, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          setErrors([]);
          Message.successMsg(response.data.success);
        } else if (response.status === 404) {
          Message.errorMsg(response.data.error);
        } else if (response.status === 422) {
          setErrors(response.data);
        }
      }
    );
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("change_password_title")}</p>
      <form onSubmit={(e) => changePasswordFunc(e)}>
        <div className="row form-block">
          <div className="col-12 col-sm-6 col-xl-4 pb-4 mb-4">
            <label htmlFor="current_password">
              {t("change_password_current_password_label")}
            </label>
            <input
              placeholder="******"
              className={
                "form-input" + (errors.current_password ? " input-error" : "")
              }
              name="current_password"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              autoComplete="off"
            />
            {errors.current_password && (
              <ValidationMessage message={errors.current_password[0]} />
            )}
          </div>
          <div className="col-12 col-sm-6 col-xl-4 pb-4 mb-4">
            <label htmlFor="new_password">
              {t("change_password_new_password_label")}
            </label>
            <input
              placeholder="******"
              className={
                "form-input" + (errors.new_password ? " input-error" : "")
              }
              name="new_password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              autoComplete="off"
            />
            {errors.new_password && (
              <ValidationMessage message={errors.new_password[0]} />
            )}
          </div>
          <div className="col-12 col-sm-6 col-xl-4 pb-4 mb-4">
            <label htmlFor="new_password_confirmation">
              {t("change_password_confirm_new_password_label")}
            </label>
            <input
              placeholder="******"
              className={
                "form-input" +
                (errors.new_password_confirmation ? " input-error" : "")
              }
              name="new-password_confirmation"
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              autoComplete="off"
            />
            {errors.new_password_confirmation && (
              <ValidationMessage
                message={errors.new_password_confirmation[0]}
              />
            )}
          </div>
          <div className="col-12 col-sm-6 pb-4 mb-4 d-flex">
            {btnLoading ? (
              <button type="button" className="button-blue has-icon">
                <i className="icon spinner-icon me-2" />
                {t("change_password_saving_button")}
              </button>
            ) : (
              <button type="submit" className="button-blue">
                {t("change_password_save_button")}
              </button>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default ChangePassword;
