import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as Request from "../../api/Request";
import * as ApiUrl from "../../api/ApiUrl";
import * as Path from "../../routes/GeneralPaths";
import * as Message from "../../helpers/Messages";
import ValidationMessage from "../../helpers/ValidationMessage";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [showPassword, setShowPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState("");

  const getUserData = () => {
    Request.getRequest(ApiUrl.resetPassword + token).then((response) => {
      if (response.status === 200) {
        setEmail(response.data.user.email);
      } else {
        navigate(Path.login);
      }
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const resetPassword = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = {
      password: password,
      password_confirmation: confirmPassword,
    };
    Request.patchRequest(ApiUrl.storePassword + email, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          Message.successMsg(response.data.success);
          navigate(Path.login);
        } else if (response.status === 404 || response.status === 422) {
          setErrorList(response.data);
        }
      }
    );
  };

  return (
    <div className="form-logins">
      <div className="login_bg">
        <p className="login_note">{t("reset_password_text")}</p>
        <form className="w-100" onSubmit={(e) => resetPassword(e)}>
          <label>{t("reset_password_password_label")}</label>
          <div className="form-group">
            <input
              type={!showPassword ? "password" : "text"}
              name="password"
              placeholder={t("reset_password_password_placeholder")}
              value={password}
              className={
                "form-input" +
                (errorList.password || errorList.error ? " input-error" : "")
              }
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
            />
            <i className="icon lock-icon" />

            <div
              className="show-hide"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <i className="icon show-icon" />
              ) : (
                <i className="icon hide-icon" />
              )}
            </div>

            {(errorList.password || errorList.error) && (
              <ValidationMessage
                message={
                  errorList.password ? errorList.password[0] : errorList.error
                }
              />
            )}
          </div>
          <label>{t("reset_password_confirm_password_label")}</label>
          <div className="form-group position-relative pb-4 mb-0">
            <input
              type={!showConfirmPassword ? "password" : "text"}
              name="password_confirmation"
              placeholder={t("reset_password_confirm_password_placeholder")}
              value={confirmPassword}
              className={
                "form-input" +
                (errorList.password || errorList.error ? " input-error" : "")
              }
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="off"
            />
            <i className="icon lock-icon" />
            <div
              className="show-hide"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <i className="icon show-icon" />
              ) : (
                <i className="icon hide-icon" />
              )}
            </div>
          </div>
          {btnLoading === true ? (
            <button type="button" className="button-blue has-icon w-100 mt-2">
              <i className="spinner-icon" />
              {t("reset_password_btn_loading")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100 mt-2">
              {t("reset_password_btn")}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
