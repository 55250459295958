import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import * as Path from "../../../../routes/GeneralPaths";
import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as AppConfig from "../../../../helpers/AppConfig";

import DatePicker from "react-datepicker";
import sq from "date-fns/locale/sq";
import SearchFilter from "../../../../helpers/SearchFilter";
import ListResponse from "../../../../helpers/ListResponse";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";
import Periodic from "../../../../assets/images/covers/periodic.svg";
import CheckCover from "../../../../helpers/CheckCover";
import moment from "moment";

import Description from "./Description";
import Isbd from "./Isbd";
import Marc from "./Marc";

const ShowSerial = () => {
  const { t } = useTranslation();
  const param = useParams();
  const navigate = useNavigate();
  const [biblioItem, setBiblioItem] = useState({});
  const [biblio, setBiblio] = useState([]);
  const [approvedSeries, setApprovedSeries] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTo, setSearchTo] = useState({ date: "", value: "" });
  const [searchFrom, setSearchFrom] = useState({ date: "", value: "" });
  const [searched, setSearched] = useState(false);
  const [error, setError] = useState({});
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);
  const [tab, setTab] = useState("description");
  const [btnLoading, setBtnLoading] = useState(false);

  var date = new Date();
  date.setFullYear(date.getFullYear());

  const handleChangeDate = (e, date_type) => {
    if (date_type === "from") {
      setSearchFrom({
        date: e ? moment(e).format("DD-MM-yyyy") : "",
        value: e,
      });
    } else if (date_type === "to") {
      setSearchTo({
        date: e ? moment(e).format("DD-MM-yyyy") : "",
        value: e,
      });
    }
  };

  useEffect(() => {
    showSerial();
  }, [activePage, searched]);

  const showSerial = (hasSearch) => {
    const serialId = param.id;
    let request = ApiUrl.showSerial + serialId;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }
    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        from: searchFrom.date,
        to: searchTo.date,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        from: searchFrom.date,
        to: searchTo.date,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);
    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setBiblioItem(response.data.serial.biblioitem);
        setBiblio(response.data.serial.biblioitem.biblio);
        setApprovedSeries(response.data.approvedSeries.data);
        setLoading(false);
        setError({});
        setPagination(response.data.approvedSeries);
      } else if (response.status === 404) {
        navigate(Path.listSerials);
        Message.errorMsg(response.data.error);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        setSearched(false);
        navigate(-1);
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const checkCoverSerie = (cover) => {
    if (cover !== null) {
      return AppConfig.serieCoversPath + cover;
    } else {
      return Periodic;
    }
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("show_serial")}</p>
      <div className="detailed-biblio-infohead">
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <div className="material-type">
              {biblioItem.biblio.materialtype
                ? biblioItem.biblio.materialtype.name
                : ""}
            </div>
            <div className="material-title">
              {biblioItem.biblio.title +
                (biblioItem.biblio.subtitle
                  ? " : " + biblioItem.biblio.subtitle
                  : "")}
            </div>
            <div className="material-author">
              {biblioItem.biblio.authors.length > 0
                ? biblioItem.biblio.authors
                    .map((author) => author.name)
                    .join(", ")
                : ""}
            </div>
          </Fragment>
        )}
      </div>
      <div className="col-12 mb-4 d-block d-md-none image-holder text-center">
        {!loading && (
          <img
            src={CheckCover(
              biblioItem.cover,
              biblioItem.biblio.materialtype.id
            )}
            alt="Material cover"
          />
        )}
      </div>
      <div className="mt-4 pt-4">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li
            className={
              "nav-item col-12 col-md-4 p-0" +
              (tab === "description" ? " active" : "")
            }
            role="presentation"
          >
            <button
              className={
                "nav-link w-100" + (tab === "description" ? " active" : "")
              }
              onClick={() => setTab("description")}
            >
              {t("show_serial_description_tab")}
            </button>
          </li>
          <li
            className={
              "nav-item col-12 col-md-4 p-0" + (tab === "isbd" ? " active" : "")
            }
            role="presentation"
          >
            <button
              className={"nav-link w-100" + (tab === "isbd" ? " active" : "")}
              onClick={() => setTab("isbd")}
            >
              {t("show_serial_isbd_tab")}
            </button>
          </li>
          <li
            className={
              "nav-item col-12 col-md-4 p-0" + (tab === "marc" ? " active" : "")
            }
            role="presentation"
          >
            <button
              className={"nav-link w-100" + (tab === "marc" ? " active" : "")}
              onClick={() => setTab("marc")}
            >
              {t("show_serial_marc_tab")}
            </button>
          </li>
        </ul>

        <div className="tab-content-bg">
          {!loading ? (
            tab === "description" ? (
              <Description />
            ) : tab === "isbd" ? (
              <Isbd />
            ) : (
              tab === "marc" && <Marc />
            )
          ) : (
            <div className="d-flex justify-content-center">
              <Loading />
            </div>
          )}
        </div>
        <div className="specimen-data mb-5 mt-5">
          <div className="py-4 detailed-biblio-infohead h-table">
            <div className="col-12 material-title">
              {t("show_serial_series_details")}
            </div>
          </div>
          <div className="row mt-5 serie_info">
            <form onSubmit={(e) => showSerial(e)}>
              <div className="form-block col-12 col-sm-6 col-lg-3 pb-3 me-4">
                <DatePicker
                  placeholderText={t("show_serial_from_date")}
                  name="from"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={sq}
                  dateFormat="dd-MM-yyyy"
                  selected={searchFrom.value}
                  onChange={(e) => handleChangeDate(e, "from")}
                  autoComplete="off"
                  className={"date_input" + (error.from ? " input-error" : "")}
                />
                {error.from && <ValidationMessage message={error.from[0]} />}
              </div>
              <div className="form-block col-12 col-sm-6 col-lg-3 pb-3 me-4">
                <DatePicker
                  placeholderText={t("show_serial_to_date")}
                  name="to"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={sq}
                  dateFormat="dd-MM-yyyy"
                  selected={searchTo.value}
                  onChange={(e) => handleChangeDate(e, "to")}
                  autoComplete="off"
                  className={"date_input" + (error.to ? " input-error" : "")}
                />
                {error.to && <ValidationMessage message={error.to[0]} />}
              </div>
              <div>
                {btnLoading === true ? (
                  <button
                    className="button-blue has-icon ml-3 mb-3"
                    type="button"
                  >
                    <i className="icon spinner-icon me-2" />
                    {t("show_serial_searching")}
                  </button>
                ) : (
                  <button
                    className="button-blue has-icon ml-3 mb-3"
                    type="submit"
                    onClick={() => {
                      setActivePage("1");
                      setSearched(true);
                    }}
                  >
                    <i className="icon search-icon me-2" />
                    {t("show_serial_search")}
                  </button>
                )}
              </div>
            </form>
            <div className="table-acc my-5 mt-4">
              <div className="table-header-acc">
                <div className="col-4">{t("show_serial_pattern")}</div>
                <div className="col-4">{t("show_serial_publishing_year")}</div>
                <div className="col-4">{t("show_serial_status")}</div>
              </div>
              <div className="table-list-acc">
                {loading ? (
                  <div className="text-center" colSpan="3">
                    <Loading />
                  </div>
                ) : (
                  approvedSeries.map((item, index) => (
                    <div
                      key={index}
                      className="card no-side-borders accordion"
                      id="acordionSerie"
                    >
                      <div className="accordion-item">
                        <div
                          className="items-centered py-2 accordion-header accordion-collapse collapse show"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          <div
                            className="col-12 col-md-4 pl-0"
                            data-label={t("show_serial_pattern")}
                          >
                            {item.pattern ? item.pattern : "-"}
                          </div>
                          <div
                            className="col-12 col-md-4"
                            data-label={t("show_serial_publishing_year")}
                          >
                            {item.published_date !== null
                              ? moment(item.published_date).format("DD-MM-yyyy")
                              : "-"}
                          </div>
                          <div
                            className="col-12 col-md-4"
                            data-label={t("show_serial_status")}
                          >
                            {item.approved === 1
                              ? t("show_serial_approved")
                              : t("show_serial_not_approved")}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div
                          id="collapseOne"
                          className="series-div accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionSerie"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="image-holder">
                                <img
                                  src={checkCoverSerie(item.cover)}
                                  alt="cover"
                                />
                              </div>
                              {item.items.map((items, index) => (
                                <div className="info-acc">
                                  <div key={index} className="row m-0 mb-3">
                                    <div className="w-100">
                                      <div className="serie-copy-title">
                                        {t("show_serial_copy")} {index + 1}
                                      </div>
                                      <div className="item-attributes-summary p-3">
                                        <div className="col-12 col-md-4">
                                          <div>
                                            {t("show_serial_codification")} :{" "}
                                            {items.inventory_number}
                                          </div>
                                          <div>
                                            {t("show_serial_inventary_number")}{" "}
                                            : {items.id}
                                          </div>
                                          {AppConfig.authRole() ===
                                            "administrator" && (
                                            <Fragment>
                                              <div>
                                                {t("show_serial_place_number")}{" "}
                                                : {items.place_number}
                                              </div>
                                              <div>
                                                {t(
                                                  "show_serial_type_of_borrowing"
                                                )}{" "}
                                                : {items.borrowing_type_trans}
                                              </div>
                                            </Fragment>
                                          )}
                                        </div>
                                        {AppConfig.authRole() ===
                                        "administrator" ? (
                                          <Fragment>
                                            <div className="col-12 col-md-4">
                                              <div className="row m-auto pb-2">
                                                {items.lost === 0 ? (
                                                  <i className="icon close-icon cancel" />
                                                ) : (
                                                  <i className="icon approve-icon approve" />
                                                )}{" "}
                                                {t("show_serial_not_in_stock")}
                                              </div>
                                              <div>
                                                {t(
                                                  "show_serial_date_not_in_stock"
                                                )}
                                                :{" "}
                                                {items.lost_date !== null
                                                  ? moment(
                                                      items.lost_date
                                                    ).format("DD-MM-yyyy")
                                                  : "-"}
                                              </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                              <div className="row m-0 pb-2">
                                                {items.damaged === 0 ? (
                                                  <i className="icon close-icon cancel" />
                                                ) : (
                                                  <i className="icon approve-icon approve" />
                                                )}{" "}
                                                {t("show_serial_damaged")}
                                              </div>
                                              <div>
                                                {t("show_serial_date_damaged")}:{" "}
                                                {items.damaged_date !== null
                                                  ? moment(
                                                      items.damaged_date
                                                    ).format("DD-MM-yyyy")
                                                  : "-"}
                                              </div>
                                              <div>
                                                {t(
                                                  "show_serial_rinovation_date"
                                                )}
                                                :{" "}
                                                {items.renewal_date !== null
                                                  ? moment(
                                                      items.renewal_date
                                                    ).format("DD-MM-yyyy")
                                                  : "-"}
                                              </div>
                                            </div>
                                          </Fragment>
                                        ) : (
                                          <div className="col-12 col-md-4">
                                            <div>
                                              {t("show_serial_place_number")} :{" "}
                                              {items.place_number}
                                            </div>
                                            <div>
                                              {t(
                                                "show_serial_type_of_borrowing"
                                              )}{" "}
                                              : {items.borrowing_type}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        <div className="col-12 pr-0 text-right">
                                          {items.borrowed === 0 &&
                                          items.pending === 0 &&
                                          items.reserved === 0 ? (
                                            <span className="item-available">
                                              {t("show_serial_free")}
                                            </span>
                                          ) : (
                                            ""
                                          )}

                                          {items.borrowed === 1 ? (
                                            <span className="item-borrowed">
                                              {t("show_serial_borrowed")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {items.pending === 1 ? (
                                            <span className="item-pending pl-2 pr-2">
                                              {t(
                                                "show_serial_awaiting_to_be_borrowed"
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {items.reserved === 1 ? (
                                            <span className="item-reserved pl-2 pr-2">
                                              {t("show_serial_reserved")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="text-center no-results" colSpan="3">
                <ListResponse
                  totalItems={pagination && pagination.total}
                  loading={loading}
                  searched={searched}
                />
              </div>
            </div>
            <CustomPagination
              data={pagination}
              setActivePage={(e) => {
                setActivePage(e);
                Object.keys(error).length > 0 && setSearched(false);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShowSerial;
