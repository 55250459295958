import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ValidationMessage from "../../../../../helpers/ValidationMessage";
import Loading from "../../../../../helpers/Loading";
import ListResponse from "../../../../../helpers/ListResponse";
import CustomPagination from "../../../../../helpers/CustomPagination";
import SearchFilter from "../../../../../helpers/SearchFilter";
import EditServer from "./EditServer";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../../helpers/ConfirmPopup";

const ServersList = () => {
  const { t } = useTranslation();

  const [listOfServers, setListOfServers] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);

  //  Add server states
  const [addServerName, setAddServerName] = useState("");
  const [addServerUrl, setAddServerUrl] = useState("");
  const [addServerPort, setAddServerPort] = useState("");
  const [addServerDatabase, setAddServerDatabase] = useState("");
  const [addServerErrors, setAddServerErrors] = useState([]);
  const [addBtnLoading, setAddBtnLoading] = useState(false);

  //  Search server states
  const [searchServer, setSearchServer] = useState("");
  const [searchErrors, setSearchErrors] = useState([]);
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  //  Server modal states
  const [serverId, setServerId] = useState("");
  const [serverModal, setServerModal] = useState(false);
  const setServerVisible = (visible) => {
    setServerModal(visible);
    setServerId("");
  };

  useEffect(() => {
    serverList();
  }, [action, activePage, searched]);

  const serverList = (e) => {
    let urlRequest = ApiUrl.listServer;
    var searchParams = {};

    // If the filter button is clicked
    if (e) {
      setSearchBtnLoading(true);
      e.preventDefault();
    }

    // Change page
    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      // If the filter button is clicked, but we are in the first page
      searchParams = {
        term: searchServer,
      };
    } else if (searched && activePage > 1) {
      // If the filter button is clicked, but we are not in the first page
      searchParams = {
        term: searchServer,
        page: activePage,
      };
    }

    if (action && searchServer.length < 1) {
      searchParams = {
        page: activePage,
      };
    }

    // Url of the request with filter parameters
    urlRequest = SearchFilter(searchParams, urlRequest);

    // the function which return the list of servers
    Request.getRequest(urlRequest).then((response) => {
      setSearchBtnLoading(false);
      if (response.status === 200) {
        setListOfServers(response.data.servers.data);
        setPagination(response.data.servers);
        setLoading(false);
        setAction(false);
        setSearchErrors([]);
      } else if (response.status === 422) {
        setAction(false);
        setSearchErrors(response.data);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const addServerFunc = (e) => {
    e.preventDefault();
    setAddBtnLoading(true);
    var formData = {
      name: addServerName,
      url: addServerUrl,
      port_number: addServerPort,
      database_name: addServerDatabase,
    };
    Request.postRequest(ApiUrl.storeServer, formData).then((response) => {
      setAddBtnLoading(false);
      if (response.status === 201) {
        Message.successMsg(response.data.success);
        setAction(true);
        setAddServerName("");
        setAddServerUrl("");
        setAddServerPort("");
        setAddServerDatabase("");
        setSearchServer("");
        setAddServerErrors([]);
        setSearched(false);
        setActivePage("1");
        setSearchErrors([]);
      } else if (response.status === 422) {
        setAddServerErrors(response.data);
      }
    });
  };

  const deleteServer = (id, title) => {
    ConfirmPopup.deletePopup(title).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteServer + id).then((response) => {
          if (response.status === 200) {
            Message.successMsg(response.data.success);
            setAction(true);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(response.data.error);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("server_list_title")}</p>
      <div className="add-item pt-4 pb-2 px-4 mb-5">
        <form onSubmit={(e) => addServerFunc(e)}>
          <div className="row">
            <label>{t("servers_list_add_server_label")}</label>
            <div className="form-block col-12 col-sm-6 col-md-4 col-xl-3">
              <input
                placeholder={t("servers_list_add_server_name_placeholder")}
                className={
                  "form-input" + (addServerErrors?.name ? " input-error" : "")
                }
                name="name"
                type="text"
                value={addServerName}
                onChange={(e) => setAddServerName(e.target.value)}
              />
              {addServerErrors?.name && (
                <ValidationMessage message={addServerErrors?.name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 col-xl-2">
              <input
                placeholder={t("servers_list_add_url_placeholder")}
                className={
                  "form-input" + (addServerErrors?.url ? " input-error" : "")
                }
                name="url"
                type="text"
                value={addServerUrl}
                onChange={(e) => setAddServerUrl(e.target.value)}
              />
              {addServerErrors?.url && (
                <ValidationMessage message={addServerErrors?.url[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 col-xl-2">
              <input
                placeholder={t("servers_list_add_port_placeholder")}
                className={
                  "form-input" +
                  (addServerErrors?.port_number ? " input-error" : "")
                }
                name="port_number"
                type="number"
                value={addServerPort}
                onChange={(e) => setAddServerPort(e.target.value)}
              />
              {addServerErrors?.port_number && (
                <ValidationMessage message={addServerErrors?.port_number[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 col-xl-2">
              <input
                placeholder={t("servers_list_add_database_placeholder")}
                className={
                  "form-input" +
                  (addServerErrors?.database_name ? " input-error" : "")
                }
                name="database_name"
                type="text"
                value={addServerDatabase}
                onChange={(e) => setAddServerDatabase(e.target.value)}
              />
              {addServerErrors?.database_name && (
                <ValidationMessage
                  message={addServerErrors?.database_name[0]}
                />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl-3 pb-3">
              {addBtnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("servers_list_adding_server_button")}
                </button>
              ) : (
                <button className="button-blue" type="submit">
                  {t("servers_list_add_server_button")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="search-item px-4 mb-5">
        <form onSubmit={(e) => serverList(e)}>
          <div className="row">
            <label>{t("servers_list_search_server_label")}</label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t("servers_list_search_server_placeholder")}
                className={
                  "form-input" + (searchErrors?.term ? " input-error" : "")
                }
                name="server"
                value={searchServer}
                type="text"
                onChange={(e) => setSearchServer(e.target.value)}
              />
              {searchErrors?.term && (
                <ValidationMessage message={searchErrors?.term[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 pb-3">
              {searchBtnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("catalog_listbibiblioitems_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon"
                  type="submit"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("catalog_listbibiblioitems_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" width="25%" className="text-left pl-0">
                {t("servers_list_table_title_server_name")}
              </th>
              <th role="columnheader" width="15%" className="text-left pl-0">
                {t("servers_list_table_title_server_url")}
              </th>
              <th role="columnheader" width="15%" className="text-left pl-0">
                {t("servers_list_table_title_server_port")}
              </th>
              <th role="columnheader" width="15%" className="text-left pl-0">
                {t("servers_list_table_title_server_database")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("servers_list_table_title_edit")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("servers_list_table_title_delete")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <Loading />
                </td>
              </tr>
            ) : (
              listOfServers.map((server, index) => (
                <tr key={index} role="row">
                  <td
                    role="cell"
                    data-label={t("servers_list_table_title_server")}
                  >
                    {server.name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("servers_list_table_title_server")}
                  >
                    {server.url}
                  </td>
                  <td
                    role="cell"
                    data-label={t("servers_list_table_title_server")}
                  >
                    {server.port_number}
                  </td>
                  <td
                    role="cell"
                    data-label={t("servers_list_table_title_server")}
                  >
                    {server.database_name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("servers_list_table_title_edit")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={() => {
                        setServerModal(true);
                        setServerId(server.id);
                      }}
                    >
                      <i className="icon edit-icon" />
                    </button>
                    {serverModal && serverId === server.id && (
                      <EditServer
                        onShowModal={serverModal}
                        setServerVisible={setServerVisible}
                        setModified={setAction}
                        serverId={server.id}
                        serverName={server.name}
                        serverUrl={server.url}
                        serverPort={server.port_number}
                        serverDatabase={server.database_name}
                      />
                    )}
                  </td>
                  <td
                    role="cell"
                    data-label={t("servers_list_table_title_delete")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={() => {
                        deleteServer(server.id, server.name);
                      }}
                    >
                      <i className="icon delete-icon" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </Fragment>
  );
};

export default ServersList;
