import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { BibliosList } from "../../../../../store/Catalog/EditBibliography/BiblioList";
import { Bibliography } from "../../../../../store/Catalog/EditBibliography/Bibliography";
import { MediaTypes } from "../../../../../store/Catalog/EditBibliography/MediaTypes";
import { Language } from "../../../../../store/Catalog/EditBibliography/Language";
import { MaterialTypes } from "../../../../../store/Catalog/EditBibliography/MaterialTypes";
import { CheckAuthors } from "../../../../../helpers/CheckValue";

import Downshift from "downshift";
import ReactSelect from "react-select";
import AuthorModal from "./Modals/AuthorModal";
import KeywordsModal from "./Modals/KeywordsModal";
import GeoTermsModal from "./Modals/GeoTermsModal";
import ValidationMessage from "../../../../../helpers/ValidationMessage";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api//ApiUrl";

const EditBiblio = ({ errorList }) => {
  const { t } = useTranslation("");

  const bibliosList = BibliosList((state) => state.bibliosList);
  const addBibliographyUpdate = Bibliography(
    (state) => state.addBibliographyUpdate
  );

  const bibliographyData = Bibliography((state) => state.bibliographyData);
  const materialTypes = MaterialTypes((state) => state.materials);
  const languages = Language((state) => state.languages);
  const mediaTypes = MediaTypes((state) => state.medias);

  const [searchedBiblios, setSearchedBiblios] = useState([]);

  const [oldBiblioData] = useState(bibliographyData);
  const [selectedNewBiblio, setSelectedNewBiblio] = useState(false);

  // Author modal
  const [authorModal, setAuthorModal] = useState(false);

  // Keyword modal
  const [keywordModal, setKeywordModal] = useState(false);

  // Geoterm modal
  const [geotermModal, setGeotermModal] = useState(false);

  const filterTitleList = (value) => {
    addBibliographyUpdate({ ...bibliographyData, title: value });
    if (value.length > 2) {
      setSearchedBiblios(
        bibliosList.filter((biblio) =>
          biblio.title
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(
              value
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            )
        )
      );
    }
  };

  const downshiftOnChange = (biblioId) => {
    let request = ApiUrl.selectedBiblio + biblioId;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        addBibliographyUpdate({
          ...bibliographyData,
          title: response?.data?.biblio?.title
            ? response.data.biblio.title
            : "",
          authors: response?.data?.biblio?.authors
            ? response.data.biblio.authors
            : [],
          medium: response?.data?.biblio?.medium
            ? response.data.biblio.medium
            : "",
          subtitle: response?.data?.biblio?.subtitle
            ? response.data.biblio.subtitle
            : "",
          materialtype_id: response?.data?.biblio?.materialtype
            ? response.data.biblio.materialtype
            : "",
          part_name: response?.data?.biblio?.part_name
            ? response.data.biblio.part_name
            : "",
          part_number: response?.data?.biblio?.part_number
            ? response.data.biblio.part_number
            : "",
          biblio_language_id: response?.data?.biblio?.language
            ? response.data.biblio.language
            : "",
          agerestriction: response?.data?.biblio?.agerestriction
            ? response.data.biblio.agerestriction
            : "",
          year_original_publication: response?.data?.biblio
            ?.year_original_publication
            ? response.data.biblio.year_original_publication
            : "",
          mediatype_id: response?.data?.biblio?.mediatype
            ? response.data.biblio.mediatype
            : "",
          keywords: response?.data?.biblio?.keywords
            ? response.data.biblio.keywords
            : [],
          geoterms: response?.data?.biblio?.geographicalterms
            ? response.data.biblio.geographicalterms
            : [],
          notes: response?.data?.biblio?.notes
            ? response.data.biblio.notes
            : "",
          abstract: response?.data?.biblio?.abstract
            ? response.data.biblio.abstract
            : "",
        });
        setSelectedNewBiblio(true);
      }
    });
  };

  const clearTitle = () => {
    addBibliographyUpdate(oldBiblioData);
    setSelectedNewBiblio(false);
  };

  const removeAuthor = (author) => {
    const removeIndex = bibliographyData?.authors.findIndex(
      (item) => item.id === author.id
    );
    bibliographyData?.authors.splice(removeIndex, 1);
    addBibliographyUpdate({
      ...bibliographyData,
      authors: bibliographyData?.authors,
    });
  };

  const removeKeyword = (keyword) => {
    const removeIndex = bibliographyData?.keywords.findIndex(
      (item) => item.id === keyword.id
    );
    bibliographyData?.keywords.splice(removeIndex, 1);
    addBibliographyUpdate({
      ...bibliographyData,
      keywords: bibliographyData?.keywords,
    });
  };

  const removeGeoterm = (geoterm) => {
    const removeIndex = bibliographyData?.geoterms.findIndex(
      (item) => item.id === geoterm.id
    );
    bibliographyData?.geoterms.splice(removeIndex, 1);
    addBibliographyUpdate({
      ...bibliographyData,
      geoterms: bibliographyData?.geoterms,
    });
  };

  return (
    <Fragment>
      <Downshift
        onChange={(selection) => {
          downshiftOnChange(selection.id);
        }}
        itemToString={(item) => item}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          selectedItem,
        }) => (
          <div className="form-block mb-5">
            <label>
              {t("edit_bibliography_title")}
              <span className="biblioitem-tag">(245/A)</span>
            </label>
            <div className="clear-title">
              <input
                {...getInputProps({
                  value: bibliographyData.title,
                  onChange: (e) => {
                    filterTitleList(e.target.value);
                  },
                })}
                className={
                  "form-input" + (errorList.title ? " input-error" : "")
                }
                placeholder={t("edit_bibliography_title_placeholder")}
              />
              {selectedNewBiblio && (
                <i
                  className="icon close-red-icon"
                  onClick={() => clearTitle()}
                />
              )}
            </div>
            <div {...getMenuProps()}>
              {isOpen &&
              bibliographyData.title.length > 2 &&
              searchedBiblios.length > 0 ? (
                <div className="downshift-list">
                  {searchedBiblios.slice(0, 5).map((item, index) => (
                    <div
                      {...getItemProps({
                        key: index,
                        index,
                        item,
                        className:
                          selectedItem?.id === item?.id
                            ? "downshift-item selected"
                            : "downshift-item",
                      })}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            {errorList.title && (
              <ValidationMessage message={errorList.title[0]} />
            )}
          </div>
        )}
      </Downshift>
      <div className="form-block mb-5">
        <div className="d-flex align-items-center">
          <button
            className="button-gray-blue"
            onClick={() => setAuthorModal(true)}
            type="button"
          >
            <i className="icon plus-icon me-2" />
            {t("edit_bibliography_add_author")}
          </button>
          <label className="mb-0">
            <span className="biblioitem-tag">(100/A)</span>
          </label>
        </div>
        <div className="form-input tags-container w-100 p-3 mt-3">
          {bibliographyData?.authors?.length > 0 ? (
            bibliographyData.authors.map((author, index) => (
              <span key={index} className="tag">
                {`${author.name} / ${CheckAuthors(author.pivot.type)}`}
                <button
                  className="button-unstyled icon close-red-icon"
                  type="button"
                  onClick={() => removeAuthor(author)}
                />
              </span>
            ))
          ) : (
            <span className="empty-span">
              {t("edit_bibliography_add_author_placeholder")}
            </span>
          )}
        </div>
        {authorModal && (
          <AuthorModal
            onShowModal={authorModal}
            selectedAuthors={bibliographyData?.authors}
            setAuthorVisible={(e) => setAuthorModal(e)}
          />
        )}
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_medium")}
            <span className="biblioitem-tag">(245/H)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_medium_placeholder")}
            className={"form-input" + (errorList.medium ? " input-error" : "")}
            name="medium"
            value={bibliographyData.medium}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                medium: e.target.value,
              })
            }
          />
          {errorList.medium && (
            <ValidationMessage message={errorList.medium[0]} />
          )}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_subtitle")}
            <span className="biblioitem-tag">(245/B)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_subtitle_placeholder")}
            className={
              "form-input" + (errorList.subtitle ? " input-error" : "")
            }
            name="subtitle"
            value={bibliographyData.subtitle}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                subtitle: e.target.value,
              })
            }
          />
          {errorList.subtitle && (
            <ValidationMessage message={errorList.subtitle[0]} />
          )}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>{t("edit_bibliography_material_type")}</label>
          <ReactSelect
            className={
              "form-react-select" +
              (errorList.materialtype_id ? " input-error" : "")
            }
            classNamePrefix="select_input"
            name="material_type"
            placeholder={t("edit_bibliography_material_type_placeholder")}
            isClearable={true}
            value={
              bibliographyData?.materialtype_id
                ? {
                    value: bibliographyData.materialtype_id.id,
                    label: bibliographyData.materialtype_id.name,
                  }
                : ""
            }
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                materialtype_id: { id: e.value, name: e.label },
              })
            }
            isSearchable={false}
            options={materialTypes.map((item) => ({
              value: item.id,
              label: item.name_trans,
            }))}
          />
          {errorList.materialtype_id && (
            <ValidationMessage message={errorList.materialtype_id[0]} />
          )}
        </div>
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_part_name")}
            <span className="biblioitem-tag">(245/N)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_part_name_placeholder")}
            className={
              "form-input" + (errorList.part_name ? " input-error" : "")
            }
            name="part_name"
            value={bibliographyData?.part_name}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                part_name: e.target.value,
              })
            }
          />
          {errorList.part_name && (
            <ValidationMessage message={errorList.part_name[0]} />
          )}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_part_number")}
            <span className="biblioitem-tag">(245/P)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_part_number_placeholder")}
            className={
              "form-input" + (errorList.part_number ? " input-error" : "")
            }
            name="part_number"
            value={bibliographyData?.part_number}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                part_number: e.target.value,
              })
            }
          />
          {errorList.part_number && (
            <ValidationMessage message={errorList.part_number[0]} />
          )}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_original_lanugage")}
            <span className="biblioitem-tag">(041/A)</span>
          </label>
          <ReactSelect
            className={
              "form-react-select" +
              (errorList.biblio_language_id ? " input-error" : "")
            }
            classNamePrefix="select_input"
            name="biblio_language_id"
            placeholder={t("edit_bibliography_original_lanugage_placeholder")}
            isClearable={true}
            isSearchable={false}
            value={
              bibliographyData?.biblio_language_id
                ? {
                    value: bibliographyData.biblio_language_id.id,
                    label: bibliographyData.biblio_language_id.name,
                  }
                : ""
            }
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                biblio_language_id: { id: e.value, name: e.label },
              })
            }
            options={languages.map((item) => ({
              value: item.id,
              label: item.name_trans,
            }))}
          />
          {errorList.biblio_language_id && (
            <ValidationMessage message={errorList.biblio_language_id[0]} />
          )}
        </div>
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_agerestriction")}
            <span className="biblioitem-tag">(521/A)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_agerestriction_placeholder")}
            className={
              "form-input" + (errorList.agerestriction ? " input-error" : "")
            }
            name="agerestriction"
            value={bibliographyData?.agerestriction}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                agerestriction: e.target.value,
              })
            }
          />
          {errorList.agerestriction && (
            <ValidationMessage message={errorList.agerestriction[0]} />
          )}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>{t("edit_bibliography_year_original_publication")}</label>
          <input
            type="text"
            placeholder={t(
              "edit_bibliography_year_original_publication_placeholder"
            )}
            className={
              "form-input" +
              (errorList.year_original_publication ? " input-error" : "")
            }
            name="year_original_publication"
            value={bibliographyData?.year_original_publication}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                year_original_publication: e.target.value,
              })
            }
          />
          {errorList.year_original_publication && (
            <ValidationMessage
              message={errorList.year_original_publication[0]}
            />
          )}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>{t("edit_bibliography_media_type")}</label>
          <ReactSelect
            className={
              "form-react-select" +
              (errorList.mediatype_id ? " input-error" : "")
            }
            classNamePrefix="select_input"
            name="media_type"
            placeholder={t("edit_bibliography_media_type_placeholder")}
            isClearable={true}
            isSearchable={false}
            value={
              bibliographyData?.mediatype_id
                ? {
                    value: bibliographyData.mediatype_id.id,
                    label: bibliographyData.mediatype_id.name,
                  }
                : ""
            }
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                media_type: { id: e.value, name: e.label },
              })
            }
            options={mediaTypes.map((item) => ({
              value: item.id,
              label: item.name_trans,
            }))}
          />
          {errorList.mediatype_id && (
            <ValidationMessage message={errorList.mediatype_id[0]} />
          )}
        </div>
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-md-6 mb-5">
          <div className="d-flex align-items-center">
            <button
              className="button-gray-blue"
              onClick={() => setKeywordModal(true)}
              type="button"
            >
              <i className="icon plus-icon me-2" />
              {t("edit_bibliography_add_keywords")}
            </button>
            <label className="mb-0">
              <span className="biblioitem-tag">(650/A)</span>
            </label>
          </div>
          <div className="form-input tags-container w-100 p-3 mt-3">
            {bibliographyData?.keywords.length > 0 ? (
              bibliographyData.keywords.map((keyword, index) => (
                <span key={index} className="tag">
                  {keyword.name}
                  <button
                    className="button-unstyled icon close-red-icon"
                    onClick={() => removeKeyword(keyword)}
                    type="button"
                  />
                </span>
              ))
            ) : (
              <span className="empty-span">
                {t("edit_bibliography_add_keywords_placeholder")}
              </span>
            )}
          </div>
          {keywordModal && (
            <KeywordsModal
              onShowModal={keywordModal}
              selectedKeywords={bibliographyData?.keywords}
              setKeywordVisible={(e) => setKeywordModal(e)}
            />
          )}
        </div>
        <div className="col-12 col-md-6 mb-5">
          <div className="d-flex align-items-center">
            <button
              className="button-gray-blue"
              onClick={() => setGeotermModal(true)}
              type="button"
            >
              <i className="icon plus-icon me-2" />
              {t("edit_bibliography_geographical_terms")}
            </button>
            <label className="mb-0">
              <span className="biblioitem-tag">(651/A)</span>
            </label>
          </div>
          <div className="form-input tags-container w-100 p-3 mt-3">
            {bibliographyData?.geoterms.length > 0 ? (
              bibliographyData.geoterms.map((geoterm, index) => (
                <span key={index} className="tag">
                  {geoterm.name}
                  <button
                    className="button-unstyled icon close-red-icon"
                    onClick={() => removeGeoterm(geoterm)}
                    type="button"
                  />
                </span>
              ))
            ) : (
              <span className="empty-span">
                {t("edit_bibliography_geographical_terms_placeholder")}
              </span>
            )}
            {geotermModal && (
              <GeoTermsModal
                onShowModal={geotermModal}
                selectedGeoterms={bibliographyData?.geoterms}
                setGeotermVisible={(e) => setGeotermModal(e)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-md-6 mb-4">
          <label>{t("edit_bibliography_abstract")}</label>
          <textarea
            placeholder={t("edit_bibliography_abstract_placeholder")}
            className={
              "form-input" + (errorList.abstract ? " input-error" : "")
            }
            rows="3"
            name="abstract"
            value={bibliographyData?.abstract}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                abstract: e.target.value,
              })
            }
          />
          {errorList.abstract && (
            <ValidationMessage message={errorList.abstract[0]} />
          )}
        </div>
        <div className="col-12 col-md-6 mb-4">
          <label>{t("edit_bibliography_notes")}</label>
          <textarea
            placeholder={t("edit_bibliography_notes_placeholder")}
            className={"form-input" + (errorList.notes ? " input-error" : "")}
            rows="3"
            name="notes"
            value={bibliographyData?.notes}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                notes: e.target.value,
              })
            }
          />
          {errorList.notes && (
            <ValidationMessage message={errorList.notes[0]} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default EditBiblio;
