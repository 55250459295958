import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import Borrow from "../../../components/panel/borrowing/Borrow";

const BorrowView = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>{t("borrow_list_views")}</title>
      </Helmet>
      <Sidebar>
        <Borrow />
      </Sidebar>
    </Fragment>
  );
};

export default BorrowView;
