import { Fragment } from "react";
import ReactTooltip from "react-tooltip";

export const tooltipDisabledMobile = (index, title) => {
  let screenWidth = window.innerWidth;
  let titleLength = title.length;

  if (screenWidth < 768) {
    return (
      <div
        className={"title-tooltip-" + index}
        id={"tooltip" + index}
        style={{ width: "100%" }}
      >
        {title}
      </div>
    );
  } else if (screenWidth > 767 && titleLength >= 18) {
    return (
      <div
        className={"title-tooltip-" + index}
        id={"tooltip" + index}
        data-tip
        data-for={"toolTip" + index}
        style={{ width: "fit-content" }}
      >
        {title.slice(0, 15) + "..."}
        <ReactTooltip id={"toolTip" + index} place="top" effect="solid">
          {title}
        </ReactTooltip>
      </div>
    );
  } else if (screenWidth > 767 && titleLength < 18) {
    return <div className={"title-tooltip-" + index}>{title}</div>;
  }
};

export const tooltip = (index, title, text) => {
  return (
    <div id={"tooltip" + index} data-tip data-for={"toolTip" + index}>
      {title}
      <ReactTooltip id={"toolTip" + index} place="top" effect="solid">
        {text}
      </ReactTooltip>
    </div>
  );
};

export const disabledTooltipBtn = (id, title, text) => {
  return (
    <Fragment>
      <div
        className="button-blue"
        id={id}
        data-tip
        data-for={id}
        style={{ width: "fit-content", cursor: "pointer" }}
      >
        {title}
      </div>
      <ReactTooltip id={id} place="top" effect="solid">
        {text}
      </ReactTooltip>
    </Fragment>
  );
};
