import { Fragment } from "react";
import { useSidebarStatus } from "../../../store/SidebarStatus";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import SidebarContent from "./SidebarContent";

const Sidebar = ({ children }) => {
  const sidebarStatus = useSidebarStatus((state) => state.status);
  return (
    <Fragment>
      <Header />
      <div className={`vertical-menu ${sidebarStatus ? "open" : ""}`}>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
      </div>
      <div className="main-content">
        <div className="page-content">{children}</div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default Sidebar;
