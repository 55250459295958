export const nextDomain = process.env.REACT_APP_NEXT_BASE_URL;

/* OPAC */
export const homepage = nextDomain;
export const nextProfile = nextDomain + "profile";

export const login = "/";
export const forgotPassword = "/forgotpassword";
export const resetPasswordParam = "/resetpassword/:token";
export const ativateAccountParam = "/user/registration/:token";
export const university = "/university";
export const about = "/about";
export const privacyPolicies = "/privacy-policies";
export const help = "/help";
export const contact = "/contact";
export const news = "/news";
export const newsPostTitle = "/news/:title";
export const newsPost = "/news/";
export const search = "/search";
export const detailedID = "/detailed/:id";
export const detailed = "/detailed/";
export const advanced_search = "/advanced-search";
export const most_read_books = "/most-read-books";
export const latest_books = "/latest-books";
export const digitized_books = "/digitized-books";

/* Opac */

/* Opac Panel */
export const opacProfile = "/profile";
export const opacChangePassword = "/change-password";
/* Opac Panel */

/* Dashboard */
export const dashboard = "/dashboard";

/* Catalog */
export const listBiblioitems = "/catalog/listbiblioitems";
export const showBiblioitemID = "/catalog/listbiblioitems/showbiblioitem/:id";
export const showBiblioitem = "/catalog/listbiblioitems/showbiblioitem/";
export const editBiblioitemID = "/catalog/listbiblioitems/editbiblioitem/:id";
export const editBiblioitem = "/catalog/listbiblioitems/editbiblioitem/";

export const createBiblio = "/catalog/bibliography";
export const editBiblioIdBiblioitem =
  "/catalog/bibliography/editbiblio/:id/:biblioitem_id";
export const editBiblioID = "/catalog/bibliography/editbiblio/:id";
export const editBiblio = "/catalog/bibliography/editbiblio/";
export const createBiblioItemID = "/catalog/bibliography/createbiblioitem/:id";
export const createBiblioItem = "/catalog/bibliography/createbiblioitem/";
export const editBiblioitemonlyID =
  "/catalog/bibliography/editbiblioitemonly/:biblio_id/:id";
export const editBiblioitemonly = "/catalog/bibliography/editbiblioitemonly/";
export const createItemID = "/catalog/bibliography/createitem/:id";
export const createItem = "/catalog/bibliography/createitem/";
export const editItemsID = "/catalog/bibliography/edititems/:id";
export const editItems = "/catalog/bibliography/edititems/";

export const importMarc = "/catalog/uploadmarcfile";
export const confirmMarc = "/catalog/uploadmarcfile/confirmmarcrecords";
export const exportMarc = "/catalog/exportmarc";

export const z3950Service = "/catalog/z3950/service";
export const confirmZ3950 = "/catalog/z3950/service/confirmrecords";
export const z3950Server = "/catalog/z3950/server";

export const createAuthor = "/catalog/author";
export const editAuthorId = "/catalog/editauthor/:id";
export const editAuthor = "/catalog/editauthor/";

export const createKeyWords = "/catalog/keyword";
export const editKeywordId = "/catalog/editkeyword/:id";
export const editKeyword = "/catalog/editkeyword/";

export const createPublisher = "/catalog/publisher";

export const createGeogreaphicalTerm = "/catalog/geographicalterm";
/* Catalog */

/* Borrow */
export const borrow = "/borrowing/index";
export const searchMemberID = "/borrowing/index/item/:id/member";
export const searchMember = "/borrowing/index/item/";
export const searchMaterialID = "/borrowing/index/member/:id/item";
export const searchMaterial = "/borrowing/index/member/";

export const confirmBorrowId =
  "/borrowing/index/member/:member_id/item/:item_id";
export const confirmBorrow = "/borrowing/index/member/";

export const listBorrowings = "/borrowing/list";
export const borrowingDetailsID = "/borrowing/list/:id";
export const borrowingDetails = "/borrowing/list/";
export const listPending = "/borrowing/listpending";
export const reservations = "/borrowing/reservationlist";
/* Borrow */

/* Serial */
export const createSerial = "/serial/create";
export const listSerials = "/serial/listserials";
export const showSerialID = "/serial/listserials/show/:id";
export const showSerial = "/serial/listserials/show/";
export const editSerialID = "/serial/listserials/edit/:id";
export const editSerial = "/serial/listserials/edit/";
export const frequence = "/serial/frequence";
/* Serial */

/* Members */
export const listMembers = "/member/list";
export const showMemberID = "/member/list/show/:id";
export const showMember = "/member/list/show/";
export const editMemberID = "/member/list/edit/:id";
export const editMember = "/member/list/edit/";
export const addMember = "/member/invite";
/* Members */

/* Staff */
export const listStaff = "/staff/list";
export const showStaffID = "/staff/list/show/:id";
export const showStaff = "/staff/list/show/";
export const editStaffID = "/staff/list/edit/:id";
export const editStaff = "/staff/list/edit/";
export const addStaff = "/staff/invite";
/* Staff */
export const editUserID = "/user/edit/:id";
export const editUser = "/user/edit/";

/* Acquisition */
export const listAcquisition = "/acquisition/list";
export const editAcquisitionId = "/acquisition/list/edit/:id";
export const editAcquisition = "/acquisition/list/edit/";
export const showAcquisitionId = "/acquisition/list/show/:id";
export const showAcquisition = "/acquisition/list/show/";
export const createAcquisition = "/acquisition/create";
export const aproveAcquisition = "/acquisition/aprove";
export const cancelAcquisition = "/acquisition/create";
/* Acquisition */

/* Report */
export const reportStats = "/report/stats";
export const reportCatalog = "/report/catalog";
export const reportBorrowing = "/report/borrowing";
/* Report */

/* Backup */
export const listBackup = "/list/backup";
export const logs = "/logs";
/* Backup */

/* Blog */
export const listBlogPosts = "/blog/list";
export const editBlogPostId = "/blog/list/edit/:id";
export const editBlogPost = "/blog/list/edit/";
export const createBlogPost = "/blog/create";
/* Blog */

/* Change Password */
export const changePassword = "/changepassword";
/* Change Password */

// Error 404
export const pageError404 = "*";
