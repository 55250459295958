import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import error from "../../assets/images/error-img.png";

const Pages404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="error-404 container-fluid small-offset-left">
      <div className="logo">
        {/* <img src="/static/media/logo-uniel.18df3860.svg" /> */}
      </div>
      <div>
        <h1>
          4<span>0</span>4
        </h1>
        <h2>{t("page_not_found_title")}</h2>
        <p>{t("page_not_found_text")}</p>
      </div>
      <button className="button-blue" onClick={() => navigate(-1)}>
        {t("page_not_found_go_back_button")}
      </button>
    </div>
  );
};

export default Pages404;
