import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationAl from "./al/translation.json";
import translationEn from "./en/translation.json";

// The translations for the english and albanian language
const resources = {
  al: {
    translation: translationAl,
  },
  en: {
    translation: translationEn,
  },
};

const language = localStorage.getItem("i18nextLng");
if (!language) {
  localStorage.setItem("i18nextLng", "en");
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng") || "en",
    fallbackLng: "en",

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
