import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchFilter from "../../../../helpers/SearchFilter";
import ListResponse from "../../../../helpers/ListResponse";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Tooltip from "../../../../helpers/Tooltip";

const InLibrary = () => {
  const { t } = useTranslation();

  const [listBiblioitem, setListBiblioitem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnAddLoading, setBtnAddLoading] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listBiblioitems();
  }, [activePage]);

  const listBiblioitems = () => {
    let request = ApiUrl.reportInLibrary;

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
      request = SearchFilter(searchParams, request);
    }

    Request.getRequest(request).then((result) => {
      if (result.status === 200) {
        setListBiblioitem(result.data.inlibrary.data);
        setLoading(false);
        setPagination(result.data.inlibrary);
      } else {
        setListBiblioitem([]);
        setLoading(true);
      }
    });
  };

  const download = (e) => {
    e.preventDefault();
    setBtnAddLoading(true);
    let request = ApiUrl.reportExportInLibrary;

    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      setBtnAddLoading(false);
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "Report_InLibrary.xlsx");
      link.click();
    });
  };

  return (
    <Fragment>
      <div className="ml-1 mt-4 pt-3 mb-4 pb-3">
        {btnAddLoading ? (
          <button className="button-blue has-icon" type="button">
            <i className="icon spinner-icon me-2" />
            {t("inlibrary_exporting")}
          </button>
        ) : (
          <button
            className="button-blue has-icon"
            type="button"
            onClick={(e) => download(e)}
          >
            <i className="icon download-icon me-2" />
            {t("inlibrary_export")}
          </button>
        )}
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th width="25%" role="columnheader" className="text-left">
                {t("inlibrary_title")}
              </th>
              <th width="25%" role="columnheader" className="text-center">
                {t("inlibrary_author")}
              </th>
              <th width="15%" role="columnheader" className="text-center">
                {t("inlibrary_country_number")}
              </th>
              <th width="20%" role="columnheader" className="text-center">
                {t("inlibrary_inventary_number")}
              </th>
              <th width="15%" role="columnheader" className="text-center">
                {t("inlibrary_borrowing_type")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loading />
                </td>
              </tr>
            ) : (
              listBiblioitem.map((item, index) => (
                <tr key={item.id}>
                  <td
                    role="cell"
                    data-label={t("inlibrary_title")}
                    className="title-borrowing"
                  >
                    {Tooltip.tooltipDisabledMobile(
                      "-title-" + index,
                      item.biblioitem.biblio.title
                    )}
                  </td>
                  <td
                    role="cell"
                    data-label={t("inlibrary_author")}
                    className="text-center"
                  >
                    {item.biblioitem.biblio["authors"].map(
                      (item, index, arr) => {
                        if (index === arr.length - 1) {
                          return item["name"];
                        } else {
                          return item["name"] + "; ";
                        }
                      }
                    )}
                  </td>
                  <td
                    role="cell"
                    data-label={t("inlibrary_country_number")}
                    className="text-center"
                  >
                    {item.place_number}
                  </td>
                  <td
                    role="cell"
                    data-label={t("inlibrary_inventary_number")}
                    className=" text-center"
                  >
                    {item.inventory_number + " / " + item.id}
                  </td>
                  <td
                    role="cell"
                    data-label={t("inlibrary_borrowing_type")}
                    className="text-center"
                  >
                    {item.borrowing_type_trans}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </Fragment>
  );
};

export default InLibrary;
