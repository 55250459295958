import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import Loading from "../../../../helpers/Loading";
import ListResponse from "../../../../helpers/ListResponse";
import CustomPagination from "../../../../helpers/CustomPagination";
import SearchFilter from "../../../../helpers/SearchFilter";
import EditPublisher from "./EditPublisher";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const PublishersList = () => {
  const { t } = useTranslation();

  const [listOfPublishers, setListOfPublishers] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);

  //  Add publisher states
  const [addPublisherName, setAddPublisherName] = useState("");
  const [addPublisherErrors, setAddPublisherErrors] = useState([]);
  const [addBtnLoading, setAddBtnLoading] = useState(false);

  //  Search publisher states
  const [searchPublisherName, setSearchPublisherName] = useState("");
  const [searchErrors, setSearchErrors] = useState({});
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  //  Publisher modal states
  const [publisherId, setPublisherId] = useState("");
  const [publisherModal, setPublisherModal] = useState(false);
  const setPublisherVisible = (visible) => {
    setPublisherModal(visible);
    setPublisherId("");
  };

  useEffect(() => {
    publishersList();
  }, [action, activePage, searched]);

  const publishersList = (e) => {
    let urlRequest = ApiUrl.listPublishers;
    var searchParams = {};

    // If the filter button is clicked
    if (e) {
      setSearchBtnLoading(true);
      e.preventDefault();
    }

    // Change page
    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      // If the filter button is clicked, but we are in the first page
      searchParams = {
        publisher_name: searchPublisherName,
      };
    } else if (searched && activePage > 1) {
      // If the filter button is clicked, but we are not in the first page
      searchParams = {
        publisher_name: searchPublisherName,
        page: activePage,
      };
    }

    if (action && searchPublisherName.length < 1) {
      searchParams = {
        page: activePage,
      };
    }

    // Url of the request with filter parameters
    urlRequest = SearchFilter(searchParams, urlRequest);

    // the function which return the list of publishers
    Request.getRequest(urlRequest).then((response) => {
      setSearchBtnLoading(false);
      if (response.status === 200) {
        setListOfPublishers(response.data.publishers.data);
        setPagination(response.data.publishers);
        setLoading(false);
        setAction(false);
        setSearchErrors({});
      } else if (response.status === 422) {
        setAction(false);
        setSearchErrors(response.data);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const addPublisher = (e) => {
    e.preventDefault();
    setAddBtnLoading(true);
    Request.postRequest(ApiUrl.storePublisher, { name: addPublisherName }).then(
      (response) => {
        setAddBtnLoading(false);
        if (response.status === 201) {
          Message.successMsg(response.data.success);
          setAction(true);
          setAddPublisherName("");
          setSearchPublisherName("");
          setAddPublisherErrors([]);
          setSearched(false);
          setActivePage("1");
          setSearchErrors([]);
        } else if (response.status === 422) {
          setAddPublisherErrors(response.data);
        }
      }
    );
  };

  const deletePublisher = (id, title) => {
    ConfirmPopup.deletePopup(title).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deletePublisher + id).then((response) => {
          if (response.status === 200) {
            Message.successMsg(response.data.success);
            setAction(true);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(response.data.error);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("publisher_list_title")}</p>
      <div className="add-item pt-4 pb-2 px-4 mb-5">
        <form onSubmit={(e) => addPublisher(e)}>
          <div className="row">
            <label>{t("publishers_list_add_publisher_label")}</label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t("publishers_list_add_publisher_placeholder")}
                className={
                  "form-input" +
                  (addPublisherErrors?.name ? " input-error" : "")
                }
                name="name"
                type="text"
                value={addPublisherName}
                onChange={(e) => setAddPublisherName(e.target.value)}
              />
              {addPublisherErrors?.name && (
                <ValidationMessage message={addPublisherErrors?.name[0]} />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-4 pb-3">
              {addBtnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("publishers_list_adding_publisher_button")}
                </button>
              ) : (
                <button className="button-blue" type="submit">
                  {t("publishers_list_add_publisher_button")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="search-item px-4 mb-5">
        <form onSubmit={(e) => publishersList(e)}>
          <div className="row">
            <label>{t("publishers_list_search_publisher_label")}</label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t("publishers_list_search_publisher_placeholder")}
                className={
                  "form-input" +
                  (searchErrors?.publisher_name ? " input-error" : "")
                }
                name="publisher_name"
                value={searchPublisherName}
                type="text"
                onChange={(e) => setSearchPublisherName(e.target.value)}
              />
              {searchErrors?.publisher_name && (
                <ValidationMessage message={searchErrors?.publisher_name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 pb-3">
              {searchBtnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("catalog_listbibiblioitems_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon"
                  type="submit"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("catalog_listbibiblioitems_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" width="70%" className="text-left pl-0">
                {t("publishers_list_table_title_publisher")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("publishers_list_table_title_edit")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("publishers_list_table_title_delete")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3" className="text-center">
                  <Loading />
                </td>
              </tr>
            ) : (
              listOfPublishers.map((publisher, index) => (
                <tr key={index} role="row">
                  <td
                    role="cell"
                    data-label={t("publishers_list_table_title_publisher")}
                  >
                    {publisher.name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("publishers_list_table_title_edit")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={() => {
                        setPublisherModal(true);
                        setPublisherId(publisher.id);
                      }}
                    >
                      <i className="icon edit-icon" />
                    </button>
                    {publisherModal && publisherId === publisher.id && (
                      <EditPublisher
                        onShowModal={publisherModal}
                        setPublisherVisible={setPublisherVisible}
                        setModified={setAction}
                        publisherId={publisher.id}
                        publisherName={publisher.name}
                      />
                    )}
                  </td>
                  <td
                    role="cell"
                    data-label={t("publishers_list_table_title_delete")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={() => {
                        deletePublisher(publisher.id, publisher.name);
                      }}
                    >
                      <i className="icon delete-icon" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(searchErrors).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default PublishersList;
