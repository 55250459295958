import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Borrowings from "./Borrowings";
import Reservations from "./Reservations";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as Tooltip from "../../../helpers/Tooltip";
import * as AppConfig from "../../../helpers/AppConfig";

const ShowMember = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [details, setDetails] = useState("");
  const [role, setRole] = useState("");
  const [tab, setTab] = useState("borrowings");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    memberDetails();
  }, []);

  const memberDetails = () => {
    const memberID = param.id;

    let request = ApiUrl.showMember + memberID;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setDetails(response.data.member);
        setRole(response.data.member.role);
        setLoading(false);
      } else if (response.status === 404) {
        navigate(Path.listMembers);
        Message.successMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const borrowingsTab = (e) => {
    e.preventDefault();
    setTab("borrowings");
  };

  const reservationsTab = (e) => {
    e.preventDefault();
    setTab("reservations");
  };

  const userGender = (param) => {
    switch (param) {
      case "M":
        return t("male");
      case "F":
        return t("female");
      default:
        return "-";
    }
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("show_member_details")}</p>
      <div className="member-data row mb-5">
        <div className="list-data col-12 col-md-8 d-flex">
          <ul>
            <li className="member-detail">
              {t("show_member_name_lastname_role")} :{" "}
              {(details.first_name ? details.first_name : "-") +
                " " +
                (details.last_name ? details.last_name : "-") +
                " / " +
                (role.name_trans ? role.name_trans : "-")}
            </li>
            <li>
              {t("show_member_email")} : {details.email ? details.email : "-"}
            </li>
            <li>
              {t("show_member_mobile")} : {details.phone ? details.phone : "-"}
            </li>
            <li>
              {t("show_member_birthday")} :{" "}
              {details.dob ? moment(details.dob).format("DD-MM-yyy") : "-"}
            </li>
            <li>
              {t("show_member_gender")} :{" "}
              {userGender(details.gender ? details.gender : "-")}
            </li>
            <li>
              {t("show_member_id_number")} : {details.nid ? details.nid : "-"}
            </li>
            <li>
              {t("show_member_state_city")} :{" "}
              {(details.state ? details.state : "-") +
                " / " +
                (details.city ? details.city : "-")}
            </li>
            <li>
              {t("show_member_address_postal_code")} :{" "}
              {(details.address ? details.address : "-") +
                " / " +
                (details.postal_code ? details.postal_code : "-")}
            </li>
            {details.role_id === 4 && (
              <Fragment>
                <li>
                  {t("show_member_faculty")} :{" "}
                  {details.faculty ? details.faculty : "-"}
                </li>
                <li>
                  {t("show_member_department")} :{" "}
                  {details.department ? details.department : "-"}
                </li>
                <li>
                  {t("show_member_program")} :{" "}
                  {details.program ? details.program : "-"}
                </li>
                <li>
                  {t("show_member_education_level")} :{" "}
                  {details.edulevel ? details.edulevel : "-"}
                </li>
                <li>
                  {t("show_member_study_time")} :{" "}
                  {details.fullorparttime
                    ? details.fullorparttime === "fulltime"
                      ? t("confirmation_item_fulltime")
                      : t("confirmation_item_part_time")
                    : "-"}
                </li>
                <li>
                  {t("show_member_year")} : {details.year ? details.year : "-"}
                </li>
              </Fragment>
            )}
            <li></li>
          </ul>
        </div>
        {!loading && (
          <Fragment>
            <div className="data-btn col-12 col-md-4">
              {!details.token ? (
                <button
                  onClick={() =>
                    navigate(Path.searchMaterial + details.id + "/item")
                  }
                  className="button-blue"
                >
                  {t("show_member_borrow_new_material")}
                </button>
              ) : (
                Tooltip.disabledTooltipBtn(
                  "borrow",
                  t("show_member_borrow_new_item"),
                  t("show_member_borrow_new_item_text")
                )
              )}
            </div>
          </Fragment>
        )}
      </div>
      <div className="data-tabs tab-style1 mb-5">
        <ul className="nav nav-tabs mb-0 mb-md-5" id="myTab" role="tablist">
          <li
            className={
              "nav-item col-12 col-md-6 p-0" +
              (tab === "borrowings" ? "nav-link active" : "nav-link")
            }
          >
            <div
              className={tab === "borrowings" ? "nav-link active" : "nav-link"}
              onClick={(e) => borrowingsTab(e)}
            >
              {t("show_member_borrow")}
            </div>
          </li>
          <li
            className={
              "nav-item col-12 col-md-6 p-0" +
              (tab === "reservations" ? "nav-link active" : "nav-link")
            }
          >
            <div
              className={
                tab === "reservations" ? "nav-link active" : "nav-link"
              }
              onClick={(e) => reservationsTab(e)}
            >
              {t("show_member_reservation")}
            </div>
          </li>
        </ul>

        <div className="tab-content mt-5">
          {loading === false && (
            <Fragment>
              {tab === "borrowings" && (
                <Borrowings memberID={param.id}></Borrowings>
              )}
              {tab === "reservations" && (
                <Reservations memberID={param.id}></Reservations>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ShowMember;
