import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";
const ShowAcquisition = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();
  const [details, setDetails] = useState("");

  useEffect(() => {
    const id = param.id;

    Request.getRequest(ApiUrl.showAcquisition + id).then((response) => {
      if (response.status === 200) {
        setDetails(response.data.acquisition);
      } else if (response.status === 404) {
        navigate(-1);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  }, []);

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("show_acquisition_details")}</p>
      <div className="row mb-5">
        <div className="list-data col-12 col-md-8 d-flex">
          <ul>
            <li>
              {t("show_acquisition_title")} :{" "}
              {details.title ? details.title : "-"}
            </li>
            <li>
              {t("show_acquisition_author")} :{" "}
              {details.author ? details.author : "-"}
            </li>
            <li>
              {t("show_acquisition_copies")} :{" "}
              {details.copies ? details.copies : "-"}
            </li>
            <li>
              {t("show_acquisition_donor_buyer")} :{" "}
              {details.seller_donor ? details.seller_donor : "-"}
            </li>
            <li>
              {t("show_acquisition_date_expected_to_arrive")} :{" "}
              {details.expected_on
                ? details.expected_on !== null &&
                  moment(details.expected_on).format("DD-MM-yyyy")
                : "-"}
            </li>
            <li>
              {t("show_acquisition_comments")} :{" "}
              {details.comments ? details.comments : "-"}
            </li>
            <li>
              {t("show_acquisition_status")} :{" "}
              {details.status_trans ? details.status_trans : "-"}
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default ShowAcquisition;
