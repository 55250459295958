import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import sq from "date-fns/locale/sq";
import DatePicker from "react-datepicker";
import FormData from "form-data";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Messages from "../../../helpers/Messages";

const CreateAcquisition = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [copies, setCopies] = useState("");
  const [seller_donor, setSeller_donor] = useState("");
  const [expected_on, setExpected_on] = useState({ date: "", value: "" });
  const [comments, setComments] = useState("");
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  var date = new Date();
  date.setFullYear(date.getFullYear());

  const handleChangeDate = (e) => {
    setExpected_on({
      date: e ? moment(e).format("DD-MM-yyyy") : "",
      value: e ? moment(e).format("DD-MM-yyyy") : "",
    });
  };

  const saveAcquisition = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    var formData = new FormData();
    formData.append("author", author);
    formData.append("title", title);
    formData.append("copies", copies);
    formData.append("seller_donor", seller_donor);
    formData.append("expected_on", expected_on.date);
    formData.append("comments", comments);
    formData.append("_method", "POST");

    Request.postRequest(ApiUrl.storeAcquisition, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        navigate(Path.listAcquisition);
        Messages.successMsg(response.data.success);
        setTitle("");
        setAuthor("");
        setCopies("");
        setSeller_donor("");
        setExpected_on({ date: "", value: "" });
        setComments("");
        setError([]);
      } else {
        setError(response.data);
      }
    });
  };

  return (
    <Fragment>
      <div className="create-acquisition">
        <p className="partial-title big-divide-bottom">
          {t("create_acquisition_new_acquisition")}
        </p>
        <form onSubmit={(e) => saveAcquisition(e)}>
          <div className="row">
            <div className="col-12 col-sm-6 flex-column col-form">
              <div className="form-block">
                <label>{t("create_acquisition_title")}</label>
                <input
                  placeholder={t("create_acquisition_add_title")}
                  className={
                    "form-input " + (error.title ? " input-error" : "")
                  }
                  name="title"
                  type="text"
                  id="title"
                  onChange={(e) => setTitle(e.target.value)}
                />
                {error.title && <ValidationMessage message={error.title[0]} />}
              </div>
              <div className="form-block">
                <label>{t("create_acquisition_author")}</label>
                <input
                  placeholder={t("create_acquisition_add_author")}
                  className={
                    "form-input " + (error.author ? " input-error" : "")
                  }
                  name="author"
                  type="text"
                  id="author"
                  onChange={(e) => setAuthor(e.target.value)}
                />
                {error.author && (
                  <ValidationMessage message={error.author[0]} />
                )}
              </div>
              <div className="form-block">
                <label>{t("create_acquisition_number_of_copies")}</label>
                <input
                  id="copies"
                  placeholder={t("create_acquisition_add_number_of_copies")}
                  className={
                    "form-input " + (error.copies ? " input-error" : "")
                  }
                  name="copies"
                  type="number"
                  onChange={(e) => setCopies(e.target.value)}
                />
                {error.copies && (
                  <ValidationMessage message={error.copies[0]} />
                )}
              </div>
              <div className="form-block">
                <label>{t("create_acquisition_donor_seller")}</label>
                <input
                  placeholder={t("create_acquisition_add_donor_seller")}
                  className={
                    "form-input " + (error.seller_donor ? " input-error" : "")
                  }
                  id="seller_donor"
                  name="seller_donor"
                  type="text"
                  onChange={(e) => setSeller_donor(e.target.value)}
                />
                {error.seller_donor && (
                  <ValidationMessage message={error.seller_donor[0]} />
                )}
              </div>
            </div>
            <div className="col-12 col-sm-6 flex-column col-form ">
              <div className="form-block">
                <label>{t("create_acquisition_date_of_arrival")}</label>
                <DatePicker
                  placeholderText={t("create_acquisition_choose_date")}
                  name="expected_on"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  locale={sq}
                  dateFormat="dd-MM-yyyy"
                  value={expected_on.value}
                  onChange={(e) => handleChangeDate(e)}
                  autoComplete="off"
                  className={
                    "date_input" + (error.expected_on ? " input-error" : "")
                  }
                />
                {error.expected_on && (
                  <ValidationMessage message={error.expected_on[0]} />
                )}
              </div>
              <div className="form-block">
                <label>{t("create_acquisition_comments")}</label>
                <textarea
                  placeholder={t("create_acquisition_add_comments")}
                  className={
                    "form-input tage " + (error.comments ? " input-error" : "")
                  }
                  name="comments"
                  cols="50"
                  rows="3"
                  id="comments"
                  onChange={(e) => setComments(e.target.value)}
                />
                {error.comments && (
                  <ValidationMessage message={error.comments[0]} />
                )}
              </div>
              <div className="pb-4 mb-3 mt-sm-3 mt-auto position-submit-acq-button offset-7">
                {btnLoading ? (
                  <button className="button-blue has-icon w-100" type="button">
                    <i className="icon spinner-icon me-2" />
                    {t("create_acquisition_saving")}
                  </button>
                ) : (
                  <button className="button-blue has-icon w-100" type="submit">
                    {t("create_acquisition_save")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default CreateAcquisition;
