import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";

const SearchMember = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [memberDetails, setMemberDetails] = useState({});
  const [biblio, setBiblio] = useState("");

  // Search by nid
  const [memberListNid, setMemberListNid] = useState({});
  const [loadingNid, setLoadingNid] = useState(true);
  const [search_nid, setSearch_Nid] = useState("");
  const [btnLoadingNid, setBtnLoadingNid] = useState(false);
  const [searchedByNid, setSearchedByNid] = useState(false);

  // Search member
  const [memberList, setMemberList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search_text, setSearch_Text] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchedByMember, setSearchedByMember] = useState(false);
  const [pagination, setPagination] = useState([]);

  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [error, setError] = useState([]);

  useEffect(() => {
    materialDetail();
    if (searched && searchedByMember) {
      searchMember();
    }
  }, [activePage, searched]);

  const materialDetail = () => {
    const material_id = param.id;
    Request.getRequest(ApiUrl.searchMember + material_id + "/member").then(
      (response) => {
        if (response.status === 200) {
          setMemberDetails(response.data.item);
          setBiblio(response.data.item.biblioitem.biblio);
        } else {
          Message.errorMsg(t("error_message"));
        }
      }
    );
  };

  const searchMember = (e) => {
    const material_id = param.id;

    let request = ApiUrl.searchMember + material_id + "/member";

    var searchParams = {};

    if (e) {
      e.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: search_text,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: search_text,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setMemberList(response.data.members.data);
        setError([]);
        setPagination(response.data.members);
        setSearchedByMember(true);
        setSearchedByNid(false);
        setMemberListNid({});
        setLoading(false);
      } else if (response.status === 422) {
        setError(response.data);
      } else if (response.status === 404) {
        navigate(-1);
        Message.errorMsg(response.data.error);
      }
    });
  };

  const searchMemberNID = (e) => {
    if (e) {
      e.preventDefault();
      setBtnLoadingNid(true);
    }

    Request.postRequest(ApiUrl.searchNID, { nid: search_nid }).then(
      (response) => {
        setBtnLoadingNid(false);
        if (response.status === 200) {
          setMemberListNid(response.data.user);
          setSearchedByNid(true);
          setError([]);
          setLoadingNid(false);
          setMemberList([]);
          setSearchedByMember(false);
          setPagination([]);
        } else if (response.status === 422) {
          setError(response.data);
        } else if (response.status === 400) {
          Message.errorMsg(response.data.error);
        } else if (response.status === 403) {
          Message.errorMsg(response.data.error);
        } else if (response.status === 404) {
          navigate(-1);
          Message.errorMsg(response.data.error);
        }
      }
    );
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("search_member_member_details")}</p>
      <div className="member-data row mb-5">
        <div className="list-data col-12 col-md-5 col-lg-4">
          <ul>
            <li className="member-detail">
              {t("search_member_codification")} :{" "}
              {memberDetails.inventory_number}
            </li>
            <li>
              {t("search_member_inventary_number")} : {memberDetails.id}
            </li>
            <li>
              {t("search_member_title")} : {biblio.title}
            </li>
          </ul>
        </div>
        <div className="data-btn col-12 col-md-7 col-lg-8">
          <div className="mt-5 mt-md-0 p-0 search--member">
            <form onSubmit={(e) => searchMember(e)}>
              <div className="row justify-content-end">
                <label className="col-12">
                  {t("search_member_search_member")}
                </label>
                <div className="form-block col-12 col-sm-7 col-xl-8">
                  <input
                    placeholder={t("search_member_search_member")}
                    className={
                      "form-input" + (error.search_text ? " input-error" : "")
                    }
                    name="search_text"
                    onChange={(e) => setSearch_Text(e.target.value)}
                    type="text"
                  />
                  {error.search_text && (
                    <ValidationMessage message={error.search_text[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-sm-5 col-xl-4">
                  {btnLoading ? (
                    <button className="button-blue has-icon" type="button">
                      <i className="icon spinner-icon me-2" />
                      {t("search_member_searching")}
                    </button>
                  ) : (
                    <button
                      className="button-blue has-icon"
                      type="submit"
                      onClick={() => {
                        setSearched(true);
                        setActivePage("1");
                      }}
                    >
                      <i className="icon search-icon me-2" />
                      {t("search_member_search")}
                    </button>
                  )}
                </div>
              </div>
            </form>
            <div className="lined-title mt-3 mb-5">
              <span>{t("search_member_or")}</span>
            </div>
            <form onSubmit={(e) => searchMemberNID(e)}>
              <div className="row mb-5 justify-content-end">
                <label className="col-12">
                  {t("search_member_search_member_number_of_identification")}
                </label>
                <div className="form-block col-12 col-sm-7 col-xl-8">
                  <input
                    placeholder={t("search_member_search_member_Nid")}
                    className={"form-input" + (error.nid ? " input-error" : "")}
                    name="search_nid"
                    onChange={(e) => setSearch_Nid(e.target.value)}
                    type="text"
                  />
                  {error.nid && <ValidationMessage message={error.nid[0]} />}
                </div>
                <div className="form-block col-12 col-sm-5 col-xl-4">
                  {btnLoadingNid ? (
                    <button type="button" className="button-blue has-icon">
                      <i className="icon spinner-icon me-2" />
                      {t("search_member_searching")}
                    </button>
                  ) : (
                    <button
                      className="button-blue has-icon"
                      type="submit"
                      onClick={() => {
                        setSearched(true);
                      }}
                    >
                      <i className="icon search-icon me-2" />
                      {t("search_member_search")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {searched && (searchedByNid || searchedByMember) && (
        <div className="table-list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th
                  width="20%"
                  role="columnheader"
                  className="pr-3 header-list text-left"
                >
                  {t("search_member_firstname")}
                </th>
                <th
                  width="20%"
                  role="columnheader"
                  className="pr-3 header-list text-center"
                >
                  {t("search_member_lastname")}
                </th>
                <th
                  width="20%"
                  role="columnheader"
                  className="pr-3 header-list text-center"
                >
                  {t("search_member_email")}
                </th>
                <th
                  width="20%"
                  role="columnheader"
                  className="pr-3 header-list text-center"
                >
                  {t("search_member_choose")}
                </th>
              </tr>
            </thead>
            <tbody>
              {searchedByMember &&
                (loading ? (
                  <tr>
                    <td className="text-center" colSpan="4">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  memberList.map((member, index) => (
                    <tr key={index}>
                      <td
                        role="cell"
                        data-label={t("search_member_firstname")}
                        className=""
                      >
                        {member.first_name}
                      </td>
                      <td
                        role="cell"
                        data-label={t("search_member_lastname")}
                        className="text-center"
                      >
                        {member.last_name}
                      </td>
                      <td
                        role="cell"
                        data-label={t("search_member_email")}
                        className="text-center"
                      >
                        {member.email}
                      </td>
                      <td
                        role="cell"
                        data-label={t("search_member_choose")}
                        className="text-center"
                      >
                        <button
                          className="button-blue-borrow"
                          onClick={() =>
                            navigate(
                              Path.confirmBorrow +
                                member.id +
                                "/item/" +
                                param.id
                            )
                          }
                        >
                          {t("search_member_choose")}
                        </button>
                      </td>
                    </tr>
                  ))
                ))}

              {searchedByNid &&
                (loadingNid ? (
                  <tr>
                    <td className="text-center" colSpan="4">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  <tr key={memberListNid.id}>
                    <td
                      role="cell"
                      data-label={t("search_member_firstname")}
                      className=""
                    >
                      {memberListNid.first_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("search_member_lastname")}
                      className="text-center"
                    >
                      {memberListNid.last_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("search_member_email")}
                      className="text-center"
                    >
                      {memberListNid.email}
                    </td>
                    <td
                      role="cell"
                      data-label={t("search_member_choose")}
                      className="text-center"
                    >
                      <button
                        className="button-blue-borrow"
                        onClick={() =>
                          navigate(
                            Path.confirmBorrow +
                              memberListNid.id +
                              "/item/" +
                              param.id
                          )
                        }
                      >
                        {t("search_member_choose")}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={searched}
          />
        </div>
      )}
      {searchedByMember && (
        <CustomPagination
          data={pagination}
          setActivePage={(e) => setActivePage(e)}
        />
      )}
    </Fragment>
  );
};

export default SearchMember;
