import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import sq from "date-fns/locale/sq";
import SearchFilter from "../../../../helpers/SearchFilter";
import ListResponse from "../../../../helpers/ListResponse";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Tooltip from "../../../../helpers/Tooltip";

const DamagedLost = () => {
  const { t } = useTranslation();

  const [listBiblioitem, setListBiblioitem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [from, setFrom] = useState({ date: "", value: "" });
  const [to, setTo] = useState({ date: "", value: "" });
  const [searched, setSearched] = useState(false);
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnAddLoading, setBtnAddLoading] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listBiblioitems();
  }, [activePage, searched]);

  var date = new Date();
  date.setFullYear(date.getFullYear());

  const handleChangeDate = (e, date_type) => {
    if (date_type === "from") {
      setFrom({
        date: e ? moment(e).format("DD-MM-yyyy") : "",
        value: e ? moment(e).format("DD-MM-yyyy") : "",
      });
    } else if (date_type === "to") {
      setTo({
        date: e ? moment(e).format("DD-MM-yyyy") : "",
        value: e ? moment(e).format("DD-MM-yyyy") : "",
      });
    }
  };

  const listBiblioitems = (hasSearch) => {
    let request = ApiUrl.reportDamagedLost;

    var searchParams = {};
    if (Object.keys(error).length > 0) {
      setSearched(false);
    }

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        status: status,
        from: from.date,
        to: to.date,
        filtro: "",
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        status: status,
        from: from.date,
        to: to.date,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((result) => {
      setBtnLoading(false);
      if (result.status === 200) {
        setListBiblioitem(result.data.items.data);
        setLoading(false);
        setError({});
        setPagination(result.data.items);
      } else if (result.status === 422) {
        setError(result.data);
      } else {
        setListBiblioitem([]);
        setLoading(true);
      }
    });
  };

  const download = (e) => {
    e.preventDefault();
    setBtnAddLoading(true);
    let request = ApiUrl.exportDamagedLost;

    var searchParams = {
      status: status,
      from: from.date,
      to: to.date,
      export_damagedlost: "",
    };
    request = SearchFilter(searchParams, request);

    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      setBtnAddLoading(false);
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "Report_Demtuar_Spastruar.xlsx");
      link.click();
    });
  };

  return (
    <Fragment>
      <form onSubmit={(e) => listBiblioitems(e)}>
        <div className="row input-items mb-2 pb-3">
          <div className="form-block col-md-4">
            <label htmlFor="status-button">{t("damaged_lost_status")}: </label>
            <Select
              className={
                "form-react-select" + (error.status ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="status"
              isSearchable={false}
              isClearable={true}
              placeholder={t("damaged_lost_choose")}
              options={[
                { value: "damaged", label: t("damaged") },
                { value: "lost", label: t("lost") },
              ]}
              onChange={(e) => (e ? setStatus(e.value) : setStatus(""))}
            />
            {error.status && <ValidationMessage message={error.status[0]} />}
          </div>
          <div className="form-block col-md-4">
            <label htmlFor="from">{t("damaged_lost_from_data")}:</label>
            <DatePicker
              placeholderText={t("damaged_lost_choose_date")}
              name="from"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale={sq}
              value={from.value}
              dateFormat="dd-MM-yyyy"
              onChange={(e) => handleChangeDate(e, "from")}
              autoComplete="off"
              className={"date_input" + (error.from ? " input-error" : "")}
            />
            {error.from && <ValidationMessage message={error.from[0]} />}
          </div>
          <div className="form-block col-md-4">
            <label htmlFor="to">{t("damaged_lost_to_data")}:</label>
            <DatePicker
              placeholderText={t("damaged_lost_choose_date")}
              name="to"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale={sq}
              value={to.value}
              dateFormat="dd-MM-yyyy"
              onChange={(e) => handleChangeDate(e, "to")}
              autoComplete="off"
              className={"date_input" + (error.to ? " input-error" : "")}
            />
            {error.to && <ValidationMessage message={error.to[0]} />}
          </div>
        </div>

        <div className="row pb-0">
          <div className="col-md-12 mb-4 d-flex flex-wrap">
            {btnLoading === true ? (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 mr-5 me-4"
                type="button"
              >
                <i className="icon spinner-icon me-2" />
                {t("damaged_lost_searching")}
              </button>
            ) : (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 mr-5 me-4"
                type="submit"
                onClick={() => {
                  setSearched(true);
                  setActivePage("1");
                }}
              >
                <i className="icon search-icon me-2" />
                {t("damaged_lost_search")}
              </button>
            )}
            {btnAddLoading === true ? (
              <button className="button-blue has-icon mt-0 mb-3" type="button">
                <i className="icon spinner-icon me-2" />
                {t("damaged_lost_exporting")}
              </button>
            ) : (
              <button
                className="button-blue has-icon mt-0 mb-3"
                type="button"
                onClick={(e) => download(e)}
              >
                <i className="icon download-icon me-2" />
                {t("damaged_lost_export")}
              </button>
            )}
          </div>
        </div>
      </form>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" width="20%" className="text-left">
                {t("damaged_lost_material_type")}
              </th>
              <th role="columnheader" width="25%" className="text-center">
                {t("damaged_lost_title_author")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("damaged_lost_publisher")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("damaged_lost_issn_isbn")}
              </th>
              <th role="columnheader" width="25%" className="text-center">
                {t("damaged_lost_inventary_number")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loading />
                </td>
              </tr>
            ) : (
              listBiblioitem.map((item, index) => (
                <tr key={item.id}>
                  <td
                    role="cell"
                    data-label={t("damaged_lost_material_type")}
                    className="title-borrowing"
                  >
                    {item.biblioitem.biblio.materialtype.name_trans}
                  </td>
                  <td
                    role="cell"
                    data-label={t("damaged_lost_title_author")}
                    className="title-borrowing text-center"
                  >
                    <div className="d-inline-flex">
                      {Tooltip.tooltipDisabledMobile(
                        "-title-" + index,
                        item.biblioitem.biblio.title
                      )}
                    </div>
                    {/* autoret */}
                    <div>
                      {item.biblioitem.biblio["authors"].map(
                        (item, index, arr) => {
                          if (index === arr.length - 1) {
                            return item["name"];
                          } else {
                            return item["name"] + "; ";
                          }
                        }
                      )}
                    </div>
                  </td>
                  <td
                    role="cell"
                    data-label={t("damaged_lost_publisher")}
                    className="text-center"
                  >
                    {item.biblioitem.publisher
                      ? item.biblioitem.publisher.name
                      : ""}
                  </td>
                  <td
                    role="cell"
                    data-label={t("damaged_lost_issn_isbn")}
                    className="text-center"
                  >
                    {item.biblioitem.isbn ? item.biblioitem.isbn : "-"} /{" "}
                    {item.biblioitem.issn ? item.biblioitem.issn : "-"}
                  </td>
                  <td
                    role="cell"
                    data-label={t("damaged_lost_inventary_number")}
                    className="text-center"
                  >
                    {item.inventory_number + " / " + item.id}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </Fragment>
  );
};

export default DamagedLost;
