import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import SearchFilter from "../../../../helpers/SearchFilter";
import ListResponse from "../../../../helpers/ListResponse";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Tooltip from "../../../../helpers/Tooltip";

const Bibliography = () => {
  const { t } = useTranslation();

  const [listBiblioitem, setListBiblioitem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [materialType, setMaterialType] = useState("");
  const [author, setAuthor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [keyword, setKeyword] = useState("");
  const [geoterm, setGeoterm] = useState("");
  const [year, setYear] = useState("");
  const [language, setLanguage] = useState("");
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [geotermList, setGeotermList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [searched, setSearched] = useState(false);
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnAddLoading, setBtnAddLoading] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listBiblioitems();
  }, [activePage, searched]);

  const listBiblioitems = (hasSearch) => {
    let request = ApiUrl.reportBibliography;
    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }
    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        materialtype: materialType,
        author: author,
        publisher: publisher,
        keyword: keyword,
        geographicalterm: geoterm,
        year: year,
        language: language,
        bibliography: "",
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        materialtype: materialType,
        author: author,
        publisher: publisher,
        keyword: keyword,
        geographicalterm: geoterm,
        year: year,
        language: language,
        bibliography: "",
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((result) => {
      setBtnLoading(false);
      if (result.status === 200) {
        setListBiblioitem(result.data.biblioitems.data);
        setMaterialTypeList(result.data.materialtypes);
        setAuthorList(result.data.authors);
        setPublisherList(result.data.publishers);
        setKeywordList(result.data.keywords);
        setGeotermList(result.data.geographicalterms);
        setLanguageList(result.data.languages);
        setError([]);
        setLoading(false);
        setPagination(result.data.biblioitems);
      } else if (result.status === 422) {
        setError(result.data);
        setSearched(false);
      } else {
        setListBiblioitem([]);
        setLoading(true);
        setSearched(false);
      }
    });
  };

  const download = (e) => {
    e.preventDefault();
    setBtnAddLoading(true);
    let request = ApiUrl.exportCatalog;

    var searchParams = {
      materialtype: materialType,
      author: author,
      publisher: publisher,
      keyword: keyword,
      geographicalterm: geoterm,
      year: year,
      language: language,
      export_catalog: "",
    };

    request = SearchFilter(searchParams, request);

    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      setBtnAddLoading(false);
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "ReportCatalog.xlsx");
      link.click();
    });
  };

  return (
    <Fragment>
      <form onSubmit={(e) => listBiblioitems(e)}>
        <div className="row">
          <div className="form-block col-sm-6">
            <label className="label-paragraph">
              {t("bibliography_material_type")}
            </label>
            <Select
              className={
                "form-react-select" + (error.materialtype ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="materialtype"
              isClearable={true}
              isSearchable={true}
              placeholder={t("bibliography_material_type")}
              options={materialTypeList.map((item) => ({
                value: item.id,
                label: item.name_trans,
              }))}
              onChange={(e) =>
                e ? setMaterialType(e.value) : setMaterialType("")
              }
            />
            {error.materialtype && (
              <ValidationMessage message={error.materialtype[0]} />
            )}
          </div>
          <div className="form-block col-sm-6">
            <label className="label-paragraph">
              {t("bibliography_author")}
            </label>
            <Select
              className={
                "form-react-select" + (error.author ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="author"
              isSearchable={true}
              placeholder={t("bibliography_author")}
              isClearable={true}
              options={authorList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(e) => (e ? setAuthor(e.value) : setAuthor(""))}
            />
            {error.author && <ValidationMessage message={error.author[0]} />}
          </div>
          <div className="form-block col-sm-6">
            <label className="label-paragraph">
              {t("bibliography_publisher")}
            </label>
            <Select
              className={
                "form-react-select" + (error.publisher ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="publisher"
              isClearable={true}
              isSearchable={true}
              placeholder={t("bibliography_publisher")}
              options={publisherList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(e) => (e ? setPublisher(e.value) : setPublisher(""))}
            />
            {error.publisher && (
              <ValidationMessage message={error.publisher[0]} />
            )}
          </div>
          <div className="form-block col-sm-6">
            <label className="label-paragraph">
              {t("bibliography_key_words")}
            </label>
            <Select
              className={
                "form-react-select" + (error.keyword ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="keyword"
              isClearable={true}
              isSearchable={true}
              placeholder={t("bibliography_key_words")}
              options={keywordList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(e) => (e ? setKeyword(e.value) : setKeyword(""))}
            />
            {error.keyword && <ValidationMessage message={error.keyword[0]} />}
          </div>
          <div className="form-block col-sm-6">
            <label className="label-paragraph">
              {t("bibliography_geographical_terms")}
            </label>
            <Select
              className={
                "form-react-select" +
                (error.geographicalterm ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="geoterm"
              isClearable={true}
              isSearchable={true}
              placeholder={t("bibliography_geographical_terms")}
              options={geotermList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(e) => (e ? setGeoterm(e.value) : setGeoterm(""))}
            />
            {error.geographicalterm && (
              <ValidationMessage message={error.geographicalterm[0]} />
            )}
          </div>
          <div className="form-block col-sm-6">
            <label className="label-paragraph">
              {t("bibliography_year_of_publication")}
            </label>
            <input
              className={"form-input" + (error.year ? " input-error" : "")}
              placeholder={t("bibliography_write_the_year")}
              name="year"
              type="number"
              onChange={(e) => setYear(e.target.value)}
            />
            {error.year && <ValidationMessage message={error.year[0]} />}
          </div>
          <div className="form-block col-sm-6">
            <label className="label-paragraph">
              {t("bibliography_publication_langluage")}
            </label>
            <Select
              className={
                "form-react-select" + (error.language ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="language"
              isClearable={true}
              isSearchable={true}
              placeholder={t("bibliography_publication_langluage")}
              options={languageList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(e) => (e ? setLanguage(e.value) : setLanguage(""))}
            />
            {error.language && (
              <ValidationMessage message={error.language[0]} />
            )}
          </div>
          <div className="col-md-12 mb-4 mt-3 d-flex flex-wrap">
            {btnLoading === true ? (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 me-4"
                type="button"
              >
                <i className="icon spinner-icon me-2" />
                {t("bibliography_searching")}
              </button>
            ) : (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 me-4"
                type="submit"
                onClick={() => {
                  setSearched(true);
                  setActivePage("1");
                }}
              >
                <i className="icon search-icon me-2" />
                {t("bibliography_search")}
              </button>
            )}
            {btnAddLoading === true ? (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 export-bibliography"
                type="button"
              >
                <i className="icon spinner-icon me-2" />
                {t("bibliography_exporting")}
              </button>
            ) : (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 export-bibliography"
                type="button"
                onClick={(e) => download(e)}
              >
                <i className="icon download-icon me-2" />
                {t("bibliography_export")}
              </button>
            )}
          </div>
        </div>
      </form>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "20px" }}
              >
                {t("bibliography_material_type")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "20px" }}
              >
                {t("bibliography_title_author")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "20px" }}
              >
                {t("bibliography_publisher")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "10px" }}
              >
                {t("bibliography_year_of_publication")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "10px" }}
              >
                {t("bibliography_number_of_copies")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "10px" }}
              >
                {t("bibliography_isbn_issn")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "10px" }}
              >
                {t("bibliography_contributers")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="7">
                  <Loading />
                </td>
              </tr>
            ) : (
              listBiblioitem.map((item, index) => (
                <tr key={item.id}>
                  <td
                    role="cell"
                    data-label={t("bibliography_material_type")}
                    className="title-borrowing"
                  >
                    {item.biblio.materialtype.name_trans}
                  </td>
                  <td
                    role="cell"
                    data-label={t("bibliography_title_author")}
                    className="title-borrowing text-center"
                  >
                    <div className="d-inline-flex">
                      {Tooltip.tooltipDisabledMobile(
                        "-title-" + index,
                        item.biblio.title
                      )}
                    </div>
                    {/* autoret */}
                    <div>
                      {item.biblio["authors"].map((item, index, arr) => {
                        if (index === arr.length - 1) {
                          return item["name"];
                        } else {
                          return item["name"] + "; ";
                        }
                      })}
                    </div>
                  </td>
                  <td
                    role="cell"
                    data-label={t("bibliography_publisher")}
                    className="text-center"
                  >
                    {item.publisher ? item.publisher.name : ""}
                  </td>
                  <td
                    role="cell"
                    data-label={t("bibliography_year_of_publication")}
                    className="text-center"
                  >
                    {item.publication_year}
                  </td>
                  <td
                    role="cell"
                    data-label={t("bibliography_number_of_copies")}
                    className="text-center"
                  >
                    {item.items_count}
                  </td>
                  <td
                    role="cell"
                    data-label={t("bibliography_isbn_issn")}
                    className="text-center"
                  >
                    {item.isbn ? item.isbn : "-"} /{" "}
                    {item.issn ? item.issn : "-"}
                  </td>
                  <td
                    role="cell"
                    data-label={t("bibliography_contributers")}
                    className="text-center"
                  >
                    {item["contributors"].map((item, index, arr) => {
                      if (index === arr.length - 1) {
                        return item["name"];
                      } else {
                        return item["name"] + "; ";
                      }
                    })}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </Fragment>
  );
};

export default Bibliography;
