import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Auth from "./Auth";
import ForgotPassword from "../../components/authentication/ForgotPassword";

const ForgotPasswordView = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>{t("forgot_password_tab_title")}</title>
      </Helmet>
      <div className="d-flex flex-wrap login-view" style={{ width: 100 + "%" }}>
        <Auth />
        <div className="right-auth-page">
          <ForgotPassword />
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPasswordView;
