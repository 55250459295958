import React, { useState } from "react";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { helperRoutes, privateRoutes, publicRoutes } from "./Routes";
import { useUserdata } from "../store/UserData";
import ScrollToTop from "../helpers/ScrollToTop";
import i18next from "i18next";

import * as Request from "../api/Request";
import * as ApiUrl from "../api/ApiUrl";
import * as AppConfig from "../helpers/AppConfig";
import { Language } from "../store/Language";

const AppRouter = () => {
  const language = Language((state) => state.addLanguage);
  useEffect(() => {
    AppConfig.isLogged() && userData();

    if (AppConfig.language()) {
      var lang = "en";

      if (AppConfig.language() === "al") {
        lang = "al";
      } else {
        lang = "en";
      }

      localStorage.setItem("i18nextLng", lang);
      language(lang);
    } else {
      AppConfig.setLanguage(i18next.language);
    }
  }, []);

  const addLoggedUserData = useUserdata((state) => state.addUserData);

  const [role, setRole] = useState("");

  const userData = () => {
    Request.postRequest(ApiUrl.profileData).then((response) => {
      if (response.status === 200) {
        addLoggedUserData(response.data);
        setRole(response.data.role);
      }
    });
  };

  return (
    <ScrollToTop>
      <Router>
        <Routes>
          {privateRoutes(role).map((route, idx) => (
            <Route exact path={route.path} element={route.element} key={idx} />
          ))}
          {publicRoutes().map((route, idx) => (
            <Route exact path={route.path} element={route.element} key={idx} />
          ))}
          {helperRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Routes>
      </Router>
    </ScrollToTop>
  );
};

export default AppRouter;
