import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Sidebar from "../../../../components/layouts/sidebar/Sidebar";
import EditBibliography from "../../../../components/panel/catalog/bibliography/EditBibliography/EditBibliography";

const EditBibliographyView = () => {
  const { t } = useTranslation("");
  return (
    <Fragment>
      <Helmet>
        <title>{t("catalog_editbibliography_tab")}</title>
      </Helmet>
      <Sidebar>
        <EditBibliography />
      </Sidebar>
    </Fragment>
  );
};

export default EditBibliographyView;
