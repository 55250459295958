import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";

const EditFrequence = ({
  onShowModal,
  id,
  description,
  unit,
  issuesPerUnit,
  unitPerIssue,
  setFrequenceVisible,
  setModified,
}) => {
  const { t } = useTranslation();

  const [descFrequence, setDescFrequence] = useState(description);
  const [unitFrequence, setUnitFrequence] = useState(unit);
  const [issuesPerUnitFrequence, setIssuesPerUnitFrequence] =
    useState(issuesPerUnit);
  const [unitsPerIssueFrequence, setUnitsPerIssueFrequence] =
    useState(unitPerIssue);
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const updateFrequence = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var formData = {
      description: descFrequence,
      unit: unitFrequence,
      issues_per_unit: issuesPerUnitFrequence,
      units_per_issue: unitsPerIssueFrequence,
    };

    Request.patchRequest(ApiUrl.updateFrequence + id, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          setFrequenceVisible(false);
          setModified(true);
          Message.successMsg(response.data.success);
        } else if (response.status === 422) {
          setError(response.data);
        }
      }
    );
  };

  return (
    <Modal
      isOpen={onShowModal}
      toggle={() => setFrequenceVisible(false)}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => setFrequenceVisible()}
        tag="span"
      >
        {t("edit_frequence_popup")}
      </ModalHeader>
      <form onSubmit={(e) => updateFrequence(e)}>
        <ModalBody>
          <div className="row">
            <div className="form-block">
              <label>{t("edit_frequence_description")}</label>
              <input
                placeholder={t("edit_frequence_description")}
                id="description"
                name="description"
                type="text"
                value={descFrequence}
                onChange={(e) => setDescFrequence(e.target.value)}
                className={
                  "form-input" + (error.description ? " input-error" : "")
                }
              />
              {error?.description && (
                <ValidationMessage message={error?.description} />
              )}
            </div>
            <div className="form-block">
              <label>{t("edit_frequence_unit")}</label>
              <input
                placeholder={t("edit_frequence_unit")}
                id="unit"
                name="unit"
                type="text"
                value={unitFrequence}
                onChange={(e) => setUnitFrequence(e.target.value)}
                className={"form-input" + (error.unit ? " input-error" : "")}
              />
              {error?.unit && <ValidationMessage message={error?.unit} />}
            </div>
            <div className="form-block col-12 col-sm-6">
              <label>{t("edit_frequence_series_per_unit")}</label>
              <input
                id="issues_per_unit"
                placeholder={t("edit_frequence_series_per_unit")}
                name="issues_per_unit"
                type="number"
                value={issuesPerUnitFrequence}
                onChange={(e) => setIssuesPerUnitFrequence(e.target.value)}
                className={
                  "form-input" + (error.issues_per_unit ? " input-error" : "")
                }
              />
              {error?.issues_per_unit && (
                <ValidationMessage message={error?.issues_per_unit} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6">
              <label htmlFor="units_per_issue">
                {t("edit_frequence_unit_per_serie")}
              </label>
              <input
                id="units_per_issue"
                placeholder={t("edit_frequence_unit_per_serie")}
                name="units_per_issue"
                type="number"
                value={unitsPerIssueFrequence}
                onChange={(e) => setUnitsPerIssueFrequence(e.target.value)}
                className={
                  "form-input" + (error.units_per_issue ? " input-error" : "")
                }
              />
              {error?.units_per_issue && (
                <ValidationMessage message={error?.units_per_issue} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button
                  className="button-blue has-icon w-100 text-center"
                  type="button"
                >
                  <i className="icon spinner-icon me-2" />
                  {t("edit_frequence_creating")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon w-100 text-center"
                  type="submit"
                >
                  {t("edit_frequence_create")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100 text-center"
                type="button"
                onClick={() => setFrequenceVisible()}
              >
                {t("edit_frequence_cancel")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditFrequence;
