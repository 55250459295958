import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { serieCoversPath } from "../../../../helpers/AppConfig";
import { CheckSelectedDate } from "../../../../helpers/CheckValue";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const EditSerie = ({
  onShowModal,
  setShowSerie,
  serieId,
  pattern,
  publishedDate,
  cover,
  setUpdated,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [editPattern, setEditPattern] = useState(pattern ? pattern : "");
  const [editPublishedDate, setEditPublishedDate] = useState(
    publishedDate ? publishedDate : ""
  );
  const [editCover, setEditCover] = useState(cover ? cover : "");
  const [editCoverValue, setEditCoverValue] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);

  //The function that closes the modal
  const onClose = () => {
    setShowSerie(false);
    setErrorList([]);
  };

  const deleteCover = () => {
    ConfirmPopup.deletePopup().then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteSerieCover + serieId).then(
          (response) => {
            if (response.status === 200) {
              setUpdated(true);
              setEditCover("");
              setEditCoverValue("");
              Message.successMsg(response.data.success);
            } else if (response.status === 404) {
              Message.errorMsg(response.data.error);
            }
          }
        );
      }
    });
  };

  const updateSerie = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const formData = new FormData();
    if (editCoverValue) {
      formData.append("cover", editCoverValue);
    }
    formData.append("serial_id", id);
    formData.append("pattern", editPattern);
    formData.append(
      "published_date",
      editPublishedDate ? moment(editPublishedDate).format("yyyy-MM-DD") : ""
    );
    formData.append("_method", "PATCH");

    Request.postRequestExport(ApiUrl.updateSerie + serieId, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          Message.successMsg(response.data.success);
          setUpdated();
          onClose();
        } else if (response.status === 405) {
          Message.infoMsg(response.data.info);
          onClose();
        } else if (response.status === 422) {
          setErrorList(response.data);
        } else {
          onClose();
        }
      }
    );
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("edit_serial_edit_serie_popup_title")}
      </ModalHeader>
      <form onSubmit={(e) => updateSerie(e)}>
        <ModalBody>
          <div className="form-block col-12">
            <label>{t("edit_serial_edit_serie_popup_serie_name_label")}</label>
            <input
              type="text"
              placeholder={t(
                "edit_serial_edit_serie_popup_serie_name_placeholder"
              )}
              className={
                "form-input" + (errorList.pattern ? " input-error" : "")
              }
              name="pattern"
              value={editPattern}
              onChange={(e) => setEditPattern(e.target.value)}
            />
            {errorList.pattern && (
              <ValidationMessage message={errorList.pattern[0]} />
            )}
          </div>
          <div className="form-block col-12">
            <label>
              {t("edit_serial_edit_serie_popup_serie_published_date_label")}
            </label>
            <ReactDatePicker
              placeholderText={t(
                "edit_serial_edit_serie_popup_serie_published_date_placeholder"
              )}
              name="published_date"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              // locale={sq}
              dateFormat="dd-MM-yyyy"
              selected={CheckSelectedDate(editPublishedDate)}
              onChange={(e) => setEditPublishedDate(e)}
              autoComplete="off"
              className={
                "date_input" + (errorList.published_date ? " input-error" : "")
              }
            />
            {errorList.published_date && (
              <ValidationMessage message={errorList.published_date[0]} />
            )}
          </div>
          <div className="form-block col-12">
            <label>{t("edit_serial_edit_serie_popup_serie_cover_label")}</label>
            <div className="upload-file-box">
              <div
                className={
                  "form-input" + (errorList.cover ? " input-error" : "")
                }
              >
                {editCover === "" ? (
                  <Fragment>
                    <input
                      className="upload-input"
                      onChange={(e) => {
                        setEditCover(e.target.files[0]);
                        setEditCoverValue(e.target.files[0]);
                      }}
                      accept="image/*"
                      name="cover"
                      type="file"
                    />
                    <div className="upload-input-placeholder">
                      <span>
                        {t(
                          "edit_serial_edit_serie_popup_serie_cover_placeholder"
                        )}
                      </span>
                      <i className="icon upload-icon" />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="upload-input-placeholder">
                      {editCoverValue === "" ? (
                        <Fragment>
                          <span>{editCover}</span>
                          <i
                            className="icon close-red-icon"
                            onClick={(e) => deleteCover(e)}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <span>{editCoverValue.name}</span>
                          <i
                            className="icon close-red-icon"
                            onClick={() => {
                              setEditCover("");
                              setEditCoverValue("");
                            }}
                          />
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
              {editCover !== "" && (
                <div className="uploaded-image">
                  <div className="image-div">
                    {editCover !== "" && editCoverValue !== "" ? (
                      <img
                        src={URL.createObjectURL(editCoverValue)}
                        alt="Cover"
                      />
                    ) : (
                      <img src={serieCoversPath + editCover} alt="Cover" />
                    )}
                  </div>
                </div>
              )}
              {errorList.cover && (
                <ValidationMessage message={errorList.cover[0]} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button className="button-blue has-icon w-100">
                  <i className="icon spinner-icon me-2" />
                  {t("edit_serial_edit_serie_popup_saving")}
                </button>
              ) : (
                <button className="button-blue w-100">
                  {t("edit_serial_edit_serie_popup_save")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("edit_serial_edit_serie_popup_cancel")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditSerie;
