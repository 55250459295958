import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

import * as Message from "../../../helpers/Messages";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const IndexBackup = () => {
  const { t } = useTranslation();

  const [listBackup, setListBackup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    backupList();
  }, [activePage]);

  const backupList = () => {
    let request = ApiUrl.listbackups;
    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setListBackup(response.data.backups.data);
        setLoading(false);
        setPagination(response.data.backups);
      } else {
        setListBackup([]);
        setLoading(true);
      }
    });
  };

  const generateBackup = (e) => {
    e.preventDefault();
    let request = ApiUrl.listbackups;

    setDownloadLoading(true);
    Request.getRequest(request + "?backup_now=").then((response) => {
      setDownloadLoading(false);
      if (response.status === 201) {
        backupList();
        Message.successMsg(response.data.success);
      }
    });
  };

  const download = (e, id, name) => {
    const link = document.createElement("a");
    Request.getRequestForDownload(ApiUrl.listbackups + "?download=" + id).then(
      (response) => {
        link.href = URL.createObjectURL(
          new Blob([response.data], { type: "text/sql" })
        );
        link.setAttribute("download", name + ".sql");
        link.click();
      }
    );
  };

  const deleteBackup = (e, id, name) => {
    ConfirmPopup.deletePopup(name).then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(ApiUrl.delteBackup + id).then((response) => {
          if (response.status === 200) {
            backupList();
            Message.successMsg(response.data.success);
          } else if (response.status === 405) {
            Message.errorMsg(response.data.error);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <div className="backup">
        <p className="partial-title mb-5">{t("index_backup_title")}</p>
        <div className="mb-5 download-backup">
          {downloadLoading === true ? (
            <button className="button-blue has-icon ml-0">
              <i className="icon spinner-icon me-2" />
              {t("index_backup_generating")}
            </button>
          ) : (
            <button
              className="button-blue has-icon ml-0"
              onClick={(e) => generateBackup(e)}
            >
              {t("index_backup_generate")}
            </button>
          )}
        </div>
        <div className="table-list mb-5">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" width="25%" className="text-left">
                  {t("index_backup_name_of_backup")}
                </th>
                <th role="columnheader" width="25%" className="text-center">
                  {t("index_backup_date")}
                </th>
                <th role="columnheader" width="25%" className="text-center">
                  {t("index_backup_download")}
                </th>
                <th role="columnheader" width="25%" className="text-center">
                  {t("index_backup_delete")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="4">
                    <Loading />
                  </td>
                </tr>
              ) : (
                listBackup.map((backup, index) => (
                  <tr key={index}>
                    <td
                      role="cell"
                      data-label={t("index_backup_name_of_backup")}
                    >
                      {backup.name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("index_backup_date")}
                      className="text-center"
                    >
                      {moment(backup.created_at).format("DD MMM, YYYY")}
                    </td>
                    <td
                      role="cell"
                      data-label={t("index_backup_download")}
                      className="text-center"
                    >
                      <button
                        type="button"
                        onClick={(e) => download(e, backup.id, backup.name)}
                        className="button-unstyled-icon"
                      >
                        <i className="icon download-icon" />
                      </button>
                    </td>
                    <td
                      role="cell"
                      data-label={t("index_backup_delete")}
                      className="text-center"
                    >
                      <button
                        type="button"
                        onClick={(e) => deleteBackup(e, backup.id, backup.name)}
                        className="button-unstyled-icon"
                      >
                        <i className="icon delete-icon me-2" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={false}
          />
        </div>
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </Fragment>
  );
};
export default IndexBackup;
