import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ShowBibliography } from "../../../../../store/Catalog/ShowBibliography/Bibliography";
import CheckCover from "../../../../../helpers/CheckCover";

import * as AppConfig from "../../../../../helpers/AppConfig";

const Isbd = () => {
  const { t } = useTranslation("");

  const biblioData = ShowBibliography((state) => state.biblioData);

  return (
    <div className="row show-material">
      <div className="col-12 col-sm-4 d-none d-md-block image-holder">
        <img
          src={CheckCover(biblioData.cover, biblioData.biblio.materialtype.id)}
          alt="Material cover"
        />
      </div>
      <div className="col-12 col-md-8 description-holder">
        <div>
          <div className="row m-0 p-0 name-item">
            {`${biblioData?.biblio?.title ? biblioData.biblio.title : ""}
            ${
              biblioData?.biblio?.subtitle
                ? " : " + biblioData.biblio.subtitle
                : ""
            }
            ${
              biblioData?.biblio?.authors?.length > 0 &&
              " / " +
                biblioData.biblio.authors
                  .map((author) => author.name.split(",").reverse().join(" "))
                  .join("; ")
                ? biblioData?.biblio?.authors?.length > 0 &&
                  " / " +
                    biblioData.biblio.authors
                      .map((author) =>
                        author.name.split(",").reverse().join(" ")
                      )
                      .join("; ")
                : ""
            }
            ${
              biblioData?.contributors?.length > 0 &&
              biblioData?.biblio?.authors?.length < 1
                ? " / " +
                  biblioData.contributors
                    .map((contributor) =>
                      contributor.name.split("; ").reverse().join(" ")
                    )
                    .join("; ")
                : biblioData?.contributors.length > 0 &&
                  biblioData?.biblio?.authors.length > 0 &&
                  "; " +
                    biblioData.contributors
                      .map((contributor) =>
                        contributor.name.split("; ").reverse().join(" ")
                      )
                      .join("; ")
                ? biblioData?.contributors?.length > 0 &&
                  biblioData?.biblio?.authors?.length < 1
                  ? " / " +
                    biblioData.contributors
                      .map((contributor) =>
                        contributor.name.split("; ").reverse().join(" ")
                      )
                      .join("; ")
                  : biblioData?.contributors.length > 0 &&
                    biblioData?.biblio?.authors.length > 0 &&
                    "; " +
                      biblioData.contributors
                        .map((contributor) =>
                          contributor.name.split("; ").reverse().join(" ")
                        )
                        .join("; ")
                : ""
            }
            ${
              biblioData?.biblio?.part_number
                ? " / " + biblioData.biblio.part_number
                : ""
            }
            ${
              biblioData?.biblio?.part_name
                ? " / " + biblioData.biblio.part_name
                : ""
            }
            ${
              biblioData?.publication_number
                ? ".- " + biblioData?.publication_number
                : ""
            }
            ${biblioData?.place ? ".- " + biblioData.place : ""}
            ${
              biblioData?.publisher?.name
                ? " : " + biblioData.publisher.name
                : ""
            }
            ${
              biblioData?.publication_year
                ? ", " + biblioData.publication_year
                : ""
            }
            ${biblioData?.pages ? ".- " + biblioData.pages : ""}
            ${
              biblioData?.physical_details
                ? "; " + biblioData.physical_details
                : ""
            }
            ${
              biblioData?.dimensions ? "; " + biblioData.dimensions + "." : ""
            }`}
          </div>
          {biblioData?.biblio?.notes && (
            <div className="row m-0 p-0 name-item">
              {biblioData?.biblio?.notes ? biblioData?.biblio?.notes : ""}
            </div>
          )}
          {biblioData?.issn && (
            <div className="row m-0 p-0 name-item">
              {t("catalog_showbibliography_isbd_issn")}: {biblioData.issn}
            </div>
          )}
          {biblioData?.isbn && (
            <div className="row m-0 p-0 name-item">
              {t("catalog_showbibliography_isbd_isbn")}: {biblioData.isbn}
            </div>
          )}
          {biblioData?.biblio?.keywords?.length > 0 && (
            <div className="row m-0 p-0 mt-3 mb-3">
              <div className="col-12 ps-0">
                <span className="name-desc">
                  {t("catalog_showbibliography_isbd_keywords")}
                </span>
              </div>
              <div className="col-12 ps-0 name-item">
                {biblioData.biblio.keywords.map((keyword, index) => (
                  <span key={index} className="tag">
                    {keyword.name}
                  </span>
                ))}
              </div>
            </div>
          )}

          {biblioData?.biblio?.geographicalterms?.length > 0 && (
            <div className="row m-0 p-0 mt-3 mb-3">
              <div className="col-12 ps-0">
                <span className="name-desc">
                  {t("catalog_showbibliography_isbd_geographical_terms")}
                </span>
              </div>
              <div className="col-12 ps-0 name-item">
                {biblioData.biblio.geographicalterms.map((terms, index) => (
                  <span key={index} className="tag">
                    {terms.name}
                  </span>
                ))}
              </div>
            </div>
          )}

          {biblioData?.kdu && (
            <Fragment>
              <div className="row m-0 p-0">
                <span className="name-desc text-start p-0">
                  {t("catalog_showbibliography_isbd_kdu")}
                </span>
              </div>
              <div className="row m-0 p-0 name-item">
                {biblioData.kdu && biblioData.kdu}
              </div>
            </Fragment>
          )}
          <br />
          <a
            href={`${
              AppConfig.apiBaseUrl +
              "catalog/biblio/generate-isbd/" +
              biblioData.id
            }`}
            className="button-unstyled-icon"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon print-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Isbd;
