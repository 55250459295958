import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";

const ShowBorrowing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [borrowing, setBorrowing] = useState("");
  const [item, setItem] = useState("");
  const [biblio, setBiblio] = useState("");
  const [biblioitem, setBiblioitem] = useState("");
  const [member, setMember] = useState("");
  const [authors, setAuthors] = useState([]);
  const [publisher, setPublisher] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnPostponeLoading, setBtnPostponeLoading] = useState(false);

  useEffect(() => {
    borrowingDetails();
  }, []);

  const borrowingDetails = () => {
    const borrowing_id = param.id;

    Request.getRequest(ApiUrl.showBorrowing + borrowing_id).then((response) => {
      if (response.status === 200) {
        setBorrowing(response.data.borrowing);
        setItem(response.data.borrowing.item);
        setBiblio(response.data.borrowing.item.biblioitem.biblio);
        setBiblioitem(response.data.borrowing.item.biblioitem);
        setMember(response.data.borrowing.user);
        setAuthors(response.data.borrowing.item.biblioitem.biblio.authors);
        setPublisher(response.data.borrowing.item.biblioitem.publisher);
        setLoading(false);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const postponeBorrowing = (e) => {
    const borrowings_id = param.id;
    setBtnPostponeLoading(true);
    var formData = {
      id: borrowings_id,
    };
    e.preventDefault();

    Request.postRequest(ApiUrl.postponeBorrowing, formData).then((response) => {
      setBtnPostponeLoading(true);
      if (response.status === 200) {
        navigate(Path.listBorrowings);
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
        borrowingDetails();
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const returnBorrowing = (e) => {
    const borrowings_id = param.id;
    var formData = {
      id: borrowings_id,
    };
    e.preventDefault();

    Request.postRequest(ApiUrl.returnBorrowing, formData).then((response) => {
      if (response.status === 200) {
        navigate(Path.listBorrowings);
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
        borrowingDetails();
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const userGender = (param) => {
    switch (param) {
      case "M":
        return t("male");
      case "F":
        return t("female");
      default:
        return "-";
    }
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("show_borrowing_details")}</p>
      <div className="borrowing-details mb-5">
        <div className="member">
          <div className="b-header">
            <h3>{t("show_borrowing_member")}</h3>
          </div>
          <div className="b-details">
            <span className="attr-col">
              {t("show_borrowing_firstname_lastname")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">
              {(member.first_name ? member.first_name : "-") +
                " " +
                (member.last_name ? member.last_name : "-")}{" "}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_email")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">
              {member.email ? member.email : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_phone_number")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;
            <span className="attr-val">
              {member.phone ? member.phone : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_gender")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">
              {member.gender ? userGender(member.gender) : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_city_state")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;
            <span className="attr-val">
              {(member.state ? member.state : "-") +
                " / " +
                (member.city ? member.city : "-")}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_address_postal_code")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">
              {(member.address ? member.address : "-") +
                " / " +
                (member.postal_code ? member.postal_code : "-")}{" "}
            </span>
            <br></br>
          </div>
        </div>
        <div className="material">
          <div className="b-header">
            <h3>{t("show_borrowing_material")}</h3>
          </div>
          <div className="b-details">
            <span className="attr-col">{t("show_borrowing_title")} : </span>{" "}
            &nbsp;&nbsp;
            <span className="attr-val">
              {biblio.title ? biblio.title : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_author")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;
            <span className="attr-val">
              {authors.map((author) => author.name).join("; ")}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_publisher")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;
            <span className="attr-val">
              {publisher.name ? publisher.name : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_codification")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">
              {item.inventory_number ? item.inventory_number : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_inventary_number")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">{item.id ? item.id : "-"}</span>
            <br></br>
            <span className="attr-col">{t("show_borrowing_type")} : </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">
              {item.borrowing_type_trans ? item.borrowing_type_trans : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_place_number")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">
              {item.place_number ? item.place_number : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_publishing_year")} :{" "}
            </span>{" "}
            &nbsp;&nbsp;{" "}
            <span className="attr-val">
              {biblioitem.publication_year ? biblioitem.publication_year : "-"}
            </span>
            <br></br>
          </div>
        </div>
        <div className="borrowing">
          <div className="b-header">
            <h3>{t("show_borrowing_borrowing_details")}</h3>
          </div>
          <div className="b-details">
            <span className="attr-col">
              {t("show_borrowing_borrowing_date")} :{" "}
            </span>{" "}
            <span className="attr-val">
              {borrowing.created_at
                ? moment(borrowing.created_at).format("DD-MM-yyyy")
                : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_delivered_date")} :{" "}
            </span>{" "}
            <span className="attr-val">
              {borrowing.returned_on
                ? moment(borrowing.returned_on).format("DD-MM-yyyy")
                : "-"}
            </span>
            <br></br>
            <span className="attr-col">
              {t("show_borrowing_date_to_be_delivered")} :{" "}
            </span>{" "}
            <span className="attr-val">
              {borrowing.should_return_on
                ? moment(borrowing.should_return_on).format("DD-MM-yyyy")
                : "-"}
            </span>
            <br></br>
          </div>
        </div>
      </div>
      <div className="row borrowing-btns">
        {!loading && borrowing.returned_on === null && (
          <Fragment>
            <div className="d-flex">
              <div className="pr-3 me-3">
                <form onSubmit={(e) => returnBorrowing(e)}>
                  <button className="button-blue-border" type="submit">
                    {t("show_borrowing_return")}
                  </button>
                </form>
              </div>
              <div>
                <form onSubmit={(e) => postponeBorrowing(e)}>
                  {btnPostponeLoading === true ? (
                    <button className="button-blue has-icon" type="button">
                      <i className="icon spinner-icon me-2" />
                      {t("show_borrowing_being_applied")}
                    </button>
                  ) : (
                    <button className="button-blue has-icon" type="submit">
                      {t("show_borrowing_postpone")}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ShowBorrowing;
