import { useTranslation } from "react-i18next";
import { Fragment, useState, useEffect } from "react";
import {  useParams } from "react-router-dom";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";

import CheckCover from "../../../../helpers/CheckCover";
import { CheckContributors } from "../../../../helpers/CheckValue";
import { CheckContributorsType } from "../../../../helpers/CheckValue"
import { CheckAuthors } from "../../../../helpers/CheckValue"

const Marc = () => {
    const { t } = useTranslation();
    const param = useParams();
    const [biblioItem, setBiblioItem] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        showMarc();
    }, []);

    const showMarc = () => {
        const serialId = param.id;
        let request = ApiUrl.showSerial + serialId;
    
        Request.getRequest(request).then((response) => {
          if (response.status === 200) {
            setBiblioItem(response.data.serial.biblioitem);
            setLoading(false);
          }
        });
    };

    return (
        <div className="row show-material">
          <div className="col-12 col-sm-4 d-none d-md-block image-holder">
            <img
              src={CheckCover(biblioItem?.cover, biblioItem?.biblio?.materialtype?.id)}
              alt="Material cover"
            />
          </div>
          <div className="col-12 col-md-8 description-holder marc">
            <div>
              {biblioItem?.lccn && (
                <div className="row pb-3">
                  <div className="col-1 name-desc">010</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{biblioItem.lccn}</span>
                  </div>
                </div>
              )}
              {biblioItem?.national_control_number && (
                <div className="row pb-3">
                  <div className="col-1 name-desc">016</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">
                      {biblioItem.national_control_number}
                    </span>
                  </div>
                </div>
              )}
              {biblioItem?.isbn && (
                <div className="row pb-3">
                  <div className="col-1 name-desc">020</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{biblioItem.isbn}</span>
                  </div>
                </div>
              )}
              {biblioItem?.issn && (
                <div className="row pb-3">
                  <div className="col-1 name-desc">022</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{biblioItem.issn}</span>
                  </div>
                </div>
              )}
              {biblioItem?.biblio?.language && (
                <div className="row pb-3">
                  <div className="col-1 name-desc">041</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">
                      {biblioItem.biblio.language.name_trans}
                    </span>
                  </div>
                </div>
              )}
              {biblioItem?.kdu && (
                <div className="row pb-3">
                  <div className="col-1 name-desc">080</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{biblioItem.kdu}</span>
                  </div>
                </div>
              )}
              {biblioItem?.biblio?.authors.map((author, index) => {
                return (
                  <Fragment key={index}>
                    <div className="row">
                      <div className="col-1 name-desc">100</div>
                      <div className="col-11 marc-info">
                        <span className="name-item-a">_a </span>
                        <span className="name-item">{author.name}</span>
                      </div>
                    </div>
                    <div className="row pb-3">
                      <div className="col-1"></div>
                      <div className="col-11 marc-info">
                        <span className="name-item-a">_e </span>
                        <span className="name-item">{author?.pivot?.type ? CheckAuthors(author.pivot.type) : ""}</span>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
              <Fragment>
                {biblioItem?.biblio?.title && (
                  <div className="row">
                    <div className="col-1 name-desc">245</div>
                    <div className="col-11 marc-info">
                      <span className="name-item-a">_a </span>
                      <span className="name-item">{biblioItem.biblio.title}</span>
                    </div>
                  </div>
                )}
                {biblioItem?.biblio?.subtitle && (
                  <div
                    className={"row" + (!biblioItem?.biblio?.title ? " pt-3" : "")}
                  >
                    <div className="col-1 name-desc">
                      {!biblioItem?.biblio?.title && "245"}
                    </div>
                    <div className="col-11 marc-info">
                      <span className="name-item-a">_b </span>
                      <span className="name-item">
                        {biblioItem.biblio.subtitle}
                      </span>
                    </div>
                  </div>
                )}
                {(biblioItem?.biblio?.authors > 0 ||
                  biblioItem?.contributors?.length > 0) && (
                  <div
                    className={"row" + (!biblioItem.biblio.title ? " pt-3" : "")}
                  >
                    <div className="col-1 name-desc">
                      {(!biblioItem?.biblio?.title ||
                        !biblioItem?.biblio?.subtitle) &&
                        "245"}
                    </div>
                    <div className="col-11 marc-info">
                      <span className="name-item-a">_c </span>
                      <span className="name-item">
                        {`${
                          biblioItem?.biblio?.authors?.length > 0
                            ? biblioItem.biblio.authors
                                .map((author) =>
                                  author.name.split(",").reverse().join(" ")
                                )
                                .join("; ")
                            : ""
                        }${
                            biblioItem?.contributors?.length > 0 &&
                            biblioItem?.biblio?.authors?.length < 1
                            ? biblioItem.contributors
                                .map(
                                  (contributor) =>
                                    `${CheckContributors(
                                      contributor.pivot.type
                                    )} ${contributor.name
                                      .split(",")
                                      .reverse()
                                      .join(" ")}`
                                )
                                .join("; ")
                            : biblioItem?.contributors?.length > 0 &&
                            biblioItem?.biblio?.authors?.length > 0 &&
                              "; " +
                              biblioItem.contributors
                                  .map(
                                    (contributor) =>
                                      `${CheckContributors(
                                        contributor.pivot.type
                                      )} ${contributor.name
                                        .split(",")
                                        .reverse()
                                        .join(" ")}`
                                  )
                                  .join("; ")
                        }`}
                      </span>
                    </div>
                  </div>
                )}
                {biblioItem?.biblio?.medium && (
                  <div
                    className={
                      "row" +
                      (!biblioItem?.biblio?.title ||
                      !biblioItem?.biblio?.subtitle ||
                      biblioItem?.biblio?.authors?.length < 1 ||
                      biblioItem?.contributors?.length < 1
                        ? " pt-3"
                        : "")
                    }
                  >
                    <div className="col-1 name-desc">
                      {(!biblioItem?.biblio?.title ||
                        !biblioItem?.biblio?.subtitle) &&
                        "245"}
                    </div>
                    <div className="col-11 marc-info">
                      <span className="name-item-a">_h </span>
                      <span className="name-item">{biblioItem.biblio.medium}</span>
                    </div>
                  </div>
                )}
                {biblioItem?.biblio?.part_number && (
                  <div
                    className={
                      "row" +
                      (!biblioItem.biblio.title ||
                      !biblioItem.biblio.subtitle ||
                      biblioItem?.biblio?.authors?.length < 1 ||
                      biblioItem?.contributors?.length < 1 ||
                      !biblioItem.biblio.medium
                        ? " pt-3"
                        : "")
                    }
                  >
                    <div className="col-1 name-desc">
                      {(!biblioItem?.biblio?.title ||
                        !biblioItem?.biblio?.subtitle ||
                        biblioItem?.biblio?.authors?.length < 1 ||
                        biblioItem?.contributors?.length < 1 ||
                        !biblioItem?.biblio?.medium) &&
                        "245"}
                    </div>
                    <div className="col-11 marc-info">
                      <span className="name-item-a">_n </span>
                      <span className="name-item">
                        {biblioItem.biblio.part_number}
                      </span>
                    </div>
                  </div>
                )}
                {biblioItem?.biblio?.part_name && (
                  <div
                    className={
                      "row" +
                      (!biblioItem.biblio.title ||
                      !biblioItem.biblio.subtitle ||
                      biblioItem?.biblio?.authors?.length < 1 ||
                      biblioItem?.contributors?.length < 1 ||
                      !biblioItem.biblio.medium ||
                      !biblioItem.biblio.part_number
                        ? " pt-3"
                        : "")
                    }
                  >
                    <div className="col-1 name-desc">
                      {(!biblioItem.biblio.title ||
                        !biblioItem.biblio.subtitle ||
                        biblioItem?.biblio?.authors?.length < 1 ||
                        biblioItem?.contributors?.length < 1 ||
                        !biblioItem.biblio.medium ||
                        !biblioItem.biblio.part_number) &&
                        "245"}
                    </div>
                    <div className="col-11 marc-info">
                      <span className="name-item-a">_p </span>
                      <span className="name-item">
                        {biblioItem.biblio.part_name}
                      </span>
                    </div>
                  </div>
                )}
              </Fragment>
              {biblioItem?.publication_number && (
                <div className="row pt-3">
                  <div className="col-1 name-desc">250</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">
                      {biblioItem.publication_number}
                    </span>
                  </div>
                </div>
              )}
              {biblioItem?.place && (
                <div className="row pt-3">
                  <div className="col-1 name-desc">260</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{biblioItem.place}</span>
                  </div>
                </div>
              )}
              {biblioItem?.publisher && (
                <div className={"row" + (!biblioItem.place ? " pt-3" : "")}>
                  <div className="col-1 name-desc">
                    {!biblioItem.place && "260"}
                  </div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_b </span>
                    <span className="name-item">{biblioItem.publisher.name}</span>
                  </div>
                </div>
              )}
              {biblioItem?.publication_year && (
                <div
                  className={
                    "row" +
                    (!biblioItem.place || !biblioItem.publisher ? " pt-3" : "")
                  }
                >
                  <div className="col-1 name-desc">
                    {(!biblioItem.place || !biblioItem.publisher) && "260"}
                  </div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_c </span>
                    <span className="name-item">{biblioItem.publication_year}</span>
                  </div>
                </div>
              )}
              {biblioItem?.pages && (
                <div className="row pt-3">
                  <div className="col-1 name-desc">300</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{biblioItem.pages}</span>
                  </div>
                </div>
              )}
              {biblioItem.physical_details && (
                <div className={"row" + (!biblioItem.pages ? " pt-3" : "")}>
                  <div className="col-1 name-desc">
                    {!biblioItem.pages && "300"}
                  </div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_b </span>
                    <span className="name-item">{biblioItem.physical_details}</span>
                  </div>
                </div>
              )}
              {biblioItem.dimensions && (
                <div
                  className={
                    "row" +
                    (!biblioItem.pages || !biblioItem.physical_details
                      ? " pt-3"
                      : "")
                  }
                >
                  <div className="col-1 name-desc">
                    {(!biblioItem.pages || !biblioItem.physical_details) && "300"}
                  </div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_c </span>
                    <span className="name-item">{biblioItem.dimensions}</span>
                  </div>
                </div>
              )}
              {/* {frequence && (
                    <div className="row">
                      <div className="col-1 name-desc">310</div>
                      <div className="col-11 marc-info">
                        <span className="name-item-a">_a </span>
                        <span className="name-item">{frequence.description}</span>
                      </div>
                    </div>
                  )} */}
              {biblioItem?.price && (
                <div className="row pt-3">
                  <div className="col-1 name-desc">365</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_b </span>
                    <span className="name-item">{biblioItem.price}</span>
                  </div>
                </div>
              )}
              {biblioItem?.currency && (
                <div className={"row" + (!biblioItem.price ? " pt-3" : "")}>
                  <div className="col-1 name-desc">
                    {!biblioItem.price && "365"}
                  </div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_c </span>
                    <span className="name-item">{biblioItem.currency}</span>
                  </div>
                </div>
              )}
              {biblioItem?.biblio?.notes && (
                <div className="row pt-3">
                  <div className="col-1 name-desc">500</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{biblioItem.biblio.notes}</span>
                  </div>
                </div>
              )}
              {biblioItem?.biblio?.abstract && (
                <div className="row pt-3">
                  <div className="col-1 name-desc">520</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{biblioItem.biblio.abstract}</span>
                  </div>
                </div>
              )}
              {biblioItem?.biblio?.agerestriction && (
                <div className="row pt-3">
                  <div className="col-1 name-desc">521</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">
                      {biblioItem.biblio.agerestriction}
                    </span>
                  </div>
                </div>
              )}
              {biblioItem?.biblio?.keywords?.length > 0 &&
                biblioItem.biblio.keywords.map((keyword, index) => (
                  <div key={index} className="row pt-3">
                    <div className="col-1 name-desc">650</div>
                    <div className="col-11 marc-info">
                      <span className="name-item-a">_a </span>
                      <span className="name-item">{keyword.name}</span>
                    </div>
                  </div>
                ))}
              {biblioItem?.biblio?.geographicalterms?.length > 0 &&
                biblioItem.biblio.geographicalterms.map(
                  (geographicalterm, index) => (
                    <div key={index} className="row pt-3">
                      <div className="col-1 name-desc">651</div>
                      <div className="col-11 marc-info">
                        <span className="name-item-a">_a </span>
                        <span className="name-item">{geographicalterm.name}</span>
                      </div>
                    </div>
                  )
                )}
              {biblioItem?.contributors?.length > 0 &&
                biblioItem.contributors.map((contributor, index) => (
                  <Fragment key={index}>
                    <div className="row pt-3">
                      <div className="col-1 name-desc">700</div>
                      <div className="col-11 marc-info">
                        <span className="name-item-a">_a </span>
                        <span className="name-item">{contributor.name}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1"></div>
                      <div className="col-11 marc-info">
                        <span className="name-item-a">_e </span>
                        <span className="name-item">{contributor?.pivot?.type ? CheckContributorsType(contributor.pivot.type) : ""}</span>
                      </div>
                    </div>
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
      );
};

export default Marc;