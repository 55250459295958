import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ReactSelect from "react-select";
import ValidationMessage from "../../../../../helpers/ValidationMessage";
import Loading from "../../../../../helpers/Loading";
import ListResponse from "../../../../../helpers/ListResponse";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as Path from "../../../../../routes/GeneralPaths";

const ServicesList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Error YAZ
  const [serverError, setServerError] = useState({});

  //   Services list
  const [listOfService, setListOfServices] = useState([]);
  const [servicesTotal, setServicesTotal] = useState(0);

  //   Services list
  const [listOfServers, setListOfServers] = useState([]);
  const [loading, setLoading] = useState(true);

  //  Search server states
  const [searchSpecification, setSearchSpecification] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchServerId, setSearchServerId] = useState("");
  const [searchErrors, setSearchErrors] = useState([]);
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  //   Checked records
  const [checkedRecords, setCheckedRecords] = useState({});
  const [checkedRecordsValue, setCheckedRecordsValue] = useState({});

  useEffect(() => {
    serverTypes();
  }, []);

  const servicesList = (e) => {
    setSearchBtnLoading(true);
    e.preventDefault();
    let urlRequest = ApiUrl.getRecords;
    var searchParams = {};

    searchParams = {
      specification: searchSpecification,
      term: searchTerm,
      server_id: searchServerId,
    };

    // the function which return the list of services
    Request.postRequest(urlRequest, searchParams).then((response) => {
      setSearchBtnLoading(false);
      if (response.status === 200) {
        setListOfServices(response?.data?.data);
        setServicesTotal(response?.data?.count);
        setSearchErrors([]);
        setSearched(true);
      } else if (response.status === 422) {
        setSearchErrors(response.data);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const serverTypes = () => {
    Request.getRequest(ApiUrl.getServers).then((response) => {
      if (response.status === 200) {
        setListOfServers(response.data.servers);
        setLoading(false);
      } else if (response.status === 403) {
        setServerError(response.data);
        setLoading(false);
      }
    });
  };

  const checkBox = (e, index, recordValue) => {
    const checkedValues = {
      [`record_ids[${index}]`]: index,
    };

    const checkedRecordsList = {
      [`record_${index}`]: recordValue,
    };

    if (!e.target.checked) {
      delete checkedRecords[`record_ids${index}`];
      delete checkedRecordsValue[`record_${index}`];
      var uncheckedRecords = {
        ...checkedRecords,
      };
      var uncheckedRecordsValues = {
        ...checkedRecordsValue,
      };
      setCheckedRecords(uncheckedRecords);
      setCheckedRecordsValue(uncheckedRecordsValues);
    } else {
      var checkedInputs = {
        ...checkedRecords,
      };
      var checkedInputsValues = {
        ...checkedRecordsValue,
      };
      Object.assign(checkedInputs, checkedValues);
      Object.assign(checkedInputsValues, checkedRecordsList);
      setCheckedRecords(checkedInputs);
      setCheckedRecordsValue(checkedInputsValues);
    }
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("services_list_title")}</p>
      {!loading &&
        (serverError?.error?.length > 0 ? (
          <div className="alert alert-danger" role="alert">
            {serverError.error}
          </div>
        ) : (
          <Fragment>
            <div className="search-item mb-5">
              <form onSubmit={(e) => servicesList(e)}>
                <div className="row">
                  <label>{t("servers_list_search_server_label")}</label>
                  <div className="form-block col-12 col-md-5 col-lg-3">
                    <input
                      placeholder={t(
                        "services_list_search_service_name_placeholder"
                      )}
                      className={
                        "form-input" +
                        (searchErrors?.term ? " input-error" : "")
                      }
                      name="term"
                      value={searchTerm}
                      type="text"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchErrors?.term && (
                      <ValidationMessage message={searchErrors?.term[0]} />
                    )}
                  </div>
                  <div className="form-block col-12 col-md-5 col-lg-3">
                    <ReactSelect
                      className={
                        "form-react-select" +
                        (searchErrors.specification ? " input-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="specification"
                      placeholder={t(
                        "services_list_search_service_type_placeholder"
                      )}
                      isClearable={true}
                      onChange={(e) => setSearchSpecification(e ? e.value : "")}
                      isSearchable={false}
                      options={[
                        {
                          value: "isbn",
                          label: t("services_list_select_option_isbn"),
                        },
                        {
                          value: "ti",
                          label: t("services_list_select_option_title"),
                        },
                        {
                          value: "au",
                          label: t("services_list_select_option_author"),
                        },
                      ]}
                    />
                    {searchErrors?.specification && (
                      <ValidationMessage
                        message={searchErrors?.specification[0]}
                      />
                    )}
                  </div>
                  <div className="form-block col-12 col-md-5 col-lg-3">
                    <ReactSelect
                      className={
                        "form-react-select" +
                        (searchErrors.server_id ? " input-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="specification"
                      placeholder={t("services_list_search_server_placeholder")}
                      isClearable={true}
                      onChange={(e) => setSearchServerId(e ? e.value : "")}
                      isSearchable={false}
                      options={listOfServers.map((server) => ({
                        value: server.id,
                        label:
                          server.name +
                          (server.database_name
                            ? " (" + server.database_name + ")"
                            : ""),
                      }))}
                    />
                    {searchErrors?.server_id && (
                      <ValidationMessage message={searchErrors?.server_id[0]} />
                    )}
                  </div>
                  <div className="form-block col-12 col-sm-6 col-md-4 col-lg-3 pb-3">
                    {searchBtnLoading ? (
                      <button className="button-blue has-icon" type="button">
                        <i className="icon spinner-icon me-2" />
                        {t("services_list_searching_server_button")}
                      </button>
                    ) : (
                      <button className="button-blue has-icon" type="submit">
                        <i className="icon search-icon me-2" />
                        {t("services_list_search_server_button")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            {listOfService.length > 0 && (
              <div className="row mb-4">
                <div className="col-12 col-sm-6">
                  {Object.keys(checkedRecordsValue).length > 0 && (
                    <div className="row pl-0 colored-sb pr-3">
                      <div className="col-12 col-lg-3 col-md-4">
                        <button
                          type="button"
                          className="button-blue ml-0"
                          onClick={() =>
                            navigate(Path.confirmZ3950, {
                              state: { checkedRecordsValue },
                            })
                          }
                        >
                          {t("services_list_upload_button")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-end align-items-center">
                  {t("services_list_records_number")}: {servicesTotal}
                </div>
              </div>
            )}
            <div className="table-list">
              <table role="table" className="w-100">
                <thead>
                  <tr role="row">
                    <th
                      role="columnheader"
                      width="45%"
                      className="text-left pl-0"
                    >
                      {t("services_list_table_title_title")}
                    </th>
                    <th
                      role="columnheader"
                      width="40%"
                      className="text-left pl-0"
                    >
                      {t("services_list_table_title_isbn_issn")}
                    </th>
                    <th
                      role="columnheader"
                      width="15%"
                      className="text-end pl-0"
                    >
                      {t("servers_list_table_title_choose")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        <Loading />
                      </td>
                    </tr>
                  ) : (
                    listOfService.map((service, index) => (
                      <tr key={index} role="row">
                        <td
                          role="cell"
                          data-label={t("services_list_table_title_title")}
                        >
                          {service?.title ? service?.title : "-"}
                        </td>
                        <td
                          role="cell"
                          data-label={t("services_list_table_title_isbn_issn")}
                        >
                          {`${service?.isbn ? service?.isbn : "-"} / ${
                            service?.issn ? service?.issn : "-"
                          }`}
                        </td>
                        <td
                          role="cell"
                          data-label={t("servers_list_table_title_choose")}
                          className="form-group"
                        >
                          <input
                            type="checkbox"
                            value={index}
                            className="form-input-checkbox"
                            id={index}
                            onChange={(e) => checkBox(e, index, service)}
                          />
                          <label className="float-end" htmlFor={index}></label>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <ListResponse
                totalItems={servicesTotal}
                loading={loading}
                searched={searched}
              />
            </div>
            {listOfService.length > 0 && (
              <div className="row my-4">
                <div className="col-12 col-sm-6">
                  {Object.keys(checkedRecordsValue).length > 0 && (
                    <div className="row pl-0 colored-sb pr-3">
                      <div className="col-12 col-lg-3 col-md-4">
                        <button
                          type="button"
                          className="button-blue ml-0"
                          onClick={() =>
                            navigate(Path.confirmZ3950, {
                              state: { checkedRecordsValue },
                            })
                          }
                        >
                          {t("services_list_upload_button")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-end align-items-center">
                  {t("services_list_records_number")}: {servicesTotal}
                </div>
              </div>
            )}
          </Fragment>
        ))}
    </Fragment>
  );
};

export default ServicesList;
