import { isLogged } from "../helpers/AppConfig";
import { useUserdata } from "../store/UserData";

const ProtectedRoute = ({ roles, children }) => {
  const getUserData = useUserdata((state) => state.userData);

  const isAuthorized = isLogged();
  const rolesRequired = !!roles?.length;
  const rolesMatch = rolesRequired
    ? roles.some((r) => getUserData.role?.indexOf(r) >= 0)
    : true;

  if (isAuthorized && rolesMatch) {
    return children;
  } else {
    return "";
  }
};

export default ProtectedRoute;
