import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ListResponse from "../../../../helpers/ListResponse";
import DatePicker from "react-datepicker";
import Select from "react-select";
import sq from "date-fns/locale/sq";
import moment from "moment";
import Loading from "../../../../helpers/Loading";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import CustomPagination from "../../../../helpers/CustomPagination";
import SearchFilter from "../../../../helpers/SearchFilter";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Tooltip from "../../../../helpers/Tooltip";

const BorrowingInTime = () => {
  const { t } = useTranslation();

  const [listBiblioitem, setListBiblioitem] = useState([]);
  const [facultiesList, setFacultiesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnAddLoading, setBtnAddLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [from, setFrom] = useState({ date: "", value: "" });
  const [to, setTo] = useState({ date: "", value: "" });
  const [faculty, setFaculty] = useState("");
  const [activePage, setActivePage] = useState("");
  const [searched, setSearched] = useState(false);
  const [error, setError] = useState({});
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listBiblioitems();
  }, [activePage, searched]);

  var date = new Date();
  date.setFullYear(date.getFullYear());

  const handleChangeDate = (e, date_type) => {
    if (date_type === "to") {
      setTo({
        date: e ? moment(new Date(e)).format("DD-MM-yyyy") : "",
        value: e ? moment(new Date(e)).format("DD-MM-yyyy") : "",
      });
    } else if (date_type === "from") {
      setFrom({
        date: e ? moment(new Date(e)).format("DD-MM-yyyy") : "",
        value: e ? moment(new Date(e)).format("DD-MM-yyyy") : "",
      });
    }
  };

  const listBiblioitems = (hasSearch) => {
    let request = ApiUrl.reportBorrowingInTime;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
      setSearched(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        from: from.date,
        to: to.date,
        faculty: faculty,
        filtro: "",
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        from: from.date,
        to: to.date,
        faculty: faculty,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((result) => {
      setBtnLoading(false);
      if (result.status === 200) {
        setListBiblioitem(result.data.biblioitems.data);
        setFacultiesList(result.data.faculties);
        setLoading(false);
        setError({});
        setPagination(result.data.biblioitems);
      } else if (result.status === 422) {
        setError(result.data);
      } else {
        setListBiblioitem([]);
        setLoading(true);
      }
    });
  };

  const download = (e) => {
    e.preventDefault();
    setBtnAddLoading(true);
    let request = ApiUrl.reportExportBorrowingInTime;

    var searchParams = {
      from: from.date,
      to: to.date,
      faculty: faculty,
      export_borrowings: "",
    };

    request = SearchFilter(searchParams, request);

    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      setBtnAddLoading(false);
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "Report_Borrowings_InTime.xlsx");
      link.click();
    });
  };

  return (
    <Fragment>
      <form onSubmit={(e) => listBiblioitems(e)}>
        <div className="row input-items mb-1 pb-3">
          <div className="form-block col-md-4">
            <label htmlFor="from">{t("borrowing_intime_from")}:</label>
            <DatePicker
              placeholderText={t("borrowing_intime_choose_date")}
              name="from"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale={sq}
              dateFormat="DD-MM-yyyy"
              value={from.value}
              onChange={(e) => handleChangeDate(e, "from")}
              autoComplete="off"
              className={"date_input" + (error.from ? " input-error" : "")}
            />
            {error.from && <ValidationMessage message={error.from} />}
          </div>
          <div className="form-block col-md-4">
            <label htmlFor="to">{t("borrowing_intime_to")}:</label>
            <DatePicker
              placeholderText={t("borrowing_intime_choose_date")}
              name="to"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale={sq}
              dateFormat="DD-MM-yyyy"
              value={to.value}
              onChange={(e) => handleChangeDate(e, "to")}
              autoComplete="off"
              className={"date_input" + (error.to ? " input-error" : "")}
            />
            {error.to && <ValidationMessage message={error.to} />}
          </div>
          <div className="form-block col-md-4">
            <label htmlFor="to">{t("borrowing_intime_faculty")}:</label>
            <Select
              className={
                "form-react-select" + (error.faculty ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="faculty"
              isSearchable={true}
              placeholder={t("borrowing_intime_faculty")}
              isClearable={true}
              options={facultiesList.map((item) => ({
                value: item,
                label: item,
              }))}
              onChange={(e) => (e ? setFaculty(e.value) : setFaculty(""))}
            />
            {error.faculty && <ValidationMessage message={error.faculty} />}
          </div>
        </div>
        <div className="row pb-4 mb-2">
          <div className="col-md-12 d-flex flex-wrap">
            {btnLoading === true ? (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 mr-5 me-4"
                type="button"
              >
                <i className="icon spinner-icon me-2" />
                {t("borrowing_intime_searching")}
              </button>
            ) : (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 mr-5 me-4"
                type="submit"
                onClick={() => {
                  setSearched(true);
                  setActivePage("1");
                }}
              >
                <i className="icon search-icon me-2" />
                {t("borrowing_intime_search")}
              </button>
            )}
            {btnAddLoading === true ? (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 export-in-time"
                type="button"
              >
                <i className="icon spinner-icon me-2" />
                {t("borrowing_intime_exporting")}
              </button>
            ) : (
              <button
                className="button-blue has-icon ml-0 mt-0 mb-3 export-in-time"
                type="submit"
                onClick={(e) => download(e)}
              >
                <i className="icon download-icon me-2" />
                {t("borrowing_intime_export")}
              </button>
            )}
          </div>
        </div>
      </form>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th width="30%" role="columnheader" className="text-left">
                {t("borrowing_intime_title")}
              </th>
              <th width="20%" role="columnheader" className="text-center">
                {t("borrowing_intime_author")}
              </th>
              <th width="20%" role="columnheader" className="text-center">
                {t("borrowing_intime_publisher")}
              </th>
              <th width="15%" role="columnheader" className="text-center">
                {t("borrowing_intime_issn_isbn")}
              </th>
              <th width="15%" role="columnheader" className="text-center">
                {t("borrowing_intime_number_of_borrowings")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loading />
                </td>
              </tr>
            ) : (
              listBiblioitem.map((item, index) => (
                <tr key={item.id}>
                  <td
                    role="cell"
                    data-label={t("borrowing_intime_title")}
                    className="title-borrowing"
                  >
                    {Tooltip.tooltipDisabledMobile(
                      "-title-" + index,
                      item.biblio.title
                    )}
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrowing_intime_author")}
                    className="text-center"
                  >
                    {item.biblio["authors"].map((item, index, arr) => {
                      if (index === arr.length - 1) {
                        return item["name"];
                      } else {
                        return item["name"] + "; ";
                      }
                    })}
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrowing_intime_publisher")}
                    className="text-center"
                  >
                    {item.publisher ? item.publisher.name : ""}
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrowing_intime_issn_isbn")}
                    className=" text-center"
                  >
                    {item.isbn} {item.isbn && item.issn && " / "} {item.issn}
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrowing_intime_number_of_borrowings")}
                    className="text-center"
                  >
                    {item.borrowings_count}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(error).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default BorrowingInTime;
