import { t } from "i18next";
import React, { Fragment, useState } from "react";
import Bibliography from "./Bibliography";
import DamagedLost from "./DamagedLost";

import * as AppConfig from "../../../../helpers/AppConfig";

const ReportBibliography = () => {
  const [tab, setTab] = useState("bibliography");

  const damagedOrLost = (e) => {
    e.preventDefault();
    setTab("damaged");
  };

  const bibliography = (e) => {
    e.preventDefault();
    setTab("bibliography");
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("index_bibliography_catalog")}</p>
      <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
        <li
          className={
            AppConfig.authRole() === "administrator"
              ? "nav-item col-12 col-md-6 p-0" +
                (tab === "bibliography" ? "nav-link active" : "nav-link")
              : "nav-item col-12 p-0" +
                (tab === "bibliography" ? "nav-link active" : "nav-link")
          }
        >
          <div
            className={tab === "bibliography" ? "nav-link active" : "nav-link"}
            onClick={(e) => bibliography(e)}
          >
            {t("index_bibliography_bibliography")}
          </div>
        </li>
        {AppConfig.authRole() === "administrator" && (
          <li
            className={
              "nav-item col-12 col-md-6 p-0" +
              (tab === "damaged" ? "nav-link active" : "nav-link")
            }
          >
            <div
              className={tab === "damaged" ? "nav-link active" : "nav-link"}
              onClick={(e) => damagedOrLost(e)}
            >
              {t("index_bibliography_damaged")}
            </div>
          </li>
        )}
      </ul>

      {tab === "bibliography" && <Bibliography />}
      {tab === "damaged" && AppConfig.authRole() === "administrator" && (
        <DamagedLost />
      )}
    </Fragment>
  );
};

export default ReportBibliography;
