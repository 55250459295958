import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchFilter from "../../../../helpers/SearchFilter";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";
import CreateFrequence from "./CreateFrequence";
import EditFrequence from "./EditFrequence";
import ListResponse from "../../../../helpers/ListResponse";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as AppConfig from "../../../../helpers/AppConfig";
import * as Messages from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const FrequenceList = () => {
  const { t } = useTranslation();

  const [frequenceList, setFrequenceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  // Create frequence modal
  const [createFrequenceModal, setCreateFrequenceModal] = useState(false);

  // Edit frequence modal
  const [editFrequenceModal, setEditFrequenceModal] = useState(false);
  const [frequenceId, setFrequenceId] = useState("");

  useEffect(() => {
    listFrequences();
  }, [activePage]);

  const listFrequences = () => {
    let request = ApiUrl.listFrequences;
    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
      request = SearchFilter(searchParams, request);
    }
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setFrequenceList(response.data.frequences.data);
        setLoading(false);
        setPagination(response.data.frequences);
      } else {
        Messages.errorMsg(t("error_message"));
      }
    });
  };

  const deleteFrequence = (e, id, name) => {
    ConfirmPopup.deletePopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteFrequence + id).then((response) => {
          if (response.status === 200) {
            listFrequences();
            Messages.successMsg(response.data.success);
          } else if (response.status === 405) {
            Messages.errorMsg(response.data.error);
          } else if (response.status === 404) {
            Messages.errorMsg(response.data.error);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("frequence_list_frequence")}</p>
      <button
        onClick={() => setCreateFrequenceModal(true)}
        className="button-blue mb-4"
      >
        {t("frequence_list_create_frequence")}
      </button>
      {createFrequenceModal && (
        <CreateFrequence
          onShowModal={createFrequenceModal}
          setFrequenceVisible={(e) => setCreateFrequenceModal(e)}
          setModified={() => listFrequences()}
        />
      )}
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" width="20%" className="text-left">
                {t("frequence_list_description")}
              </th>
              <th role="columnheader" width="20%" className="text-center">
                {t("frequence_list_unit")}
              </th>
              <th role="columnheader" width="20%" className="text-center">
                {t("frequence_list_series_per_unit")}
              </th>
              <th role="columnheader" width="20%" className="text-center">
                {t("frequence_list_unit_per_series")}
              </th>
              <th role="columnheader" width="20%" className="text-center">
                {t("frequence_list_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loading />
                </td>
              </tr>
            ) : (
              frequenceList.map((frequence) => (
                <tr key={frequence.id} role="row">
                  <td role="cell" data-label={t("frequence_list_description")}>
                    {frequence.description}
                  </td>
                  <td
                    role="cell"
                    data-label={t("frequence_list_unit")}
                    className="text-center"
                  >
                    {frequence.unit}
                  </td>
                  <td
                    role="cell"
                    data-label={t("frequence_list_series_per_unit")}
                    className="text-center"
                  >
                    {frequence.issues_per_unit}
                  </td>
                  <td
                    role="cell"
                    data-label={t("frequence_list_unit_per_series")}
                    className="text-center"
                  >
                    {frequence.units_per_issue}
                  </td>
                  <td
                    role="cell"
                    data-label={t("frequence_list_actions")}
                    className="text-center"
                  >
                    <div className="d-flex flex-row justify-content-sm-center">
                      <button
                        type="button"
                        onClick={() => {
                          setFrequenceId(frequence.id);
                          setEditFrequenceModal(true);
                        }}
                        className="button-unstyled-icon me-3"
                      >
                        <i className="icon edit-icon" />
                      </button>
                      <button
                        type="button"
                        onClick={(e) =>
                          deleteFrequence(
                            e,
                            frequence.id,
                            frequence.description
                          )
                        }
                        className="button-unstyled-icon ms-3"
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </div>
                    {editFrequenceModal && frequence.id === frequenceId && (
                      <EditFrequence
                        onShowModal={editFrequenceModal}
                        id={frequence.id}
                        description={frequence.description}
                        unit={frequence.unit}
                        issuesPerUnit={frequence.issues_per_unit}
                        unitPerIssue={frequence.units_per_issue}
                        setFrequenceVisible={(e) => {
                          setEditFrequenceModal(e);
                          setFrequenceId("");
                        }}
                        setModified={() => listFrequences()}
                      />
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </Fragment>
  );
};

export default FrequenceList;
