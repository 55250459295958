import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import * as Path from "../../routes/GeneralPaths";

import LogoWhite from "../../assets/images/logo/logo-white.svg";

const Auth = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="left-auth-page">
        <div className="main-logo">
          <a href={Path.homepage}>
            {/* <img src={LogoWhite} alt="Logo" /> */}
            <button className="button-blue">Home</button>
          </a>
        </div>
        <div className="main-title">
          <h1>{t("identify_login_your_library_banner_text")}</h1>
        </div>
      </div>
    </Fragment>
  );
};

export default Auth;
