import create from "zustand";
const bibliographyData = {};
export const Bibliography = create((set) => ({
  bibliographyData,
  addBibliographyData: (data) =>
    set(() => ({
      bibliographyData: {
        authors: data?.data?.authors ? data?.data?.authors : [],
        biblios: data?.data?.biblios ? data?.data?.biblios : [],
        geoterms: data?.data?.geographical_terms
          ? data?.data?.geographical_terms
          : [],
        keywords: data?.data?.key_words ? data?.data?.key_words : [],
        languages: data?.data?.languages ? data?.data?.languages : [],
        materialTypes: data?.data?.material_types
          ? data?.data?.material_types
          : [],
        mediaTypes: data?.data?.media_types ? data?.data?.media_types : [],
        contributors: [],
        publishers: [],
      },
    })),

  addBibliographyUpdate: (data) =>
    set(() => ({
      bibliographyData: data,
    })),
  reset: () => set(() => ({ bibliographyData : {} })),
}));
