export const authorTypes = [
  { value: "primary", label: "author_type_first" },
  { value: "secondary", label: "author_type_second" },
  { value: "third", label: "author_type_third" },
  { value: "author", label: "author_type_author" },
];

export const contributorTypes = [
  {
    value: "editor",
    label: "contributor_type_editor",
  },
  {
    value: "translator",
    label: "contributor_type_interpreter",
  },
  {
    value: "reviewer",
    label: "contributor_type_reviewer",
  },
  {
    value: "preparatory",
    label: "contributor_type_preparatory",
  },
  {
    value: "contributor",
    label: "contributor_type_contributor",
  },
];
