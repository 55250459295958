import { Fragment } from "react";
import { ShowBibliography } from "../../../../../store/Catalog/ShowBibliography/Bibliography";
import CheckCover from "../../../../../helpers/CheckCover";
import { CheckContributors } from "../../../../../helpers/CheckValue";
import { CheckContributorsType } from "../../../../../helpers/CheckValue"
import { CheckAuthors } from "../../../../../helpers/CheckValue";

const Marc = () => {
  const biblioData = ShowBibliography((state) => state.biblioData);

  return (
    <div className="row show-material">
      <div className="col-12 col-sm-4 d-none d-md-block image-holder">
        <img
          src={CheckCover(biblioData.cover, biblioData.biblio.materialtype.id)}
          alt="Material cover"
        />
      </div>
      <div className="col-12 col-md-8 description-holder marc">
        <div>
          {biblioData?.lccn && (
            <div className="row pb-3">
              <div className="col-1 name-desc">010</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">{biblioData.lccn}</span>
              </div>
            </div>
          )}
          {biblioData?.national_control_number && (
            <div className="row pb-3">
              <div className="col-1 name-desc">016</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">
                  {biblioData.national_control_number}
                </span>
              </div>
            </div>
          )}
          {biblioData?.isbn && (
            <div className="row pb-3">
              <div className="col-1 name-desc">020</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">{biblioData.isbn}</span>
              </div>
            </div>
          )}
          {biblioData?.issn && (
            <div className="row pb-3">
              <div className="col-1 name-desc">022</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">{biblioData.issn}</span>
              </div>
            </div>
          )}
          {biblioData?.biblio?.language && (
            <div className="row pb-3">
              <div className="col-1 name-desc">041</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">
                  {biblioData.biblio.language.name_trans}
                </span>
              </div>
            </div>
          )}
          {biblioData?.kdu && (
            <div className="row pb-3">
              <div className="col-1 name-desc">080</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">{biblioData.kdu}</span>
              </div>
            </div>
          )}
          {biblioData?.biblio?.authors.map((author, index) => {
            return (
              <Fragment key={index}>
                <div className="row">
                  <div className="col-1 name-desc">100</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{author.name}</span>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-1"></div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_e </span>
                    <span className="name-item">{author?.pivot?.type ? CheckAuthors(author.pivot.type) : ""}</span>
                  </div>
                </div>
              </Fragment>
            );
          })}
          <Fragment>
            {biblioData?.biblio?.title && (
              <div className="row">
                <div className="col-1 name-desc">245</div>
                <div className="col-11 marc-info">
                  <span className="name-item-a">_a </span>
                  <span className="name-item">{biblioData.biblio.title}</span>
                </div>
              </div>
            )}
            {biblioData?.biblio?.subtitle && (
              <div
                className={"row" + (!biblioData?.biblio?.title ? " pt-3" : "")}
              >
                <div className="col-1 name-desc">
                  {!biblioData?.biblio?.title && "245"}
                </div>
                <div className="col-11 marc-info">
                  <span className="name-item-a">_b </span>
                  <span className="name-item">
                    {biblioData.biblio.subtitle}
                  </span>
                </div>
              </div>
            )}
            {(biblioData?.biblio?.authors > 0 ||
              biblioData?.contributors?.length > 0) && (
              <div
                className={"row" + (!biblioData.biblio.title ? " pt-3" : "")}
              >
                <div className="col-1 name-desc">
                  {(!biblioData?.biblio?.title ||
                    !biblioData?.biblio?.subtitle) &&
                    "245"}
                </div>
                <div className="col-11 marc-info">
                  <span className="name-item-a">_c </span>
                  <span className="name-item">
                    {`${
                      biblioData?.biblio?.authors.length > 0
                        ? biblioData.biblio.authors
                            .map((author) =>
                              author.name.split(",").reverse().join(" ")
                            )
                            .join("; ")
                        : ""
                    }${
                      biblioData.contributors.length > 0 &&
                      biblioData?.biblio?.authors.length < 1
                        ? biblioData.contributors
                            .map(
                              (contributor) =>
                                `${CheckContributors(
                                  contributor.pivot.type
                                )} ${contributor.name
                                  .split(",")
                                  .reverse()
                                  .join(" ")}`
                            )
                            .join("; ")
                        : biblioData.contributors.length > 0 &&
                          biblioData?.biblio?.authors.length > 0 &&
                          "; " +
                            biblioData.contributors
                              .map(
                                (contributor) =>
                                  `${CheckContributors(
                                    contributor.pivot.type
                                  )} ${contributor.name
                                    .split(",")
                                    .reverse()
                                    .join(" ")}`
                              )
                              .join("; ")
                    }`}
                  </span>
                </div>
              </div>
            )}
            {biblioData?.biblio?.medium && (
              <div
                className={
                  "row" +
                  (!biblioData?.biblio?.title ||
                  !biblioData?.biblio?.subtitle ||
                  biblioData?.biblio?.authors.length < 1 ||
                  biblioData?.contributors?.length < 1
                    ? " pt-3"
                    : "")
                }
              >
                <div className="col-1 name-desc">
                  {(!biblioData?.biblio?.title ||
                    !biblioData?.biblio?.subtitle) &&
                    "245"}
                </div>
                <div className="col-11 marc-info">
                  <span className="name-item-a">_h </span>
                  <span className="name-item">{biblioData.biblio.medium}</span>
                </div>
              </div>
            )}
            {biblioData?.biblio?.part_number && (
              <div
                className={
                  "row" +
                  (!biblioData.biblio.title ||
                  !biblioData.biblio.subtitle ||
                  biblioData?.biblio?.authors.length < 1 ||
                  biblioData?.contributors?.length < 1 ||
                  !biblioData.biblio.medium
                    ? " pt-3"
                    : "")
                }
              >
                <div className="col-1 name-desc">
                  {(!biblioData?.biblio?.title ||
                    !biblioData?.biblio?.subtitle ||
                    biblioData?.biblio?.authors.length < 1 ||
                    biblioData?.contributors?.length < 1 ||
                    !biblioData?.biblio?.medium) &&
                    "245"}
                </div>
                <div className="col-11 marc-info">
                  <span className="name-item-a">_n </span>
                  <span className="name-item">
                    {biblioData.biblio.part_number}
                  </span>
                </div>
              </div>
            )}
            {biblioData?.biblio?.part_name && (
              <div
                className={
                  "row" +
                  (!biblioData.biblio.title ||
                  !biblioData.biblio.subtitle ||
                  biblioData?.biblio?.authors.length < 1 ||
                  biblioData?.contributors?.length < 1 ||
                  !biblioData.biblio.medium ||
                  !biblioData.biblio.part_number
                    ? " pt-3"
                    : "")
                }
              >
                <div className="col-1 name-desc">
                  {(!biblioData.biblio.title ||
                    !biblioData.biblio.subtitle ||
                    biblioData?.biblio?.authors.length < 1 ||
                    biblioData?.contributors?.length < 1 ||
                    !biblioData.biblio.medium ||
                    !biblioData.biblio.part_number) &&
                    "245"}
                </div>
                <div className="col-11 marc-info">
                  <span className="name-item-a">_p </span>
                  <span className="name-item">
                    {biblioData.biblio.part_name}
                  </span>
                </div>
              </div>
            )}
          </Fragment>
          {biblioData?.publication_number && (
            <div className="row pt-3">
              <div className="col-1 name-desc">250</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">
                  {biblioData.publication_number}
                </span>
              </div>
            </div>
          )}
          {biblioData?.place && (
            <div className="row pt-3">
              <div className="col-1 name-desc">260</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">{biblioData.place}</span>
              </div>
            </div>
          )}
          {biblioData?.publisher && (
            <div className={"row" + (!biblioData.place ? " pt-3" : "")}>
              <div className="col-1 name-desc">
                {!biblioData.place && "260"}
              </div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_b </span>
                <span className="name-item">{biblioData.publisher.name}</span>
              </div>
            </div>
          )}
          {biblioData?.publication_year && (
            <div
              className={
                "row" +
                (!biblioData.place || !biblioData.publisher ? " pt-3" : "")
              }
            >
              <div className="col-1 name-desc">
                {(!biblioData.place || !biblioData.publisher) && "260"}
              </div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_c </span>
                <span className="name-item">{biblioData.publication_year}</span>
              </div>
            </div>
          )}
          {biblioData?.pages && (
            <div className="row pt-3">
              <div className="col-1 name-desc">300</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">{biblioData.pages}</span>
              </div>
            </div>
          )}
          {biblioData.physical_details && (
            <div className={"row" + (!biblioData.pages ? " pt-3" : "")}>
              <div className="col-1 name-desc">
                {!biblioData.pages && "300"}
              </div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_b </span>
                <span className="name-item">{biblioData.physical_details}</span>
              </div>
            </div>
          )}
          {biblioData.dimensions && (
            <div
              className={
                "row" +
                (!biblioData.pages || !biblioData.physical_details
                  ? " pt-3"
                  : "")
              }
            >
              <div className="col-1 name-desc">
                {(!biblioData.pages || !biblioData.physical_details) && "300"}
              </div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_c </span>
                <span className="name-item">{biblioData.dimensions}</span>
              </div>
            </div>
          )}
          {/* {frequence && (
                <div className="row">
                  <div className="col-1 name-desc">310</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{frequence.description}</span>
                  </div>
                </div>
              )} */}
          {biblioData?.price && (
            <div className="row pt-3">
              <div className="col-1 name-desc">365</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_b </span>
                <span className="name-item">{biblioData.price}</span>
              </div>
            </div>
          )}
          {biblioData?.currency && (
            <div className={"row" + (!biblioData.price ? " pt-3" : "")}>
              <div className="col-1 name-desc">
                {!biblioData.price && "365"}
              </div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_c </span>
                <span className="name-item">{biblioData.currency}</span>
              </div>
            </div>
          )}
          {biblioData?.biblio?.notes && (
            <div className="row pt-3">
              <div className="col-1 name-desc">500</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">{biblioData.biblio.notes}</span>
              </div>
            </div>
          )}
          {biblioData?.biblio?.abstract && (
            <div className="row pt-3">
              <div className="col-1 name-desc">520</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">{biblioData.biblio.abstract}</span>
              </div>
            </div>
          )}
          {biblioData?.biblio?.agerestriction && (
            <div className="row pt-3">
              <div className="col-1 name-desc">521</div>
              <div className="col-11 marc-info">
                <span className="name-item-a">_a </span>
                <span className="name-item">
                  {biblioData.biblio.agerestriction}
                </span>
              </div>
            </div>
          )}
          {biblioData?.biblio?.keywords.length > 0 &&
            biblioData.biblio.keywords.map((keyword, index) => (
              <div key={index} className="row pt-3">
                <div className="col-1 name-desc">650</div>
                <div className="col-11 marc-info">
                  <span className="name-item-a">_a </span>
                  <span className="name-item">{keyword.name}</span>
                </div>
              </div>
            ))}
          {biblioData?.biblio?.geographicalterms.length > 0 &&
            biblioData.biblio.geographicalterms.map(
              (geographicalterm, index) => (
                <div key={index} className="row pt-3">
                  <div className="col-1 name-desc">651</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{geographicalterm.name}</span>
                  </div>
                </div>
              )
            )}
          {biblioData?.contributors?.length > 0 &&
            biblioData.contributors.map((contributor, index) => (
              <Fragment key={index}>
                <div className="row pt-3">
                  <div className="col-1 name-desc">700</div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_a </span>
                    <span className="name-item">{contributor.name}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-11 marc-info">
                    <span className="name-item-a">_e </span>
                    <span className="name-item">{contributor?.pivot?.type ? CheckContributorsType(contributor.pivot.type) : ""}</span>
                  </div>
                </div>
              </Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Marc;
