import moment from "moment";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { authRole } from "../../../../helpers/AppConfig";
import {
  CheckBorrowingType,
  CheckSelectedDate,
} from "../../../../helpers/CheckValue";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";

const EditItems = ({ items, serieId, setUpdateItems }) => {
  const { t } = useTranslation();
  const [editItems, setEditItems] = useState(items);
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => setEditItems(items), []);

  const handleChangeSelect = (e, name, i) => {
    var items = editItems;
    items[i][name] = e ? e.value : "";
    setEditItems([...items]);
  };

  const handleChange = (e, name, i) => {
    var items = editItems;
    items[i][name] = e.target.value;
    setEditItems([...items]);
  };

  const handleChangeDate = (e, name, i) => {
    var items = editItems;
    items[i][name] = e ? moment(e).format("yyyy-MM-DD") : null;
    setEditItems([...items]);
  };

  const updateItems = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var formData = new FormData();
    editItems.forEach((item, index) => {
      formData.append(`items[${index}][id]`, item.id);
      formData.append(`items[${index}][borrowing_type]`, item.borrowing_type);
      formData.append(
        `items[${index}][inventory_number]`,
        item.inventory_number
      );
      formData.append(`items[${index}][place_number]`, item.place_number);
      formData.append(`items[${index}][damaged]`, item.damaged);
      formData.append(
        `items[${index}][damaged_date]`,
        item.damaged_date ? item.damaged_date : ""
      );
      formData.append(
        `items[${index}][renewal_date]`,
        item.renewal_date ? item.renewal_date : ""
      );
      formData.append(`items[${index}][lost]`, item.lost);
      formData.append(
        `items[${index}][lost_date]`,
        item.lost_date ? item.lost_date : ""
      );
    });
    formData.append(`_method`, "PATCH");

    Request.postRequest(ApiUrl.updateSerieItems + serieId, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          setErrorList([]);
          setUpdateItems(true);
          Message.successMsg(response.data.success);
        } else if (response.status === 422) {
          setErrorList(response.data);
        }
      }
    );
  };

  return (
    <form onSubmit={(e) => updateItems(e)}>
      {editItems.map((item, index) => (
        <div key={item.id} className="row card-item-bg mt-3">
          <div className="form-block col-12 col-md-4">
            <label>
              {t("edit_serial_serie_list_edit_item_borrowing_type_label")}
            </label>
            <ReactSelect
              className={
                "form-react-select" +
                (errorList[`items.${index}.borrowing_type`]
                  ? " input-error"
                  : "")
              }
              classNamePrefix="select_input"
              name={"items[" + item.id + "][borrowing_type]"}
              placeholder={t(
                "edit_serial_serie_list_edit_item_borrowing_type_placeholder"
              )}
              defaultValue={
                item.borrowing_type
                  ? CheckBorrowingType(item.borrowing_type)
                  : null
              }
              isSearchable={false}
              isClearable={true}
              menuPlacement="auto"
              options={[
                {
                  value: "library",
                  label: t("check_borrowing_type_library"),
                },
                { value: "normal", label: t("check_borrowing_type_normal") },
                {
                  value: "unavailable",
                  label: t("check_borrowing_type_unavailable"),
                },
              ]}
              onChange={(e) =>
                handleChangeSelect(e, `borrowing_type`, index, "item")
              }
            />
            {errorList[`items.${index}.borrowing_type`] && (
              <ValidationMessage
                message={errorList[`items.${index}.borrowing_type`]}
              />
            )}
          </div>
          <div className="form-block col-12 col-md-4">
            <label>
              {t("edit_serial_serie_list_edit_item_codification_label")}
            </label>
            <input
              placeholder={t(
                "edit_serial_serie_list_edit_item_codification_placeholder"
              )}
              className={
                "form-input" +
                (errorList[`items.${index}.inventory_number`]
                  ? " input-error"
                  : "")
              }
              name={"items[" + item.id + "][inventory_number]"}
              onChange={(e) =>
                handleChange(e, `inventory_number`, index, "item")
              }
              type="text"
              defaultValue={item.inventory_number ? item.inventory_number : ""}
            />
            {errorList[`items.${index}.inventory_number`] && (
              <ValidationMessage
                message={errorList[`items.${index}.inventory_number`]}
              />
            )}
          </div>
          <div className="form-block col-12 col-md-4">
            <label>
              {t("edit_serial_serie_list_edit_item_place_number_label")}
            </label>
            <input
              placeholder={t(
                "edit_serial_serie_list_edit_item_place_number_placeholder"
              )}
              className={
                "form-input" +
                (errorList[`items.${index}.place_number`] ? " input-error" : "")
              }
              name={"items[" + item.id + "][place_number]"}
              onChange={(e) => handleChange(e, `place_number`, index, "item")}
              type="text"
              defaultValue={item.place_number ? item.place_number : ""}
            />
            {errorList[`items.${index}.place_number`] && (
              <ValidationMessage
                message={errorList[`items.${index}.place_number`]}
              />
            )}
          </div>
          <div className="form-block row col-12 mx-0 p-0">
            <div className="col-12 col-md-4">
              <label>
                {t("edit_serial_serie_list_edit_item_inventory_number_label")}
              </label>
              <input
                placeholder={t(
                  "edit_serial_serie_list_edit_item_inventory_number_placeholder"
                )}
                className="form-input"
                type="text"
                defaultValue={item.id}
                disabled
              />
            </div>
          </div>
          {authRole() === "administrator" && (
            <div className="row col-12 mx-0 p-0">
              <div className="form-block col-12 col-md-4">
                <label htmlFor="Demtuar">
                  {t("edit_serial_serie_list_edit_item_damaged_label")}
                </label>
                <div className="radio-buttons d-flex">
                  <div className="radio-button me-4">
                    <label htmlFor={`damaged_${index}`}>
                      {t("edit_serial_serie_list_edit_item_damaged_yes_label")}
                    </label>
                    <input
                      className="form-check-input"
                      name={"items[" + item.id + "][damaged]"}
                      onChange={(e) =>
                        handleChange(e, `damaged`, index, "item")
                      }
                      type="radio"
                      value="1"
                      id={`damaged_${index}`}
                      defaultChecked={item.damaged === 1}
                    />
                  </div>
                  <div className="radio-button ms-4">
                    <label htmlFor={`notdamaged_${index}`}>
                      {t("edit_serial_serie_list_edit_item_damaged_no_label")}
                    </label>
                    <input
                      className="form-check-input"
                      id={`notdamaged_${index}`}
                      name={"items[" + item.id + "][damaged]"}
                      onChange={(e) =>
                        handleChange(e, `damaged`, index, "item")
                      }
                      type="radio"
                      value="0"
                      defaultChecked={item.damaged === 0}
                    />
                  </div>
                </div>
                {errorList[`items.${index}.damaged`] && (
                  <ValidationMessage
                    message={errorList[`items.${index}.damaged`]}
                  />
                )}
              </div>

              {(item.damaged === 1 || item.damaged === "1") && (
                <Fragment>
                  <div className="form-block col-12 col-md-4 removable-field">
                    <label>
                      {t("edit_serial_serie_list_edit_item_damaged_date_label")}
                    </label>
                    <ReactDatePicker
                      placeholderText={t(
                        "edit_serial_serie_list_edit_item_damaged_date_placeholder"
                      )}
                      name={"items[" + item.id + "][damaged_date]"}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      // locale={sq}
                      dateFormat="dd-MM-yyyy"
                      selected={
                        item.damaged_date
                          ? CheckSelectedDate(item.damaged_date)
                          : null
                      }
                      onChange={(e) =>
                        handleChangeDate(e, `damaged_date`, index, "item")
                      }
                      autoComplete="off"
                      className={
                        "date_input" +
                        (errorList[`items.${index}.damaged_date`]
                          ? " input-error"
                          : "")
                      }
                    />
                    {errorList[`items.${index}.damaged_date`] && (
                      <ValidationMessage
                        message={errorList[`items.${index}.damaged_date`]}
                      />
                    )}
                  </div>
                  <div className="form-block col-12 col-md-4 removable-field">
                    <label>
                      {t("edit_serial_serie_list_edit_item_renewal_date_label")}
                    </label>
                    <ReactDatePicker
                      placeholderText={t(
                        "edit_serial_serie_list_edit_item_renewal_date_placeholder"
                      )}
                      name={"items[" + item.id + "][renewal_date]"}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      // locale={sq}
                      dateFormat="dd-MM-yyyy"
                      selected={
                        item.renewal_date
                          ? CheckSelectedDate(item.renewal_date)
                          : null
                      }
                      onChange={(e) =>
                        handleChangeDate(e, `renewal_date`, index, "item")
                      }
                      autoComplete="off"
                      className={
                        "date_input" +
                        (errorList[`items.${index}.renewal_date`]
                          ? " input-error"
                          : "")
                      }
                    />
                    {errorList[`items.${index}.renewal_date`] && (
                      <ValidationMessage
                        message={errorList[`items.${index}.renewal_date`]}
                      />
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}
          {authRole() === "administrator" && (
            <div className="row col-12 mx-0 p-0">
              <div className="form-block col-12 col-md-4">
                <label>
                  {t("edit_serial_serie_list_edit_item_lost_label")}
                </label>
                <div className="radio-buttons d-flex">
                  <div className="radio-button me-4">
                    <label htmlFor={`lost_${index}`}>
                      {t("edit_serial_serie_list_edit_item_lost_yes_label")}
                    </label>
                    <input
                      className="form-check-input"
                      id={`lost_${index}`}
                      name={"items[" + item.id + "][lost]"}
                      onChange={(e) => handleChange(e, `lost`, index, "item")}
                      type="radio"
                      value="1"
                      defaultChecked={item.lost === 1}
                    />
                  </div>

                  <div className="radio-button ms-4">
                    <label htmlFor={`notlost_${index}`} className="">
                      {t("edit_serial_serie_list_edit_item_lost_no_label")}
                    </label>
                    <input
                      className="form-check-input"
                      id={`notlost_${index}`}
                      name={"items[" + item.id + "][lost]"}
                      onChange={(e) => handleChange(e, `lost`, index, "item")}
                      type="radio"
                      value="0"
                      defaultChecked={item.lost === 0}
                    />
                  </div>
                </div>
                {errorList[`items.${index}.lost`] && (
                  <ValidationMessage
                    message={errorList[`items.${index}.lost`]}
                  />
                )}
              </div>
              {(item.lost === 1 || item.lost === "1") && (
                <Fragment>
                  <div className="form-block col-12 col-md-4 removable-field2">
                    <label>
                      {t("edit_serial_serie_list_edit_item_lost_date_label")}
                    </label>
                    <ReactDatePicker
                      placeholderText={t(
                        "edit_serial_serie_list_edit_item_lost_date_placeholder"
                      )}
                      name={"items[" + item.id + "][lost_date]"}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      // locale={sq}
                      dateFormat="dd-MM-yyyy"
                      selected={
                        item.lost_date ? CheckSelectedDate(item.lost_date) : ""
                      }
                      onChange={(e) =>
                        handleChangeDate(e, `lost_date`, index, "item")
                      }
                      autoComplete="off"
                      className={
                        "date_input" +
                        (errorList[`items.${index}.lost_date`]
                          ? " input-error"
                          : "")
                      }
                    />
                    {errorList[`items.${index}.lost_date`] && (
                      <ValidationMessage
                        message={errorList[`items.${index}.lost_date`]}
                      />
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </div>
      ))}

      {btnLoading ? (
        <button className="button-blue has-icon mt-3" type="button">
          <i className="icon spinner-icon me-2" />
          {t("edit_serial_serie_list_edit_item_saving_button")}
        </button>
      ) : (
        <button className="button-blue mt-3" type="submit">
          {t("edit_serial_serie_list_edit_item_save_button")}
        </button>
      )}
    </form>
  );
};
export default EditItems;
