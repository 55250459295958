import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import Loading from "../../../../helpers/Loading";
import ListResponse from "../../../../helpers/ListResponse";
import CustomPagination from "../../../../helpers/CustomPagination";
import SearchFilter from "../../../../helpers/SearchFilter";
import EditAuthor from "./EditAuthor";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const AuthorsList = () => {
  const { t } = useTranslation();

  const [listOfAuthors, setListOfAuthors] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);

  //  Add author states
  const [addAuthorName, setAddAuthorName] = useState("");
  const [addAuthorErrors, setAddAuthorErrors] = useState([]);
  const [addBtnLoading, setAddBtnLoading] = useState(false);

  //  Search author states
  const [searchAuthorName, setSearchAuthorName] = useState("");
  const [searchErrors, setSearchErrors] = useState({});
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  //  Author modal states
  const [authorId, setAuthorId] = useState("");
  const [authorModal, setAuthorModal] = useState(false);
  const setAuthorVisible = (visible) => {
    setAuthorModal(visible);
    setAuthorId("");
  };

  useEffect(() => {
    authorsList();
  }, [action, activePage, searched]);

  const authorsList = (e) => {
    let urlRequest = ApiUrl.listAuthors;
    var searchParams = {};

    // If the filter button is clicked
    if (e) {
      setSearchBtnLoading(true);
      e.preventDefault();
    }

    // Change page
    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      // If the filter button is clicked, but we are in the first page
      searchParams = {
        author_name: searchAuthorName,
      };
    } else if (searched && activePage > 1) {
      // If the filter button is clicked, but we are not in the first page
      searchParams = {
        author_name: searchAuthorName,
        page: activePage,
      };
    }

    if (action && searchAuthorName.length < 1) {
      searchParams = {
        page: activePage,
      };
    }

    // Url of the request with filter parameters
    urlRequest = SearchFilter(searchParams, urlRequest);

    // the function which return the list of authors
    Request.getRequest(urlRequest).then((response) => {
      setSearchBtnLoading(false);
      if (response.status === 200) {
        setListOfAuthors(response.data.authors.data);
        setPagination(response.data.authors);
        setLoading(false);
        setAction(false);
        setSearchErrors({});
      } else if (response.status === 422) {
        setAction(false);
        setSearchErrors(response.data);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const addAuthor = (e) => {
    e.preventDefault();
    setAddBtnLoading(true);
    Request.postRequest(ApiUrl.storeAuthor, { name: addAuthorName }).then(
      (response) => {
        setAddBtnLoading(false);
        if (response.status === 201) {
          Message.successMsg(response.data.success);
          setAction(true);
          setAddAuthorName("");
          setSearchAuthorName("");
          setAddAuthorErrors([]);
          setSearched(false);
          setActivePage("1");
          setSearchErrors([]);
        } else if (response.status === 422) {
          setAddAuthorErrors(response.data);
        }
      }
    );
  };

  const deleteAuthor = (id, title) => {
    ConfirmPopup.deletePopup(title).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteAuthor + id).then((response) => {
          if (response.status === 200) {
            Message.successMsg(response.data.success);
            setAction(true);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(response.data.error);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("author_list_title")}</p>
      <div className="add-item pt-4 pb-2 px-4 mb-5">
        <form onSubmit={(e) => addAuthor(e)}>
          <div className="row">
            <label>{t("authors_list_add_author_label")}</label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t("authors_list_add_author_placeholder")}
                className={
                  "form-input" + (addAuthorErrors?.name ? " input-error" : "")
                }
                name="name"
                type="text"
                value={addAuthorName}
                onChange={(e) => setAddAuthorName(e.target.value)}
              />
              {addAuthorErrors?.name && (
                <ValidationMessage message={addAuthorErrors?.name[0]} />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-4 pb-3">
              {addBtnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("authors_list_adding_author_button")}
                </button>
              ) : (
                <button className="button-blue" type="submit">
                  {t("authors_list_add_author_button")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="search-item px-4 mb-5">
        <form onSubmit={(e) => authorsList(e)}>
          <div className="row">
            <label>{t("authors_list_search_author_label")}</label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t("authors_list_search_author_placeholder")}
                className={
                  "form-input" +
                  (searchErrors?.author_name ? " input-error" : "")
                }
                name="author_name"
                value={searchAuthorName}
                type="text"
                onChange={(e) => setSearchAuthorName(e.target.value)}
              />
              {searchErrors?.author_name && (
                <ValidationMessage message={searchErrors?.author_name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 pb-3">
              {searchBtnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("catalog_listbibiblioitems_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon"
                  type="submit"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("catalog_listbibiblioitems_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" width="70%" className="text-left pl-0">
                {t("authors_list_table_title_author")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("authors_list_table_title_edit")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("authors_list_table_title_delete")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3" className="text-center">
                  <Loading />
                </td>
              </tr>
            ) : (
              listOfAuthors.map((author, index) => (
                <tr key={index} role="row">
                  <td
                    role="cell"
                    data-label={t("authors_list_table_title_author")}
                  >
                    {author.name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("authors_list_table_title_edit")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={() => {
                        setAuthorModal(true);
                        setAuthorId(author.id);
                      }}
                    >
                      <i className="icon edit-icon" />
                    </button>
                    {authorModal && authorId === author.id && (
                      <EditAuthor
                        onShowModal={authorModal}
                        setAuthorVisible={setAuthorVisible}
                        setModified={setAction}
                        authorId={author.id}
                        authorName={author.name}
                      />
                    )}
                  </td>
                  <td
                    role="cell"
                    data-label={t("authors_list_table_title_delete")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={() => {
                        deleteAuthor(author.id, author.name);
                      }}
                    >
                      <i className="icon delete-icon" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(searchErrors).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default AuthorsList;
