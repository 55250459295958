import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const StaffList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [listOfStaff, setListOfStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listStaff();
  }, [activePage]);

  const listStaff = () => {
    let request = ApiUrl.staffList;
    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
      request = SearchFilter(searchParams, request);
    }

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setListOfStaff(response.data.users.data);
        setLoading(false);
        setPagination(response.data.users);
      } else {
        setListOfStaff([]);
        setLoading(true);
      }
    });
  };

  const deleteStaff = (e, id, name) => {
    ConfirmPopup.deletePopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteStaff + id).then((response) => {
          if (response.status === 200) {
            listStaff();
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("staff_list_title")}</p>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" className="text-left">
                {t("staff_list_name")}
              </th>
              <th role="columnheader" className="text-center">
                {t("staff_list_lastname")}
              </th>
              <th role="columnheader" className="text-center">
                {t("staff_list_email")}
              </th>
              <th role="columnheader" className="text-center">
                {t("staff_list_role")}
              </th>
              <th role="columnheader" className="text-center">
                {t("staff_list_status")}
              </th>
              <th role="columnheader" className="text-center">
                {t("staff_list_action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="6">
                  <Loading />
                </td>
              </tr>
            ) : (
              listOfStaff.map((item) => (
                <tr key={item.id} role="row">
                  <td data-label={t("staff_list_name")}>{item.first_name}</td>
                  <td
                    data-label={t("staff_list_lastname")}
                    className="text-center "
                  >
                    {item.last_name}
                  </td>
                  <td
                    data-label={t("staff_list_email")}
                    className="text-center email"
                  >
                    {item.email}
                  </td>
                  <td
                    data-label={t("staff_list_role")}
                    className="text-center "
                  >
                    {item.role.name_trans}
                  </td>
                  <td
                    data-label={t("staff_list_status")}
                    className="text-center "
                  >
                    {item.token !== null
                      ? t("staff_list_not_confirmed")
                      : t("staff_list_confirmed")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("staff_list_action")}
                    className="text-center"
                  >
                    <div className="row justify-content-around mr-auto ml-auto action-icons">
                      <div className="col-3 show">
                        <button
                          className="button-unstyled-icon"
                          onClick={() => navigate(Path.showStaff + item.id)}
                        >
                          <i className="icon show-icon" />
                        </button>
                      </div>
                      <div className="col-3 edit">
                        <button
                          className="button-unstyled-icon"
                          onClick={(e) => navigate(Path.editStaff + item.id)}
                        >
                          <i className="icon edit-icon" />
                        </button>
                      </div>
                      <div className="col-3 delete">
                        <button
                          type="submit"
                          className="button-unstyled-icon"
                          onClick={(e) =>
                            deleteStaff(
                              e,
                              item.id,
                              item.first_name + " " + item.last_name
                            )
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
        <div>
          <CustomPagination
            data={pagination}
            setActivePage={(e) => setActivePage(e)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default StaffList;
