import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { privateRoutes } from "../../routes/Routes";

import * as Path from "../../routes/GeneralPaths";
import * as Request from "../../api/Request";
import * as ApiUrl from "../../api/ApiUrl";
import * as AppConfig from "../../helpers/AppConfig";

import ValidationMessage from "../../helpers/ValidationMessage";

const Login = () => {
  const { t } = useTranslation("");

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userRemember, setUserRemember] = useState("0");
  const [userShowPassword, setUserShowPassword] = useState(false);
  const [userErrorList, setUserErrorList] = useState([]);
  const [userShowBtnLoading, setUserShowBtnLoading] = useState(false);
  const [userShowLogin, setUserShowLogin] = useState(false);

  const [emsUsername, setEmsUsername] = useState("");
  const [emsPassword, setEmsPassword] = useState("");
  const [emsShowPassword, setEmsShowPassword] = useState(false);
  const [emsErrorList, setEmsErrorList] = useState([]);
  const [emsShowBtnLoading, setEmsShowBtnLoading] = useState(false);
  const [emsShowLogin, setEmsShowLogin] = useState(false);

  const userLogin = (e) => {
    e.preventDefault();
    setUserShowBtnLoading(true);

    const loginData = {
      email: userEmail,
      password: userPassword,
      remember: userRemember,
    };

    Request.postRequestLogin(ApiUrl.login, loginData).then((response) => {
      setUserShowBtnLoading(false);
      if (response.status === 200) {
        AppConfig.setAccessToken(
          response.data.token,
          response.data.user.role.name
        );
        if (
          sessionStorage.getItem("path") &&
          privateRoutes().find((e) => {
            if (
              e.path.includes(
                sessionStorage.getItem("path").split("/").slice(0, 3).join("/")
              ) &&
              e.access.includes(response.data.user.role.name)
            ) {
              return true;
            } else {
              return false;
            }
          })
        ) {
          window.location.replace(sessionStorage.getItem("path"));
        } else {
          window.location.replace(Path.dashboard);
        }
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 422
      ) {
        setUserErrorList(response.data);
      }
    });
  };
  const emsLogin = (e) => {
    e.preventDefault();
    setEmsShowBtnLoading(true);

    const loginData = {
      username: emsUsername,
      password: emsPassword,
    };

    Request.postRequestLogin(ApiUrl.emslogin, loginData).then((response) => {
      setEmsShowBtnLoading(false);
      if (response.status === 200) {
        AppConfig.setAccessToken(
          response.data.token,
          response.data.user.role.name
        );
        if (
          sessionStorage.getItem("path") &&
          privateRoutes().find((e) => {
            if (
              e.path.includes(
                sessionStorage.getItem("path").split("/").slice(0, 3).join("/")
              ) &&
              e.access.includes(response.data.user.role.name)
            ) {
              return true;
            } else {
              return false;
            }
          })
        ) {
          window.location.replace(sessionStorage.getItem("path"));
        } else {
          window.location.replace(Path.dashboard);
        }
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 422 ||
        response.status === 500
      ) {
        setEmsErrorList(response.data);
      }
    });
  };

  return (
    <Fragment>
      <div className="accordion form-logins" id="libraryLogin">
        <div className="login_bg staff_login">
          <h3 className="login_title">{t("identify_login_title")}</h3>
          <p className="login_note">
            {/* {t("identify_login_text")} */}
            {t("identify_login_text_test")}
          </p>
          <div
            id="userLogin"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#libraryLogin"
          >
            <form className="w-100" onSubmit={(e) => userLogin(e)}>
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  placeholder={t("identify_login_email_placeholder")}
                  className={
                    "form-input" +
                    (userErrorList.email || userErrorList.error
                      ? " input-error"
                      : "")
                  }
                  onChange={(e) => setUserEmail(e.target.value)}
                />
                <i className="icon username-icon" />
                {userErrorList.email && (
                  <ValidationMessage message={userErrorList.email[0]} />
                )}
                {userErrorList.error && (
                  <ValidationMessage message={userErrorList.error} />
                )}
              </div>
              <div className="form-group">
                <input
                  type={userShowPassword ? "text" : "password"}
                  name="password"
                  placeholder={t("identify_login_password_placeholder")}
                  className={
                    "form-input" +
                    (userErrorList.password || userErrorList.error
                      ? " input-error"
                      : "")
                  }
                  autoComplete="off"
                  onChange={(e) => setUserPassword(e.target.value)}
                />
                <i className="icon lock-icon" />

                <div
                  className="show-hide"
                  onClick={(e) => setUserShowPassword(!userShowPassword)}
                >
                  {userShowPassword ? (
                    <i className="icon show-icon" />
                  ) : (
                    <i className="icon hide-icon" />
                  )}
                </div>
                {userErrorList.password && (
                  <ValidationMessage message={userErrorList.password[0]} />
                )}
              </div>
              <div className="form-check-auth">
                <div className="form-group">
                  <input
                    className="form-input-checkbox"
                    type="checkbox"
                    name="remember"
                    id="remember"
                    onChange={(e) =>
                      setUserRemember(e.target.checked ? "1" : "0")
                    }
                  />
                  <label
                    className="custom-control-label chechbox-elt remember-me-b"
                    htmlFor="remember"
                  >
                    {t("identify_login_remember_me")}
                  </label>
                </div>
                <div className="forgot-password">
                  <Link to={Path.forgotPassword}>
                    {t("identify_login_forgot_password")}
                  </Link>
                </div>
              </div>
              {userShowBtnLoading ? (
                <button
                  type="button"
                  className="button-blue mt-2 w-100 has-icon"
                >
                  <i className="spinner-icon" />
                  {t("identify_login_button_loading")}
                </button>
              ) : (
                <button type="submit" className="button-blue mt-2 w-100">
                  {t("identify_login_button")}
                </button>
              )}
            </form>
          </div>
          {!userShowLogin && (
            <div className="show_login">
              <button
                className="button-blue"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#userLogin"
                aria-expanded="true"
                aria-controls="userLogin"
                onClick={() => {
                  setUserShowLogin(true);
                  setEmsShowLogin(false);
                }}
              >
                {t("identify_login_button")}
              </button>
            </div>
          )}
        </div>

        {/* <div className="login_bg ems_login">
          <h3 className="login_title">{t("ems_identify_login_title")}</h3>
          <p className="login_note">{t("ems_identify_login_text")}</p>
          <div
            id="emsLogin"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#libraryLogin"
          >
            <form className="w-100" onSubmit={(e) => emsLogin(e)}>
              <div className="form-group">
                <input
                  type="text"
                  name="username"
                  placeholder={t("ems_identify_login_email_placeholder")}
                  onChange={(e) => setEmsUsername(e.target.value)}
                  className={
                    "form-input" + (emsErrorList.error ? " input-error" : "")
                  }
                />
                <i className="icon username-icon" />
                {emsErrorList && (
                  <ValidationMessage message={emsErrorList.error} />
                )}
              </div>
              <div className="form-group">
                <input
                  type={emsShowPassword === false ? "password" : "text"}
                  name="password"
                  placeholder={t("ems_identify_login_password_placeholder")}
                  onChange={(e) => setEmsPassword(e.target.value)}
                  className={
                    "form-input" + (emsErrorList.error ? " input-error" : "")
                  }
                  autoComplete={"off"}
                />
                <i className="icon lock-icon"></i>

                <div
                  className="show-hide"
                  onClick={() => setEmsShowPassword(!emsShowPassword)}
                >
                  {emsShowPassword ? (
                    <i className="icon show-icon" />
                  ) : (
                    <i className="icon hide-icon" />
                  )}
                </div>
              </div>
              {emsShowBtnLoading ? (
                <button
                  type="button"
                  className="button-blue mt-2 w-100 has-icon"
                >
                  <i className="spinner-icon" />
                  {t("ems_identify_login_button_loading")}
                </button>
              ) : (
                <button type="submit" className="button-blue mt-2 w-100">
                  {t("ems_identify_login_button")}
                </button>
              )}
            </form>
          </div>
          {!emsShowLogin && (
            <div className="show-login">
              <button
                className="button-blue"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#emsLogin"
                aria-expanded="true"
                aria-controls="emsLogin"
                onClick={() => {
                  setUserShowLogin(false);
                  setEmsShowLogin(true);
                }}
              >
                {t("identify_login_button")}
              </button>
            </div>
          )}
        </div> */}
      </div>
    </Fragment>
  );
};

export default Login;
