import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import SearchFilter from "../../../helpers/SearchFilter";
import * as Message from "../../../helpers/Messages";
import ListResponse from "../../../helpers/ListResponse";
import Select from "react-select";
import * as AppConfig from "../../../helpers/AppConfig";
import * as Tooltip from "../../../helpers/Tooltip";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

const ListPending = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [borrowings, setBorrowings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search_text, setSearch_Text] = useState("");
  const [type, setType] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    pendingList();
  }, [activePage, searched]);

  const pendingList = (hasSearch) => {
    let request = ApiUrl.listPending;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: search_text,
        type: type,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: search_text,
        type: type,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setBorrowings(response.data.borrowings.data);
        setLoading(false);
        setError({});
        setPagination(response.data.borrowings);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const borrowMaterial = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(ApiUrl.borrowPendingMaterial, data).then((response) => {
      if (response.status === 200) {
        navigate(Path.listBorrowings);
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        pendingList();
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const cancelPending = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(ApiUrl.cancelPending, data).then((response) => {
      if (response.status === 200) {
        pendingList();
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        pendingList();
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Fragment>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12">
            <p className="partial-title mb-5">{t("list_pending_awaiting")}</p>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0">
        <form onSubmit={(e) => pendingList(e)} className="borrowing-search">
          <div className="row mb-3">
            <div className="form-block col-12 col-sm-6 col-md-4 pb-4 mb-4">
              <input
                placeholder={t("list_pending_search")}
                className={
                  "w-100 form-input" + (error.search_text ? " input-error" : "")
                }
                onChange={(e) => setSearch_Text(e.target.value)}
                name="search_text"
                type="text"
              />
              {error.search_text && (
                <ValidationMessage message={error.search_text[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 pb-4 mb-4">
              <Select
                className={
                  "form-react-select" + (error.type ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="type"
                isSearchable={false}
                isClearable={true}
                onChange={(e) => (e ? setType(e.value) : setType(""))}
                placeholder={t("list_pending_choose")}
                options={[
                  { value: "member", label: t("member") },
                  { value: "nr_inventory", label: t("codification") },
                  { value: "title", label: t("title") },
                ]}
              />
              {error.type && <ValidationMessage message={error.type[0]} />}
            </div>
            <div className="col-12 col-sm-6 col-md-4 pb-4 mb-4">
              {btnLoading === true ? (
                <button
                  className="button-blue has-icon px-5 ml-0"
                  type={"button"}
                >
                  <i className="icon spinner-icon me-2" />
                  {t("list_pending_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon px-5 ml-0"
                  type={"submit"}
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("list_pending_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="container-fluid px-0">
        <div className="table-list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th className="text-left" width="12.5%" role="columnheader">
                  {t("list_pending_member")}
                </th>
                <th className="text-center" width="12.5%" role="columnheader">
                  {t("list_pending_title_author")}
                </th>
                <th className="text-center" width="12.5%" role="columnheader">
                  {t("list_pending_type_of_borrowing")}
                </th>
                <th className="text-center" width="12.5%" role="columnheader">
                  {t("list_pending_codification_inventary_number")}
                </th>
                <th className="text-center" width="12.5%" role="columnheader">
                  {t("list_pending_give_borrowing")}
                </th>
                <th className="text-center" width="12.5%" role="columnheader">
                  {t("list_pending_cancel_borrowing")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="6">
                    <Loading />
                  </td>
                </tr>
              ) : (
                borrowings.map((borrowing, index) => (
                  <tr key={index}>
                    <td
                      className="text-left"
                      data-label={t("list_pending_member")}
                    >
                      {borrowing.user.first_name +
                        " " +
                        borrowing.user.last_name}
                    </td>
                    <td
                      className="text-center"
                      data-label={t("list_pending_title_author")}
                    >
                      <div className="text-md-center d-inline-flex">
                        {Tooltip.tooltipDisabledMobile(
                          index,
                          borrowing.item.biblioitem.biblio.title
                        )}
                      </div>
                      <div>
                        {borrowing.item.biblioitem.biblio.authors
                          .map((author) => author.name)
                          .join("; ")}
                      </div>
                    </td>
                    <td
                      className="text-center"
                      data-label={t("list_pending_type_of_borrowing")}
                    >
                      {borrowing.item.borrowing_type_trans}
                    </td>
                    <td
                      className="text-center"
                      data-label={t(
                        "list_pending_codification_inventary_number"
                      )}
                    >
                      {borrowing.item.inventory_number +
                        " / " +
                        borrowing.item.id}
                    </td>
                    <td
                      className="text-center"
                      data-label={t("list_pending_give_borrowing")}
                    >
                      <button
                        className="button-blue-borrow"
                        onClick={(e) => borrowMaterial(e, borrowing.id)}
                        type="submit"
                      >
                        {t("list_pending_give_borrowing")}
                      </button>
                    </td>
                    <td
                      className="text-center"
                      data-label={t("list_pending_cancel_borrowing")}
                    >
                      <button
                        className="button-blue"
                        onClick={(e) => cancelPending(e, borrowing.id)}
                        type="submit"
                      >
                        {t("list_pending_cancel_borrowing")}
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={searched}
          />
        </div>
      </div>
      <div className="container-fluid pt-5 pb-4">
        <div className="d-flex justify-content-center pagination-block">
          <CustomPagination
            data={pagination}
            setActivePage={(e) => {
              setActivePage(e);
              Object.keys(error).length > 0 && setSearched(false);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ListPending;
