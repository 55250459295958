import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as AppConfig from "../../../helpers/AppConfig";
import SearchFilter from "../../../helpers/SearchFilter";
import * as Message from "../../../helpers/Messages";
import ListResponse from "../../../helpers/ListResponse";
import Select from "react-select";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import * as Tooltip from "../../../helpers/Tooltip";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";
import CheckCover from "../../../helpers/CheckCover";

const SerialList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [listSerials, setListSerials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search_text, setSearch_Text] = useState("");
  const [type, setType] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    SerialsList();
  }, [activePage, searched]);

  const SerialsList = (hasSearch) => {
    let request = ApiUrl.listSerials;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: search_text,
        type: type,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: search_text,
        type: type,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setListSerials(response.data.serials.data);
        setLoading(false);
        setError({});
        setPagination(response.data.serials);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const deleteSerial = (e, id, title) => {
    ConfirmPopup.deletePopup(title).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteSerial + id).then((response) => {
          if (response.status === 200) {
            listSerials();
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else if (response.status === 405) {
            Message.infoMsg(response.data.info);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("serial_list_of_serials")}</p>
      <div className="search-item">
        <form onSubmit={(e) => SerialsList(e)}>
          <div className="row mb-5">
            <div className="form-block col-12 col-sm-6 col-md-4 pb-4">
              <input
                placeholder={t("serial_list_search")}
                className={
                  "form-input" + (error.search_text ? " input-error" : "")
                }
                name="search_text"
                type="text"
                onChange={(e) => setSearch_Text(e.target.value)}
              />
              {error.search_text && (
                <ValidationMessage message={error.search_text[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 pb-4">
              <Select
                className={
                  "form-react-select" + (error.type ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="type"
                isClearable={true}
                placeholder={t("serial_list_choose")}
                onChange={(e) => (e ? setType(e.value) : setType(""))}
                isSearchable={false}
                options={[
                  { value: "title", label: t("title") },
                  { value: "issn_isbn", label: t("issn_isbn") },
                ]}
              />
              {error.type && <ValidationMessage message={error.type[0]} />}
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              {btnLoading === true ? (
                <button className="button-blue has-icon px-5" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("serial_list_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon px-5"
                  type="submit"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("serial_list_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list-bg">
        <div id="list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" width="25%" className="text-left pl-0">
                  {t("serial_list_title_author")}
                </th>
                <th role="columnheader" width="17%" className="text-center">
                  {t("serial_list_isbn_issn")}
                </th>
                <th role="columnheader" width="17%" className="text-center">
                  {t("serial_list_publisher")}
                </th>
                <th role="columnheader" width="12%" className="text-center">
                  {t("serial_list_frequency")}
                </th>
                <th role="columnheader" width="12%" className="text-center">
                  {t("serial_list_number_of_series")}
                </th>
                <th role="columnheader" width="12%" className="text-center ">
                  {t("serial_list_change")}
                </th>
                <th role="columnheader" width="5%" className="text-center pr-0">
                  {t("serial_list_delete")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="7">
                    <Loading />
                  </td>
                </tr>
              ) : (
                listSerials.map((serial, index) => (
                  <tr
                    key={serial.id}
                    role="row"
                    onClick={() => navigate(Path.showSerial + serial.id)}
                  >
                    <td role="cell">
                      <div className="table-info-data">
                        <div className="cover">
                          <img
                            src={CheckCover(
                              serial.biblioitem.cover,
                              serial.biblioitem.biblio.materialtype_id
                            )}
                            alt="Cover"
                          />
                        </div>
                        <div className="info-block">
                          <span className="title">
                            {Tooltip.tooltipDisabledMobile(
                              serial.id,
                              serial.biblioitem.biblio.title
                            )}
                          </span>
                          <span className="subtitle">
                            {serial.biblioitem.biblio.authors
                              .map((author) => author.name)
                              .join("; ")}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td
                      role="cell"
                      data-label={t("serial_list_isbn_issn")}
                      className="text-center "
                    >
                      {serial.biblioitem.isbn}
                      {serial.biblioitem.issn && " / "}
                      {serial.biblioitem.issn}
                    </td>
                    <td
                      role="cell"
                      data-label={t("serial_list_publisher")}
                      className="text-center "
                    >
                      {serial.biblioitem.publisher !== null &&
                        serial.biblioitem.publisher.name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("serial_list_frequency")}
                      className="text-center "
                    >
                      {serial.frequence.description}
                    </td>
                    <td
                      role="cell"
                      data-label={t("serial_list_number_of_series")}
                      className="text-center "
                    >
                      {serial.series_count}
                    </td>
                    <td
                      role="cell"
                      data-label={t("serial_list_change")}
                      className="text-center"
                    >
                      <button
                        className="button-unstyled-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(Path.editSerial + serial.id);
                        }}
                      >
                        <i className="icon edit-icon" />
                      </button>
                    </td>
                    <td
                      role="cell"
                      data-label={t("serial_list_delete")}
                      className="text-center pr-2"
                    >
                      <button
                        type="submit"
                        className="button-unstyled-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteSerial(
                            e,
                            serial.id,
                            serial.biblioitem.biblio.title
                          );
                        }}
                      >
                        <i className="icon delete-icon" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={searched}
          />
        </div>
      </div>
      <div className="container-fluid pt-5 pb-4">
        <CustomPagination
          data={pagination}
          setActivePage={(e) => {
            setActivePage(e);
            Object.keys(error).length > 0 && setSearched(false);
          }}
        />
      </div>
    </Fragment>
  );
};

export default SerialList;
