import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ShowBibliography } from "../../../../../store/Catalog/ShowBibliography/Bibliography";
import { SpecimenData } from "../../../../../store/Catalog/ShowBibliography/Specimen";

import Loading from "../../../../../helpers/Loading";
import CheckCover from "../../../../../helpers/CheckCover";
import Description from "./Description";
import Isbd from "./Isbd";
import Marc from "./Marc";
import Specimen from "./Specimen";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";

const ShowBiblioitem = () => {
  const { t } = useTranslation("");
  const { id } = useParams("");

  const navigate = useNavigate();

  const resetBiblio = ShowBibliography((state) => state.reset);
  const addBiblioData = ShowBibliography((state) => state.addBiblioData);

  const resetSpecimenData = SpecimenData((state) => state.reset);
  const addSpecimenData = SpecimenData((state) => state.addSpecimenData);

  const [biblioItem, setBiblioItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("description");

  useEffect(() => {
    resetBiblio();
    resetSpecimenData();
    showBiblioitem();
  }, []);

  const showBiblioitem = () => {
    Request.getRequest(ApiUrl.showBiblioitem + id).then((response) => {
      if (response.status === 200) {
        setBiblioItem(response.data.biblioitem);
        addBiblioData(response.data.biblioitem);
        addSpecimenData(response?.data?.biblioitem?.items);
        setLoading(false);
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
        navigate(-1);
      } else {
        Message.errorMsg(t("error_message"));
        navigate(-1);
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">
        {t("catalog_showbibliography_title")}
      </p>
      <div className="detailed-biblio-infohead">
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            <div className="material-type">
              {biblioItem.biblio.materialtype
                ? biblioItem.biblio.materialtype.name_trans
                : ""}
            </div>
            <div className="material-title">
              {biblioItem.biblio.title +
                (biblioItem.biblio.subtitle
                  ? " : " + biblioItem.biblio.subtitle
                  : "")}
            </div>
            <div className="material-author">
              {biblioItem.biblio.authors.length > 0
                ? biblioItem.biblio.authors
                    .map((author) => author.name)
                    .join(", ")
                : ""}
            </div>
          </Fragment>
        )}
      </div>
      <div className="col-12 mb-4 d-block d-md-none image-holder text-center">
        {!loading && (
          <img
            src={CheckCover(
              biblioItem.cover,
              biblioItem.biblio.materialtype.id
            )}
            alt="Material cover"
          />
        )}
      </div>
      <div className="mt-4 pt-4">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li
            className={
              "nav-item col-12 col-md-4 p-0" +
              (tab === "description" ? " active" : "")
            }
            role="presentation"
          >
            <button
              className={
                "nav-link w-100" + (tab === "description" ? " active" : "")
              }
              onClick={() => setTab("description")}
            >
              {t("show_showbibliography_description_tab")}
            </button>
          </li>
          <li
            className={
              "nav-item col-12 col-md-4 p-0" + (tab === "isbd" ? " active" : "")
            }
            role="presentation"
          >
            <button
              className={"nav-link w-100" + (tab === "isbd" ? " active" : "")}
              onClick={() => setTab("isbd")}
            >
              {t("show_showbibliography_isbd_tab")}
            </button>
          </li>
          <li
            className={
              "nav-item col-12 col-md-4 p-0" + (tab === "marc" ? " active" : "")
            }
            role="presentation"
          >
            <button
              className={"nav-link w-100" + (tab === "marc" ? " active" : "")}
              onClick={() => setTab("marc")}
            >
              {t("show_showbibliography_marc_tab")}
            </button>
          </li>
        </ul>

        <div className="tab-content-bg">
          {!loading ? (
            tab === "description" ? (
              <Description />
            ) : tab === "isbd" ? (
              <Isbd />
            ) : (
              tab === "marc" && <Marc />
            )
          ) : (
            <div className="d-flex justify-content-center">
              <Loading />
            </div>
          )}
        </div>
        <Specimen loading={loading} />
      </div>
    </Fragment>
  );
};

export default ShowBiblioitem;
