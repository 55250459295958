import { Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useUserdata } from "../../../store/UserData";
import SimpleBar from "simplebar-react";

import * as Path from "../../../routes/GeneralPaths";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Request from "../../../api/Request";
import * as AppConfig from "../../../helpers/AppConfig";

const SidebarContent = () => {
  const ref = useRef();
  const sidebarRef = useRef();
  const { t } = useTranslation();

  const userData = useUserdata((state) => state.userData);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      if (ref.current.querySelector(".active")) {
        const activeA = ref.current.querySelector(".active");
        const activeLi = activeA.parentElement;
        const activeUl = activeLi.parentElement;
        const activeDiv = activeUl.parentElement;
        activeDiv.classList.add("show");
        activeDiv.parentElement.classList.add("active-item");
        const btn = activeDiv.parentElement.querySelector("button");
        btn.classList.remove("collapsed");
      }
    }
  }, []);

  if (Object.keys(userData).length > 0) {
    if (userData.role === AppConfig.authRole()) {
      var administrator = userData.role === "administrator";
      var operator = userData.role === "operator";
      var it = userData.role === "it";
      var librarian = userData.role === "librarian";
    } else {
      Request.postRequest(ApiUrl.logout).then((response) => {
        if (response.status === 200) {
          AppConfig.deleteAccessToken();
          window.location.href = Path.homepage;
        } else {
          AppConfig.deleteAccessToken();
          window.location.href = Path.homepage;
        }
      });
    }
  }

  return (
    <Fragment>
      <SimpleBar className="h-100" ref={sidebarRef}>
        <div id="sidebar-menu" ref={ref}>
          {(administrator || operator || it) && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#catalog"
                aria-expanded="false"
                aria-controls="catalog"
              >
                <i className="icon catalog-icon" />
                <span>{t("sidebar_catalog")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="catalog"
                className="collapse"
                aria-labelledby="catalog"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  {(administrator || operator) && (
                    <Fragment>
                      <li>
                        <NavLink to={Path.listBiblioitems}>
                          {t("sidebar_bibliography")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={Path.createBiblio}>
                          {t("sidebar_add_bibliography")}
                        </NavLink>
                      </li>
                      {administrator && (
                        <Fragment>
                          <li>
                            <NavLink to={Path.importMarc}>
                              {t("sidebar_import_marc")}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={Path.exportMarc}>
                              {t("sidebar_export_marc")}
                            </NavLink>
                          </li>
                        </Fragment>
                      )}
                      <li>
                        <NavLink to={Path.z3950Service}>
                          {t("sidebar_service_z3950")}
                        </NavLink>
                      </li>
                    </Fragment>
                  )}
                  {(administrator || it) && (
                    <li>
                      <NavLink to={Path.z3950Server}>
                        {t("sidebar_servers_z3950")}
                      </NavLink>
                    </li>
                  )}
                  {(administrator || operator) && (
                    <Fragment>
                      <li>
                        <NavLink to={Path.createAuthor}>
                          {t("sidebar_author")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={Path.createPublisher}>
                          {t("sidebar_publisher")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={Path.createKeyWords}>
                          {t("sidebar_key_words")}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={Path.createGeogreaphicalTerm}>
                          {t("sidebar_geographical_terms")}
                        </NavLink>
                      </li>
                    </Fragment>
                  )}
                </ul>
              </div>
            </div>
          )}
          {(administrator || operator) && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#borrow"
                aria-expanded="false"
                aria-controls="borrow"
              >
                <i className="icon borrow-icon" />
                <span>{t("sidebar_borrowing")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="borrow"
                className="collapse"
                aria-labelledby="borrow"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <li>
                    <NavLink to={Path.borrow}>{t("sidebar_borrow")}</NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.listBorrowings}>
                      {t("sidebar_borrowings_list")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.listPending}>
                      {t("sidebar_pending")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.reservations}>
                      {t("sidebar_reservations")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {(administrator || operator) && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#serial"
                aria-expanded="false"
                aria-controls="catalog"
              >
                <i className="icon serial-icon" />
                <span>{t("sidebar_serials")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="serial"
                className="collapse"
                aria-labelledby="serial"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <li>
                    <NavLink to={Path.createSerial}>
                      {t("sidebar_new_serial")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.listSerials}>
                      {t("sidebar_serials_list")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.frequence}>
                      {t("sidebar_frequences")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {(administrator || operator) && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#member"
                aria-expanded="false"
                aria-controls="member"
              >
                <i className="icon member-icon" />
                <span>{t("sidebar_members")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="member"
                className="collapse"
                aria-labelledby="member"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <li>
                    <NavLink to={Path.listMembers}>
                      {t("sidebar_members_list")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.addMember}>
                      {t("sidebar_add_member")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {administrator && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#staff"
                aria-expanded="false"
                aria-controls="staff"
              >
                <i className="icon staff-icon" />
                <span>{t("sidebar_staff")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="staff"
                className="collapse"
                aria-labelledby="staff"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <li>
                    <NavLink to={Path.listStaff}>
                      {t("sidebar_staff_list")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.addStaff}>
                      {t("sidebar_add_staff")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {(administrator || operator) && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#acquisition"
                aria-expanded="false"
                aria-controls="acquisition"
              >
                <i className="icon acquisition-icon" />
                <span>{t("sidebar_acquisition")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="acquisition"
                className="collapse"
                aria-labelledby="acquisition"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <li>
                    <NavLink to={Path.listAcquisition}>
                      {t("sidebar_acquisitions_list")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.createAcquisition}>
                      {t("sidebar_add_new_acquisition")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {(administrator || librarian) && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#report"
                aria-expanded="false"
                aria-controls="report"
              >
                <i className="icon report-icon" />
                <span>{t("sidebar_reports")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="report"
                className="collapse"
                aria-labelledby="report"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <li>
                    <NavLink to={Path.reportStats}>
                      {t("sidebar_reports_stats")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.reportCatalog}>
                      {t("sidebar_reports_catalog")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.reportBorrowing}>
                      {t("sidebar_reports_borrowing")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {(administrator || it) && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#backup"
                aria-expanded="false"
                aria-controls="backup"
              >
                <i className="icon backup-icon" />
                <span>{t("sidebar_backup")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="backup"
                className="collapse"
                aria-labelledby="backup"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <li>
                    <NavLink to={Path.listBackup}>
                      {t("sidebar_backups_list")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.logs}>{t("sidebar_logs")}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {(administrator || operator || it) && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="blog"
                data-bs-toggle="collapse"
                data-bs-target="#blog"
                aria-expanded="false"
                aria-controls="blog"
              >
                <i className="icon blog-icon" />
                <span>{t("sidebar_blog")}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="blog"
                className="collapse"
                aria-labelledby="blog"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <li>
                    <NavLink to={Path.listBlogPosts}>
                      {t("sidebar_blogs_list")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Path.createBlogPost}>
                      {t("sidebar_add_new_blog")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </SimpleBar>
    </Fragment>
  );
};

export default SidebarContent;
