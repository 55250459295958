import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import SearchFilter from "../../../helpers/SearchFilter";
import * as Message from "../../../helpers/Messages";
import ListResponse from "../../../helpers/ListResponse";
import Select from "react-select";
import * as AppConfig from "../../../helpers/AppConfig";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import * as Tooltip from "../../../helpers/Tooltip";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

const Reservations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search_text, setSearch_Text] = useState("");
  const [type, setType] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    reservationList();
  }, [activePage, searched]);

  const reservationList = (hasSearch) => {
    let request = ApiUrl.listReservations;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: search_text,
        type: type,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: search_text,
        type: type,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setReservations(response.data.reservations.data);
        setLoading(false);
        setError({});
        setPagination(response.data.reservations);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const borrowMaterial = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(ApiUrl.borrowReservedMaterial, data).then(
      (response) => {
        if (response.status === 200) {
          navigate(Path.listBorrowings);
          Message.successMsg(response.data.success);
        } else if (response.status === 404) {
          reservationList();
          Message.errorMsg(response.data.error);
        } else {
          Message.errorMsg(t("error_message"));
        }
      }
    );
  };

  return (
    <Fragment>
      <div id="reservations">
        <p className="partial-title mb-5">{t("reservations_reservation")}</p>
        <div className="input-items">
          <form onSubmit={(e) => reservationList(e)}>
            <div className="row mb-3">
              <div className="form-block col-12 col-sm-6 col-md-4 mb-4 pb-4">
                <input
                  placeholder={t("reservations_search")}
                  className={
                    "form-input" + (error.search_text ? " input-error" : "")
                  }
                  name="search_text"
                  onChange={(e) => setSearch_Text(e.target.value)}
                  type="text"
                />
                {error.search_text && (
                  <ValidationMessage message={error.search_text[0]} />
                )}
              </div>
              <div className="form-block col-12 col-sm-6 col-md-4 mb-4 pb-4">
                <Select
                  className={
                    "form-react-select" + (error.type ? " input-error" : "")
                  }
                  classNamePrefix="select_input"
                  name="type"
                  isSearchable={false}
                  isClearable={true}
                  onChange={(e) => (e ? setType(e.value) : setType(""))}
                  placeholder={t("reservations_choose")}
                  options={[
                    { value: "member", label: t("member") },
                    { value: "nr_inventory", label: t("codification") },
                    { value: "title", label: t("title") },
                  ]}
                />
                {error.type && <ValidationMessage message={error.type[0]} />}
              </div>
              <div className="col-12 col-sm-6 col-md-4 mb-4 pb-4">
                {btnLoading === true ? (
                  <button
                    className="button-blue has-icon px-5 ml-0"
                    type={"button"}
                  >
                    <i className="icon spinner-icon me-2" />
                    {t("reservations_searching")}
                  </button>
                ) : (
                  <button
                    className="button-blue has-icon px-5 ml-0"
                    type={"submit"}
                    onClick={() => {
                      setSearched(true);
                      setActivePage("1");
                    }}
                  >
                    <i className="icon search-icon me-2" />
                    {t("reservations_search")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="table-list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" className="pr-3 text-left" width="20%">
                  {t("reservations_member")}
                </th>
                <th
                  role="columnheader"
                  className="pr-3 text-center"
                  width="20%"
                >
                  {t("reservations_title_author")}
                </th>
                <th
                  role="columnheader"
                  className="pr-3 text-center"
                  width="20%"
                >
                  {t("reservations_borrowing_type")}
                </th>
                <th
                  role="columnheader"
                  className="pr-3 text-center"
                  width="20%"
                >
                  {t("reservations_codification_inventary_number")}
                </th>
                <th
                  role="columnheader"
                  className="pr-3 text-center"
                  width="20%"
                >
                  {t("reservations_give_borrowing")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="6">
                    <Loading />
                  </td>
                </tr>
              ) : (
                reservations.map((reservation, index) => (
                  <tr key={index}>
                    <td data-label={t("reservations_member")}>
                      {reservation.user.first_name +
                        " " +
                        reservation.user.last_name}
                    </td>
                    <td
                      className="text-center"
                      data-label={t("reservations_title_author")}
                    >
                      <div className="text-md-center d-inline-flex">
                        {Tooltip.tooltipDisabledMobile(
                          "title" + index,
                          reservation.item.biblioitem.biblio.title
                        )}
                      </div>
                      <div>
                        {reservation.item.biblioitem.biblio.authors
                          .map((author) => author.name)
                          .join("; ")}
                      </div>
                    </td>
                    <td
                      className="text-center"
                      data-label={t("reservations_borrowing_type")}
                    >
                      {reservation.item.borrowing_type_trans}
                    </td>
                    <td
                      className="text-center"
                      data-label={t(
                        "reservations_codification_inventary_number"
                      )}
                    >
                      {reservation.item.inventory_number +
                        " / " +
                        reservation.item.id}
                    </td>
                    <td
                      className="text-center"
                      data-label={t("reservations_give_borrowing")}
                    >
                      <form>
                        <button
                          className="button-blue-borrow"
                          onClick={(e) => borrowMaterial(e, reservation.id)}
                          type={"submit"}
                        >
                          {t("reservations_give_borrowing")}
                        </button>
                      </form>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={searched}
          />
        </div>
        <div className="container-fluid pt-5 pb-4">
          <CustomPagination
            data={pagination}
            setActivePage={(e) => {
              setActivePage(e);
              Object.keys(error).length > 0 && setSearched(false);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Reservations;
