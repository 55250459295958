import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Sidebar from "../../../../components/layouts/sidebar/Sidebar";
import ConfirmZ390Records from "../../../../components/panel/catalog/z3950/service/ConfirmRecords";

const ConfirmZ3950View = () => {
  const { t } = useTranslation("");
  return (
    <Fragment>
      <Helmet>
        <title>{t("confirm_z3950_tab")}</title>
      </Helmet>
      <Sidebar>
        <ConfirmZ390Records />
      </Sidebar>
    </Fragment>
  );
};

export default ConfirmZ3950View;
