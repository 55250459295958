import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CheckSelectedDate } from "../../../../helpers/CheckValue";
import { tooltipDisabledMobile } from "../../../../helpers/Tooltip";
import SearchFilter from "../../../../helpers/SearchFilter";
import ReactDatePicker from "react-datepicker";
import ReactSelect from "react-select";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";
import moment from "moment/moment";
import CreateSerie from "./CreateSerie";
import SeriesList from "./SeriesList";
import ListResponse from "../../../../helpers/ListResponse";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as Path from "../../../../routes/GeneralPaths";

const EditSerial = () => {
  const { t } = useTranslation("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [frequences, setFrequences] = useState([]);

  // Search serial
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState("");
  const [searched, setSearched] = useState(false);
  const [serials, setSerials] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  // Serial data
  const [serial, setSerial] = useState({});
  const [approvedSeries, setApprovedSeries] = useState([]);
  const [notApprovedSeries, setNotApprovedSeries] = useState([]);

  // Edit serial
  const [biblioitemId, setBiblioitemId] = useState("");
  const [frequenceId, setFrequenceId] = useState("");
  const [selectedFrequence, setSelectedFrequence] = useState({});
  const [startSubscription, setStartSubscription] = useState(null);
  const [endSubscription, setEndSubscription] = useState("");
  const [errorListEdit, setErrorListEdit] = useState([]);
  const [btnEditLoading, setBtnEditLoading] = useState(false);

  const [action, setAction] = useState(false);

  useEffect(() => {
    editSerial();
  }, []);

  useEffect(() => {
    searched && serialList();
  }, [activePage]);

  const editSerial = () => {
    setAction(true);
    Request.getRequest(ApiUrl.editSerial + id).then((response) => {
      if (response.status === 200) {
        setBiblioitemId(response.data.serial.biblioitem_id);
        setFrequenceId(response.data.serial.frequence.id);
        setStartSubscription(response.data.serial.start_subscription);
        setEndSubscription(response.data.serial.end_subscription);
        setSerial(response.data.serial);
        setApprovedSeries(response.data.approvedSeries);
        setNotApprovedSeries(response.data.notApprovedSeries);
        setFrequences(response.data.frequences);
        setLoading(false);
        setSelectedFrequence({
          value: response.data.serial.frequence.id,
          label: response.data.serial.frequence.description,
        });
        setAction(false);
      }
    });
  };

  const serialList = (e) => {
    let request = ApiUrl.createSerial;
    // If the filter button is clicked
    var searchParams = {
      search_text: searchText,
      type: type,
      page: activePage,
    };
    // If the filters button is clicked
    if (e) {
      setBtnLoading(true);
      setLoading(true);
      e.preventDefault();
      setActivePage(1);

      // If the filter button is clicked
      searchParams = {
        search_text: searchText,
        type: type,
        page: 1,
      };
    }

    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      setLoading(false);
      if (response.status === 200) {
        setSerials(response.data.materials.data);
        setPagination(response.data.materials);
        setBtnLoading(false);
        setLoading(false);
        setErrorList([]);
      } else if (response.status === 422) {
        setErrorList(response.data);
      }
    });
  };

  const updateSerial = (e) => {
    e.preventDefault();
    setBtnEditLoading(true);

    var formData = {
      biblioitem_id: biblioitemId,
      frequence_id: frequenceId,
      start_subscription: startSubscription
        ? moment(startSubscription).format("DD-MM-yyyy")
        : "",
      end_subscription: endSubscription
        ? moment(endSubscription).format("DD-MM-yyyy")
        : "",
    };
    Request.patchRequest(ApiUrl.updateSerial + id, formData).then(
      (response) => {
        setBtnEditLoading(false);
        if (response.status === 200) {
          navigate(Path.listSerials);
          Message.successMsg(response.data.success);
          setBiblioitemId("");
          setFrequenceId("");
          setSelectedFrequence({});
          setStartSubscription(null);
          setEndSubscription("");
          setErrorListEdit([]);
        } else if (response.status === 405) {
          Message.infoMsg(response.data.info);
        } else if (response.status === 422) {
          setErrorListEdit(response.data);
        }
      }
    );
  };
  return (
    <Fragment>
      <p className="partial-title mb-5">{t("edit_serial_title")}</p>
      <div className="search-item">
        <form
          onSubmit={(e) => {
            setSearched(true);
            searched && serialList(e);
          }}
        >
          <div className="row mb-5">
            <div className="form-block col-12 col-sm-6 col-md-4">
              <input
                placeholder={t("edit_serial_search_placeholder")}
                className={
                  "form-input" + (errorList.search_text ? " input-error" : "")
                }
                name="search_text"
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {errorList.search_text && (
                <ValidationMessage message={errorList.search_text[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4">
              <ReactSelect
                className={
                  "form-react-select" + (errorList.type ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="type"
                placeholder={t("edit_serial_choose_placeholder")}
                onChange={(e) => setType(e ? e.value : "")}
                isSearchable={false}
                isClearable={true}
                options={[
                  {
                    value: "title",
                    label: t("edit_serial_choose_option_title"),
                  },
                  {
                    value: "author",
                    label: t("edit_serial_choose_option_author"),
                  },
                  {
                    value: "issn_isbn",
                    label: t("edit_serial_choose_option_issn_isbn"),
                  },
                ]}
              />
              {errorList.type && (
                <ValidationMessage message={errorList.type[0]} />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              {btnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("edit_serial_searching_button")}
                </button>
              ) : (
                <button
                  className="button-blue"
                  onClick={() => setSearched(true)}
                  type="submit"
                >
                  {t("edit_serial_search_button")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <form onSubmit={(e) => updateSerial(e)}>
        <div className="table-list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" width="25%" className="text-left pl-0">
                  {t("edit_serial_table_title")}
                </th>
                <th role="columnheader" width="19.75%" className="text-center">
                  {t("edit_serial_table_author")}
                </th>
                <th role="columnheader" width="19.75%" className="text-center">
                  {t("edit_serial_table_isbn_issn")}
                </th>
                <th role="columnheader" width="19.75%" className="text-center">
                  {t("edit_serial_table_publisher")}
                </th>
                <th role="columnheader" width="15.75%" className="text-center">
                  {t("edit_serial_table_choose")}
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading && Object.keys(serial).length > 0 && (
                <tr className="selected">
                  <td role="cell" data-label={t("edit_serial_table_title")}>
                    {tooltipDisabledMobile(
                      "selected_title",
                      serial?.biblioitem?.biblio?.title
                    )}
                    <span className="small-text">
                      {t("edit_serial_table_current_bibliography")}
                    </span>
                  </td>
                  <td
                    role="cell"
                    data-label={t("edit_serial_table_author")}
                    className="text-center"
                  >
                    {serial?.biblioitem?.biblio?.authors
                      .map((author) => author.name)
                      .join("; ")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("edit_serial_table_isbn_issn")}
                    className="text-center"
                  >
                    {serial.biblioitem.isbn ? serial.biblioitem.isbn : "- "}
                    {serial.biblioitem.issn
                      ? " / " + serial.biblioitem.issn
                      : " / -"}
                  </td>
                  <td
                    role="cell"
                    data-label={t("edit_serial_table_publisher")}
                    className="text-center"
                  >
                    {serial.biblioitem.publisher
                      ? serial.biblioitem.publisher.name
                      : "-"}
                  </td>
                  <td
                    role="cell"
                    data-label={t("edit_serial_table_choose")}
                    className="text-center"
                  >
                    <div className="radio-buttons justify-content-md-center">
                      <div className="radio-button">
                        <input
                          className="form-check-input cursor-pointer"
                          name="biblioitem_id"
                          type="radio"
                          value={serial.biblioitem_id}
                          onChange={(e) => setBiblioitemId(e.target.value)}
                          defaultChecked="checked"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <Loading />
                  </td>
                </tr>
              ) : (
                serials.map((serial) => (
                  <tr key={serial.id} role="row">
                    <td role="cell" data-label={t("edit_serial_table_title")}>
                      {tooltipDisabledMobile(serial.id, serial.biblio.title)}
                    </td>
                    <td
                      role="cell"
                      data-label={t("edit_serial_table_author")}
                      className="text-center"
                    >
                      {serial.biblio.authors
                        .map((author) => author.name)
                        .join("; ")}
                    </td>
                    <td
                      role="cell"
                      data-label={t("edit_serial_table_isbn_issn")}
                      className="text-center"
                    >
                      {serial.isbn}
                      {serial.isbn && serial.issn && " / "}
                      {serial.issn}
                    </td>
                    <td
                      role="cell"
                      data-label={t("edit_serial_table_publisher")}
                      className="text-center"
                    >
                      {serial.publisher ? serial.publisher.name : "-"}
                    </td>
                    <td
                      role="cell"
                      data-label={t("edit_serial_table_choose")}
                      className="text-center"
                    >
                      <div className="radio-buttons justify-content-md-center">
                        <div className="radio-button">
                          <input
                            className="form-check-input cursor-pointer"
                            name="biblioitem_id"
                            type="radio"
                            value={serial.id}
                            onChange={(e) => setBiblioitemId(e.target.value)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {searched && Object.keys(pagination).length > 0 && (
            <ListResponse
              totalItems={
                pagination &&
                (Object.keys(pagination).length > 0 ? pagination.total : 0)
              }
              loading={loading}
              searched={searched}
            />
          )}
          {errorListEdit.biblioitem_id && (
            <ValidationMessage message={errorListEdit.biblioitem_id} />
          )}
        </div>
        <CustomPagination
          data={pagination}
          setActivePage={(e) => setActivePage(e)}
        />
        <div className="row my-5">
          <div className="form-block col-12 col-lg-3 col-md-4">
            <label htmlFor="frequence_id-button">
              {t("edit_serial_frequence_label")}
              <span className="biblioitem-tag">(310/A)</span>
            </label>
            <ReactSelect
              className={
                "form-react-select" +
                (errorListEdit.frequence_id ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="frequence_id"
              placeholder={t("edit_serial_frequence_placeholder")}
              isClearable={true}
              menuPlacement="auto"
              isSearchable={false}
              value={
                Object.keys(selectedFrequence).length > 0
                  ? selectedFrequence
                  : ""
              }
              options={frequences.map((item) => ({
                value: item.id,
                label: item.description,
              }))}
              onChange={(e) => {
                setFrequenceId(e.value);
                setSelectedFrequence({
                  id: e.value,
                  label: e.label,
                });
              }}
            />
            {errorListEdit.frequence_id && (
              <ValidationMessage message={errorListEdit.frequence_id} />
            )}
          </div>
          <div className="form-block col-12 col-lg-3 col-md-4">
            <label htmlFor="start_subscription">
              {t("edit_serial_start_subscription_label")}
            </label>
            <ReactDatePicker
              placeholderText={t("edit_serial_start_subscription_placeholder")}
              name="start_subscription"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              //   locale={sq}
              dateFormat="dd-MM-yyyy"
              selected={
                startSubscription ? CheckSelectedDate(startSubscription) : null
              }
              onChange={(e) =>
                e ? setStartSubscription(e) : setStartSubscription(null)
              }
              autoComplete="off"
              className={
                "date_input" +
                (errorListEdit.start_subscription ? " input-error" : "")
              }
            />
            {errorListEdit.start_subscription && (
              <ValidationMessage message={errorListEdit.start_subscription} />
            )}
          </div>
          <div className="form-block col-12 col-lg-3 col-md-4">
            <label htmlFor="end_subscription">
              {t("edit_serial_end_subscription_label")}
            </label>
            <ReactDatePicker
              placeholderText={t("edit_serial_end_subscription_placeholder")}
              name="end_subscription"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              //   locale={sq}
              dateFormat="dd-MM-yyyy"
              selected={
                endSubscription ? CheckSelectedDate(endSubscription) : null
              }
              onChange={(e) =>
                e ? setEndSubscription(e) : setEndSubscription(null)
              }
              autoComplete="off"
              className={
                "date_input" +
                (errorListEdit.end_subscription ? " input-error" : "")
              }
            />
            {errorListEdit.end_subscription && (
              <ValidationMessage message={errorListEdit.end_subscription} />
            )}
          </div>
          <div className="form-block col-12 col-lg-3 col-md-4 d-flex align-items-start">
            {btnEditLoading ? (
              <button
                className="button-blue has-icon"
                type="button"
                style={{ marginTop: "30px" }}
              >
                <i className="icon spinner-icon me-2" />
                {t("edit_serial_creating_serial_button")}
              </button>
            ) : (
              <button className="button-blue" style={{ marginTop: "30px" }}>
                {t("edit_serial_create_serial_button")}
              </button>
            )}
          </div>
        </div>
      </form>
      {!loading && (
        <CreateSerie
          biblioitemId={serial.biblioitem_id}
          notApprovedSerieId={notApprovedSeries[0].id}
          setNewSerie={() => editSerial()}
        />
      )}
      {!loading && !action && (
        <SeriesList
          biblioitemId={serial.biblioitem_id}
          approvedSeriesList={approvedSeries}
        />
      )}
    </Fragment>
  );
};

export default EditSerial;
