import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ValidationMessage from "../../helpers/ValidationMessage";

import * as Request from "../../api/Request";
import * as ApiUrl from "../../api/ApiUrl";
import * as Path from "../../routes/GeneralPaths";
import * as Message from "../../helpers/Messages";

const ActivateAccount = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    Request.getRequest(ApiUrl.activateAccount + token).then((response) => {
      if (response.status === 200) {
        setId(response.data.user.id);
        setFirstName(response.data.user.first_name);
        setLastName(response.data.user.last_name);
        setEmail(response.data.user.email);
      } else {
        navigate(Path.login);
      }
    });
  };

  const activateAccount = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = {
      id: id,
      password: password,
      password_confirmation: confirmPassword,
    };

    Request.postRequest(ApiUrl.setPasswordAccount, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          Message.successMsg(response.data.success);
          navigate(Path.login);
        } else if (response.status === 404 || response.status === 422) {
          setErrorList(response.data);
        }
      }
    );
  };

  return (
    <div className="form-logins">
      <div className="login_bg">
        <div className="w-100 user-data">
          <div className="initials">
            <span>{firstName.charAt(0) + lastName.charAt(0)}</span>
          </div>
          <div className="user-info">
            <span className="name">{`${firstName} ${lastName}`}</span>
            <span className="email">{`${email}`}</span>
          </div>
        </div>
        <form className="w-100" onSubmit={(e) => activateAccount(e)}>
          <label>{t("activate_account_password_label")}</label>
          <div className="form-group">
            <input
              type={!showPassword ? "password" : "text"}
              name="password"
              placeholder={t("activate_account_password_placeholder")}
              value={password}
              className={
                "form-input" + (errorList.password ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
            />
            <i className="icon lock-icon" />
            <div
              className="show-hide"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <i className="icon show-icon" />
              ) : (
                <i className="icon hide-icon" />
              )}
            </div>
            {errorList.password && (
              <ValidationMessage message={errorList.password[0]} />
            )}
          </div>
          <label>{t("activate_account_confirm_password_label")}</label>
          <div className="form-group">
            <input
              type={!showConfirmPassword ? "password" : "text"}
              name="password_confirmation"
              placeholder={t("activate_account_confirm_password_placeholder")}
              value={confirmPassword}
              className={
                "form-input" + (errorList.password ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <i className="icon lock-icon" />
            <div
              className="show-hide"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <i className="icon show-icon" />
              ) : (
                <i className="icon hide-icon" />
              )}
            </div>
          </div>
          {btnLoading ? (
            <button type="button" className="button-blue has-icon w-100 mt-2">
              <i className="spinner-icon" />
              {t("activate_account_btn_loading")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100 mt-2">
              {t("activate_account_btn")}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ActivateAccount;
