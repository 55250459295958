import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Borrowings from "./Borrowings";
import BorrowingInTime from "./BorrowingsInTime";
import InLibrary from "./InLibrary";

const ReportBorrowing = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState("borrowings");

  const borrowings = (e) => {
    e.preventDefault();
    setTab("borrowings");
  };

  const borrowingsInTime = (e) => {
    e.preventDefault();
    setTab("intime");
  };

  const inLibrary = (e) => {
    e.preventDefault();
    setTab("inlibrary");
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("index_borrowing_borrowing")}</p>
      <ul className="nav nav-tabs mb-4" role="tablist">
        <li className={"nav-item col-12 col-md-4 p-0" + (tab === "borrowings" ? "nav-link active" : "nav-link")}>
          <div
            className={tab === "borrowings" ? "nav-link active" : "nav-link"}
            onClick={(e) => borrowings(e)}
          >
            {t("index_borrowing_actual_borrowing")}
          </div>
        </li>
        <li className={"nav-item col-12 col-md-4 p-0" + (tab === "intime" ? "nav-link active" : "nav-link")}>
          <div
            className={tab === "intime" ? "nav-link active" : "nav-link"}
            onClick={(e) => borrowingsInTime(e)}
          >
            {t("index_borrowing_intime_borrowing")}
          </div>
        </li>
        <li className={"nav-item col-12 col-md-4 p-0" + (tab === "inlibrary" ? "nav-link active" : "nav-link")}>
          <div
            className={tab === "inlibrary" ? "nav-link active" : "nav-link"}
            onClick={(e) => inLibrary(e)}
          >
            {t("index_borrowing_in_library")}
          </div>
        </li>
      </ul>

      {tab === "borrowings" && <Borrowings />}
      {tab === "intime" && <BorrowingInTime />}
      {tab === "inlibrary" && <InLibrary />}
    </Fragment>
  );
};

export default ReportBorrowing;
