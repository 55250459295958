// Api Base URL
import { apiBaseUrl } from "../helpers/AppConfig";

// Authentication
export const login = apiBaseUrl + "login";
export const logout = apiBaseUrl + "logout";
export const emslogin = apiBaseUrl + "ems/login";
export const resetLink = apiBaseUrl + "sendresetlink";
export const resetPassword = apiBaseUrl + "resetpassword/";
export const storePassword = apiBaseUrl + "storeresetedpassword/";
export const activateAccount = apiBaseUrl + "user/registration/";
export const setPasswordAccount = apiBaseUrl + "user/store";

// User
export const profileData = apiBaseUrl + "profiledata";
export const changeUserPassword = apiBaseUrl + "storenewpassword";

// Catalog
export const listBiblioitems = apiBaseUrl + "catalog/listbiblioitems";
export const deleteBiblioitem = apiBaseUrl + "catalog/deletebiblioitem/";
export const showBiblioitem = apiBaseUrl + "catalog/showbiblioitem/";
export const editBiblioitem = apiBaseUrl + "catalog/editbiblioitem/";
export const updateBiblioitem = apiBaseUrl + "catalog/updatebiblioitem/";
export const createBiblioitem = apiBaseUrl + "catalog/createbiblioitem/";
export const storeBiblioitem = apiBaseUrl + "catalog/storebiblioitem";
export const editItems = apiBaseUrl + "catalog/edititems/";
export const updateItems = apiBaseUrl + "catalog/updateitems/";
export const editBiblioitemOnly = apiBaseUrl + "catalog/editbiblioitemonly/";
export const updateBiblioitemonly =
  apiBaseUrl + "catalog/updatebiblioitemonly/";
export const storeItems = apiBaseUrl + "catalog/storeitem";

export const listServer = apiBaseUrl + "z3950/list-of-servers";
export const storeServer = apiBaseUrl + "z3950/store-server";
export const deleteServer = apiBaseUrl + "z3950/delete-server/";
export const editServer = apiBaseUrl + "z3950/edit-server/";
export const updateServer = apiBaseUrl + "z3950/update-server/";
export const getServers = apiBaseUrl + "z3950/get-servers";
export const getRecords = apiBaseUrl + "z3950/get-data";
export const storeZ3950Records = apiBaseUrl + "z3950/store-data-records";

export const storeBiblio = apiBaseUrl + "catalog/storebiblio";

export const createBiblio = apiBaseUrl + "catalog/createbiblio";
export const editBiblio = apiBaseUrl + "catalog/editbiblio/";
export const selectedBiblio = apiBaseUrl + "catalog/selectbiblio/";
export const updateBiblio = apiBaseUrl + "catalog/updatebiblio/";

export const createKeywordBiblio = apiBaseUrl + "catalog/storekeywordbiblio";
export const createAuthorBiblio = apiBaseUrl + "catalog/storeauthorbiblio";
export const createGeotermBiblio =
  apiBaseUrl + "catalog/storegeographicaltermbiblio";

export const listAuthors = apiBaseUrl + "catalog/list/authors";
export const storeAuthor = apiBaseUrl + "catalog/storeauthor";
export const editAuthor = apiBaseUrl + "catalog/editauthor/";
export const updateAuthor = apiBaseUrl + "catalog/updateauthor/";
export const deleteAuthor = apiBaseUrl + "catalog/deleteauthor/";

export const listPublishers = apiBaseUrl + "catalog/list/publishers";
export const storePublisher = apiBaseUrl + "catalog/storepublisher";
export const editPublisher = apiBaseUrl + "catalog/editpublisher/";
export const updatePublisher = apiBaseUrl + "catalog/updatepublisher/";
export const deletePublisher = apiBaseUrl + "catalog/deletepublisher/";

export const listKeywords = apiBaseUrl + "catalog/list/keywords";
export const storeKeyword = apiBaseUrl + "catalog/storekeyword";
export const editKeyword = apiBaseUrl + "catalog/editkeyword/";
export const updateKeyword = apiBaseUrl + "catalog/updatekeyword/";
export const deleteKeyword = apiBaseUrl + "catalog/deletekeyword/";

export const listGeographicalterms =
  apiBaseUrl + "catalog/list/geographicalterm";
export const storeGeographicalterm =
  apiBaseUrl + "catalog/storegeographicalterm";
export const editGeographicalterm =
  apiBaseUrl + "catalog/editgeographicalterm/";
export const updateGeographicalterm =
  apiBaseUrl + "catalog/updategeographicalterm/";
export const deleteGeographicalterm =
  apiBaseUrl + "catalog/deletegeographicalterm/";

//Import MARC
export const saveMarcFile = apiBaseUrl + "catalog/savemarcfile";
export const confirmMarcRecords = apiBaseUrl + "catalog/confirmmarcrecords/";
export const storeMarcRecords = apiBaseUrl + "catalog/storemarcrecords";

//Export MARC
export const selectMarcRecords = apiBaseUrl + "catalog/exportmarc";
export const saveMarcRecords = apiBaseUrl + "catalog/savemarcrecords";

// Acquisition
export const listAcquisitions = apiBaseUrl + "acquisition/list";
export const storeAcquisition = apiBaseUrl + "acquisition/store";
export const showAcquisition = apiBaseUrl + "acquisition/show/";
export const editAcquisition = apiBaseUrl + "acquisition/edit/";
export const updateAcquisition = apiBaseUrl + "acquisition/update/";
export const deleteAcquisition = apiBaseUrl + "acquisition/delete/";
export const approveAcquisition = apiBaseUrl + "acquisition/approve";
export const cancelAcquisition = apiBaseUrl + "acquisition/cancel";

// Blog
export const listBlogPosts = apiBaseUrl + "blog/list";
export const storeBlogPost = apiBaseUrl + "blog/store";
export const editBlogPost = apiBaseUrl + "blog/edit/";
export const updateBlogPost = apiBaseUrl + "blog/update/";
export const deleteBlogPost = apiBaseUrl + "blog/delete/";
export const deleteIntroImage = apiBaseUrl + "blog/deleteimage/";

// Borrow
export const searchMaterialOrMember = apiBaseUrl + "borrowing";
export const searchNID = apiBaseUrl + "borrowing/get-ems-profile-data-by-nid";
export const searchMaterial = apiBaseUrl + "borrowing/member/";
export const searchMember = apiBaseUrl + "borrowing/item/";
export const confirmBorrowing = apiBaseUrl + "borrowing/member/";
export const saveBorrowing = apiBaseUrl + "borrowing/store";
export const listBorrowings = apiBaseUrl + "borrowing/list";
export const showBorrowing = apiBaseUrl + "borrowing/";
export const applyFine = apiBaseUrl + "borrowing/applyfine";
export const payFine = apiBaseUrl + "borrowing/payfine";
export const postponeBorrowing = apiBaseUrl + "borrowing/postpone";
export const listReservations = apiBaseUrl + "borrowing/reservationlist";
export const listPending = apiBaseUrl + "borrowing/listpending";
export const cancelPending = apiBaseUrl + "borrowing/cancelpending";
export const borrowPendingMaterial = apiBaseUrl + "borrowing/storecheckout";
export const borrowReservedMaterial =
  apiBaseUrl + "borrowing/storecheckoutreservation";
export const returnBorrowing = apiBaseUrl + "borrowing/return";

// Member
export const listMembers = apiBaseUrl + "member/list";
export const inviteMember = apiBaseUrl + "member/invite";
export const storeMember = apiBaseUrl + "member/storeinvatiomember";
export const showMember = apiBaseUrl + "member/show/";
export const deleteMember = apiBaseUrl + "member/delete/";
export const memberBorrowings = apiBaseUrl + "member/borrowings/";
export const memberReservations = apiBaseUrl + "member/reservations/";
export const memberFines = apiBaseUrl + "member/fines/";

// Serial
export const listSerials = apiBaseUrl + "serial/listserials";
export const showSerial = apiBaseUrl + "serial/show/";
export const createSerial = apiBaseUrl + "serial/create";
export const storeSerial = apiBaseUrl + "serial/store";
export const editSerial = apiBaseUrl + "serial/edit/";
export const updateSerial = apiBaseUrl + "serial/update/";
export const deleteSerial = apiBaseUrl + "serial/deleteserial/";
export const updateGeneratedSerie = apiBaseUrl + "serial/updategeneratedserie";
export const editSerie = apiBaseUrl + "serial/editserie/";
export const updateSerie = apiBaseUrl + "serial/updateserie/";
export const deleteSerie = apiBaseUrl + "serial/deleteserie/";
export const deleteSerieCover = apiBaseUrl + "serial/deleteseriecover/";
export const storeSerieItems = apiBaseUrl + "serial/storeserieitems";
export const deleteSerieItem = apiBaseUrl + "serial/deleteserieitem/";
export const updateSerieItems = apiBaseUrl + "serial/updateserieitems/";
export const listFrequences = apiBaseUrl + "serial/list/frequences";
export const storeFrequence = apiBaseUrl + "serial/storefrequence";
export const editFrequence = apiBaseUrl + "serial/editfrequence/";
export const updateFrequence = apiBaseUrl + "serial/updatefrequence/";
export const deleteFrequence = apiBaseUrl + "serial/deletefrequece/";

// OPAC
export const latestBooks = apiBaseUrl + "latest-books/";
export const mostReadBooks = apiBaseUrl + "most-read-books/";
export const digitizedBooks = apiBaseUrl + "digitized-books/";
export const blogPosts = apiBaseUrl + "news/";
export const showBlogPost = apiBaseUrl + "news/show/";
export const storeContact = apiBaseUrl + "storecontact";
export const simpleSearch = apiBaseUrl + "search";
export const advancedSearch = apiBaseUrl + "advanced-search";
export const detailed = apiBaseUrl + "detailed/";
export const filter = apiBaseUrl + "filter";
export const getdocument = apiBaseUrl + "document/";
export const getstatusreserve = apiBaseUrl + "detailed/status/";

// Student OPAC
export const profile = apiBaseUrl + "profile";
export const listBorrowingsOpac = apiBaseUrl + "borrowings";
export const listReservationsOpac = apiBaseUrl + "reservations";
export const fines = apiBaseUrl + "fines";
export const profileDetails = apiBaseUrl + "profile/details";
export const reserve = apiBaseUrl + "reserve";

// Backup
export const listbackups = apiBaseUrl + "backup/listbackups";
export const delteBackup = apiBaseUrl + "backup/delete/";
export const listlogs = apiBaseUrl + "logs";

// Reports
export const stats = apiBaseUrl + "report/stats";
export const reportBibliography = apiBaseUrl + "report/catalog/bibliography";
export const exportCatalog = apiBaseUrl + "report/catalog/export_catalog";
export const exportDamagedLost =
  apiBaseUrl + "report/catalog/export_damagedlost";
export const reportDamagedLost = apiBaseUrl + "report/catalog/damagedorlost";

export const reportBorrowingNow = apiBaseUrl + "report/borrowing/now";
export const reportBorrowingInTime = apiBaseUrl + "report/borrowing/intime";
export const reportInLibrary = apiBaseUrl + "report/borrowing/inlibrary";

export const reportExportBorrowingNow =
  apiBaseUrl + "report/borrowing/export_borrowings_now";
export const reportExportBorrowingInTime =
  apiBaseUrl + "report/borrowing/export_borrowings";
export const reportExportInLibrary =
  apiBaseUrl + "report/borrowing/export_inlibrary";

// Helpers
export const materialTypes = apiBaseUrl + "material/types";
export const memberRoles = apiBaseUrl + "member/roles";
export const staffRoles = apiBaseUrl + "staff/roles";
export const logActionsObjects = apiBaseUrl + "logs/actions-objects";

// Staff
export const staffList = apiBaseUrl + "staff/list";
export const staffInvite = apiBaseUrl + "staff/storeinvationstaff";
export const showStaff = apiBaseUrl + "staff/show/";
export const deleteStaff = apiBaseUrl + "staff/delete/";

export const userEdit = apiBaseUrl + "user/edit/";
export const updateUser = apiBaseUrl + "user/update/";

export const changeMembership = apiBaseUrl + "user/changemembershipend";
