import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";

import CheckCover from "../../../../helpers/CheckCover";

const Description = () => {
    const { t } = useTranslation();
    const param = useParams();
    const [biblioItem, setBiblioItem] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        showDescription();
    }, []);

    const showDescription = () => {
        const serialId = param.id;
        let request = ApiUrl.showSerial + serialId;
    
        Request.getRequest(request).then((response) => {
          if (response.status === 200) {
            setBiblioItem(response.data.serial.biblioitem);
            setLoading(false);
          }
        });
    };


    return (
        <div className="row show-material">
          <div className="col-12 col-sm-4 d-none d-md-block image-holder ">
            <img
              src={CheckCover(
                biblioItem?.cover,
                biblioItem?.biblio?.materialtype?.id
              )}
              alt="Material cover"
            />
          </div>
          <div className="col-12 col-md-8 description-holder">
            {biblioItem?.biblio?.title && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_title")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {`${
                    biblioItem?.biblio?.title +
                    (biblioItem.biblio.subtitle
                      ? " : " + biblioItem.biblio.subtitle
                      : "") +
                    (biblioItem?.biblio?.authors?.length > 0
                      ? " / " +
                      biblioItem.biblio.authors
                          .map((author) =>
                            author.name.split(",").reverse().join(" ")
                          )
                          .join("; ")
                      : "") +
                    (biblioItem?.contributors?.length > 0 &&
                        biblioItem?.biblio?.authors?.length <= 0
                      ? " / " +
                      biblioItem.contributors
                          .map((contributor) =>
                            contributor.name.split(",").reverse().join(" ")
                          )
                          .join("; ")
                      : biblioItem?.contributors?.length > 0 &&
                      biblioItem?.biblio?.authors?.length > 0
                      ? "; "
                      : biblioItem.contributors
                          .map((contributor) =>
                            contributor.name.split(",").reverse().join(" ")
                          )
                          .join("; "))
                  }`}
                </div>
              </div>
            )}
    
            {biblioItem?.biblio?.authors?.length > 0 && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_authors")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.authors
                    .map((author) => author.name)
                    .join("; ")}
                </div>
              </div>
            )}
            {biblioItem?.isbn && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_isbn")}
                </div>
                <div className="col-12 col-md-8 name-item">{biblioItem.isbn}</div>
              </div>
            )}
            {biblioItem?.issn && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_issn")}
                </div>
                <div className="col-12 col-md-8 name-item">{biblioItem.issn}</div>
              </div>
            )}
            {biblioItem?.biblio?.medium && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_medium")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.medium}
                </div>
              </div>
            )}
            {biblioItem?.publisher && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_publisher")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.publisher.name}
                </div>
              </div>
            )}
            {biblioItem?.publication_year && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_publication_year")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.publication_year}
                </div>
              </div>
            )}
            {biblioItem?.place && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_publication_place")}
                </div>
                <div className="col-12 col-md-8 name-item">{biblioItem.place}</div>
              </div>
            )}
            {biblioItem?.contributors?.length > 0 && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_contributors")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.contributors
                    .map((contributor) => contributor.name)
                    .join("; ")}
                </div>
              </div>
            )}
            {biblioItem?.pages && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_pages")}
                </div>
                <div className="col-12 col-md-8 name-item">{biblioItem.pages}</div>
              </div>
            )}
            {biblioItem?.physical_details && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_physical_details")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.physical_details}
                </div>
              </div>
            )}
            {biblioItem?.dimensions && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_dimensions")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.dimensions}
                </div>
              </div>
            )}
            {biblioItem?.biblio?.language && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_publication_language")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.language.name_trans}
                </div>
              </div>
            )}
    
            {biblioItem?.language && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_language")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.language.name_trans}
                </div>
              </div>
            )}
    
            {biblioItem?.biblio?.keywords?.length > 0 && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_keywords")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.keywords.map((keyword, index) => (
                    <span key={index} className="tag">
                      {keyword.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
    
            {biblioItem?.biblio?.geographicalterms?.length > 0 && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_geographical_terms")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.geographicalterms.map((terms, index) => (
                    <span key={index} className="tag">
                      {terms.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
    
            {biblioItem?.biblio?.part_name && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_part_name")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.part_name}
                </div>
              </div>
            )}
    
            {biblioItem?.biblio?.part_number && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_part_number")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.part_number}
                </div>
              </div>
            )}
    
            {biblioItem?.publication_number && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_publication_number")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.publication_number}
                </div>
              </div>
            )}
    
            {biblioItem?.lccn && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_lccn")}
                </div>
                <div className="col-12 col-md-8 name-item">{biblioItem.lccn}</div>
              </div>
            )}
    
            {biblioItem?.ean && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_ean")}
                </div>
                <div className="col-12 col-md-8 name-item">{biblioItem.ean}</div>
              </div>
            )}
    
            {biblioItem?.biblio?.agerestriction && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_agerestriction")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.agerestriction}
                </div>
              </div>
            )}
            {biblioItem?.biblio?.year_original_publication && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_original_publication_year")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.year_original_publication}
                </div>
              </div>
            )}
            {biblioItem?.national_control_number && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_national_control_number")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.national_control_number}
                </div>
              </div>
            )}
            {biblioItem?.biblio?.notes && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_notes")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.notes}
                </div>
              </div>
            )}
            {biblioItem?.biblio?.abstract && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_abstract")}
                </div>
                <div className="col-12 col-md-8 name-item">
                  {biblioItem.biblio.abstract}
                </div>
              </div>
            )}
            {biblioItem?.kdu && (
              <div className="row">
                <div className="col-12 col-md-4 name-desc">
                  {t("show_serial_description_kdu")}
                </div>
                <div className="col-12 col-md-8 name-item">{biblioItem.kdu}</div>
              </div>
            )}
          </div>
        </div>
      );
    
};

export default Description;