import create from "zustand";
const biblioData = {
  biblio_id: "",
  title: "",
  authors: [],
  medium: "",
  subtitle: "",
  materialtype_id: "",
  part_name: "",
  part_number: "",
  biblio_language_id: "",
  agerestriction: "",
  year_original_publication: "",
  mediatype_id: "",
  keywords: [],
  geoterms: [],
  notes: "",
  abstract: "",
};

export const Biblio = create((set) => ({
  biblioData,
  addBiblioData: (data) =>
    set(() => ({
      biblioData: {
        biblio_id: data?.biblio?.id ? data.biblio.id : "",
        title: data?.biblio?.title ? data.biblio.title : "",
        authors: data?.biblio?.authors ? data.biblio.authors : [],
        medium: data?.biblio?.medium ? data.biblio.medium : "",
        subtitle: data?.biblio?.subtitle ? data.biblio.subtitle : "",
        materialtype_id: data?.biblio?.materialtype
          ? data.biblio.materialtype
          : "",
        part_name: data?.biblio?.part_name ? data.biblio.part_name : "",
        part_number: data?.biblio?.part_number ? data.biblio.part_number : "",
        biblio_language_id: data?.biblio?.language ? data.biblio.language : "",
        agerestriction: data?.biblio?.agerestriction
          ? data.biblio.agerestriction
          : "",
        year_original_publication: data?.biblio?.year_original_publication
          ? data.biblio.year_original_publication
          : "",
        mediatype_id: data?.biblio?.mediatype ? data.biblio.mediatype : "",
        keywords: data?.biblio?.keywords ? data.biblio.keywords : [],
        geoterms: data?.biblio?.geographicalterms
          ? data.biblio.geographicalterms
          : [],
        notes: data?.biblio?.notes ? data.biblio.notes : "",
        abstract: data?.biblio?.abstract ? data.biblio.abstract : "",
      },
    })),
  addBiblioDataUpdate: (data) => set(() => ({ biblioData: data })),
  reset: () =>
    set(() => ({
      biblioData: {
        biblio_id: "",
        title: "",
        authors: [],
        medium: "",
        subtitle: "",
        materialtype_id: "",
        part_name: "",
        part_number: "",
        biblio_language_id: "",
        agerestriction: "",
        year_original_publication: "",
        mediatype_id: "",
        keywords: [],
        geoterms: [],
        notes: "",
        abstract: "",
      },
    })),
}));
