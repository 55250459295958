import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import EditPost from "../../../components/panel/blog/EditPost";

const EditPostView = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>{t("change_article_view")}</title>
      </Helmet>
      <Sidebar>
        <EditPost />
      </Sidebar>
    </Fragment>
  );
};
export default EditPostView;
