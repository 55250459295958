import { Fragment, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { authRole, serieCoversPath } from "../../../../helpers/AppConfig";
import Periodic from "../../../../assets/images/covers/periodic.svg";
import moment from "moment";
import EditSerie from "./EditSerie";
import AddItems from "./AddItems";
import Loading from "../../../../helpers/Loading";
import EditItems from "./EditItems";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const SeriesList = ({ approvedSeriesList, biblioitemId }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  // Edit serie modal
  const [serieModal, setSerieModal] = useState(false);
  const [serieId, setSerieId] = useState("");
  const [serieIdItem, setSerieIdItem] = useState("");

  const [btnLoadingShowItems, setBtnLoadingShowItems] = useState(false);

  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);

  const [editItemsAcc, setEditItemsAcc] = useState(false);

  const [approvedSeries, setApprovedSeries] = useState(approvedSeriesList);

  const CheckAccordion = (accordion) => {
    if (accordion === "edit") {
      setEditItemsAcc(true);
    } else {
      setEditItemsAcc(false);
    }
  };

  const editSerial = () => {
    Request.getRequest(ApiUrl.editSerial + id).then((response) => {
      if (response.status === 200) {
        setApprovedSeries(response.data.approvedSeries);
      }
    });
  };

  const deleteSerie = (serieId) => {
    ConfirmPopup.deletePopup(" ").then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteSerie + serieId).then((response) => {
          if (response.status === 200) {
            editSerial();
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else if (response.status === 405) {
            Message.infoMsg(response.data.info);
          }
        });
      }
    });
  };

  const deleteSerieItem = (id, serieId) => {
    ConfirmPopup.deletePopup(" ").then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteSerieItem + id).then((response) => {
          if (response.status === 200) {
            showItems(serieId);
            response?.data?.success
              ? Message.successMsg(response.data.success)
              : Message.errorMsg(response.data.error);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else if (response.status === 405) {
            Message.infoMsg(response.data.info);
          }
        });
      }
    });
  };

  const showItems = (id, e) => {
    e && e.preventDefault();
    e && setBtnLoadingShowItems(true);
    e && setItemsLoading(true);
    Request.getRequest(ApiUrl.editSerie + id).then((response) => {
      e && setBtnLoadingShowItems(false);
      e && setItemsLoading(false);
      if (response.status === 200) {
        setItems(response.data.serie.items);
      }
    });
  };

  return (
    <div className="series-list accordion" id="seriesListAccordion">
      {approvedSeries.map((serie, index) => (
        <div key={serie.id} className="serie">
          <div className="serie-header">
            <div className="cover">
              {serie.cover ? (
                <img src={serieCoversPath + serie.cover} alt="Cover" />
              ) : (
                <img src={Periodic} alt="Cover" />
              )}
            </div>
            <div className="serie-info">
              <div className="serie-data">
                <div>
                  <span className="title">
                    {t("edit_serial_serie_list_serie_name")} :
                  </span>
                  <span>{serie.pattern ? serie.pattern : "-"}</span>
                </div>
                <div>
                  <span className="title">
                    {t("edit_serial_serie_list_serie_published_date")} :
                  </span>
                  <span>
                    {serie.published_date
                      ? moment(serie.published_date).format("DD-MM-yyy")
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="serie-buttons">
                {btnLoadingShowItems && serieIdItem === serie.id ? (
                  <button className="button-blue-border has-icon" type="button">
                    <i className="icon spinner-icon me-2" />
                    {t("edit_serial_serie_list_serie_showing_items")}
                  </button>
                ) : (
                  <button
                    className="button-blue-border"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#accordion-collapse-items-" + index}
                    aria-expanded="false"
                    aria-controls={"#accordion-collapse-items-" + index}
                    onClick={(e) => {
                      serieIdItem !== serie.id && showItems(serie.id, e);
                      setSerieIdItem(serie.id);
                      CheckAccordion("show");
                    }}
                  >
                    {t("edit_serial_serie_list_serie_show_items")}
                  </button>
                )}
                <button
                  className="button-blue"
                  data-toggle="collapse"
                  data-bs-toggle="collapse"
                  data-bs-target={"#accordion-collapse-new-items-" + index}
                  aria-expanded="false"
                  aria-controls={"#accordion-collapse-new-items-" + index}
                  onClick={(e) => CheckAccordion("add")}
                >
                  {t("edit_serial_serie_list_serie_add_items")}
                </button>
              </div>
            </div>
            <div className="serie-actions">
              <button
                className="button-unstyled-icon"
                onClick={() => {
                  setSerieModal(true);
                  setSerieId(serie.id);
                }}
              >
                <i className="icon edit-icon" />
              </button>
              {serieModal && serieId === serie.id && (
                <EditSerie
                  onShowModal={serieModal}
                  setShowSerie={(e) => {
                    setSerieModal(e);
                    setSerieId("");
                  }}
                  serieId={serie.id}
                  pattern={serie.pattern}
                  publishedDate={serie.published_date}
                  cover={serie.cover}
                  setUpdated={() => editSerial()}
                />
              )}
              <button
                className="button-unstyled-icon"
                onClick={() => deleteSerie(serie.id)}
              >
                <i className="icon delete-icon" />
              </button>
            </div>
          </div>
          <div key={index} className="accordion-table-result">
            <div
              id={"accordion-collapse-items-" + index}
              className="accordion-collapse collapse"
              aria-labelledby={"#item_" + index}
              data-bs-parent="#seriesListAccordion"
            >
              {itemsLoading && serieIdItem === serie.id ? (
                <div className="mt-3">
                  <Loading />
                </div>
              ) : (
                items.length > 0 &&
                serieIdItem === serie.id &&
                items.map((item, index) => (
                  <div key={item.id} className="item">
                    <div className="item-attributes-summary">
                      <div>
                        <div
                          className={`col-12 ${
                            authRole() === "administrator"
                              ? "col-sm-4"
                              : "col-sm-5"
                          }`}
                        >
                          <div className="item-row">
                            <span className="item-title">
                              {t("edit_serial_serie_list_item_codification")} :
                            </span>
                            <span className="item-value">
                              {item?.inventory_number}
                            </span>
                          </div>
                          <div className="item-row">
                            <span className="item-title">
                              {t("edit_serial_serie_list_item_place_number")} :
                            </span>
                            <span className="item-value">
                              {item?.place_number}
                            </span>
                          </div>
                          {authRole() === "administrator" && (
                            <>
                              <div className="item-row">
                                <span className="item-title">
                                  {t(
                                    "edit_serial_serie_list_item_borrowing_type"
                                  )}{" "}
                                  :
                                </span>
                                <span className="item-value">
                                  {item?.borrowing_type_trans}
                                </span>
                              </div>
                              <div className="item-row">
                                <span className="item-title">
                                  {t(
                                    "edit_serial_serie_list_item_inventory_number"
                                  )}{" "}
                                  :
                                </span>
                                <span className="item-value">{item?.id}</span>
                              </div>
                            </>
                          )}
                        </div>
                        {authRole() !== "administrator" && (
                          <div className="col-12 col-sm-6">
                            <div className="item-row">
                              <span className="item-title">
                                {t(
                                  "edit_serial_serie_list_item_borrowing_type"
                                )}
                                :
                              </span>
                              <span className="item-value">
                                {item?.borrowing_type}
                              </span>
                            </div>
                            <div className="item-row">
                              <span className="item-title">
                                {t(
                                  "edit_serial_serie_list_item_inventory_number"
                                )}
                                :
                              </span>
                              <span className="item-value">{item?.id}</span>
                            </div>
                          </div>
                        )}
                        {authRole() === "administrator" && (
                          <>
                            <div className="col-12 col-sm-3">
                              <div className="item-row">
                                <span className="item-title">
                                  {t("edit_serial_serie_list_item_lost")}:
                                </span>
                                <span className="item-value">
                                  {item?.lost === 0
                                    ? t("edit_serial_serie_list_item_lost_no")
                                    : t("edit_serial_serie_list_item_lost_yes")}
                                </span>
                              </div>
                              <div className="item-row">
                                <span className="item-title">
                                  {t("edit_serial_serie_list_item_damaged")} :
                                </span>
                                <span className="item-value">
                                  {item?.damaged === 0
                                    ? t(
                                        "edit_serial_serie_list_item_damaged_no"
                                      )
                                    : t(
                                        "edit_serial_serie_list_item_damaged_yes"
                                      )}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <div className="item-row">
                                <span className="item-title">
                                  {t("edit_serial_serie_list_item_lost_date")} :
                                </span>
                                <span className="item-value">
                                  {item?.lost_date
                                    ? moment(item.lost_date).format(
                                        "DD-MM-yyyy"
                                      )
                                    : "-"}
                                </span>
                              </div>
                              <div className="item-row">
                                <span className="item-title">
                                  {t(
                                    "edit_serial_serie_list_item_damaged_date"
                                  )}{" "}
                                  :
                                </span>
                                <span className="item-value">
                                  {item?.damaged_date
                                    ? moment(item.damaged_date).format(
                                        "DD-MM-yyyy"
                                      )
                                    : "-"}
                                </span>
                              </div>
                              <div className="item-row">
                                <span className="item-title">
                                  {t(
                                    "edit_serial_serie_list_item_renewal_date"
                                  )}{" "}
                                  :
                                </span>
                                <span className="item-value">
                                  {item.renewal_date
                                    ? moment(item.renewal_date).format(
                                        "DD-MM-yyyy"
                                      )
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <button
                        className="button-unstyled-icon"
                        onClick={() => deleteSerieItem(item.id, serie.id)}
                      >
                        <i className="icon delete-icon"></i>
                      </button>
                    </div>
                    {index === 0 && (
                      <div className="item-action">
                        <button
                          className="button-unstyled-icon bordered"
                          data-toggle="collapse"
                          data-bs-toggle="collapse"
                          data-bs-target={
                            "#accordion-collapse-edit-items-" + index
                          }
                          aria-expanded="false"
                          aria-controls={
                            "#accordion-collapse-edit-items-" + index
                          }
                          onClick={() => CheckAccordion("edit")}
                        >
                          <i className="icon edit-icon"></i>
                        </button>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
            <div
              id={"accordion-collapse-new-items-" + index}
              className="accordion-collapse collapse"
              aria-labelledby={"#item_" + index}
              data-bs-parent="#seriesListAccordion"
            >
              <AddItems
                biblioitemId={biblioitemId}
                serieId={serie.id}
                setUpdateItems={() => showItems(serie.id)}
              />
            </div>
            <div
              id={"accordion-collapse-edit-items-" + index}
              className="accordion-collapse collapse"
              aria-labelledby={"#edit_item_" + index}
              data-bs-parent="#seriesListAccordion"
            >
              {editItemsAcc && (
                <EditItems
                  items={items}
                  serieId={serie.id}
                  setUpdateItems={() => showItems(serie.id)}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SeriesList;
