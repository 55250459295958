import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Bibliography } from "../../../../../store/Catalog/EditBibliography/Bibliography";
import { authRole } from "../../../../../helpers/AppConfig";
import ReactDatePicker from "react-datepicker";
import {
  CheckBorrowingType,
  CheckSelectedDate,
} from "../../../../../helpers/CheckValue";
import { useState } from "react";

import ValidationMessage from "../../../../../helpers/ValidationMessage";
import moment from "moment/moment";
import ReactSelect from "react-select";

const EditItems = ({ errorList }) => {
  const { t } = useTranslation("");

  const addBibliographyUpdate = Bibliography(
    (state) => state.addBibliographyUpdate
  );
  const bibliographyData = Bibliography((state) => state.bibliographyData);

  const [index, setIndex] = useState(0);

  const deleteItem = (id) => {
    var items = bibliographyData?.items;
    items.forEach((item) => {
      if (item.id === id) {
        items.splice(item, 1);
      }
    });
    addBibliographyUpdate({ ...bibliographyData, items });
    var deletedItems = bibliographyData?.deletedItems;
    deletedItems.push(id);
    addBibliographyUpdate({ ...bibliographyData, deletedItems });
  };

  const addNewItem = () => {
    var newItems = bibliographyData?.newItems;
    var newItemData = {
      borrowing_type: "",
      inventory_number: "",
      place_number: "",
      damaged: 0,
      damaged_date: "",
      renewal_date: "",
      id: index,
    };
    newItems.push(newItemData);
    addBibliographyUpdate({ ...bibliographyData, newItems });
    setIndex(index + 1);
  };

  const removeItem = (id) => {
    var newItems = bibliographyData?.newItems;
    newItems.forEach((item, index) => {
      if (item.id === id) {
        newItems.splice(index, 1);
      }
    });
    addBibliographyUpdate({ ...bibliographyData, newItems });
  };

  const handleChangeSelect = (e, name, i, status) => {
    if (status === "item") {
      var items = bibliographyData?.items;
      items[i][name] = e ? e.value : "";
      addBibliographyUpdate({ ...bibliographyData, items });
    } else if (status === "newItem") {
      var newItems = bibliographyData?.newItems;
      newItems[i][name] = e ? e.value : "";
      addBibliographyUpdate({ ...bibliographyData, newItems });
    }
  };

  const handleChange = (e, name, i, status) => {
    if (status === "item") {
      var items = bibliographyData?.items;
      items[i][name] = e.target.value;
      addBibliographyUpdate({ ...bibliographyData, items });
    } else if (status === "newItem") {
      var newItems = bibliographyData?.newItems;
      newItems[i][name] = e.target.value;
      addBibliographyUpdate({ ...bibliographyData, newItems });
    }
  };

  const handleChangeDate = (e, name, i, status) => {
    if (status === "item") {
      var items = bibliographyData?.items;
      items[i][name] = e ? moment(e).format("yyyy-MM-DD") : null;
      addBibliographyUpdate({ ...bibliographyData, items });
    } else if (status === "newItem") {
      var newItems = bibliographyData?.newItems;
      newItems[i][name] = e ? moment(e).format("yyyy-MM-DD") : null;
      addBibliographyUpdate({ ...bibliographyData, newItems });
    }
  };

  return (
    <Fragment>
      {bibliographyData?.items.map((item, index) => (
        <Fragment key={item.id}>
          <div className="row card-item-bg mb-4 ">
            <div className="form-block col-12 col-md-4 mb-5">
              <label>{t("edit_bibliography_items_borrow_type_label")}</label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList[`items.${index}.borrowing_type`]
                    ? " input-error"
                    : "")
                }
                classNamePrefix="select_input"
                name={"items[" + item.id + "][borrowing_type]"}
                placeholder={t(
                  "edit_bibliography_items_borrow_type_placeholder"
                )}
                defaultValue={
                  item.borrowing_type
                    ? CheckBorrowingType(item.borrowing_type)
                    : null
                }
                isSearchable={false}
                isClearable={true}
                menuPlacement="auto"
                options={[
                  {
                    value: "library",
                    label: t("check_borrowing_type_library"),
                  },
                  { value: "normal", label: t("check_borrowing_type_normal") },
                  {
                    value: "unavailable",
                    label: t("check_borrowing_type_unavailable"),
                  },
                ]}
                onChange={(e) =>
                  handleChangeSelect(e, `borrowing_type`, index, "item")
                }
              />
              {errorList[`items.${index}.borrowing_type`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.borrowing_type`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4 mb-5">
              <label>{t("edit_bibliography_items_codification_label")}</label>
              <input
                placeholder={t(
                  "edit_bibliography_items_codification_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`items.${index}.inventory_number`]
                    ? " input-error"
                    : "")
                }
                name={"items[" + item.id + "][inventory_number]"}
                onChange={(e) =>
                  handleChange(e, `inventory_number`, index, "item")
                }
                type="text"
                defaultValue={
                  item.inventory_number ? item.inventory_number : ""
                }
              />
              {errorList[`items.${index}.inventory_number`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.inventory_number`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4 mb-5">
              <label>{t("edit_bibliography_items_place_number_label")}</label>
              <input
                placeholder={t(
                  "edit_bibliography_items_place_number_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`items.${index}.place_number`]
                    ? " input-error"
                    : "")
                }
                name={"items[" + item.id + "][place_number]"}
                onChange={(e) => handleChange(e, `place_number`, index, "item")}
                type="text"
                defaultValue={item.place_number ? item.place_number : ""}
              />
              {errorList[`items.${index}.place_number`] && (
                <ValidationMessage
                  message={errorList[`items.${index}.place_number`]}
                />
              )}
            </div>
            <div className="form-block row col-12 m-0 p-0">
              <div className="col-12 col-md-4 mb-5">
                <label>
                  {t("edit_bibliography_items_inventory_number_label")}
                </label>
                <input
                  placeholder={t(
                    "edit_bibliography_items_inventory_number_placeholder"
                  )}
                  className="form-input"
                  type="text"
                  defaultValue={item.id}
                  disabled
                />
              </div>
            </div>
            {authRole() === "administrator" && (
              <div className="form-block row col-12 m-0 p-0">
                <div className="col-12 col-md-4 mb-5">
                  <label htmlFor="Demtuar">
                    {t("edit_bibliography_items_damaged_label")}
                  </label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button me-4">
                      <label htmlFor={`damaged_${index}`}>
                        {t("edit_bibliography_items_damaged_yes_label")}
                      </label>
                      <input
                        className="form-check-input"
                        name={"items[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          handleChange(e, `damaged`, index, "item")
                        }
                        type="radio"
                        value="1"
                        id={`damaged_${index}`}
                        defaultChecked={item.damaged === 1}
                      />
                    </div>
                    <div className="radio-button ms-4">
                      <label htmlFor={`notdamaged_${index}`}>
                        {t("edit_bibliography_items_notdamaged_label")}
                      </label>
                      <input
                        className="form-check-input"
                        id={`notdamaged_${index}`}
                        name={"items[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          handleChange(e, `damaged`, index, "item")
                        }
                        type="radio"
                        value="0"
                        defaultChecked={item.damaged === 0}
                      />
                    </div>
                  </div>
                  {errorList[`items.${index}.damaged`] && (
                    <ValidationMessage
                      message={errorList[`items.${index}.damaged`]}
                    />
                  )}
                </div>

                {(item.damaged === 1 || item.damaged === "1") && (
                  <Fragment>
                    <div className="col-12 col-md-4 mb-5 removable-field">
                      <label>
                        {t("edit_bibliography_items_damaged_date_label")}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "edit_bibliography_items_damaged_date_placeholder"
                        )}
                        name={"items[" + item.id + "][damaged_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.damaged_date
                            ? CheckSelectedDate(item.damaged_date)
                            : null
                        }
                        onChange={(e) =>
                          handleChangeDate(e, `damaged_date`, index, "item")
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`items.${index}.damaged_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`items.${index}.damaged_date`] && (
                        <ValidationMessage
                          message={errorList[`items.${index}.damaged_date`]}
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-4 mb-5 removable-field">
                      <label>
                        {t("edit_bibliography_items_renovation_date_label")}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "edit_bibliography_items_renovation_date_placeholder"
                        )}
                        name={"items[" + item.id + "][renewal_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.renewal_date
                            ? CheckSelectedDate(item.renewal_date)
                            : null
                        }
                        onChange={(e) =>
                          handleChangeDate(e, `renewal_date`, index, "item")
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`items.${index}.renewal_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`items.${index}.renewal_date`] && (
                        <ValidationMessage
                          message={errorList[`items.${index}.renewal_date`]}
                        />
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            )}
            {authRole() === "administrator" && (
              <div className="form-block row col-12 m-0 p-0">
                <div className="col-12 col-md-4 mb-5">
                  <label htmlFor="Spastruar">
                    {t("edit_bibliography_items_lost_label")}
                  </label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button me-4">
                      <label htmlFor={`lost_${index}`}>
                        {t("edit_bibliography_items_lost_yes_label")}
                      </label>
                      <input
                        className="form-check-input"
                        id={`lost_${index}`}
                        name={"items[" + item.id + "][lost]"}
                        onChange={(e) => handleChange(e, `lost`, index, "item")}
                        type="radio"
                        value="1"
                        defaultChecked={item.lost === 1}
                      />
                    </div>

                    <div className="radio-button ms-4">
                      <label htmlFor={`notlost_${index}`} className="">
                        {t("edit_bibliography_items_notlost_label")}
                      </label>
                      <input
                        className="form-check-input"
                        id={`notlost_${index}`}
                        name={"items[" + item.id + "][lost]"}
                        onChange={(e) => handleChange(e, `lost`, index, "item")}
                        type="radio"
                        value="0"
                        defaultChecked={item.lost === 0}
                      />
                    </div>
                  </div>
                  {errorList[`items.${index}.lost`] && (
                    <ValidationMessage
                      message={errorList[`items.${index}.lost`]}
                    />
                  )}
                </div>
                {(item.lost === 1 || item.lost === "1") && (
                  <Fragment>
                    <div className="col-12 col-md-4 mb-5 removable-field2">
                      <label>
                        {t("edit_bibliography_items_lost_date_label")}
                      </label>
                      <ReactDatePicker
                        placeholderText={t(
                          "edit_bibliography_items_last_date_placeholder"
                        )}
                        name={"items[" + item.id + "][lost_date]"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // locale={sq}
                        dateFormat="dd-MM-yyyy"
                        selected={
                          item.lost_date
                            ? CheckSelectedDate(item.lost_date)
                            : ""
                        }
                        onChange={(e) =>
                          handleChangeDate(e, `lost_date`, index, "item")
                        }
                        autoComplete="off"
                        className={
                          "date_input" +
                          (errorList[`items.${index}.lost_date`]
                            ? " input-error"
                            : "")
                        }
                      />
                      {errorList[`items.${index}.lost_date`] && (
                        <ValidationMessage
                          message={errorList[`items.${index}.lost_date`]}
                        />
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            )}

            <div className="add-remove-buttons">
              {index + 1 === bibliographyData?.items.length &&
                bibliographyData?.newItems.length === 0 && (
                  <button
                    className="button-blue-border-item"
                    type="button"
                    onClick={() => addNewItem()}
                  >
                    +
                  </button>
                )}
              <button
                type="button"
                className="button-blue-item"
                onClick={() => deleteItem(item.id)}
              >
                -
              </button>
            </div>
          </div>
        </Fragment>
      ))}

      {bibliographyData?.newItems.map((item, index_new) => {
        return (
          <div key={item.id} className="row card-item mb-4 ">
            <div className="form-block col-12 col-md-4 mb-5">
              <label>{t("edit_bibliography_items_borrow_type_label")}</label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList[`newitems.${index_new}.borrowing_type`]
                    ? " input-error"
                    : "")
                }
                classNamePrefix="select_input"
                name={"newitems[" + item.id + "][borrowing_type]"}
                placeholder={t(
                  "edit_bibliography_items_borrow_type_placeholder"
                )}
                isSearchable={false}
                isClearable={true}
                menuPlacement="auto"
                options={[
                  {
                    value: "library",
                    label: t("check_borrowing_type_library"),
                  },
                  {
                    value: "normal",
                    label: t("check_borrowing_type_normal"),
                  },
                  {
                    value: "unavailable",
                    label: t("check_borrowing_type_unavailable"),
                  },
                ]}
                onChange={(e) =>
                  handleChangeSelect(e, `borrowing_type`, index_new, "newItem")
                }
              />
              {errorList[`newitems.${index_new}.borrowing_type`] && (
                <ValidationMessage
                  message={errorList[`newitems.${index_new}.borrowing_type`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4 mb-5">
              <label>{t("edit_bibliography_items_codification_label")}</label>
              <input
                placeholder={t(
                  "edit_bibliography_items_codification_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`newitems.${index_new}.inventory_number`]
                    ? " input-error"
                    : "")
                }
                name={"newitems[" + item.id + "][inventory_number]"}
                onChange={(e) =>
                  handleChange(e, `inventory_number`, index_new, "newItem")
                }
                defaultValue={item.inventory_number}
                type="text"
              />
              {errorList[`newitems.${index_new}.inventory_number`] && (
                <ValidationMessage
                  message={errorList[`newitems.${index_new}.inventory_number`]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4 mb-5">
              <label>{t("edit_bibliography_items_place_number_label")}</label>
              <input
                placeholder={t(
                  "edit_bibliography_items_place_number_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList[`newitems.${index_new}.place_number`]
                    ? " input-error"
                    : "")
                }
                name={"newitems[" + item.id + "][place_number]"}
                onChange={(e) =>
                  handleChange(e, `place_number`, index_new, "newItem")
                }
                type="text"
                defaultValue={item.place_number}
              />
              {errorList[`newitems.${index_new}.place_number`] && (
                <ValidationMessage
                  message={errorList[`newitems.${index_new}.place_number`]}
                />
              )}
            </div>

            {authRole() === "administrator" && (
              <div className="form-block row col-12 m-0 p-0">
                <div className="col-12 col-md-4 mb-5">
                  <label>{t("edit_bibliography_items_damaged_label")}</label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button me-4">
                      <label htmlFor={`damaged-${index_new}`}>
                        {t("edit_bibliography_items_damaged_yes_label")}
                      </label>
                      <input
                        className="form-check-input"
                        id={`damaged-${index_new}`}
                        name={"newitems[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          handleChange(e, `damaged`, index_new, "newItem")
                        }
                        type="radio"
                        defaultValue="1"
                        defaultChecked={item.damaged === 1}
                      />
                    </div>
                    <div className="radio-button ms-4">
                      <label htmlFor={`notdamaged-${index_new}`}>
                        {t("edit_bibliography_items_notdamaged_label")}
                      </label>
                      <input
                        className="form-check-input"
                        data-target="removable-field"
                        id={`notdamaged-${index_new}`}
                        name={"newitems[" + item.id + "][damaged]"}
                        onChange={(e) =>
                          handleChange(e, `damaged`, index_new, "newItem")
                        }
                        type="radio"
                        defaultValue="0"
                        defaultChecked={item.damaged === 0}
                      />
                    </div>
                  </div>
                  {errorList[`newitems.${index_new}.damaged`] && (
                    <ValidationMessage
                      message={errorList[`newitems.${index_new}.damaged`]}
                    />
                  )}
                </div>
                {item.damaged === 1 ||
                  (item.damaged === "1" && (
                    <Fragment>
                      <div className="col-12 col-md-4 mb-5 removable-field">
                        <label>
                          {t("edit_bibliography_items_damaged_date_label")}
                        </label>
                        <ReactDatePicker
                          placeholderText={t(
                            "edit_bibliography_items_damaged_date_placeholder"
                          )}
                          name={"newitems[" + item.id + "][damaged_date]"}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          // locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={CheckSelectedDate(item.damaged_date)}
                          onChange={(e) =>
                            handleChangeDate(
                              e,
                              `damaged_date`,
                              index_new,
                              "newItem"
                            )
                          }
                          autoComplete="off"
                          className={
                            "date_input" +
                            (errorList[`newitems.${index_new}.damaged_date`]
                              ? " input-error"
                              : "")
                          }
                        />
                        {errorList[`newitems.${index_new}.damaged_date`] && (
                          <ValidationMessage
                            message={
                              errorList[`newitems.${index_new}.damaged_date`]
                            }
                          />
                        )}
                      </div>
                      <div className="col-12 col-md-4 mb-5 removable-field">
                        <label>
                          {t("edit_bibliography_items_renovation_date_label")}
                        </label>
                        <ReactDatePicker
                          placeholderText={t(
                            "edit_bibliography_items_renovation_date_placeholder"
                          )}
                          name={"newitems[" + item.id + "][renewal_date]"}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          // locale={sq}
                          dateFormat="dd-MM-yyyy"
                          selected={CheckSelectedDate(item.renewal_date)}
                          onChange={(e) =>
                            handleChangeDate(
                              e,
                              `renewal_date`,
                              index_new,
                              "newItem"
                            )
                          }
                          autoComplete="off"
                          className={
                            "date_input" +
                            (errorList[`newitems.${index_new}.renewal_date`]
                              ? " input-error"
                              : "")
                          }
                        />
                        {errorList[`newitems.${index_new}.renewal_date`] && (
                          <ValidationMessage
                            message={
                              errorList[`newitems.${index_new}.renewal_date`]
                            }
                          />
                        )}
                      </div>
                    </Fragment>
                  ))}
              </div>
            )}
            <div className="add-remove-buttons">
              {index_new + 1 === bibliographyData?.newItems.length && (
                <button
                  className="button-blue-border-item"
                  type="button"
                  onClick={() => addNewItem()}
                >
                  +
                </button>
              )}
              <button
                type="button"
                className="button-blue-item"
                onClick={() => removeItem(item.id)}
              >
                -
              </button>
            </div>
          </div>
        );
      })}

      {bibliographyData?.items.length === 0 &&
        bibliographyData?.newItems.length === 0 && (
          <button
            className="button-blue-border-item"
            type="button"
            onClick={() => addNewItem()}
          >
            +
          </button>
        )}
    </Fragment>
  );
};

export default EditItems;
