import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";

import * as AppConfig from "../../../helpers/AppConfig";

//i18n
import i18n from "../../../i18n/i18n";
import languages from "../../../i18n/Languages";
import { Language } from "../../../store/Language";

//The function which allows the user to change the language to english or german
const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);
  const language = Language((state) => state.addLanguage);

  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng");
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang) => {
    //set language as i18n
    AppConfig.setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    setSelectedLang(lang);
    language(lang);
    window.location.reload();
  };

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block me-4"
    >
      <DropdownToggle className="button-white-rounded" tag="button">
        <span
          className="d-flex align-items-center"
          style={{ width: "24px", height: "24px" }}
        >
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="Flag"
            height="16"
          />
        </span>
      </DropdownToggle>
      <DropdownMenu className="language-switch dropdown-menu-end">
        {map(Object.keys(languages), (key) => (
          <DropdownItem
            key={key}
            onClick={() => changeLanguageAction(key)}
            className={`notify-item ${
              selectedLang === key ? "active" : "none"
            }`}
          >
            <img
              src={get(languages, `${key}.flag`)}
              alt="Flag"
              className="me-1"
              height="12"
            />
            <span className="align-middle">
              {get(languages, `${key}.label`)}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
