import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../helpers/Loading";
import ListResponse from "../../../helpers/ListResponse";
import CustomPagination from "../../../helpers/CustomPagination";
import SearchFilter from "../../../helpers/SearchFilter";
import moment from "moment";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as Tooltip from "../../../helpers/Tooltip";
import * as AppConfig from "../../../helpers/AppConfig";
const Reservations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const param = useParams();
  const [reservationsList, setReservationsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    ListReservations();
  }, [activePage]);

  const ListReservations = () => {
    let request = ApiUrl.memberReservations;
    const memberID = param.id;

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
      request = SearchFilter(searchParams, request);
    }

    Request.getRequest(request + memberID).then((response) => {
      if (response.status === 200) {
        setReservationsList(response.data.reservations.data);
        setLoading(false);
        setPagination(response.data.reservations);
      } else if (response.status === 404) {
        navigate(Path.listMembers);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const borrowMaterial = (e, id) => {
    e.preventDefault();
    const data = { id: id };
    Request.postRequest(ApiUrl.borrowReservedMaterial, data).then(
      (response) => {
        if (response.status === 200) {
          navigate(Path.listBorrowings);
          Message.successMsg(response.data.success);
        } else if (response.status === 404) {
          ListReservations();
          Message.errorMsg(response.data.error);
        } else {
          Message.errorMsg(t("error_message"));
        }
      }
    );
  };

  return (
    <Fragment>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" className="text-center" width="25%">
                {t("show_member_reservations_title_author")}
              </th>
              <th role="columnheader" className="text-center" width="25%">
                {t("show_member_reservations_codification_inventary_number")}
              </th>
              <th role="columnheader" className="text-center" width="25%">
                {t("show_member_reservations_date_of_reservation")}
              </th>
              <th role="columnheader" className="text-center" width="25%">
                {t("show_member_reservations_borrow")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="4">
                  <Loading />
                </td>
              </tr>
            ) : (
              reservationsList.map((reservation, index) => (
                <tr key={index}>
                  <td
                    role="cell"
                    data-label={t("show_member_reservations_title_author")}
                    className="text-center"
                  >
                    <div className="d-inline-flex">
                      {Tooltip.tooltipDisabledMobile(
                        index,
                        reservation.item.biblioitem.biblio.title
                      )}
                    </div>
                    <div>
                      {reservation.item.biblioitem.biblio.authors
                        .map((author) => author.name)
                        .join("; ")}
                    </div>
                  </td>
                  <td
                    role="cell"
                    data-label={t(
                      "show_member_reservations_codification_inventary_number"
                    )}
                    className="text-center "
                  >
                    {reservation.item.inventory_number +
                      " / " +
                      reservation.item.id}
                  </td>
                  <td
                    role="cell"
                    data-label={t(
                      "show_member_reservations_date_of_reservation"
                    )}
                    className="text-center "
                  >
                    {reservation.created_at &&
                      moment(reservation.created_at).format("DD-MM-yyyy")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("show_member_reservations_borrow")}
                    className="text-center status"
                  >
                    <button
                      className="button-blue-borrow"
                      onClick={(e) => borrowMaterial(e, reservation.id)}
                      type={"submit"}
                    >
                      {t("show_member_reservations_borrow")}
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
      </div>
      <div className="container-fluid pt-5 pb-4">
        <CustomPagination
          data={pagination}
          setActivePage={(e) => setActivePage(e)}
        />
      </div>
    </Fragment>
  );
};

export default Reservations;
