import { useTranslation } from "react-i18next";

const ListResponse = ({ totalItems, loading, searched }) => {
  const { t } = useTranslation("");
  if (totalItems === 0 && !loading && searched) {
    return <div className="table-list-results">{t("no_results_found")}</div>;
  }
  if (totalItems === 0 && !loading && !searched) {
    return <div className="table-list-results">{t("nothing_to_show")}</div>;
  }
};

export default ListResponse;
