import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import Loading from "../../../../helpers/Loading";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";

const StatisticsChart = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [borrowingsMonth, setBorrowingsMonth] = useState([]);

  const calculateMonth = (month) => {
    switch (month) {
      case 1:
        return t("january");
      case 2:
        return t("february");
      case 3:
        return t("march");
      case 4:
        return t("april");
      case 5:
        return t("may");
      case 6:
        return t("june");
      case 7:
        return t("july");
      case 8:
        return t("august");
      case 9:
        return t("september");
      case 10:
        return t("october");
      case 11:
        return t("november");
      case 12:
        return t("december");
      default:
        return "";
    }
  };

  //populating and adding properties to the chart
  const options = {
    chart: {
      id: "month",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: borrowingsMonth.map((borrowing) =>
        calculateMonth(borrowing.month)
      ), //will be displayed on the x-asis
    },
    stroke: {
      show: true,
      curve: "smooth",
      colors: ["#214b9e"],
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "start",
      fontSize: "13px",
      fontFamily: "Poppins-Regular",
      fontWeight: 500,
      markers: {
        colors: ["#214b9e"],
        strokeColors: ["#214b9e"],
      },
    },
  };

  const series = [
    {
      name: t("borrowings_through_months"), //will be displayed on the y-axis
      type: "bar",
      color: "#214b9e",
      data: borrowingsMonth.map((borrow) => borrow.data),
    },
  ];

  //the function which return the list of total, completed, canceled leads
  useEffect(() => {
    stats();
  }, []);

  const stats = () => {
    Request.getRequest(ApiUrl.stats).then((result) => {
      if (result.status === 200) {
        setBorrowingsMonth(result.data.borrowings_m);
        setLoading(false);
      } else {
        setBorrowingsMonth([]);
        setLoading(true);
      }
    });
  };

  return (
    <Fragment>
      {loading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : (
        <div id="overview-chart" dir="ltr">
          <ReactApexChart
            series={series}
            options={options}
            type="bar"
            height={240}
            className="apex-charts"
          />
        </div>
      )}
    </Fragment>
  );
};

export default StatisticsChart;
