import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import ShowAcquisition from "../../../components/panel/acquisition/ShowAcquisition";

const ShowAcquisitionView = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>{t("show_acquisition_views")}</title>
      </Helmet>
      <Sidebar>
        <ShowAcquisition />
      </Sidebar>
    </Fragment>
  );
};

export default ShowAcquisitionView;
