import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Sidebar from "../../../../../components/layouts/sidebar/Sidebar";
import CreateBiblio from "../../../../../components/panel/catalog/bibliography/CreateBibliography/CreateBiblio";

const CreateBiblioView = () => {
  const { t } = useTranslation("");
  return (
    <Fragment>
      <Helmet>
        <title>{t("catalog_create_bibliography_createbiblio_tab")}</title>
      </Helmet>
      <Sidebar>
        <CreateBiblio />
      </Sidebar>
    </Fragment>
  );
};

export default CreateBiblioView;
