import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";

const CreateFrequence = ({ onShowModal, setFrequenceVisible, setModified }) => {
  const { t } = useTranslation();

  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [issuesPerUnit, setIssuesPerUnit] = useState("");
  const [unitsPerIssue, setUnitsPerIssue] = useState("");
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const onCloseModal = () => {
    setDescription("");
    setUnit("");
    setIssuesPerUnit("");
    setUnitsPerIssue("");
    setError([]);
    setFrequenceVisible(false);
  };

  const createFrequence = (e) => {
    setBtnLoading(true);
    e.preventDefault();
    var formData = {
      description: description,
      unit: unit,
      issues_per_unit: issuesPerUnit,
      units_per_issue: unitsPerIssue,
    };

    Request.postRequest(ApiUrl.storeFrequence, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setDescription("");
        setUnit("");
        setIssuesPerUnit("");
        setUnitsPerIssue("");
        setError([]);
        onCloseModal();
        setModified(true);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      }
    });
  };

  return (
    <Modal
      isOpen={onShowModal}
      toggle={() => onCloseModal()}
      size="md"
      centered
    >
      <ModalHeader
        className="text-dark font-size-15"
        toggle={() => onCloseModal()}
        tag="span"
      >
        {t("create_frequence_popup")}
      </ModalHeader>
      <form onSubmit={(e) => createFrequence(e)}>
        <ModalBody>
          <div className="form-block p-0">
            <label>{t("create_frequence_description")}</label>
            <input
              placeholder={t("create_frequence_description")}
              name="description"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={
                "form-input" + (error.description ? " input-error" : "")
              }
            />
            {error?.description && (
              <ValidationMessage message={error?.description} />
            )}
          </div>
          <div className="form-block p-0">
            <label>{t("create_frequence_unit")}</label>
            <input
              placeholder={t("create_frequence_unit")}
              name="unit"
              type="text"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              className={"form-input" + (error.unit ? " input-error" : "")}
            />
            {error?.unit && <ValidationMessage message={error?.unit} />}
          </div>
          <div className="row">
            <div className="form-block col-12 col-sm-6">
              <label>{t("create_frequence_series_per_unit")}</label>
              <input
                placeholder={t("create_frequence_series_per_unit")}
                name="issues_per_unit"
                type="number"
                value={issuesPerUnit}
                onChange={(e) => setIssuesPerUnit(e.target.value)}
                className={
                  "form-input" + (error.issues_per_unit ? " input-error" : "")
                }
              />
              {error?.issues_per_unit && (
                <ValidationMessage message={error?.issues_per_unit} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6">
              <label>{t("create_frequence_unit_per_serie")}</label>
              <input
                placeholder={t("create_frequence_unit_per_serie")}
                name="units_per_issue"
                type="number"
                value={unitsPerIssue}
                onChange={(e) => setUnitsPerIssue(e.target.value)}
                className={
                  "form-input" + (error.units_per_issue ? " input-error" : "")
                }
              />
              {error?.units_per_issue && (
                <ValidationMessage message={error?.units_per_issue} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button
                  className="button-blue has-icon w-100 text-center"
                  type="button"
                >
                  <i className="icon spinner-icon me-2" />
                  {t("create_frequence_creating")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon w-100 text-center"
                  type="submit"
                >
                  {t("create_frequence_create")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100 text-center"
                type="button"
                onClick={() => onCloseModal()}
              >
                {t("create_frequence_cancel")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateFrequence;
