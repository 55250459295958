import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import FormData from "form-data";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as AppConfig from "../../../helpers/AppConfig";
import * as Message from "../../../helpers/Messages";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const EditPost = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();
  const editorRef = useRef(null);

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [introImage, setIntroImage] = useState("");
  const [introImagePath, setIntroImagePath] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const getEditorContent = () => {
    setDescription(editorRef.current.getContent());
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const post_id = param.id;
    Request.getRequest(ApiUrl.editBlogPost + post_id).then((response) => {
      if (response.status === 200) {
        setId(response.data.blog.id);
        setTitle(response.data.blog.title);
        setIntroImagePath(
          response.data.blog.intro_image ? response.data.blog.intro_image : ""
        );
        setDescription(response.data.blog.description);
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
        navigate(-1);
      } else {
        Message.errorMsg(t("error_message"));
        navigate(-1);
      }
    });
  };

  const updatePost = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const post_id = param.id;
    const formData = new FormData();
    formData.append("intro_image", introImage);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("_method", "PATCH");

    Request.postRequestExport(ApiUrl.updateBlogPost + post_id, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          navigate(Path.listBlogPosts);
          Message.successMsg(response.data.success);
        } else if (response.status === 422) {
          setError(response.data);
        }
      }
    );
  };

  const deleteIntroImage = (id, title) => {
    ConfirmPopup.deletePopup(title).then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteIntroImage + id).then((response) => {
          if (response.status === 200) {
            Message.successMsg(response.data.success);
            setIntroImage("");
            setIntroImagePath("");
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("edit_post_change_article")}</p>
      <form onSubmit={(e) => updatePost(e)}>
        <div className="row flex-column">
          <div className="form-block col-sm-6">
            <label htmlFor="title">{t("edit_post_title")}</label>
            <input
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              className={"form-input" + (error.title ? " input-error" : "")}
              id="title"
              name="title"
              value={title}
              placeholder={t("edit_post_article_title")}
            />
            {error.title && <ValidationMessage message={error.title[0]} />}
          </div>
          <div className="form-block col-12 col-sm-6">
            <label>{t("edit_post_image_of_article")}</label>
            <div className="upload-file-box">
              <div
                className={
                  "form-input" + (error.intro_image ? " input-error" : "")
                }
              >
                {introImage === "" && introImagePath === "" ? (
                  <Fragment>
                    <input
                      className="upload-input"
                      onChange={(e) => {
                        setIntroImage(e.target.files[0]);
                        setIntroImagePath(e.target.files[0]);
                      }}
                      accept="image/*"
                      name="cover"
                      type="file"
                    />
                    <div className="upload-input-placeholder">
                      <span>{t("edit_post_image_of_article")}</span>
                      <i className="icon upload-icon" />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    {introImage === "" ? (
                      <div className="upload-input-placeholder">
                        <span>{introImagePath}</span>
                        <i
                          className="icon close-red-icon"
                          onClick={() => deleteIntroImage(id, introImagePath)}
                        />
                      </div>
                    ) : (
                      <div className="upload-input-placeholder">
                        <span>{introImage.name}</span>
                        <i
                          className="icon close-red-icon"
                          onClick={() => {
                            setIntroImage("");
                            setIntroImagePath("");
                          }}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
              {(introImage !== "" || introImagePath !== "") && (
                <div className="uploaded-image">
                  <div className="image-div">
                    {introImage !== "" ? (
                      <img src={URL.createObjectURL(introImage)} alt="Cover" />
                    ) : (
                      <img
                        src={AppConfig.blogPath + introImagePath}
                        alt="Cover"
                      />
                    )}
                  </div>
                </div>
              )}
              {error.intro_image && (
                <ValidationMessage message={error.intro_image[0]} />
              )}
            </div>
          </div>

          <div className="col-12 col-sm-12 pb-4 mb-2 position-relative">
            <label htmlFor="description">{t("edit_post_description")}</label>
            <div className={error.description ? " input-error" : ""}>
              <Editor
                onSubmit={(e) => getEditorContent(e)}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={description}
                init={{
                  height: 500,
                  plugins: [
                    "advlist autolink link image lists charmap print preview hr anchor pagebreak",
                    "searchreplace wordcount visualblocks code fullscreen insertdatetime media nonbreaking",
                    "table emoticons template paste help",
                  ],
                  toolbar:
                    "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist outdent indent | link image | print preview media fullpage | " +
                    "forecolor backcolor emoticons | help",
                  menu: {
                    favs: {
                      title: "My Favorites",
                      items: "code visualaid | searchreplace | emoticons",
                    },
                  },
                  menubar: "favs file edit view insert format tools table help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>
            {error.description && (
              <ValidationMessage message={error.description[0]} />
            )}
          </div>
          <div className="col-12 col-sm-12 pb-4 mt-3">
            {btnLoading === true ? (
              <button className="button-blue has-icon" type="button">
                <i className="icon spinner-icon me-2" />
                {t("saving_edit_post")}
              </button>
            ) : (
              <button className="button-blue has-icon" type="submit">
                {t("save_edit_post")}
              </button>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default EditPost;
