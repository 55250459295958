import { t } from "i18next";

export const CheckContributors = (contributor) => {
  switch (contributor) {
    case "translator":
      return t("contributor_check_type_interpreter");
    case "editor":
      return t("contributor_check_type_editor");
    case "reviewer":
      return t("contributor_check_type_reviewer");
    case "preparatory":
      return t("contributor_check_type_preparatory");
    default:
      return "";
  }
};

export const CheckContributorsType = (contributor) => {
  switch (contributor) {
    case "translator":
      return t("contributor_check_full_type_interpreter");
    case "editor":
      return t("contributor_check_full_type_editor");
    case "reviewer":
      return t("contributor_check_full_type_reviewer");
    case "preparatory":
      return t("contributor_check_full_type_preparatory");
    default:
      return "";
  }
};

export const CheckContributorsValue = (contributor) => {
  switch (contributor) {
    case "translator":
      return { value: "translator", label: t("contributor_type_interpreter") };
    case "editor":
      return { value: "editor", label: t("contributor_type_editor") };
    case "editor.":
      return { value: "editor", label: t("contributor_type_editor") };
    case "reviewer":
      return { value: "reviewer", label: t("contributor_type_reviewer") };
    case "preparatory":
      return { value: "preparatory", label: t("contributor_type_preparatory") };
    default:
      return { value: "contributor", label: t("contributor_type_contributor") };
  }
};

export const CheckAuthors = (author) => {
  switch (author) {
    case "primary":
      return t("author_type_first");
    case "secondary":
      return t("author_type_second");
    case "third":
      return t("author_type_third");
    case "author":
      return t("author_type_author");
    default:
      return "";
  }
};

export const CheckAuthorsValue = (author) => {
  switch (author) {
    case "primary":
      return { value: "primary", label: t("author_type_first") };
    case "secondary":
      return { value: "secondary", label: t("author_type_second") };
    case "third":
      return { value: "third", label: t("author_type_third") };
    case "author.":
      return { value: "author", label: t("author_type_first") };
    case "author":
      return { value: "author", label: t("author_type_first") };
    default:
      return { value: "author", label: t("author_type_author") };
  }
};

export const CheckBorrowingType = (author) => {
  switch (author) {
    case "library":
      return { value: "library", label: t("check_borrowing_type_library") };
    case "normal":
      return { value: "normal", label: t("check_borrowing_type_normal") };
    case "unavailable":
      return {
        value: "unavailable",
        label: t("check_borrowing_type_unavailable"),
      };
    default:
      return null;
  }
};

export const CheckSelectedDate = (value) => {
  if (value === "" || value === null) {
    return null;
  } else {
    return new Date(value);
  }
};
