import { useTranslation } from "react-i18next";
import { SpecimenData } from "../../../../../store/Catalog/ShowBibliography/Specimen";
import { apiBaseUrl, authRole } from "../../../../../helpers/AppConfig";
import moment from "moment";
import Loading from "../../../../../helpers/Loading";

const Specimen = ({ loading }) => {
  const { t } = useTranslation("");

  const specimenData = SpecimenData((state) => state.specimenData);

  return (
    <div className="specimen-data mt-5">
      <div className="py-0 mb-4 detailed-biblio-infohead">
        <div className="col-12 material-title">
          {t("catalog_showbibliography_specimen_data")}
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : specimenData.length > 0 ? (
        specimenData.map((item, index) => (
          <div key={index} className="specimen-item">
            <div className="item-attributes-summary">
              <div
                className={`col-12 ${
                  authRole() === "administrator" ? "col-sm-4" : "col-sm-5"
                }`}
              >
                <div className="item-row">
                  <span className="item-title">
                    {t("catalog_showbibliography_specimen_codification")} :
                  </span>
                  <span className="item-value">{item?.inventory_number}</span>
                </div>
                <div className="item-row">
                  <span className="item-title">
                    {t("catalog_showbibliography_specimen_place_number")} :
                  </span>
                  <span className="item-value">{item?.place_number}</span>
                </div>
                {authRole() === "administrator" && (
                  <>
                    <div className="item-row">
                      <span className="item-title">
                        {t("catalog_showbibliography_specimen_borrowing_type")}{" "}
                        :
                      </span>
                      <span className="item-value">
                        {item?.borrowing_type_trans}
                      </span>
                    </div>
                    <div className="item-row">
                      <span className="item-title">
                        {t(
                          "catalog_showbibliography_specimen_inventory_number"
                        )}{" "}
                        :
                      </span>
                      <span className="item-value">{item?.id}</span>
                    </div>
                  </>
                )}
              </div>
              {authRole() !== "administrator" && (
                <div className="col-12 col-sm-6">
                  <div className="item-row">
                    <span className="item-title">
                      {t("catalog_showbibliography_specimen_borrowing_type")} :
                    </span>
                    <span className="item-value">
                      {item?.borrowing_type && t('catalog_showbibliography_specimen_not_borrowed')}
                    </span>
                  </div>
                  <div className="item-row">
                    <span className="item-title">
                      {t("catalog_showbibliography_specimen_inventory_number")}{" "}
                      :
                    </span>
                    <span className="item-value">{item?.id}</span>
                  </div>
                </div>
              )}
              {authRole() === "administrator" && (
                <>
                  <div className="col-12 col-sm-3">
                    <div className="item-row">
                      <span className="item-title">
                        {t("catalog_showbibliography_specimen_lost")} :
                      </span>
                      <span className="item-value">
                        {item?.lost === 0
                          ? t("catalog_showbibliography_specimen_lost_no")
                          : t("catalog_showbibliography_specimen_lost_yes")}
                      </span>
                    </div>
                    <div className="item-row">
                      <span className="item-title">
                        {t("catalog_showbibliography_specimen_damaged")} :
                      </span>
                      <span className="item-value">
                        {item?.damaged === 0    ? t("catalog_showbibliography_specimen_damaged_no")
                          : t("catalog_showbibliography_specimen_damaged_yes")}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4">
                    <div className="item-row">
                      <span className="item-title">
                        {t("catalog_showbibliography_specimen_lost_date")} :
                      </span>
                      <span className="item-value">
                        {item?.lost_date
                          ? moment(item.lost_date).format("DD-MM-yyyy")
                          : "-"}
                      </span>
                    </div>
                    <div className="item-row">
                      <span className="item-title">
                        {t("catalog_showbibliography_specimen_damaged_date")} :
                      </span>
                      <span className="item-value">
                        {item?.damaged_date
                          ? moment(item.damaged_date).format("DD-MM-yyyy")
                          : "-"}
                      </span>
                    </div>
                    <div className="item-row">
                      <span className="item-title">
                        {t("catalog_showbibliography_specimen_renovation_date")}{" "}
                        :
                      </span>
                      <span className="item-value">
                        {item.renewal_date
                          ? moment(item.renewal_date).format("DD-MM-yyyy")
                          : "-"}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="item-action">
              {item.borrowed === 0 && (
                <span className="green-tag pl-2 pr-2">
                  {t("catalog_showbibliography_specimen_available")}
                </span>
              )}
              {item.borrowed === 1 && (
                <span className="red-tag pl-2 pr-2">
                  {t("catalog_showbibliography_specimen_borrowed")}
                </span>
              )}

              <a
                href={`${
                  apiBaseUrl + "catalog/item/generate-location/" + item.id
                }`}
                className="button-unstyled-icon"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon print-icon" />
              </a>
            </div>
          </div>
        ))
      ) : (
        <span className="nothing-to-show">
          {t("catalog_showbibliography_no_specimen_data")}
        </span>
      )}
    </div>
  );
};

export default Specimen;
