import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Sidebar from "../../../../components/layouts/sidebar/Sidebar";
import ShowBiblioitem from "../../../../components/panel/catalog/bibliography/ShowBiblioitem/ShowBiblioitem";

const ShowBiblioitemView = () => {
  const { t } = useTranslation("");
  return (
    <Fragment>
      <Helmet>
        <title>{t("catalog_showbibliography_tab")}</title>
      </Helmet>
      <Sidebar>
        <ShowBiblioitem />
      </Sidebar>
    </Fragment>
  );
};

export default ShowBiblioitemView;
