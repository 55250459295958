import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../helpers/Loading";
import moment from "moment";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";

const ShowStaff = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    staffDetails();
  }, []);

  const staffDetails = () => {
    const staffID = param.id;

    let request = ApiUrl.showStaff + staffID;

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setDetails(response.data.staff);
        setLoading(false);
      } else if (response.status === 404) {
        navigate(Path.listStaff);
        Message.successMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("show_staff_details")}</p>
      <div className="row mb-5">
        <div className="list-data col-12 col-md-8 d-flex">
          {loading ? (
            <tr>
              <td className="text-center" colSpan="3">
                <Loading />
              </td>
            </tr>
          ) : (
            <ul>
              <li className="member-detail">
                {t("show_staff_name_lastname_role")} :{" "}
                {details.first_name + " " + details.last_name} /{" "}
                {details.role.name_trans}
              </li>
              <li>
                {t("show_staff_email")} : {details.email ? details.email : "-"}
              </li>
              <li>
                {t("show_staff_mobile")} : {details.phone ? details.phone : "-"}
              </li>
              <li>
                {t("show_staff_birthday")} :{" "}
                {details.dob ? moment(details.dob).format("DD-MM-yyy") : "-"}
              </li>

              <li>
                {t("show_staff_gender")} :{" "}
                {details.gender
                  ? (details.gender === "F" && t("female")) ||
                    (details.gender === "M" && t("male"))
                  : "-"}
              </li>
              <li>
                {t("show_staff_state_city")} :{" "}
                {details.state ? details.state : "-"} /{" "}
                {details.city ? details.city : "-"}
              </li>
              <li>
                {t("show_staff_adress_postal_code")} :{" "}
                {details.address ? details.address : "-"} /{" "}
                {details.postal_code ? details.postal_code : "-"}
              </li>
              <li></li>
            </ul>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ShowStaff;
