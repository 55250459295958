import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import CreatePost from "../../../components/panel/blog/CreatePost";

const CreatePostView = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>{t("new_article_view")}</title>
      </Helmet>
      <Sidebar>
        <CreatePost />
      </Sidebar>
    </Fragment>
  );
};
export default CreatePostView;
