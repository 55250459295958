import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StatisticsChart from "./StatisticsChart";
import StatisticsChartYear from "./StatisticsChartYear";
import Loading from "../../../../helpers/Loading";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Tooltip from "../../../../helpers/Tooltip";

const IndexStats = () => {
  const { t } = useTranslation();

  const [oldest, setOldest] = useState([]);
  const [mostBorrowed, setMostBorrowed] = useState([]);
  const [neverBorrowed, setNeverBorrowed] = useState([]);
  const [bestMembers, setBestMembers] = useState([]);
  const [totalPrice, setTotalPrice] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    stats();
  }, []);

  const stats = () => {
    Request.getRequest(ApiUrl.stats).then((result) => {
      if (result.status === 200) {
        setOldest(result.data.oldest);
        setMostBorrowed(result.data.the_most_borrowed);
        setNeverBorrowed(result.data.never_borrowed);
        setBestMembers(result.data.best_members);
        setTotalPrice(result.data.total_price);
        setLoading(false);
      } else {
        setOldest([]);
        setMostBorrowed([]);
        setNeverBorrowed([]);
        setBestMembers([]);
        setTotalPrice([]);
        setLoading(true);
      }
    });
  };

  return (
    <Fragment>
      <div className="partial-title mb-5">{t("index_stats_statistics")}</div>
      <div className="row mb-5">
        <div className="col-12 col-xl-6 mb-4">
          <div className="stats-title font-smaller mb-4">
            {t("index_stats_financial_information")}
          </div>
          <div className="table-list">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th role="columnheader" className="text-left rounded">
                    {t("index_stats_total_value_is")}{" "}
                    {totalPrice.total_price ? totalPrice.total_price : 0}{" "}
                    {t("index_stats_currency")}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xl-6 mb-4">
          <div className="stats-title font-smaller mb-4">
            {t("index_stats_oldest_books")}
          </div>
          <div className="table-list">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th width="70%" role="columnheader" className="text-left">
                    {t("index_stats_title_author")}
                  </th>
                  <th width="30%" role="columnheader" className="text-center">
                    {t("index_stats_year_of_publication")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="2">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  oldest.map((oldest, index) => (
                    <tr key={index} className="bg-white tr-report-stats">
                      <td data-label={t("index_stats_title_author")}>
                        {Tooltip.tooltipDisabledMobile(
                          "-" + index,
                          oldest["title"]
                        )}
                        <p className="autor">
                          {oldest["authors"].map((item, index, arr) => {
                            if (index === arr.length - 1) {
                              return item["name"];
                            } else {
                              return item["name"] + "; ";
                            }
                          })}
                        </p>
                      </td>
                      <td
                        className="text-center"
                        data-label={t("index_stats_year_of_publication")}
                      >
                        {oldest["year"]}
                      </td>
                    </tr>
                  ))
                )}
                {!loading && oldest.length === 0 && (
                  <tr>
                    <td colSpan={2} className="nothing--to--show my-3">
                      {t("nothing_to_show_list_post")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-xl-6 most-borrowed">
          <div className="stats-title font-smaller mb-4">
            {t("index_stats_most_borrowed_books")}
          </div>
          <div className="table-list">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th width="70%" role="columnheader" className="text-left">
                    {t("index_stats_title_author")}
                  </th>
                  <th width="30%" role="columnheader" className="text-center">
                    {t("index_stats_nr_of_borrowings")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="2">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  mostBorrowed.map((item, index) => (
                    <tr key={index} className="bg-white tr-report-stats">
                      <td data-label={t("index_stats_title_author")}>
                        {Tooltip.tooltipDisabledMobile(
                          "-title-" + index,
                          item["title"]
                        )}
                        <p className="autor">
                          {item["authors"].map((item_a, index, arr) => {
                            if (index === arr.length - 1) {
                              return item_a["name"];
                            } else {
                              return item_a["name"] + "; ";
                            }
                          })}
                        </p>
                      </td>
                      <td
                        className="text-center"
                        data-label={t("index_stats_nr_of_borrowings")}
                      >
                        {item["borrowings_count"]}
                      </td>
                    </tr>
                  ))
                )}
                {!loading && mostBorrowed.length === 0 && (
                  <tr>
                    <td colSpan={2} className="nothing--to--show my-3">
                      {t("nothing_to_show_list_post")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="stats-title font-smaller mt-5 mb-4">
            {t("index_stats_never_borrowed_books")}
          </div>
          <div className="table-list">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th role="columnheader" className="text-center">
                    {t("index_stats_title_author")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="2">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  neverBorrowed.map((item, index) => (
                    <tr key={index} className="bg-white tr-report-stats">
                      <td
                        data-label={t("index_stats_title_author")}
                        className="text-center"
                      >
                        <div className="d-inline-flex">
                          {Tooltip.tooltipDisabledMobile(
                            "-titull_" + index,
                            item["title"]
                          )}
                        </div>
                        <p className="autor">
                          {item["authors"].map((item_a, index, arr) => {
                            if (index === arr.length - 1) {
                              return item_a["name"];
                            } else {
                              return item_a["name"] + "; ";
                            }
                          })}
                        </p>
                      </td>
                    </tr>
                  ))
                )}
                {!loading && neverBorrowed.length === 0 && (
                  <tr>
                    <td colSpan={2} className="nothing--to--show my-3">
                      {t("nothing_to_show_list_post")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="stats-title font-smaller mt-5 mb-4">
            {t("index_stats_members_with_most_borrowings")}
          </div>
          <div className="table-list">
            <table role="table" className="w-100">
              <thead>
                <tr role="row">
                  <th width="70%" role="columnheader" className="text-left">
                    {t("index_stats_member")}
                  </th>
                  <th width="30%" role="columnheader" className="text-center">
                    {t("index_stats_nr_of_borrowings")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center" colSpan="2">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  bestMembers.map((item, index) => (
                    <tr key={index} className="bg-white tr-report-stats">
                      <td data-label={t("index_stats_member")}>
                        {item["first_name"]} {item["last_name"]}
                      </td>
                      <td
                        data-label={t("index_stats_nr_of_borrowings")}
                        className="text-center"
                      >
                        {item["borrowings_count"]}
                      </td>
                    </tr>
                  ))
                )}
                {!loading && bestMembers.length === 0 && (
                  <tr>
                    <td colSpan={10} className="nothing--to--show my-3">
                      {t("nothing_to_show_list_post")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col-12 col-md-6 mt-5" id="month">
              <p className="text-center chart-title">
                {t("borrowings_through_months")}
              </p>
              <StatisticsChart />
            </div>
            <div className="col-12 col-md-6 mt-5" id="year">
              <p className="text-center chart-title">
                {t("borrowings_through_years")}
              </p>
              <StatisticsChartYear />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default IndexStats;
