import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import Loading from "../../../../helpers/Loading";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";

const StatisticsChartYear = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [borrowingsYear, setBorrowingsYear] = useState([]);

  //populating and adding properties to the chart
  const options = {
    chart: {
      id: "year",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: borrowingsYear.map((borrowing) => borrowing.year), //will be displayed on the x-asis
    },
    stroke: {
      show: true,
      curve: "smooth",
      colors: ["#214b9e"],
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "start",
      fontSize: "13px",
      fontFamily: "Poppins-Regular",
      fontWeight: 500,
      markers: {
        colors: ["#214b9e"],
        strokeColors: ["#214b9e"],
      },
    },
  };

  const series = [
    {
      name: t("borrowings_through_years"), //will be displayed on the y-axis
      type: "bar",
      color: "#214b9e",
      data: borrowingsYear.map((borrow) => borrow.data),
    },
  ];

  //the function which return the list of total, completed, canceled leads
  useEffect(() => {
    stats();
  }, []);

  const stats = () => {
    Request.getRequest(ApiUrl.stats).then((result) => {
      if (result.status === 200) {
        setBorrowingsYear(result.data.borrowings_y);
        setLoading(false);
      } else {
        setBorrowingsYear([]);
        setLoading(true);
      }
    });
  };

  return (
    <Fragment>
      {loading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : (
        <div id="overview-chart" dir="ltr">
          <ReactApexChart
            series={series}
            options={options}
            type="bar"
            height={240}
            className="apex-charts"
          />
        </div>
      )}
    </Fragment>
  );
};

export default StatisticsChartYear;
