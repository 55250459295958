import { biblioCoversPath } from "./AppConfig";

import Article from "../assets/images/covers/article.svg";
import Book from "../assets/images/covers/book.svg";
import Periodic from "../assets/images/covers/periodic.svg";
import Manuscript from "../assets/images/covers/manuscript.svg";
import Map from "../assets/images/covers/map.svg";
import Dissertation from "../assets/images/covers/dissertation.svg";
import AudioVisualMaterial from "../assets/images/covers/audio_visual_material.svg";
import Document from "../assets/images/covers/document.svg";

const CheckCover = (cover, materialType) => {
  if (cover !== null) {
    return biblioCoversPath + cover;
  }
  if (cover === null) {
    switch (materialType) {
      case 1:
        return Article;
      case 2:
        return Book;
      case 3:
        return Periodic;
      case 4:
        return Manuscript;
      case 5:
        return Map;
      case 6:
        return Dissertation;
      case 7:
        return AudioVisualMaterial;
      case 8:
        return Document;
      default:
        return "";
    }
  }
};

export default CheckCover;
