import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import EditSerial from "../../../components/panel/serial/EditSerial/EditSerial";

const EditSerialView = () => {
  const { t } = useTranslation("");
  return (
    <Fragment>
      <Helmet>
        <title>{t("serial_edit_serial_tab")}</title>
      </Helmet>
      <Sidebar>
        <EditSerial />
      </Sidebar>
    </Fragment>
  );
};

export default EditSerialView;
