import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { authRole } from "../../../../helpers/AppConfig";
import {
  CheckBorrowingType,
  CheckSelectedDate,
} from "../../../../helpers/CheckValue";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";

const CreateSerie = ({ biblioitemId, notApprovedSerieId, setNewSerie }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [pattern, setPattern] = useState("");
  const [publishedData, setPublishedDate] = useState("");
  const [cover, setCover] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const [index, setIndex] = useState(0);

  const [newItems, setNewItems] = useState([]);

  const addNewItem = () => {
    var items = newItems;
    var newItemData = {
      borrowing_type: "",
      inventory_number: "",
      place_number: "",
      damaged: 0,
      damaged_date: "",
      renewal_date: "",
      id: index,
    };
    items.push(newItemData);
    setNewItems([...items]);
    setIndex(index + 1);
  };

  useEffect(() => {
    addNewItem();
  }, []);

  const removeItem = (id) => {
    var items = newItems;
    items.forEach((item, index) => {
      if (item.id === id) {
        newItems.splice(index, 1);
      }
    });

    setNewItems([...items]);
  };

  const handleChangeSelect = (e, name, i) => {
    var items = newItems;
    items[i][name] = e ? e.value : "";
    setNewItems([...items]);
  };

  const handleChange = (e, name, i) => {
    var items = newItems;
    items[i][name] = e.target.value;
    setNewItems([...items]);
  };

  const handleChangeDate = (e, name, i) => {
    var items = newItems;
    items[i][name] = e ? moment(e).format("yyyy-MM-DD") : null;
    setNewItems([...items]);
  };

  const updateGeneratedSerie = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var formData = new FormData();

    if (cover !== "") {
      formData.append("cover", cover);
    }
    formData.append("approved", "1");
    formData.append("serial_id", id);
    formData.append("serie_id", notApprovedSerieId);
    formData.append("pattern", pattern);
    formData.append(
      "published_date",
      publishedData ? moment(publishedData).format("yyyy-MM-DD") : ""
    );
    formData.append("_method", "PATCH");

    newItems.forEach((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        key !== "id" &&
          formData.append(`items_not_approved_serie[${index}][${key}]`, value);
      }
      formData.append(
        `items_not_approved_serie[${index}][biblioitem_id]`,
        biblioitemId
      );
      formData.append(
        `items_not_approved_serie[${index}][serie_id]`,
        notApprovedSerieId
      );
    });

    Request.postRequest(ApiUrl.updateGeneratedSerie, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          setPattern("");
          setPublishedDate("");
          setCover("");
          setErrorList([]);
          setNewItems([]);
          setNewItems([
            {
              borrowing_type: "",
              inventory_number: "",
              place_number: "",
              damaged: 0,
              damaged_date: "",
              renewal_date: "",
              id: index,
            },
          ]);
          setIndex(index + 1);
          Message.successMsg(response.data.success);
          setNewSerie(true);
        } else if (response.status === 422) {
          setErrorList(response.data);
        }
      }
    );
  };

  return (
    <Fragment>
      <div className="generate-serie">
        <span className="serie-title">
          {t("edit_serial_create_serie_title")}
        </span>
        <form onSubmit={(e) => updateGeneratedSerie(e)}>
          <div className="col-12 col-md-10 d-flex flex-wrap mb-4 align-items-baseline">
            <div className="form-block col-12 col-md-4 p-0 pe-md-2">
              <label>{t("edit_serial_create_serie_serie_name_label")}</label>
              <input
                placeholder={t(
                  "edit_serial_create_serie_serie_name_placeholder"
                )}
                className={
                  "form-input" + (errorList.pattern ? " input-error" : "")
                }
                value={pattern}
                name="pattern"
                type="text"
                onChange={(e) => setPattern(e.target.value)}
              />
              {errorList.pattern && (
                <ValidationMessage message={errorList.pattern[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4 p-0 px-md-2">
              <label>
                {t("edit_serial_create_serie_published_date_label")}
              </label>
              <ReactDatePicker
                placeholderText={t(
                  "edit_serial_create_serie_published_date_placeholder"
                )}
                name="published_date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                //   locale={sq}
                dateFormat="dd-MM-yyyy"
                selected={
                  publishedData ? CheckSelectedDate(publishedData) : null
                }
                onChange={(e) =>
                  e ? setPublishedDate(e) : setPublishedDate(null)
                }
                autoComplete="off"
                className={
                  "date_input" +
                  (errorList.published_date ? " input-error" : "")
                }
              />
              {errorList.published_date && (
                <ValidationMessage message={errorList.published_date[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4 p-0 ps-md-2">
              <label>{t("edit_serial_create_serie_cover_label")}</label>
              <div className="upload-file-box">
                <div
                  className={
                    "form-input" + (errorList.cover ? " input-error" : "")
                  }
                >
                  {cover === "" ? (
                    <Fragment>
                      <input
                        className="upload-input"
                        onChange={(e) => setCover(e.target.files[0])}
                        accept="image/*"
                        name="cover"
                        type="file"
                      />
                      <div className="upload-input-placeholder">
                        <span>
                          {t("edit_serial_create_serie_cover_placeholder")}
                        </span>
                        <i className="icon upload-icon" />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="upload-input-placeholder">
                        <span>{cover.name}</span>
                        <i
                          className="icon close-red-icon"
                          onClick={() => setCover("")}
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
                {cover !== "" && (
                  <div className="uploaded-image">
                    <div className="image-div">
                      <img src={URL.createObjectURL(cover)} alt="Cover" />
                    </div>
                  </div>
                )}
                {errorList.cover && (
                  <ValidationMessage message={errorList.cover[0]} />
                )}
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12 col-md-10 p-0">
              {newItems.map((item, index) => (
                <Fragment key={item.id}>
                  <div className="row card-item-bg ">
                    <div className="form-block col-12 col-md-4 mb-4">
                      <label>
                        {t("edit_serial_create_serie_borrow_type_label")}
                      </label>
                      <ReactSelect
                        className={
                          "form-react-select" +
                          (errorList[
                            `items_not_approved_serie.${index}.borrowing_type`
                          ]
                            ? " input-error"
                            : "")
                        }
                        classNamePrefix="select_input"
                        name={
                          "items_not_approved_serie[" +
                          item.id +
                          "][borrowing_type]"
                        }
                        placeholder={t(
                          "edit_serial_create_serie_borrow_type_placeholder"
                        )}
                        defaultValue={
                          item.borrowing_type
                            ? CheckBorrowingType(item.borrowing_type)
                            : null
                        }
                        isSearchable={false}
                        isClearable={true}
                        menuPlacement="auto"
                        options={[
                          {
                            value: "library",
                            label: t("check_borrowing_type_library"),
                          },
                          {
                            value: "normal",
                            label: t("check_borrowing_type_normal"),
                          },
                          {
                            value: "unavailable",
                            label: t("check_borrowing_type_unavailable"),
                          },
                        ]}
                        onChange={(e) =>
                          handleChangeSelect(e, `borrowing_type`, index)
                        }
                      />
                      {errorList[
                        `items_not_approved_serie.${index}.borrowing_type`
                      ] && (
                        <ValidationMessage
                          message={
                            errorList[
                              `items_not_approved_serie.${index}.borrowing_type`
                            ]
                          }
                        />
                      )}
                    </div>
                    <div className="form-block col-12 col-md-4 mb-4">
                      <label>
                        {t("edit_serial_create_serie_codification_label")}
                      </label>
                      <input
                        placeholder={t(
                          "edit_serial_create_serie_codification_placeholder"
                        )}
                        className={
                          "form-input" +
                          (errorList[
                            `items_not_approved_serie.${index}.inventory_number`
                          ]
                            ? " input-error"
                            : "")
                        }
                        name={
                          "items_not_approved_serie[" +
                          item.id +
                          "][inventory_number]"
                        }
                        onChange={(e) =>
                          handleChange(e, `inventory_number`, index)
                        }
                        type="text"
                        defaultValue={
                          item.inventory_number ? item.inventory_number : ""
                        }
                      />
                      {errorList[
                        `items_not_approved_serie.${index}.inventory_number`
                      ] && (
                        <ValidationMessage
                          message={
                            errorList[
                              `items_not_approved_serie.${index}.inventory_number`
                            ]
                          }
                        />
                      )}
                    </div>
                    <div className="form-block col-12 col-md-4 mb-4">
                      <label>
                        {t("edit_serial_create_serie_place_number_label")}
                      </label>
                      <input
                        placeholder={t(
                          "edit_serial_create_serie_place_number_placeholder"
                        )}
                        className={
                          "form-input" +
                          (errorList[
                            `items_not_approved_serie.${index}.place_number`
                          ]
                            ? " input-error"
                            : "")
                        }
                        name={
                          "items_not_approved_serie[" +
                          item.id +
                          "][place_number]"
                        }
                        onChange={(e) => handleChange(e, `place_number`, index)}
                        type="text"
                        defaultValue={
                          item.place_number ? item.place_number : ""
                        }
                      />
                      {errorList[
                        `items_not_approved_serie.${index}.place_number`
                      ] && (
                        <ValidationMessage
                          message={
                            errorList[
                              `items_not_approved_serie.${index}.place_number`
                            ]
                          }
                        />
                      )}
                    </div>
                    {authRole() === "administrator" && (
                      <div className="form-block row col-12 m-0 p-0">
                        <div className="col-12 col-md-4 mb-4">
                          <label>
                            {t("edit_serial_create_serie_damaged_label")}
                          </label>
                          <div className="radio-buttons d-flex">
                            <div className="radio-button me-4">
                              <label htmlFor={`damaged_${index}`}>
                                {t(
                                  "edit_serial_create_serie_damaged_yes_label"
                                )}
                              </label>
                              <input
                                className="form-check-input"
                                name={
                                  "items_not_approved_serie[" +
                                  item.id +
                                  "][damaged]"
                                }
                                onChange={(e) =>
                                  handleChange(e, `damaged`, index)
                                }
                                type="radio"
                                value="1"
                                id={`damaged_${index}`}
                                defaultChecked={item.damaged === 1}
                              />
                            </div>
                            <div className="radio-button ms-4">
                              <label htmlFor={`notdamaged_${index}`}>
                                {t("edit_serial_create_serie_notdamaged_label")}
                              </label>
                              <input
                                className="form-check-input"
                                id={`notdamaged_${index}`}
                                name={
                                  "items_not_approved_serie[" +
                                  item.id +
                                  "][damaged]"
                                }
                                onChange={(e) =>
                                  handleChange(e, `damaged`, index)
                                }
                                type="radio"
                                value="0"
                                defaultChecked={item.damaged === 0}
                              />
                            </div>
                          </div>
                          {errorList[
                            `items_not_approved_serie.${index}.damaged`
                          ] && (
                            <ValidationMessage
                              message={
                                errorList[
                                  `items_not_approved_serie.${index}.damaged`
                                ]
                              }
                            />
                          )}
                        </div>

                        {(item.damaged === 1 || item.damaged === "1") && (
                          <Fragment>
                            <div className="col-12 col-md-4 mb-4">
                              <label>
                                {t(
                                  "edit_serial_create_serie_damaged_date_label"
                                )}
                              </label>
                              <ReactDatePicker
                                placeholderText={t(
                                  "edit_serial_create_serie_damaged_date_placeholder"
                                )}
                                name={
                                  "items_not_approved_serie[" +
                                  item.id +
                                  "][damaged_date]"
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                // locale={sq}
                                dateFormat="dd-MM-yyyy"
                                selected={
                                  item.damaged_date
                                    ? CheckSelectedDate(item.damaged_date)
                                    : null
                                }
                                onChange={(e) =>
                                  handleChangeDate(e, `damaged_date`, index)
                                }
                                autoComplete="off"
                                className={
                                  "date_input" +
                                  (errorList[
                                    `items_not_approved_serie.${index}.damaged_date`
                                  ]
                                    ? " input-error"
                                    : "")
                                }
                              />
                              {errorList[
                                `items_not_approved_serie.${index}.damaged_date`
                              ] && (
                                <ValidationMessage
                                  message={
                                    errorList[
                                      `items_not_approved_serie.${index}.damaged_date`
                                    ]
                                  }
                                />
                              )}
                            </div>
                            <div className="col-12 col-md-4 mb-4">
                              <label>
                                {t(
                                  "edit_serial_create_serie_renovation_date_label"
                                )}
                              </label>
                              <ReactDatePicker
                                placeholderText={t(
                                  "edit_serial_create_serie_renovation_date_placeholder"
                                )}
                                name={
                                  "items_not_approved_serie[" +
                                  item.id +
                                  "][renewal_date]"
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                // locale={sq}
                                dateFormat="dd-MM-yyyy"
                                selected={
                                  item.renewal_date
                                    ? CheckSelectedDate(item.renewal_date)
                                    : null
                                }
                                onChange={(e) =>
                                  handleChangeDate(e, `renewal_date`, index)
                                }
                                autoComplete="off"
                                className={
                                  "date_input" +
                                  (errorList[
                                    `items_not_approved_serie.${index}.renewal_date`
                                  ]
                                    ? " input-error"
                                    : "")
                                }
                              />
                              {errorList[
                                `items_not_approved_serie.${index}.renewal_date`
                              ] && (
                                <ValidationMessage
                                  message={
                                    errorList[
                                      `items_not_approved_serie.${index}.renewal_date`
                                    ]
                                  }
                                />
                              )}
                            </div>
                          </Fragment>
                        )}
                      </div>
                    )}
                    <div className="add-remove-buttons">
                      {index + 1 === newItems.length && (
                        <button
                          className="button-blue-border-item"
                          type="button"
                          onClick={() => addNewItem()}
                        >
                          +
                        </button>
                      )}
                      {newItems.length !== 1 && (
                        <button
                          type="button"
                          className="button-blue-item"
                          onClick={() => removeItem(item.id)}
                        >
                          -
                        </button>
                      )}
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
            <div className="col-12 col-md-2 ps-0 ps-md-3 mt-3 align-self-end">
              {btnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("edit_serial_create_serie_saving_serie")}
                </button>
              ) : (
                <button className="button-blue">
                  {t("edit_serial_create_serie_save_serie")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default CreateSerie;
