import { Fragment, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import FormData from "form-data";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";

const CreatePost = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const [title, setTitle] = useState("");
  const [introImage, setIntroImage] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const getEditorContent = () => {
    setDescription(editorRef.current.getContent());
  };

  const savePost = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("intro_image", introImage);
    formData.append("title", title);
    formData.append("description", description);

    Request.postRequestExport(ApiUrl.storeBlogPost, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 201) {
          navigate(Path.listBlogPosts);
          Message.successMsg(response.data.success);
        } else if (response.status === 422) {
          setError(response.data);
        }
      }
    );
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("create_post_new_article")}</p>
      <form onSubmit={(e) => savePost(e)}>
        <div className="row flex-column">
          <div className="form-block col-sm-6">
            <label htmlFor="title">{t("title_create_post")}</label>
            <input
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              className={"form-input" + (error.title ? " input-error" : "")}
              id="title"
              name="title"
              value={title}
              placeholder={t("title_of_post")}
            />
            {error.title && <ValidationMessage message={error.title[0]} />}
          </div>
          <div className="form-block col-12 col-sm-6">
            <label htmlFor="intro_image">{t("create_post_image")}</label>
            <div className="upload-file-box">
              <div
                className={
                  "form-input" + (error.intro_image ? " input-error" : "")
                }
              >
                {introImage === "" ? (
                  <Fragment>
                    <input
                      className="upload-input"
                      onChange={(e) => setIntroImage(e.target.files[0])}
                      accept="image/*"
                      name="cover"
                      type="file"
                    />
                    <div className="upload-input-placeholder">
                      <span>{t("create_post_image_placeholder")}</span>
                      <i className="icon upload-icon" />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="upload-input-placeholder">
                      <span>{introImage.name}</span>
                      <i
                        className="icon close-red-icon"
                        onClick={() => setIntroImage("")}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
              {introImage !== "" && (
                <div className="uploaded-image">
                  <div className="image-div">
                    <img src={URL.createObjectURL(introImage)} alt="Cover" />
                  </div>
                </div>
              )}
              {error.intro_image && (
                <ValidationMessage message={error.intro_image[0]} />
              )}
            </div>
          </div>
          <div className="form-block col-12 col-sm-12 position-relative">
            <label htmlFor="description">{t("create_post_description")}</label>
            <div className={error.description ? " input-error" : ""}>
              <Editor
                onChange={(e) => getEditorContent(e)}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue=""
                init={{
                  height: 500,
                  plugins: [
                    "advlist autolink link image lists charmap print preview hr anchor pagebreak",
                    "searchreplace wordcount visualblocks code fullscreen insertdatetime media nonbreaking",
                    "table emoticons template paste help",
                  ],
                  toolbar:
                    "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist outdent indent | link image | print preview media fullpage | " +
                    "forecolor backcolor emoticons | help",
                  menu: {
                    favs: {
                      title: "My Favorites",
                      items: "code visualaid | searchreplace | emoticons",
                    },
                  },
                  menubar: "favs file edit view insert format tools table help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>
            {error.description && (
              <ValidationMessage message={error.description[0]} />
            )}
          </div>
          <div className="col-12 col-sm-12">
            {btnLoading ? (
              <button className="button-blue has-icon" type="button">
                <i className="icon spinner-icon me-2" />
                {t("saving_create_post")}
              </button>
            ) : (
              <button className="button-blue has-icon" type="submit">
                {t("save_create_post")}
              </button>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default CreatePost;
