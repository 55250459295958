import { useTranslation } from "react-i18next";
import { ShowBibliography } from "../../../../../store/Catalog/ShowBibliography/Bibliography";
import CheckCover from "../../../../../helpers/CheckCover";

const Description = () => {
  const { t } = useTranslation("");

  const biblioData = ShowBibliography((state) => state.biblioData);

  return (
    <div className="row show-material">
      <div className="col-12 col-sm-4 d-none d-md-block image-holder ">
        <img
          src={CheckCover(
            biblioData?.cover,
            biblioData?.biblio?.materialtype?.id
          )}
          alt="Material cover"
        />
      </div>
      <div className="col-12 col-md-8 description-holder">
        {biblioData?.biblio?.title && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_title")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {`${
                biblioData?.biblio?.title +
                (biblioData.biblio.subtitle
                  ? " : " + biblioData.biblio.subtitle
                  : "") +
                (biblioData?.biblio?.authors.length > 0
                  ? " / " +
                    biblioData.biblio.authors
                      .map((author) =>
                        author.name.split(",").reverse().join(" ")
                      )
                      .join("; ")
                  : "") +
                (biblioData?.contributors.length > 0 &&
                biblioData?.biblio?.authors.length <= 0
                  ? " / " +
                    biblioData.contributors
                      .map((contributor) =>
                        contributor.name.split(",").reverse().join(" ")
                      )
                      .join("; ")
                  : biblioData?.contributors.length > 0 &&
                    biblioData?.biblio?.authors.length > 0
                  ? "; "
                  : biblioData.contributors
                      .map((contributor) =>
                        contributor.name.split(",").reverse().join(" ")
                      )
                      .join("; "))
              }`}
            </div>
          </div>
        )}

        {biblioData?.biblio?.authors.length > 0 && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_authors")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.authors
                .map((author) => author.name)
                .join("; ")}
            </div>
          </div>
        )}
        {biblioData?.isbn && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_isbn")}
            </div>
            <div className="col-12 col-md-8 name-item">{biblioData.isbn}</div>
          </div>
        )}
        {biblioData?.issn && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_issn")}
            </div>
            <div className="col-12 col-md-8 name-item">{biblioData.issn}</div>
          </div>
        )}
        {biblioData?.biblio?.medium && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_medium")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.medium}
            </div>
          </div>
        )}
        {biblioData?.publisher && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_publisher")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.publisher.name}
            </div>
          </div>
        )}
        {biblioData?.publication_year && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_publication_year")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.publication_year}
            </div>
          </div>
        )}
        {biblioData?.place && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_publication_place")}
            </div>
            <div className="col-12 col-md-8 name-item">{biblioData.place}</div>
          </div>
        )}
        {biblioData?.contributors.length > 0 && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_contributors")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.contributors
                .map((contributor) => contributor.name)
                .join("; ")}
            </div>
          </div>
        )}
        {biblioData?.pages && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_pages")}
            </div>
            <div className="col-12 col-md-8 name-item">{biblioData.pages}</div>
          </div>
        )}
        {biblioData?.physical_details && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_physical_details")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.physical_details}
            </div>
          </div>
        )}
        {biblioData?.dimensions && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_dimensions")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.dimensions}
            </div>
          </div>
        )}
        {biblioData?.biblio.language && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_publication_language")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.language.name_trans}
            </div>
          </div>
        )}

        {biblioData?.language && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_language")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.language.name_trans}
            </div>
          </div>
        )}

        {biblioData?.biblio?.keywords.length > 0 && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_keywords")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.keywords.map((keyword, index) => (
                <span key={index} className="tag">
                  {keyword.name}
                </span>
              ))}
            </div>
          </div>
        )}

        {biblioData?.biblio?.geographicalterms.length > 0 && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_geographical_terms")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.geographicalterms.map((terms, index) => (
                <span key={index} className="tag">
                  {terms.name}
                </span>
              ))}
            </div>
          </div>
        )}

        {biblioData?.biblio.part_name && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_part_name")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.part_name}
            </div>
          </div>
        )}

        {biblioData?.biblio.part_number && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_part_number")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.part_number}
            </div>
          </div>
        )}

        {biblioData?.publication_number && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_publication_number")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.publication_number}
            </div>
          </div>
        )}

        {biblioData?.lccn && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_lccn")}
            </div>
            <div className="col-12 col-md-8 name-item">{biblioData.lccn}</div>
          </div>
        )}

        {biblioData?.ean && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_ean")}
            </div>
            <div className="col-12 col-md-8 name-item">{biblioData.ean}</div>
          </div>
        )}

        {biblioData?.biblio?.agerestriction && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_agerestriction")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.agerestriction}
            </div>
          </div>
        )}
        {biblioData?.biblio?.year_original_publication && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_original_publication_year")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.year_original_publication}
            </div>
          </div>
        )}
        {biblioData?.national_control_number && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_national_control_number")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.national_control_number}
            </div>
          </div>
        )}
        {biblioData?.biblio?.notes && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_notes")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.notes}
            </div>
          </div>
        )}
        {biblioData?.biblio?.abstract && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_abstract")}
            </div>
            <div className="col-12 col-md-8 name-item">
              {biblioData.biblio.abstract}
            </div>
          </div>
        )}
        {biblioData?.kdu && (
          <div className="row">
            <div className="col-12 col-md-4 name-desc">
              {t("catalog_showbibliography_desc_kdu")}
            </div>
            <div className="col-12 col-md-8 name-item">{biblioData.kdu}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Description;
