import create from "zustand";
const itemsData = {
  items: [
    {
      id: 1,
      borrowing_type: "",
      place_number: "",
      renewal_date: "",
      damaged: 0,
      damaged_date: "",
    },
  ],
  newItems: [],
  deletedItems: [],
};

export const Items = create((set) => ({
  itemsData,
  addItemsData: (data) =>
    set(() => ({
      itemsData: {
        items: data?.items ? data.items : [],
        newItems: [],
        deletedItems: [],
      },
    })),

  addItemsUpdate: (data) => set(() => ({ itemsData: data })),
  reset: () => set(() => ({ itemsData : {
    items: [
      {
        id: 1,
        borrowing_type: "",
        place_number: "",
        renewal_date: "",
        damaged: 0,
        damaged_date: "",
      },
    ],
    newItems: [],
    deletedItems: [],
  } })),
}));
