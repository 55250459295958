import { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { tooltipDisabledMobile } from "../../../../helpers/Tooltip";
import { authorTypes, contributorTypes } from "../bibliography/HelpersList";
import {
  CheckAuthorsValue,
  CheckContributorsValue,
} from "../../../../helpers/CheckValue";
import ReactSelect from "react-select";
import Loading from "../../../../helpers/Loading";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as Path from "../../../../routes/GeneralPaths";

const ConfirmMarc = () => {
  const { t } = useTranslation("");
  const location = useLocation();
  const navigate = useNavigate();

  const [marcList, setMarcList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [materialTypes, setMaterialTypes] = useState([]);
  const [errors, setErrors] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    listMarc();
  }, []);

  const materialTypesFunc = () => {
    Request.getRequest(ApiUrl.materialTypes).then((response) => {
      if (response.status === 200) {
        setMaterialTypes(response.data.material_types);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const listMarc = () => {
    const marcFile = location?.state?.file_name;
    let request = ApiUrl.confirmMarcRecords + marcFile;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setMarcList(response.data.data);
        setLoading(false);
        materialTypesFunc();
      } else {
        navigate(-1);
      }
    });
  };

  const checkErrors = (type, name) => {
    if (errors[name]) {
      const inputName = name.replace(/\.(.+?)(?=\.|$)/g, (m, s) => `[${s}]`);
      var selectedInputs = document.querySelectorAll(
        `input[name="${inputName}"]`
      );
      selectedInputs.forEach((errorField) => {
        let errorContainer = errorField.closest(".accordion-collapse");
        if (!errorContainer.classList.contains("show")) {
          errorContainer.classList.add("show");
        }
      });
      switch (type) {
        case "input":
          return " input-error";
        case "span":
          return <ValidationMessage message={errors[name][0]} />;
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  const importMarc = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var inputs = e.target.querySelectorAll("input");

    const formData = new FormData();
    for (let input of inputs) {
      if (input.name.length > 0) {
        formData.append(input.name, input.value);
      }
    }

    Request.postRequestExport(ApiUrl.storeMarcRecords, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 201) {
          navigate(Path.listBiblioitems);
          Message.successMsg(response.data.success);
        } else if (response.status === 422) {
          setErrors(response.data);
        }
      }
    );
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("confirm_marc_title")}</p>
      <form onSubmit={(e) => importMarc(e)}>
        <div className="row mb-5 m-0 accordion-table">
          <div className="header-accordion-table">
            <div className="col-3 d-flex justify-content-start">
              {t("confirm_marc_accordion_table_title")}
            </div>
            <div className="col-2">
              {t("confirm_marc_accordion_table_isbn_issn")}
            </div>
            <div className="col-3">
              {t("confirm_marc_accordion_table_author")}
            </div>
            <div className="col-2">
              {t("confirm_marc_accordion_table_publisher")}
            </div>
            <div className="col-2">
              {t("confirm_marc_accordion_table_language")}
            </div>
          </div>
          <div
            id="accordion"
            className={
              "accordion-table-data" + (loading ? " py-3 text-center" : "")
            }
          >
            {!loading ? (
              marcList.map((value, index) => {
                return (
                  <div key={index} className="accordion-table-result">
                    <div
                      className="accordion-table-item"
                      data-bs-toggle="collapse"
                      data-bs-target={"#accordion-collapse-item_" + index}
                      aria-expanded="false"
                      aria-controls={"#accordion-collapse-item_" + index}
                    >
                      <div
                        className="col-12 col-md-3 text-left d-flex"
                        data-label={
                          t("confirm_marc_accordion_table_title") + ": "
                        }
                      >
                        {tooltipDisabledMobile(index, value.title)}
                      </div>
                      <div
                        className="col-12 col-md-2"
                        data-label={
                          t("confirm_marc_accordion_table_isbn_issn") + ": "
                        }
                      >
                        {value.isbn || value.issn
                          ? value.isbn || " / " + value.issn || ""
                          : "- / -"}
                      </div>
                      <div
                        className="col-12 col-md-3 text-center"
                        data-label={
                          t("confirm_marc_accordion_table_author") + ": "
                        }
                      >
                        {value?.author?.length > 0
                          ? value.author.map((author) => author).join("; ")
                          : "-"}
                      </div>
                      <div
                        className="col-12 col-md-2 text-center"
                        data-label={
                          t("confirm_marc_accordion_table_publisher") + ": "
                        }
                      >
                        {value?.publisher_name ? value.publisher_name : "-"}
                      </div>
                      <div
                        className="col-12 col-md-2 text-center"
                        data-label={
                          t("confirm_marc_accordion_table_language") + ": "
                        }
                      >
                        {value?.language_name ? value.language_name : "-"}
                      </div>
                    </div>
                    <div
                      id={"accordion-collapse-item_" + index}
                      className="accordion-collapse collapse"
                      aria-labelledby={"#item_" + index}
                    >
                      <div className="row">
                        <div className="form-block col-12 col-sm-6 col-md-4">
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_title")}
                              <span className="biblioitem-tag">(245/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".title"
                                )
                              }
                              name={"biblioitems[" + index + "][title]"}
                              type="text"
                              defaultValue={value.title}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".title"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_medium")}
                              <span className="biblioitem-tag">(245/H)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".medium"
                                )
                              }
                              name={"biblioitems[" + index + "][medium]"}
                              type="text"
                              defaultValue={value.medium}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".medium"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_subtitle")}
                              <span className="biblioitem-tag">(245/B)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".subtitle"
                                )
                              }
                              name={"biblioitems[" + index + "][subtitle]"}
                              type="text"
                              defaultValue={value.subtitle}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".subtitle"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_part_number"
                              )}
                              <span className="biblioitem-tag">(245/P)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".part_number"
                                )
                              }
                              name={"biblioitems[" + index + "][part_number]"}
                              type="text"
                              defaultValue={value.part_number}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".part_number"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_part_name"
                              )}
                              <span className="biblioitem-tag">(245/N)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".part_name"
                                )
                              }
                              name={"biblioitems[" + index + "][part_name]"}
                              type="text"
                              defaultValue={value.part_name}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".part_name"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_notes")}
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".notes"
                                )
                              }
                              name={"biblioitems[" + index + "][notes]"}
                              type="text"
                              defaultValue={value.notes}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".notes"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_abstract")}
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".abstract"
                                )
                              }
                              name={"biblioitems[" + index + "][abstract]"}
                              type="text"
                              defaultValue={value.abstract}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".abstract"
                            )}
                          </div>
                          <div className="form-block ">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_material_type"
                              )}
                            </label>
                            <ReactSelect
                              className={
                                "form-react-select" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".materialtype_id"
                                )
                              }
                              classNamePrefix="select_input"
                              name={
                                "biblioitems[" + index + "][materialtype_id]"
                              }
                              placeholder={t(
                                "confirm_marc_accordion_table_input_material_type_placeholder"
                              )}
                              isSearchable={false}
                              options={materialTypes.map((item) => ({
                                value: item.id,
                                label: item.name_trans,
                              }))}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".materialtype_id"
                            )}
                          </div>
                        </div>
                        <div className="form-block col-12 col-sm-6 col-md-4">
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_language")}
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".language_id"
                                )
                              }
                              name={"biblioitems[" + index + "][language_id]"}
                              type="text"
                              defaultValue={value.language_id}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".language_id"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_isbn")}
                              <span className="biblioitem-tag">(020/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".isbn"
                                )
                              }
                              name={"biblioitems[" + index + "][isbn]"}
                              type="text"
                              defaultValue={value.isbn}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".isbn"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_issn")}
                              <span className="biblioitem-tag">(022/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".issn"
                                )
                              }
                              name={"biblioitems[" + index + "][issn]"}
                              type="text"
                              defaultValue={value.issn}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".issn"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_publication_year"
                              )}
                              <span className="biblioitem-tag">(260/C)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".publication_year"
                                )
                              }
                              name={
                                "biblioitems[" + index + "][publication_year]"
                              }
                              type="number"
                              defaultValue={value.publication_year}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".publication_year"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_physical_details"
                              )}
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".physical_details"
                                )
                              }
                              name={
                                "biblioitems[" + index + "][physical_details]"
                              }
                              type="text"
                              defaultValue={value.physical_details}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".physical_details"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_pages")}
                              <span className="biblioitem-tag">(300/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".pages"
                                )
                              }
                              name={"biblioitems[" + index + "][pages]"}
                              type="text"
                              defaultValue={value.pages}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".pages"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_dimensions"
                              )}
                              <span className="biblioitem-tag">(300/C)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".dimensions"
                                )
                              }
                              name={"biblioitems[" + index + "][dimensions]"}
                              type="text"
                              defaultValue={value.dimensions}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".dimensions"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_place")}
                              <span className="biblioitem-tag">(260/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".place"
                                )
                              }
                              name={"biblioitems[" + index + "][place]"}
                              type="text"
                              defaultValue={value.place}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".place"
                            )}
                          </div>
                        </div>
                        <div className="form-block col-12 col-sm-6 col-md-4">
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_price")}
                              <span className="biblioitem-tag">(365/B)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".price"
                                )
                              }
                              name={"biblioitems[" + index + "][price]"}
                              type="number"
                              defaultValue={value.price}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".price"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_currency")}
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".currency"
                                )
                              }
                              name={"biblioitems[" + index + "][currency]"}
                              type="text"
                              defaultValue={value.currency}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".currency"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_kdu")}
                              <span className="biblioitem-tag">(080/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".kdu"
                                )
                              }
                              name={"biblioitems[" + index + "][kdu]"}
                              type="text"
                              defaultValue={value.kdu}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".kdu"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_national_control_number"
                              )}
                              <span className="biblioitem-tag">(016/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." +
                                    index +
                                    ".national_control_number"
                                )
                              }
                              name={
                                "biblioitems[" +
                                index +
                                "][national_control_number]"
                              }
                              type="text"
                              defaultValue={value.national_control_number}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." +
                                index +
                                ".national_control_number"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_publication_number"
                              )}
                              <span className="biblioitem-tag">(250/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".publication_number"
                                )
                              }
                              name={
                                "biblioitems[" + index + "][publication_number]"
                              }
                              type="text"
                              defaultValue={value.publication_number}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".publication_number"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_agerestriction"
                              )}
                              <span className="biblioitem-tag">(521/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".agerestriction"
                                )
                              }
                              name={
                                "biblioitems[" + index + "][agerestriction]"
                              }
                              type="text"
                              defaultValue={value.agerestriction}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".agerestriction"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_publisher_name"
                              )}
                              <span className="biblioitem-tag">(260/B)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".publisher_id"
                                )
                              }
                              name={"biblioitems[" + index + "][publisher_id]"}
                              type="text"
                              defaultValue={value.publisher_id}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".publisher_id"
                            )}
                          </div>
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_lccn")}
                              <span className="biblioitem-tag">(010/A)</span>
                            </label>
                            <input
                              className={
                                "form-input" +
                                checkErrors(
                                  "input",
                                  "biblioitems." + index + ".lccn"
                                )
                              }
                              name={"biblioitems[" + index + "][lccn]"}
                              type="text"
                              defaultValue={value.lccn}
                            />
                            {checkErrors(
                              "span",
                              "biblioitems." + index + ".lccn"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-block col-12 col-md-4">
                          <div className="pb-4 mb-3 or-bg pl-0 pr-0 confirm-Z3950-records">
                            <label>
                              {t("confirm_marc_accordion_table_input_keywords")}
                              <span className="biblioitem-tag">(650/A)</span>
                            </label>
                            <div className="form-input tags-container">
                              {value?.keywords?.length > 0
                                ? value.keywords.map((keyword, indexK) => (
                                    <input
                                      name={
                                        "biblioitems[" +
                                        index +
                                        "][keywords][" +
                                        indexK +
                                        "]"
                                      }
                                      type="text"
                                      defaultValue={keyword}
                                      readOnly
                                      key={indexK}
                                      className="tag"
                                    />
                                  ))
                                : t(
                                    "confirm_marc_accordion_table_input_no_keywords"
                                  )}
                            </div>
                          </div>
                        </div>
                        <div className="form-block col-12 col-md-4">
                          <div className="pb-4 mb-3 or-bg pl-0 pr-0 confirm-Z3950-records">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_geographical_terms"
                              )}
                              <span className="biblioitem-tag">(651/A)</span>
                            </label>
                            <div className="form-input tags-container">
                              {value?.geoterms?.length > 0
                                ? value.geoterms.map((geoterms, indexG) => (
                                    <input
                                      name={
                                        "biblioitems[" +
                                        index +
                                        "][geoterms][" +
                                        indexG +
                                        "]"
                                      }
                                      type="text"
                                      defaultValue={geoterms}
                                      readOnly
                                      className="tag"
                                      key={indexG}
                                    />
                                  ))
                                : t(
                                    "confirm_marc_accordion_table_input_no_geographical_terms"
                                  )}
                            </div>
                          </div>
                        </div>
                        <div className="form-block col-12 col-md-4">
                          <div className="pb-4 mb-3 py-0 pr-0 confirm-marc-records">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_frequence"
                              )}
                            </label>
                            {value.frequence_id ? (
                              <Fragment>
                                <input
                                  className={
                                    "form-input" +
                                    checkErrors(
                                      "input",
                                      "biblioitems." + index + ".frequence_id"
                                    )
                                  }
                                  name={
                                    "biblioitems[" + index + "][frequence_id]"
                                  }
                                  type="text"
                                  defaultValue={value.frequence_id}
                                />
                                {checkErrors(
                                  "span",
                                  "biblioitems." + index + ".frequence_id"
                                )}
                              </Fragment>
                            ) : (
                              <div className="form-input d-flex align-items-center">
                                {t(
                                  "confirm_marc_accordion_table_input_no_frequence"
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="pb-4 mb-3">
                            <label>
                              {t("confirm_marc_accordion_table_input_author")}
                            </label>
                            <div className="row">
                              {value?.author?.length > 0 ? (
                                value.author.map((author, indexA) => (
                                  <Fragment key={indexA}>
                                    <div className="form-block col-12 col-md-6">
                                      <input
                                        className={
                                          "form-input" +
                                          checkErrors(
                                            "input",
                                            "biblioitems." +
                                              index +
                                              ".author." +
                                              indexA +
                                              ".author"
                                          )
                                        }
                                        name={
                                          "biblioitems[" +
                                          index +
                                          "][author][" +
                                          indexA +
                                          "][author]"
                                        }
                                        type="text"
                                        defaultValue={author}
                                      />
                                      {checkErrors(
                                        "span",
                                        "biblioitems." +
                                          index +
                                          ".author." +
                                          indexA +
                                          ".author"
                                      )}
                                    </div>
                                    <div className="form-block col-12 col-md-6">
                                      <ReactSelect
                                        className={
                                          "form-react-select" +
                                          checkErrors(
                                            "input",
                                            "biblioitems." +
                                              index +
                                              ".author." +
                                              indexA +
                                              ".type_author"
                                          )
                                        }
                                        classNamePrefix="select_input"
                                        name={
                                          "biblioitems[" +
                                          index +
                                          "][author][" +
                                          indexA +
                                          "][type_author]"
                                        }
                                        placeholder={t(
                                          "confirm_marc_accordion_table_input_author_type_placeholder"
                                        )}
                                        isSearchable={false}
                                        options={authorTypes.map((item) => ({
                                          value: item.value,
                                          label: t(item.label),
                                        }))}
                                        defaultValue={[
                                          CheckAuthorsValue(
                                            value.type_author[indexA]
                                          ),
                                        ]}
                                      />
                                      {checkErrors(
                                        "span",
                                        "biblioitems." +
                                          index +
                                          ".author." +
                                          indexA +
                                          ".type_author"
                                      )}
                                    </div>
                                  </Fragment>
                                ))
                              ) : (
                                <div className="form-block col-12">
                                  <div className="form-input py-2">
                                    {t(
                                      "confirm_marc_accordion_table_input_no_author"
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="pb-4 mb-3">
                            <label>
                              {t(
                                "confirm_marc_accordion_table_input_contributor"
                              )}
                            </label>
                            <div className="row">
                              {value?.contributor?.length > 0 ? (
                                value.contributor.map((contributor, indexC) =>
                                  value?.type_contributor[indexC] ? (
                                    <Fragment key={indexC}>
                                      <div className="form-block col-12 col-md-6">
                                        <input
                                          className={
                                            "form-input" +
                                            checkErrors(
                                              "input",
                                              "biblioitems." +
                                                index +
                                                ".contributors." +
                                                indexC +
                                                ".contributor"
                                            )
                                          }
                                          name={
                                            "biblioitems[" +
                                            index +
                                            "][contributors][" +
                                            indexC +
                                            "][contributor]"
                                          }
                                          type="text"
                                          defaultValue={contributor}
                                        />
                                        {checkErrors(
                                          "span",
                                          "biblioitems." +
                                            index +
                                            ".contributors." +
                                            indexC +
                                            "contributor"
                                        )}
                                      </div>
                                      <div className="form-block col-12 col-md-6">
                                        <ReactSelect
                                          className={
                                            "form-react-select" +
                                            checkErrors(
                                              "input",
                                              "biblioitems." +
                                                index +
                                                ".contributors." +
                                                indexC +
                                                ".type_contributor"
                                            )
                                          }
                                          classNamePrefix="select_input"
                                          name={
                                            "biblioitems[" +
                                            index +
                                            "][contributors][" +
                                            indexC +
                                            "][type_contributor]"
                                          }
                                          placeholder={t(
                                            "confirm_marc_accordion_table_input_contributor_type_placeholder"
                                          )}
                                          isSearchable={false}
                                          options={contributorTypes.map(
                                            (item) => ({
                                              value: item.value,
                                              label: t(item.label),
                                            })
                                          )}
                                          defaultValue={CheckContributorsValue(
                                            value.type_contributor[indexC]
                                          )}
                                        />
                                        {checkErrors(
                                          "span",
                                          "biblioitems." +
                                            index +
                                            ".contributors." +
                                            indexC +
                                            ".type_contributor"
                                        )}
                                      </div>
                                    </Fragment>
                                  ) : (
                                    ""
                                  )
                                )
                              ) : (
                                <div className="form-block col-12">
                                  <div className="form-input py-2">
                                    {t(
                                      "confirm_marc_accordion_table_input_no_contributor"
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <input
                          type="hidden"
                          name={"biblioitems[" + index + "][marc_file_name]"}
                          value={location?.state?.file_name}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <Loading />
            )}
          </div>
        </div>
        <div className="mb-5">
          {btnLoading ? (
            <button type="button" className="button-blue has-icon">
              <i className="icon spinner-icon me-2" />
              {t("confirm_marc_accordion_table_button_saving")}
            </button>
          ) : (
            <button type="submit" className="button-blue">
              {t("confirm_marc_accordion_table_button_save")}
            </button>
          )}
        </div>
      </form>
    </Fragment>
  );
};

export default ConfirmMarc;
