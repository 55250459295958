import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Geoterm } from "../../../../../../store/Catalog/EditBibliography/BiblioitemLists";
import { Bibliography } from "../../../../../../store/Catalog/EditBibliography/Bibliography";
import Downshift from "downshift";
import ValidationMessage from "../../../../../../helpers/ValidationMessage";

import * as Request from "../../../../../../api/Request";
import * as ApiUrl from "../../../../../../api/ApiUrl";
import * as Message from "../../../../../../helpers/Messages";

const GeoTermsModal = ({
  onShowModal,
  setGeotermVisible,
  selectedGeoterms,
}) => {
  const { t } = useTranslation("");

  const geoterms = Geoterm((state) => state.geoterms);
  const addGeoterms = Geoterm((state) => state.addGeoterms);
  const bibliographyData = Bibliography((state) => state.bibliographyData);
  const addBibliographyUpdate = Bibliography(
    (state) => state.addBibliographyUpdate
  );

  const [geotermName, setGeotermName] = useState("");
  const [geotermId, setGeotermId] = useState("");
  const [error, setErrors] = useState("");
  const [listGeoterms, setListGeoterms] = useState([]);

  //The function that closes the modal
  const onClose = () => {
    setGeotermVisible(false);
    setErrors([]);
  };

  useEffect(() => {
    geotermsList();
  }, [selectedGeoterms]);

  const geotermsList = () => {
    setListGeoterms(
      geoterms.filter(
        (x) => !selectedGeoterms.filter((y) => y.id === x.id).length
      )
    );
  };

  const addGeoterm = (e) => {
    e.preventDefault();

    var geotermData = {
      name: geotermName,
    };

    if (
      selectedGeoterms.some((el) => el.name === geotermName) ||
      !geoterms.some((el) => el.name === geotermName)
    ) {
      Request.postRequest(ApiUrl.createGeotermBiblio, geotermData).then(
        (response) => {
          if (response.status === 201) {
            setGeotermName("");

            var newGeotermsArr = geoterms;

            newGeotermsArr.push({
              id: response?.data?.geographicalterm?.id,
              name: response?.data?.geographicalterm?.name,
            });

            addGeoterms(newGeotermsArr);

            var newGeotermsSelectedArr = selectedGeoterms;
            newGeotermsSelectedArr.push({
              id: response?.data?.geographicalterm?.id,
              name: response?.data?.geographicalterm?.name,
            });

            addBibliographyUpdate({
              ...bibliographyData,
              geoterms: newGeotermsSelectedArr,
            });
            onClose();
            Message.successMsg(response?.data?.success);
          } else if (response.status === 405) {
            Message.infoMsg(response?.data?.info);
          } else if (response.status === 422) {
            setErrors(response.data);
          } else {
            Message.errorMsg(response?.data?.error);
          }
        }
      );
    } else {
      setErrors([]);
      var newGeotermsArr = selectedGeoterms;

      newGeotermsArr.push({
        id: geotermId,
        name: geotermName,
      });
      addBibliographyUpdate({
        ...bibliographyData,
        geoterms: newGeotermsArr,
      });

      setGeotermName("");
      onClose();
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("edit_bibliography_add_geoterms_modal")}
      </ModalHeader>
      <form onSubmit={(e) => addGeoterm(e)}>
        <ModalBody>
          <Downshift
            onChange={(e) => {
              setGeotermName(e.name);
              setGeotermId(e.id);
            }}
            itemToString={(item) => (item ? item.name : "")}
          >
            {({
              selectedItem,
              getInputProps,
              getItemProps,
              highlightedIndex,
            }) => (
              <div>
                <div className="form-block row mb-0">
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      placeholder={
                        t(
                          "edit_bibliography_add_geoterms_modal_geoterm_input"
                        ) + " (650/A)"
                      }
                      {...getInputProps({
                        onChange: (e) => setGeotermName(e.target.value),
                      })}
                      value={geotermName}
                      className={
                        "form-input" + (error?.name ? " input-error" : "")
                      }
                      name="geoterm"
                    />
                    {error?.name && (
                      <ValidationMessage message={error?.name[0]} />
                    )}
                  </div>
                </div>
                <ul className="data-list list-unstyled">
                  {listGeoterms
                    .filter(
                      (item) =>
                        !geotermName ||
                        item.name
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toLowerCase()
                          .includes(geotermName.toLowerCase())
                    )
                    .slice(0)
                    .map((item, index) => (
                      <li
                        className={`item_${item.id}`}
                        {...getItemProps({
                          key: index,
                          item,
                        })}
                        style={{
                          backgroundColor:
                            (selectedItem === item ||
                              highlightedIndex === index) &&
                            "#EBECF0",
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </Downshift>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              <button
                type="buttom"
                className="button-blue w-100"
                onClick={(e) => addGeoterm(e)}
              >
                {t("edit_bibliography_add_geoterms_modal_add_button")}
              </button>
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("edit_bibliography_add_geoterms_modal_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default GeoTermsModal;
