import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../helpers/Loading";
import ListResponse from "../../../helpers/ListResponse";
import CustomPagination from "../../../helpers/CustomPagination";
import SearchFilter from "../../../helpers/SearchFilter";
import moment from "moment";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as Tooltip from "../../../helpers/Tooltip";
import * as AppConfig from "../../../helpers/AppConfig";

const Borrowings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [borrowingList, setBorrowingList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [activePage, setActivePage] = useState("");

  useEffect(() => {
    ListBorrowings();
  }, [activePage]);

  const ListBorrowings = () => {
    let request = ApiUrl.memberBorrowings;
    const memberID = param.id;

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
      request = SearchFilter(searchParams, request);
    }
    Request.getRequest(request + memberID).then((response) => {
      if (response.status === 200) {
        setBorrowingList(response.data.borrowingList.data);
        setLoading(false);
        setPagination(response.data.borrowingList);
      } else if (response.status === 404) {
        navigate(Path.listMembers);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Fragment>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" className="text-center" width="20%">
                {t("show_member_borrowing_title_author")}
              </th>
              <th role="columnheader" className="text-center" width="20%">
                {t("show_member_borrowing_codification_inventary_number")}
              </th>
              <th role="columnheader" className="text-center" width="20%">
                {t("show_member_borrowing_get_date")}
              </th>
              <th role="columnheader" className="text-center" width="20%">
                {t("show_member_borrowing_date_to_be_returned")}
              </th>
              <th role="columnheader" className="text-center" width="20%">
                {t("show_member_borrowing_status")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loading />
                </td>
              </tr>
            ) : (
              borrowingList.map((borrowing, index) => (
                <tr key={index}>
                  <td
                    role="cell"
                    data-label={t("show_member_borrowing_title_author")}
                    className="text-center"
                  >
                    <div className="d-inline-flex">
                      {Tooltip.tooltipDisabledMobile(
                        index,
                        borrowing.item.biblioitem.biblio.title
                      )}
                    </div>
                    <div>
                      {borrowing.item.biblioitem.biblio.authors
                        .map((author) => author.name)
                        .join("; ")}
                    </div>
                  </td>
                  <td
                    role="cell"
                    data-label={t(
                      "show_member_borrowing_codification_inventary_number"
                    )}
                    className="text-center "
                  >
                    {borrowing.item.inventory_number +
                      " / " +
                      borrowing.item.id}
                  </td>
                  <td
                    role="cell"
                    data-label={t("show_member_borrowing_get_date")}
                    className="text-center "
                  >
                    {borrowing.created_at &&
                      moment(borrowing.created_at).format("DD-MM-yyyy")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("show_member_borrowing_date_to_be_returned")}
                    className=" text-center "
                  >
                    {moment(borrowing.should_return_on).format("DD-MM-yyyy")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("show_member_borrowing_status")}
                    className="text-center status"
                  >
                    <span
                      className={
                        borrowing.status === "delivered_late"
                          ? "delivered-late"
                          : borrowing.status === "to_be_delivered"
                          ? "tobedelivered"
                          : borrowing.status === "delivered_on_time"
                          ? "delivered"
                          : borrowing.status === "undelivered"
                          ? "undelivered"
                          : ""
                      }
                    >
                      <i className="dot me-2 mx-1"></i>
                      {borrowing.status_trans}
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </Fragment>
  );
};

export default Borrowings;
