import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import Loading from "../../../../helpers/Loading";
import ListResponse from "../../../../helpers/ListResponse";
import CustomPagination from "../../../../helpers/CustomPagination";
import SearchFilter from "../../../../helpers/SearchFilter";
import EditGeographicalTerm from "./EditGeographicalTerm";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const GeographicalTermsList = () => {
  const { t } = useTranslation();

  const [listOfGeographicalTerms, setListOfGeographicalTerms] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);

  //  Add geographicalterm states
  const [addGeographicalTerm, setAddGeographicalTerm] = useState("");
  const [addGeographicalTermErrors, setAddGeographicalTermErrors] = useState(
    []
  );
  const [addBtnLoading, setAddBtnLoading] = useState(false);

  //  Search geographicalterm states
  const [searchGeographicalTerm, setSearchGeographicalTerm] = useState("");
  const [searchErrors, setSearchErrors] = useState({});
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  //  GeographicalTerm modal states
  const [geographicaltermId, setGeographicalTermId] = useState("");
  const [geographicaltermModal, setGeographicalTermModal] = useState(false);
  const setGeographicalTermVisible = (visible) => {
    setGeographicalTermModal(visible);
    setGeographicalTermId("");
  };

  useEffect(() => {
    geographicaltermsList();
  }, [action, activePage, searched]);

  const geographicaltermsList = (e) => {
    let urlRequest = ApiUrl.listGeographicalterms;
    var searchParams = {};

    // If the filter button is clicked
    if (e) {
      setSearchBtnLoading(true);
      e.preventDefault();
    }

    // Change page
    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      // If the filter button is clicked, but we are in the first page
      searchParams = {
        geographicalterm: searchGeographicalTerm,
      };
    } else if (searched && activePage > 1) {
      // If the filter button is clicked, but we are not in the first page
      searchParams = {
        geographicalterm: searchGeographicalTerm,
        page: activePage,
      };
    }

    if (action && searchGeographicalTerm.length < 1) {
      searchParams = {
        page: activePage,
      };
    }

    // Url of the request with filter parameters
    urlRequest = SearchFilter(searchParams, urlRequest);

    // the function which return the list of geographicalterms
    Request.getRequest(urlRequest).then((response) => {
      setSearchBtnLoading(false);
      if (response.status === 200) {
        setListOfGeographicalTerms(response.data.geographicalterms.data);
        setPagination(response.data.geographicalterms);
        setLoading(false);
        setAction(false);
        setSearchErrors({});
      } else if (response.status === 422) {
        setAction(false);
        setSearchErrors(response.data);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const addGeographicalTermFunc = (e) => {
    e.preventDefault();
    setAddBtnLoading(true);
    Request.postRequest(ApiUrl.storeGeographicalterm, {
      name: addGeographicalTerm,
    }).then((response) => {
      setAddBtnLoading(false);
      if (response.status === 201) {
        Message.successMsg(response.data.success);
        setAction(true);
        setAddGeographicalTerm("");
        setSearchGeographicalTerm("");
        setAddGeographicalTermErrors([]);
        setSearched(false);
        setActivePage("1");
        setSearchErrors([]);
      } else if (response.status === 422) {
        setAddGeographicalTermErrors(response.data);
      }
    });
  };

  const deleteGeographicalTerm = (id, title) => {
    ConfirmPopup.deletePopup(title).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteGeographicalterm + id).then(
          (response) => {
            if (response.status === 200) {
              Message.successMsg(response.data.success);
              setAction(true);
            } else if (response.status === 404) {
              Message.errorMsg(response.data.error);
            } else {
              Message.errorMsg(response.data.error);
            }
          }
        );
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("geographicalterm_list_title")}</p>
      <div className="add-item pt-4 pb-2 px-4 mb-5">
        <form onSubmit={(e) => addGeographicalTermFunc(e)}>
          <div className="row">
            <label>
              {t("geographicalterms_list_add_geographicalterm_label")}
            </label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t(
                  "geographicalterms_list_add_geographicalterm_placeholder"
                )}
                className={
                  "form-input" +
                  (addGeographicalTermErrors?.name ? " input-error" : "")
                }
                name="name"
                type="text"
                value={addGeographicalTerm}
                onChange={(e) => setAddGeographicalTerm(e.target.value)}
              />
              {addGeographicalTermErrors?.name && (
                <ValidationMessage
                  message={addGeographicalTermErrors?.name[0]}
                />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-4 pb-3">
              {addBtnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("geographicalterms_list_adding_geographicalterm_button")}
                </button>
              ) : (
                <button className="button-blue" type="submit">
                  {t("geographicalterms_list_add_geographicalterm_button")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="search-item px-4 mb-5">
        <form onSubmit={(e) => geographicaltermsList(e)}>
          <div className="row">
            <label>
              {t("geographicalterms_list_search_geographicalterm_label")}
            </label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t(
                  "geographicalterms_list_search_geographicalterm_placeholder"
                )}
                className={
                  "form-input" +
                  (searchErrors?.geographicalterm_name ? " input-error" : "")
                }
                name="geographicalterm"
                value={searchGeographicalTerm}
                type="text"
                onChange={(e) => setSearchGeographicalTerm(e.target.value)}
              />
              {searchErrors?.geographicalterm && (
                <ValidationMessage
                  message={searchErrors?.geographicalterm[0]}
                />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 pb-3">
              {searchBtnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("catalog_listbibiblioitems_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon"
                  type="submit"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("catalog_listbibiblioitems_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" width="70%" className="text-left pl-0">
                {t("geographicalterms_list_table_title_geographicalterm")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("geographicalterms_list_table_title_edit")}
              </th>
              <th role="columnheader" width="15%" className="text-center">
                {t("geographicalterms_list_table_title_delete")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3" className="text-center">
                  <Loading />
                </td>
              </tr>
            ) : (
              listOfGeographicalTerms.map((geographicalterm, index) => (
                <tr key={index} role="row">
                  <td
                    role="cell"
                    data-label={t(
                      "geographicalterms_list_table_title_geographicalterm"
                    )}
                  >
                    {geographicalterm.name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("geographicalterms_list_table_title_edit")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={() => {
                        setGeographicalTermModal(true);
                        setGeographicalTermId(geographicalterm.id);
                      }}
                    >
                      <i className="icon edit-icon" />
                    </button>
                    {geographicaltermModal &&
                      geographicaltermId === geographicalterm.id && (
                        <EditGeographicalTerm
                          onShowModal={geographicaltermModal}
                          setGeographicalTermVisible={
                            setGeographicalTermVisible
                          }
                          setModified={setAction}
                          geographicaltermId={geographicalterm.id}
                          geographicaltermName={geographicalterm.name}
                        />
                      )}
                  </td>
                  <td
                    role="cell"
                    data-label={t("geographicalterms_list_table_title_delete")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={() => {
                        deleteGeographicalTerm(
                          geographicalterm.id,
                          geographicalterm.name
                        );
                      }}
                    >
                      <i className="icon delete-icon" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(searchErrors).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default GeographicalTermsList;
