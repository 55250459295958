import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Publisher } from "../../../../../store/Catalog/EditBibliography/Publisher";
import { Language } from "../../../../../store/Catalog/EditBibliography/Language";
import { Bibliography } from "../../../../../store/Catalog/EditBibliography/Bibliography";
import { CheckContributorsType } from "../../../../../helpers/CheckValue";
import { biblioCoversPath } from "../../../../../helpers/AppConfig";

import ReactSelect from "react-select";
import ContributorsModal from "./Modals/ContributorsModal";
import ValidationMessage from "../../../../../helpers/ValidationMessage";

const EditBiblioitem = ({ errorList }) => {
  const { t } = useTranslation("");

  const publishers = Publisher((state) => state.publishers);
  const languages = Language((state) => state.languages);
  const addBibliographyUpdate = Bibliography(
    (state) => state.addBibliographyUpdate
  );

  const bibliographyData = Bibliography((state) => state.bibliographyData);

  const removeContributor = (contributor) => {
    const removeIndex = bibliographyData?.authors.findIndex(
      (item) => item.id === contributor.id
    );
    bibliographyData?.contributors.splice(removeIndex, 1);
    addBibliographyUpdate({
      ...bibliographyData,
      contributors: bibliographyData?.contributors,
    });
  };

  // Geoterm modal
  const [contributorModal, setContributorModal] = useState(false);
  return (
    <Fragment>
      <div className="form-block row mb-0">
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_isbn")}
            <span className="biblioitem-tag">(020/A)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_isbn_placeholder")}
            className={"form-input" + (errorList.isbn ? " input-error" : "")}
            name="isbn"
            value={bibliographyData.isbn}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                isbn: e.target.value,
              })
            }
          />
          {errorList.isbn && <ValidationMessage message={errorList.isbn[0]} />}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_issn")}
            <span className="biblioitem-tag">(022/A)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_issn_placeholder")}
            className={"form-input" + (errorList.isbn ? " input-error" : "")}
            name="issn"
            value={bibliographyData.issn}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                issn: e.target.value,
              })
            }
          />
          {errorList.issn && <ValidationMessage message={errorList.issn[0]} />}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_publication_year")}
            <span className="biblioitem-tag">(260/C)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_publication_year_placeholder")}
            className={
              "form-input" + (errorList.publication_year ? " input-error" : "")
            }
            name="publication_year"
            value={bibliographyData.publication_year}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                publication_year: e.target.value,
              })
            }
          />
          {errorList.publication_year && (
            <ValidationMessage message={errorList.publication_year[0]} />
          )}
        </div>
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_publication_place")}
            <span className="biblioitem-tag">(260/A)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_publication_place_placeholder")}
            className={"form-input" + (errorList.place ? " input-error" : "")}
            name="place"
            value={bibliographyData.place}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                place: e.target.value,
              })
            }
          />
          {errorList.place && (
            <ValidationMessage message={errorList.place[0]} />
          )}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_publisher")}
            <span className="biblioitem-tag">(260/B)</span>
          </label>
          <ReactSelect
            className={
              "form-react-select" +
              (errorList.publisher_id ? " input-error" : "")
            }
            classNamePrefix="select_input"
            name="publisher"
            placeholder={t("edit_bibliography_publisher_placeholder")}
            isClearable={true}
            isSearchable={false}
            options={publishers.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            value={
              bibliographyData?.publisher_id
                ? {
                    value: bibliographyData.publisher_id.id,
                    label: bibliographyData.publisher_id.name,
                  }
                : ""
            }
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                publisher_id: { id: e.value, name: e.label },
              })
            }
          />
          {errorList.publisher_id && (
            <ValidationMessage message={errorList.publisher_id[0]} />
          )}
        </div>
        <div className="col-12 col-md-4 mb-5">
          <label>
            {t("edit_bibliography_publication_number")}
            <span className="biblioitem-tag">(250/A)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_publication_number_placeholder")}
            className={
              "form-input" +
              (errorList.publication_number ? " input-error" : "")
            }
            name="publication_number"
            value={bibliographyData.publication_number}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                publication_number: e.target.value,
              })
            }
          />
          {errorList.publication_number && (
            <ValidationMessage message={errorList.publication_number[0]} />
          )}
        </div>
      </div>
      <div className="form-block mb-5">
        <div className="d-flex align-items-center">
          <button
            className="button-gray-blue"
            type="button"
            onClick={() => setContributorModal(true)}
          >
            <i className="icon plus-icon me-2" />
            {t("edit_bibliography_contributors")}
          </button>
          <label className="mb-0">
            <span className="biblioitem-tag">(700/A)</span>
          </label>
        </div>
        <div className="form-input tags-container w-100 p-3 mt-3">
          {bibliographyData?.contributors?.length > 0 ? (
            bibliographyData.contributors.map((contributor, index) => (
              <span key={index} className="tag">
                {`${contributor.name} / ${CheckContributorsType(
                  contributor.pivot.type
                )}`}
                <button
                  className="button-unstyled icon close-red-icon"
                  type="button"
                  onClick={() => removeContributor(contributor)}
                />
              </span>
            ))
          ) : (
            <span className="empty-span">
              {t("edit_bibliography_add_contributors_placeholder")}
            </span>
          )}
        </div>
        {contributorModal && (
          <ContributorsModal
            onShowModal={contributorModal}
            selectedContributors={bibliographyData?.contributors}
            setContributorVisible={(e) => setContributorModal(e)}
          />
        )}
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>{t("edit_bibliography_ilustrations")}</label>
          <input
            type="text"
            placeholder={t("edit_bibliography_ilustrations_placeholder")}
            className={
              "form-input" + (errorList.physical_details ? " input-error" : "")
            }
            name="physical_details"
            value={bibliographyData.physical_details}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                physical_details: e.target.value,
              })
            }
          />
          {errorList.physical_details && (
            <ValidationMessage message={errorList.physical_details[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>
            {t("edit_bibliography_page_number")}
            <span className="biblioitem-tag">(300/A)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_page_number_placeholder")}
            className={"form-input" + (errorList.pages ? " input-error" : "")}
            name="pages"
            value={bibliographyData.pages}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                pages: e.target.value,
              })
            }
          />
          {errorList.pages && (
            <ValidationMessage message={errorList.pages[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>
            {t("edit_bibliography_dimension")}
            <span className="biblioitem-tag">(300/C)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_dimension_placeholder")}
            className={
              "form-input" + (errorList.dimensions ? " input-error" : "")
            }
            name="dimensions"
            value={bibliographyData.dimensions}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                dimensions: e.target.value,
              })
            }
          />
          {errorList.dimensions && (
            <ValidationMessage message={errorList.dimensions[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>
            {t("edit_bibliography_lccn")}
            <span className="biblioitem-tag">(010/A)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_lccn_placeholder")}
            className={"form-input" + (errorList.lccn ? " input-error" : "")}
            name="lccn"
            value={bibliographyData.lccn}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                lccn: e.target.value,
              })
            }
          />
          {errorList.lccn && <ValidationMessage message={errorList.lccn[0]} />}
        </div>
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>{t("edit_bibliography_url")}</label>
          <div className="upload-file-box">
            <div
              className={"form-input" + (errorList.url ? " input-error" : "")}
            >
              {bibliographyData.url === "" && bibliographyData.urlNew === "" ? (
                <Fragment>
                  <input
                    className="upload-input"
                    onChange={(e) => {
                      addBibliographyUpdate({
                        ...bibliographyData,
                        url: e.target.files[0].name,
                        urlNew: e.target.files[0],
                        deleteUrl: 0,
                      });
                    }}
                    accept="application/pdf"
                    name="url"
                    type="file"
                  />
                  <div className="upload-input-placeholder">
                    <span>{t("edit_bibliography_url_placeholder")}</span>
                    <i className="icon upload-icon" />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="upload-input-placeholder">
                    <span>{bibliographyData.url}</span>
                    <i
                      className="icon close-red-icon"
                      onClick={() =>
                        addBibliographyUpdate({
                          ...bibliographyData,
                          url: "",
                          urlNew: "",
                          deleteUrl: 1,
                        })
                      }
                    />
                  </div>
                </Fragment>
              )}
            </div>
            {errorList.url && <ValidationMessage message={errorList.url[0]} />}
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>
            {t("edit_bibliography_kdu")}
            <span className="biblioitem-tag">(080/A)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_kdu_placeholder")}
            className={"form-input" + (errorList.kdu ? " input-error" : "")}
            name="kdu"
            value={bibliographyData.kdu}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                kdu: e.target.value,
              })
            }
          />
          {errorList.kdu && <ValidationMessage message={errorList.kdu[0]} />}
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>
            {t("edit_bibliography_lanugage")}
            <span className="biblioitem-tag">(041/A)</span>
          </label>
          <ReactSelect
            className={
              "form-react-select" +
              (errorList.biblioitem_language_id ? " input-error" : "")
            }
            classNamePrefix="select_input"
            name="language"
            placeholder={t("edit_bibliography_lanugage_placeholder")}
            isClearable={true}
            isSearchable={false}
            options={languages.map((item) => ({
              value: item.id,
              label: item.name_trans,
            }))}
            value={
              bibliographyData?.biblioitem_language_id
                ? {
                    value: bibliographyData.biblioitem_language_id.id,
                    label: bibliographyData.biblioitem_language_id.name,
                  }
                : ""
            }
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                biblioitem_language_id: { id: e.value, name: e.label },
              })
            }
          />
          {errorList.biblioitem_language_id && (
            <ValidationMessage message={errorList.biblioitem_language_id[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>
            {t("edit_bibliography_national_control_number")}
            <span className="biblioitem-tag">(016/A)</span>
          </label>
          <input
            type="text"
            placeholder={t(
              "edit_bibliography_national_control_number_placeholder"
            )}
            className={
              "form-input" +
              (errorList.national_control_number ? " input-error" : "")
            }
            name="national_control_number"
            value={bibliographyData.national_control_number}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                national_control_number: e.target.value,
              })
            }
          />
          {errorList.national_control_number && (
            <ValidationMessage message={errorList.national_control_number[0]} />
          )}
        </div>
      </div>
      <div className="form-block row mb-0">
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>{t("edit_bibliography_barcode")}</label>
          <input
            type="text"
            placeholder={t("edit_bibliography_barcode_placeholder")}
            className={"form-input" + (errorList.barcode ? " input-error" : "")}
            name="barcode"
            value={bibliographyData.barcode}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                barcode: e.target.value,
              })
            }
          />
          {errorList.barcode && (
            <ValidationMessage message={errorList.barcode[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>
            {t("edit_bibliography_price")}
            <span className="biblioitem-tag">(365/B)</span>
          </label>
          <input
            type="text"
            placeholder={t("edit_bibliography_price_placeholder")}
            className={"form-input" + (errorList.price ? " input-error" : "")}
            name="price"
            value={bibliographyData.price}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                price: e.target.value,
              })
            }
          />
          {errorList.price && (
            <ValidationMessage message={errorList.price[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>{t("edit_bibliography_replacement_price")}</label>
          <input
            type="text"
            placeholder={t("edit_bibliography_replacement_price_placeholder")}
            className={
              "form-input" + (errorList.replacement_price ? " input-error" : "")
            }
            name="replacement_price"
            value={bibliographyData.replacement_price}
            onChange={(e) =>
              addBibliographyUpdate({
                ...bibliographyData,
                replacement_price: e.target.value,
              })
            }
          />
          {errorList.replacement_price && (
            <ValidationMessage message={errorList.replacement_price[0]} />
          )}
        </div>
        <div className="col-12 col-sm-6 col-lg-3 mb-5">
          <label>{t("edit_bibliography_cover")}</label>
          <div className="upload-file-box">
            <div
              className={"form-input" + (errorList.cover ? " input-error" : "")}
            >
              {bibliographyData.cover === "" ? (
                <Fragment>
                  <input
                    className="upload-input"
                    onChange={(e) =>
                      addBibliographyUpdate({
                        ...bibliographyData,
                        cover: e.target.files[0].name,
                        coverValue: e.target.files[0],
                        deleteCover: 0,
                      })
                    }
                    accept="image/*"
                    name="cover"
                    type="file"
                  />
                  <div className="upload-input-placeholder">
                    <span>{t("edit_bibliography_cover_placeholder")}</span>
                    <i className="icon upload-icon" />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="upload-input-placeholder">
                    <span>{bibliographyData.cover}</span>
                    <i
                      className="icon close-red-icon"
                      onClick={() =>
                        addBibliographyUpdate({
                          ...bibliographyData,
                          cover: "",
                          coverValue: "",
                          deleteCover: 1,
                        })
                      }
                    />
                  </div>
                </Fragment>
              )}
            </div>
            {bibliographyData.cover !== "" && (
              <div className="uploaded-image">
                <div className="image-div">
                  {bibliographyData.cover !== "" &&
                  bibliographyData.coverValue !== "" ? (
                    <img
                      src={URL.createObjectURL(bibliographyData.coverValue)}
                      alt="Cover"
                    />
                  ) : (
                    <img
                      src={biblioCoversPath + bibliographyData.cover}
                      alt="Cover"
                    />
                  )}
                </div>
              </div>
            )}
            {errorList.cover && (
              <ValidationMessage message={errorList.cover[0]} />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditBiblioitem;
