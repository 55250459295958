import { useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BibliosList } from "../../../../../store/Catalog/EditBibliography/BiblioList";
import { Bibliography } from "../../../../../store/Catalog/EditBibliography/Bibliography";
import { MaterialTypes } from "../../../../../store/Catalog/EditBibliography/MaterialTypes";
import { Language } from "../../../../../store/Catalog/EditBibliography/Language";
import { MediaTypes } from "../../../../../store/Catalog/EditBibliography/MediaTypes";
import { Publisher } from "../../../../../store/Catalog/EditBibliography/Publisher";
import {
  Author,
  Geoterm,
  Keyword,
} from "../../../../../store/Catalog/EditBibliography/BiblioitemLists";

import EditItems from "./EditItems";
import EditBiblioitem from "./EditBiblioitem";
import EditBiblio from "./EditBiblio";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as Path from "../../../../../routes/GeneralPaths";

const EditBibliography = () => {
  const { t } = useTranslation("");
  const { id } = useParams();
  const navigate = useNavigate();

  const resetBibliosList = BibliosList((state) => state.reset);
  const addBibliosList = BibliosList((state) => state.addBibliosList);

  const resetBibliographyData = Bibliography((state) => state.reset);
  const addBibliographyData = Bibliography(
    (state) => state.addBibliographyData
  );

  const resetMaterialTypes = MaterialTypes((state) => state.reset);
  const addMaterialTypes = MaterialTypes((state) => state.addMaterials);

  const resetLanguages = Language((state) => state.reset);
  const addLanguages = Language((state) => state.addLanguages);

  const resetMedias = MediaTypes((state) => state.reset);
  const addMedias = MediaTypes((state) => state.addMedias);

  const resetPublishers = Publisher((state) => state.reset);
  const addPublishers = Publisher((state) => state.addPublishers);

  const resetAuthors = Author((state) => state.reset);
  const addAuthors = Author((state) => state.addAuthors);

  const resetKeywords = Keyword((state) => state.reset);
  const addKeywords = Keyword((state) => state.addKeywords);

  const resetGeoterms = Geoterm((state) => state.reset);
  const addGeoterms = Geoterm((state) => state.addGeoterms);

  const [firstPart, setFirstPart] = useState(false);
  const [secondPart, setSecondPart] = useState(true);
  const [thirdPart, setThirPart] = useState(false);

  const [loading, setLoading] = useState(true);

  const [btnLoading, setBtnLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const bibliographyData = Bibliography((state) => state.bibliographyData);

  useEffect(() => {
    resetBibliosList();
    resetBibliographyData();
    resetMaterialTypes();
    resetLanguages();
    resetMedias();
    resetPublishers();
    resetAuthors();
    resetKeywords();
    resetGeoterms();
    editbiblioitem();
  }, []);

  const editbiblioitem = () => {
    Request.getRequest(ApiUrl.editBiblioitem + id).then((response) => {
      if (response.status === 200) {
        addBibliosList(response?.data?.biblios);
        addBibliographyData(response?.data?.biblioitem);
        addMaterialTypes(response?.data?.material_types);
        addLanguages(response?.data?.languages);
        addMedias(response?.data?.media_types);
        addPublishers(response?.data?.publishers);
        addAuthors(response?.data?.authors);
        addKeywords(response?.data?.key_words);
        addGeoterms(response?.data?.geographical_terms);
        setLoading(false);
      } else if (response.status === 404) {
        navigate(-1);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const updateBibliography = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("title", bibliographyData?.title);
    bibliographyData?.authors.map((author, index) => {
      formData.append("authors[" + index + "][author_id]", author.id);
      formData.append("authors[" + index + "][type]", author.pivot.type);
    });
    formData.append("medium", bibliographyData?.medium);
    formData.append("subtitle", bibliographyData?.subtitle);
    formData.append(
      "materialtype_id",
      bibliographyData?.materialtype_id
        ? bibliographyData?.materialtype_id?.id
        : ""
    );
    formData.append("part_name", bibliographyData?.part_name);
    formData.append("part_number", bibliographyData?.part_number);
    formData.append(
      "biblio_language_id",
      bibliographyData?.biblio_language_id
        ? bibliographyData?.biblio_language_id?.id
        : ""
    );
    formData.append("agerestriction", bibliographyData?.agerestriction);
    formData.append(
      "year_original_publication",
      bibliographyData?.year_original_publication
    );
    formData.append(
      "mediatype_id",
      bibliographyData?.mediatype_id !== ""
        ? bibliographyData?.mediatype_id?.id
        : ""
    );
    bibliographyData?.keywords.map((keyword, index) => {
      formData.append("key_words[" + index + "]", keyword.id);
    });
    bibliographyData?.geoterms.map((geot, index) => {
      formData.append("geographical_terms[" + index + "]", geot.id);
    });
    formData.append("abstract", bibliographyData?.abstract);
    formData.append("notes", bibliographyData?.notes);

    formData.append("isbn", bibliographyData?.isbn);
    formData.append("issn", bibliographyData?.issn);
    formData.append("publication_year", bibliographyData?.publication_year);
    formData.append("place", bibliographyData?.place);
    formData.append(
      "publisher_id",
      bibliographyData?.publisher_id ? bibliographyData?.publisher_id?.id : ""
    );
    formData.append("publication_number", bibliographyData?.publication_number);
    bibliographyData?.contributors.map((contributor, index) => {
      formData.append("contributors[" + index + "][author_id]", contributor.id);
      formData.append(
        "contributors[" + index + "][type]",
        contributor.pivot.type
      );
    });
    formData.append("physical_details", bibliographyData?.physical_details);
    formData.append("pages", bibliographyData?.pages);
    formData.append("dimensions", bibliographyData?.dimensions);
    formData.append("lccn", bibliographyData?.lccn);
    formData.append("url", bibliographyData?.urlNew);
    if (bibliographyData.deleteUrl === 1) {
      formData.append("delete_url", 1);
    }
    formData.append("kdu", bibliographyData?.kdu);
    formData.append(
      "biblioitem_language_id",
      bibliographyData?.biblioitem_language_id
        ? bibliographyData?.biblioitem_language_id?.id
        : ""
    );
    formData.append(
      "national_control_number",
      bibliographyData?.national_control_number
    );
    formData.append("barcode", bibliographyData?.barcode);
    formData.append("price", bibliographyData?.price);
    formData.append("replacement_price", bibliographyData?.replacement_price);
    formData.append("cover", bibliographyData?.coverValue);
    if (bibliographyData.deleteCover === 1) {
      formData.append("delete_cover", 1);
    }
    formData.append("currency", bibliographyData?.currency);
    formData.append("ean", bibliographyData?.ean);
    formData.append("biblio_id", bibliographyData?.biblio_id);

    bibliographyData?.items.map((item, index) => {
      formData.append(
        "items[" + index + "][id]",
        item.id === null ? "" : item.id
      );
      formData.append(
        "items[" + index + "][borrowing_type]",
        item.borrowing_type === null ? "" : item.borrowing_type
      );
      formData.append(
        "items[" + index + "][inventory_number]",
        item.inventory_number === null ? "" : item.inventory_number
      );
      formData.append(
        "items[" + index + "][place_number]",
        item.place_number === null ? "" : item.place_number
      );
      formData.append(
        "items[" + index + "][damaged]",
        item.damaged === null ? "" : item.damaged
      );
      formData.append(
        "items[" + index + "][damaged_date]",
        item.damaged_date === null ? "" : item.damaged_date
      );
      formData.append(
        "items[" + index + "][renewal_date]",
        item.renewal_date === null ? "" : item.renewal_date
      );

      formData.append(
        "items[" + index + "][lost]",
        item.lost === null ? "" : item.lost
      );
      formData.append(
        "items[" + index + "][lost_date]",
        item.lost_date === null ? "" : item.lost_date
      );
    });

    bibliographyData?.newItems.map((item, index) => {
      formData.append(
        "newitems[" + index + "][borrowing_type]",
        item.borrowing_type === null ? "" : item.borrowing_type
      );
      formData.append(
        "newitems[" + index + "][inventory_number]",
        item.inventory_number === null ? "" : item.inventory_number
      );
      formData.append(
        "newitems[" + index + "][place_number]",
        item.place_number === null ? "" : item.place_number
      );
      formData.append(
        "newitems[" + index + "][damaged]",
        item.damaged === null ? "" : item.damaged
      );
      formData.append(
        "newitems[" + index + "][damaged_date]",
        item.damaged_date === null ? "" : item.damaged_date
      );
      formData.append(
        "newitems[" + index + "][renewal_date]",
        item.renewal_date === null ? "" : item.renewal_date
      );
    });

    bibliographyData?.deletedItems.map((item, index) => {
      formData.append("deleteditems[" + index + "]", item);
    });

    formData.append("_method", "PATCH");

    Request.postRequestExport(ApiUrl.updateBiblioitem + id, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          navigate(Path.listBiblioitems);
          Message.successMsg(response.data.success);
          setErrors([]);
        } else if (response.status === 405) {
          Message.infoMsg(response.data.info);
        } else if (response.status === 422) {
          setErrors(response.data);
        }
      }
    );
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("edit_editbibliography_title")}</p>
      <div className="editbiblioitem">
        <div
          className={
            "accordion_item" + (firstPart && !loading ? " active" : "")
          }
        >
          <div className="acc-item">
            <button
              className="acc_button"
              onClick={() => setFirstPart(!firstPart)}
            >
              {t("edit_editbibliography_first_part")}
            </button>
            <span
              className="acc_icons"
              onClick={() => setFirstPart(!firstPart)}
            >
              <i className="icon acc-arrow" />
            </span>
          </div>
          {firstPart && !loading && (
            <div className="content">
              <EditBiblio errorList={errors} />
            </div>
          )}
        </div>
        <div
          className={
            "accordion_item" + (secondPart && !loading ? " active" : "")
          }
        >
          <div className="acc-item">
            <button
              className="acc_button"
              onClick={() => setSecondPart(!secondPart)}
            >
              {t("edit_editbibliography_second_part")}
            </button>
            <span
              className="acc_icons"
              onClick={() => setSecondPart(!secondPart)}
            >
              <i className="icon acc-arrow" />
            </span>
          </div>
          {secondPart && !loading && (
            <div className="content">
              <EditBiblioitem errorList={errors} />
            </div>
          )}
        </div>
        <div
          className={
            "accordion_item" + (thirdPart && !loading ? " active" : "")
          }
        >
          <div className="acc-item">
            <button
              className="acc_button"
              onClick={() => setThirPart(!thirdPart)}
            >
              {t("edit_editbibliography_third_part")}
            </button>
            <span className="acc_icons" onClick={() => setThirPart(!thirdPart)}>
              <i className="icon acc-arrow" />
            </span>
          </div>
          {thirdPart && !loading && (
            <div className="content">
              <EditItems errorList={errors} />
            </div>
          )}
        </div>

        <div className="d-flex justify-content-end mt-4">
          {!loading &&
            (btnLoading ? (
              <button className="button-blue has-icon" type="button">
                <i className="icon spinner-icon me-2" />
                {t("edit_bibliography_saving_button")}
              </button>
            ) : (
              <button
                className="button-blue"
                onClick={(e) => updateBibliography(e)}
                type="submit"
              >
                {t("edit_bibliography_save_button")}
              </button>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default EditBibliography;
