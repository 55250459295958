import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";
import { tooltipDisabledMobile } from "../../../helpers/Tooltip";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const ListPosts = () => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState([]);
  const [listPosts, setListPosts] = useState([]);
  const [title, setTitle] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    listBlogPosts();
  }, [activePage, searched]);

  const listBlogPosts = (hasSearch) => {
    let request = ApiUrl.listBlogPosts;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        title: title,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        title: title,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setListPosts(response.data.blog.data);
        setError({});
        setLoading(false);
        setPagination(response.data.blog);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        setListPosts([]);
        setLoading(true);
      }
    });
  };

  const deletePost = (e, id, title) => {
    ConfirmPopup.deletePopup(title).then((result) => {
      if (result.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteBlogPost + id).then((response) => {
          if (response.status === 200) {
            listBlogPosts();
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("list_post_of_articles")}</p>
      <div className="input-items">
        <form onSubmit={(e) => listBlogPosts(e)}>
          <div className="row mb-5">
            <div className="form-block col-sm-6 col-md-4">
              <input
                placeholder={t("list_posts_search_for_title")}
                className={"form-input" + (error.title ? " input-error" : "")}
                name="title"
                value={title}
                type="text"
                onChange={(e) => setTitle(e.target.value)}
              />
              {error.title && <ValidationMessage message={error.title[0]} />}
            </div>
            <div className="form-block col-12 col-lg-3 col-md-4">
              {btnLoading === true ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("list_post_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon"
                  name="submit"
                  type="submit"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("list_post_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                role="columnheader"
                className="text-left"
                style={{ width: 35 + "%" }}
              >
                {t("list_post_title")}
              </th>
              <th
                role="columnheader"
                className="text-left"
                style={{ width: 21.66 + "%" }}
              >
                {t("list_post_description")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: 21.66 + "%" }}
              >
                {t("list_post_date_of_creation")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: 21.66 + "%" }}
              >
                {t("list_post_actions")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="4">
                  <Loading />
                </td>
              </tr>
            ) : (
              listPosts.map((listPosts, index) => (
                <tr key={index}>
                  <td data-label={t("list_post_title")}>
                    {tooltipDisabledMobile("-title-" + index, listPosts.title)}
                  </td>
                  <td
                    className="text-center"
                    data-label={t("list_post_description")}
                  >
                    <div className="d-flex description">
                      {parse(listPosts.description.substring(0, 20) + "...")}
                    </div>
                  </td>
                  <td
                    className="text-center"
                    data-label={t("list_post_date_of_creation")}
                  >
                    {moment(listPosts.created_at).format("DD MMM, YYYY")}
                  </td>
                  <td
                    className="text-center"
                    data-label={t("list_post_actions")}
                  >
                    <div className="row  justify-content-end justify-content-md-around mr-auto ml-auto action-icons">
                      <div className="button-unstyled-icon col-3">
                        <Link to={Path.editBlogPost + listPosts.id}>
                          <i className="icon edit-icon" />
                        </Link>
                      </div>

                      <div className="col-3 delete">
                        <button
                          type="button"
                          className="button-unstyled-icon"
                          onClick={(e) =>
                            deletePost(e, listPosts.id, listPosts.title)
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(error).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default ListPosts;
