import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";
import ListResponse from "../../../helpers/ListResponse";
import Select from "react-select";
import SearchFilter from "../../../helpers/SearchFilter";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";
import * as Tooltip from "../../../helpers/Tooltip";

const Borrow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // List of material types
  const [materialTypes, setMaterialTypes] = useState([]);

  // Search naterials or members
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [borrowingType, setBorrowingType] = useState("");
  const [isMember, setIsMember] = useState(false);
  const [isMaterial, setIsMaterial] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [listResults, setListResults] = useState([]);
  const [loading, setLoading] = useState(true);

  // Searcg by nid
  const [nid, setNid] = useState("");
  const [listResultsNid, setListResultsNid] = useState({});
  const [loadingNid, setLoadingNid] = useState(false);
  const [btnLoadingNid, setBtnLoadingNid] = useState(false);
  const [searchedByNid, setSearchedByNid] = useState(false);

  const [activePage, setActivePage] = useState("");
  const [searched, setSearched] = useState(false);
  const [error, setError] = useState([]);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    materialTypesList();
    if (searched && (isMember || isMaterial) && !searchedByNid) {
      searchMaterialOrMember();
    }
  }, [activePage, searched]);

  const materialTypesList = () => {
    Request.getRequest(ApiUrl.materialTypes).then((response) => {
      if (response.status === 200) {
        setMaterialTypes(response.data.material_types);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const searchMaterialOrMember = (e) => {
    let request = ApiUrl.searchMaterialOrMember;

    var searchParams = {};

    if (e) {
      e.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: searchText,
        type: type,
        material_type: materialType,
        borrowing_type: borrowingType,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: searchText,
        type: type,
        material_type: materialType,
        borrowing_type: borrowingType,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setListResults(response.data.results.data);
        setLoading(false);
        setListResultsNid({});
        setSearchedByNid(false);
        setError([]);
        setPagination(response.data.results);
        if (type === "member") {
          setIsMember(true);
          setIsMaterial(false);
        } else if (type === "material") {
          setIsMember(false);
          setIsMaterial(true);
        }
      } else if (response.status === 422) {
        setError(response.data);
        setIsMember(false);
        setIsMaterial(false);
      }
    });
  };

  const searchMemberNID = (e) => {
    if (e) {
      e.preventDefault();
      setBtnLoadingNid(true);
    }

    Request.postRequest(ApiUrl.searchNID, { nid: nid }).then((response) => {
      setBtnLoadingNid(false);
      if (response.status === 200) {
        setListResults([]);
        setLoading(false);
        setListResultsNid(response.data.user);
        setError([]);
        setSearchedByNid(true);
        setLoadingNid(false);
        setIsMember(true);
        setIsMaterial(false);
      } else if (response.status === 422) {
        setError(response.data);
        setIsMember(false);
        setIsMaterial(false);
        setSearchedByNid(false);
      } else if (response.status === 403) {
        Message.errorMsg(response.data.error);
      } else if (response.status === 400) {
        Message.errorMsg(response.data.error);
      } else {
        console.log(response.data.error);
        if (response.data.error) {
          Message.errorMsg(response.data.error);
        } else {
          Message.errorMsg(t("error_message"));
        }
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">
        {t("borrow_search_for_material_or_member")}
      </p>
      <div className="search-item">
        <form onSubmit={(e) => searchMaterialOrMember(e)}>
          <div className="row">
            <div className="form-block col-12 col-sm-6 col-md-4 order-0">
              <input
                placeholder={t("borrow_search")}
                onChange={(e) => setSearchText(e.target.value)}
                className={
                  "form-input" + (error.search_text ? " input-error" : "")
                }
                name="search_text"
                type="text"
                value={searchText}
              />
              {error.search_text && (
                <ValidationMessage message={error.search_text[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 order-1">
              <Select
                className={
                  "form-react-select" + (error.type ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="type"
                isSearchable={false}
                onChange={(e) => {
                  e ? setType(e.value) : setType("");
                  setMaterialType("");
                  setBorrowingType("");
                }}
                placeholder={t("borrow_specification")}
                isClearable={true}
                options={[
                  { value: "member", label: t("member") },
                  { value: "material", label: t("material") },
                ]}
              />
              {error.type && <ValidationMessage message={error.type[0]} />}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 order-5 order-md-2">
              {btnLoading ? (
                <button className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("borrow_searching")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-blue has-icon"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("borrow_search")}
                </button>
              )}
            </div>
            {type === "material" && (
              <Fragment>
                <div className="form-block col-12 col-sm-6 col-md-4 order-3">
                  <Select
                    name="material_type"
                    className={
                      "form-react-select" +
                      (error.material_type ? " input-error" : "")
                    }
                    isSearchable={false}
                    isClearable={true}
                    onChange={(e) =>
                      e ? setMaterialType(e.value) : setMaterialType("")
                    }
                    classNamePrefix="select_input"
                    placeholder={t("borrow_material_type")}
                    options={materialTypes.map((item) => ({
                      value: item.id,
                      label: item.name_trans,
                    }))}
                  />
                  {error.material_type && (
                    <ValidationMessage message={error.material_type[0]} />
                  )}
                </div>
                <div className="form-block col-12 col-sm-6 col-md-4 order-4">
                  <Select
                    className={
                      "form-react-select" +
                      (error.borrowing_type ? " input-error" : "")
                    }
                    classNamePrefix="select_input"
                    name="borrowing_type"
                    isSearchable={false}
                    isClearable={true}
                    onChange={(e) =>
                      e ? setBorrowingType(e.value) : setBorrowingType("")
                    }
                    placeholder={t("borrow_borrowing_type")}
                    options={[
                      { value: "normal", label: t("normal") },
                      { value: "library", label: t("library") },
                    ]}
                  />
                  {error.borrowing_type && (
                    <ValidationMessage message={error.borrowing_type[0]} />
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </form>
        <div className="lined-title mt-3 mb-5">
          <span>{t("borrow_or")}</span>
        </div>
        <form onSubmit={(e) => searchMemberNID(e)}>
          <div className="row mb-4">
            <label className="col-12">{t("borrow_search_nid")}</label>
            <div className="form-block col-12 col-sm-6 col-md-4">
              <input
                placeholder={t("borrow_search_for_nid")}
                onChange={(e) => setNid(e.target.value)}
                className={"form-input" + (error.nid ? " input-error" : "")}
                name="nid"
                type="text"
                value={nid}
              />
              {error.nid && <ValidationMessage message={error.nid[0]} />}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-">
              {btnLoadingNid === true ? (
                <button className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("borrow_is_searching")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-blue has-icon"
                  onClick={() => {
                    setSearched(true);
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("borrow_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>

      {isMember && (
        <div className="table-list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" width="25%" className="text-left">
                  {t("borrow_name")}
                </th>
                <th
                  role="columnheader"
                  width="25%"
                  className="pr-2 text-center"
                >
                  {t("borrow_lastname")}
                </th>
                <th
                  role="columnheader"
                  width="25%"
                  className="pr-2 text-center"
                >
                  {t("borrow_email")}
                </th>
                <th
                  role="columnheader"
                  width="25%"
                  className="pr-2 text-center"
                >
                  {t("borrow_watch")}
                </th>
              </tr>
            </thead>
            <tbody>
              {!searchedByNid &&
                (loading ? (
                  <tr>
                    <td className="text-center" colSpan="4">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  listResults.map((result, index) => (
                    <tr key={index}>
                      <td
                        role="cell"
                        data-label={t("borrow_name")}
                        className="pb-3 title-borrowing"
                      >
                        {result.first_name}
                      </td>
                      <td
                        role="cell"
                        data-label={t("borrow_lastname")}
                        className="text-center"
                      >
                        {result.last_name}
                      </td>
                      <td
                        role="cell"
                        data-label={t("borrow_email")}
                        className=" text-center"
                      >
                        {result.email}
                      </td>
                      <td
                        role="cell"
                        data-label={t("borrow_watch")}
                        className="text-center"
                      >
                        <button
                          className="button-unstyled-icon"
                          onClick={() => {
                            navigate(Path.showMember + result.id);
                          }}
                        >
                          <i className="icon show-icon" />
                        </button>
                      </td>
                    </tr>
                  ))
                ))}

              {searchedByNid &&
                (loadingNid ? (
                  <tr>
                    <td className="text-center" colSpan="4">
                      <Loading />
                    </td>
                  </tr>
                ) : (
                  <tr key={listResultsNid.id}>
                    <td
                      role="cell"
                      data-label={t("borrow_name")}
                      className="pb-3 title-borrowing"
                    >
                      {listResultsNid.first_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("borrow_lastname")}
                      className="text-center"
                    >
                      {listResultsNid.last_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("borrow_email")}
                      className=" text-center"
                    >
                      {listResultsNid.email}
                    </td>
                    <td
                      role="cell"
                      data-label={t("borrow_watch")}
                      className="text-center"
                    >
                      <button
                        className="button-unstyled-icon"
                        onClick={() => {
                          navigate(Path.showMember + listResultsNid.id);
                        }}
                      >
                        <i className="icon show-icon" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!searchedByNid ? (
            <ListResponse
              totalItems={pagination && pagination.total}
              loading={loading}
              searched={searched}
            />
          ) : (
            <ListResponse
              totalItems={Object.keys(listResultsNid).length}
              loading={loadingNid}
              searched={searched}
            />
          )}
        </div>
      )}

      {isMaterial && (
        <div className="table-list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th width="15%" role="columnheader" className="text-left">
                  {t("borrow_title")}
                </th>
                <th width="15%" role="columnheader" className="text-center">
                  {t("borrow_isbn_issn")}
                </th>
                <th width="15%" role="columnheader" className="text-center">
                  {t("borrow_publisher")}
                </th>
                <th width="20%" role="columnheader" className="text-center">
                  {t("borrow_author")}
                </th>
                <th width="15%" role="columnheader" className="text-center">
                  {t("borrow_number_of_copies")}
                </th>
                <th width="20%" role="columnheader" className="text-center">
                  {t("borrow_choose")}
                </th>
              </tr>
            </thead>
            <tbody>
              {listResults.map((result, index) => (
                <tr key={index}>
                  <td role="cell" data-label={t("borrow_title")} className="">
                    {Tooltip.tooltipDisabledMobile(index, result.biblio.title)}
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrow_isbn_issn")}
                    className=" text-center"
                  >
                    <span className="isbn-issn">
                      {result.isbn || result.issn}
                    </span>
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrow_publisher")}
                    className="text-center"
                  >
                    {!result.publisher ? "" : result.publisher.name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrow_author")}
                    className="text-center"
                  >
                    {result.biblio.authors
                      .map((author) => author.name)
                      .join("; ")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrow_number_of_copies")}
                    className="text-center"
                  >
                    {result.items_count} <br />
                  </td>
                  <td
                    role="cell"
                    data-label={t("borrow_choose")}
                    className="text-center pl-0"
                  >
                    {result.items_count > 0 && (
                      <button
                        className="button-blue-borrow"
                        onClick={() => {
                          navigate(
                            Path.searchMember +
                              result.items.map((freeitem) => freeitem.id) +
                              "/member"
                          );
                        }}
                      >
                        {t("borrow_choose")}
                      </button>
                    )}

                    {result.items_count === 0 && (
                      <span
                        className="d-inline-block "
                        tabIndex="0"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={t("borrow_no_free_copies")}
                      >
                        <button
                          className="button-blue-borrow disabled"
                          style={{ pointerEvents: "none" }}
                          type="button"
                          disabled
                        >
                          {t("borrow_choose")}
                        </button>
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={searched}
          />
        </div>
      )}
      {((pagination.total > 1 && isMaterial) ||
        (pagination.total > 1 && isMember)) && (
        <CustomPagination
          data={pagination}
          setActivePage={(e) => setActivePage(e)}
        />
      )}
    </Fragment>
  );
};

export default Borrow;
