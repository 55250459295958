import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { tooltipDisabledMobile } from "../../../../helpers/Tooltip";

import ReactSelect from "react-select";
import ListResponse from "../../../../helpers/ListResponse";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import CustomPagination from "../../../../helpers/CustomPagination";
import Loading from "../../../../helpers/Loading";
import SearchFilter from "../../../../helpers/SearchFilter";
import CheckCover from "../../../../helpers/CheckCover";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as Path from "../../../../routes/GeneralPaths";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const ListBiblioitems = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [biblioitems, setBiblioitems] = useState([]);
  const [errorList, setErrorList] = useState({});
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [searched, setSearched] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [action, setAction] = useState(false);

  //   Search states
  const [text, setText] = useState("");
  const [specification, setSpecification] = useState("");

  useEffect(() => {
    biblioitemsList();
  }, [activePage, searched, action]);

  const biblioitemsList = (e) => {
    let urlRequest = ApiUrl.listBiblioitems;
    var searchParams = {};

    // If the filters button is clicked
    if (e) {
      setBtnLoading(true);
      e.preventDefault();
    }

    // Change page
    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      // If the filter button is clicked, but we are in the first page
      searchParams = {
        text: text,
        specification: specification,
      };
    } else if (searched && activePage > 1) {
      // If the filter button is clicked, but we are not in the first page
      searchParams = {
        text: text,
        specification: specification,
        page: activePage,
      };
    }

    // Url of the request with filter parameters
    urlRequest = SearchFilter(searchParams, urlRequest);

    // the function which return the list of biblioitems
    Request.getRequest(urlRequest).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setBiblioitems(response.data.biblioitems.data);
        setPagination(response.data.biblioitems);
        setLoading(false);
        setAction(false);
        setErrorList({});
      } else if (response.status === 422) {
        setErrorList(response.data);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  const deleteBiblioitem = (id, title) => {
    ConfirmPopup.deletePopup(title).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteBiblioitem + id).then((response) => {
          if (response.status === 200) {
            Message.successMsg(response.data.success);
            setAction(true);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(response.data.error);
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">
        {t("catalog_listbibiblioitems_title")}
      </p>
      <div className="search-item">
        <form onSubmit={(e) => biblioitemsList(e)}>
          <div className="row mb-5">
            <div className="form-block col-12 col-sm-6 col-md-4">
              <input
                placeholder={t("catalog_listbibiblioitems_input_placeholder")}
                className={
                  "form-input" + (errorList.text ? " input-error" : "")
                }
                name="text"
                type="text"
                onChange={(e) => setText(e.target.value)}
              />
              {errorList.text && (
                <ValidationMessage message={errorList.text[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 ">
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList.specification ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="specification"
                placeholder={t("catalog_listbibiblioitems_select_choose")}
                isClearable={true}
                onChange={(e) => setSpecification(e ? e.value : "")}
                isSearchable={false}
                options={[
                  {
                    value: "title",
                    label: t("catalog_listbibiblioitems_select_option_title"),
                  },
                  {
                    value: "author",
                    label: t("catalog_listbibiblioitems_select_option_author"),
                  },
                  {
                    value: "contributors",
                    label: t(
                      "catalog_listbibiblioitems_select_option_contributors"
                    ),
                  },
                  {
                    value: "publisher",
                    label: t(
                      "catalog_listbibiblioitems_select_option_publisher"
                    ),
                  },
                ]}
              />
              {errorList.specification && (
                <ValidationMessage message={errorList.specification[0]} />
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              {btnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("catalog_listbibiblioitems_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon"
                  type="submit"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("catalog_listbibiblioitems_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list-bg">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" width="25%" className="text-left pl-0">
                {t("catalog_listbibiblioitems_table_title_author")}
              </th>
              <th role="columnheader" width="17%" className="text-center">
                {t("catalog_listbibiblioitems_table_isbn_issn")}
              </th>
              <th role="columnheader" width="17%" className="text-center">
                {t("catalog_listbibiblioitems_table_publisher")}
              </th>
              <th role="columnheader" width="12%" className="text-center">
                {t("catalog_listbibiblioitems_table_contributor")}
              </th>
              <th role="columnheader" width="12%" className="text-center">
                {t("catalog_listbibiblioitems_table_copies")}
              </th>
              <th role="columnheader" width="12%" className="text-center">
                {t("catalog_listbibiblioitems_table_edit")}
              </th>
              <th role="columnheader" width="5%" className="text-center pr-0">
                {t("catalog_listbibiblioitems_table_delete")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <Loading />
                </td>
              </tr>
            ) : (
              biblioitems.map((biblioitem, index) => (
                <tr
                  key={index}
                  role="row"
                  onClick={() =>
                    navigate(
                      biblioitems.serial
                        ? Path.showSerial + biblioitem.serial.id
                        : Path.showBiblioitem + biblioitem.id
                    )
                  }
                >
                  <td role="cell">
                    <div className="table-info-data">
                      <div className="cover">
                        <img
                          src={CheckCover(
                            biblioitem.cover,
                            biblioitem.biblio.materialtype.id
                          )}
                          alt="Cover"
                        />
                      </div>
                      <div className="info-block">
                        <span className="title">
                          {tooltipDisabledMobile(
                            biblioitem.id,
                            biblioitem.biblio.title
                          )}
                        </span>
                        <span className="subtitle">
                          {biblioitem.biblio.authors
                            .map((author) => author.name)
                            .join("; ")}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    role="cell"
                    data-label={t("catalog_listbibiblioitems_table_isbn_issn")}
                    className="text-center"
                  >
                    {(biblioitem.issn && biblioitem.issn) ||
                      (biblioitem.isbn && biblioitem.isbn)}
                  </td>
                  <td
                    role="cell"
                    data-label={t("catalog_listbibiblioitems_table_publisher")}
                    className="text-center"
                  >
                    {biblioitem.publisher && biblioitem.publisher.name}
                  </td>
                  <td
                    role="cell"
                    data-label={t(
                      "catalog_listbibiblioitems_table_contributor"
                    )}
                    className="text-center"
                  >
                    {biblioitem.contributors
                      .map((contributor) => contributor.name)
                      .join("; ")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("catalog_listbibiblioitems_table_copies")}
                    className="text-center"
                  >
                    {biblioitem.items_count && biblioitem.items_count}
                  </td>
                  <td
                    role="cell"
                    data-label={t("catalog_listbibiblioitems_table_edit")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(Path.editBiblioitem + biblioitem.id);
                      }}
                    >
                      <i className="icon edit-icon" />
                    </button>
                  </td>
                  <td
                    role="cell"
                    data-label={t("catalog_listbibiblioitems_table_delete")}
                    className="text-center"
                  >
                    <button
                      className="button-unstyled-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteBiblioitem(
                          biblioitem.id,
                          biblioitem.biblio.title
                        );
                      }}
                    >
                      <i className="icon delete-icon" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(errorList).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default ListBiblioitems;
