import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Bibliography } from "../../../../../../store/Catalog/CreateBibliography/CreateBibliography";
import { Biblio } from "../../../../../../store/Catalog/CreateBibliography/CreateBiblio";
import Downshift from "downshift";
import ValidationMessage from "../../../../../../helpers/ValidationMessage";

import * as Request from "../../../../../../api/Request";
import * as ApiUrl from "../../../../../../api/ApiUrl";
import * as Message from "../../../../../../helpers/Messages";

const KeywordsModal = ({
  onShowModal,
  setKeywordVisible,
  selectedKeywords,
}) => {
  const { t } = useTranslation("");

  const bibliographyData = Bibliography((state) => state.bibliographyData);
  const addBibliographyUpdate = Bibliography(
    (state) => state.addBibliographyUpdate
  );
  const addBiblioDataUpdate = Biblio((state) => state.addBiblioDataUpdate);
  const biblioData = Biblio((state) => state.biblioData);

  const [keywordName, setKeywordName] = useState("");
  const [keywordId, setKeywordId] = useState("");
  const [error, setErrors] = useState("");
  const [listKeywords, setListKeywords] = useState([]);

  //The function that closes the modal
  const onClose = () => {
    setKeywordVisible(false);
    setErrors([]);
  };

  useEffect(() => {
    keywordsList();
  }, [selectedKeywords]);

  const keywordsList = () => {
    setListKeywords(
      bibliographyData.keywords.filter(
        (x) => !selectedKeywords.filter((y) => y.id === x.id).length
      )
    );
  };

  const addKeyword = (e) => {
    e.preventDefault();

    var keywordData = {
      name: keywordName,
    };

    if (
      selectedKeywords.some((el) => el.name === keywordName) ||
      !bibliographyData.keywords.some((el) => el.name === keywordName)
    ) {
      Request.postRequest(ApiUrl.createKeywordBiblio, keywordData).then(
        (response) => {
          if (response.status === 201) {
            setKeywordName("");

            var newKeywordsArr = bibliographyData.keywords;
            newKeywordsArr.push({
              id: response?.data?.keyword?.id,
              name: response?.data?.keyword?.name,
            });
            addBibliographyUpdate({
              ...bibliographyData,
              keywords: newKeywordsArr,
            });

            var newKeywordsSelectedArr = selectedKeywords;
            newKeywordsSelectedArr.push({
              id: response?.data?.keyword?.id,
              name: response?.data?.keyword?.name,
            });
            addBiblioDataUpdate({
              ...biblioData,
              keywords: newKeywordsSelectedArr,
            });

            onClose();
            Message.successMsg(response?.data?.success);
          } else if (response.status === 405) {
            Message.infoMsg(response?.data?.info);
          } else if (response.status === 422) {
            setErrors(response.data);
          } else {
            Message.errorMsg(response?.data?.error);
          }
        }
      );
    } else {
      setErrors([]);
      var newKeywordsArr = selectedKeywords;

      newKeywordsArr.push({
        id: keywordId,
        name: keywordName,
      });
      addBiblioDataUpdate({
        ...biblioData,
        keywords: newKeywordsArr,
      });

      setKeywordName("");
      onClose();
    }
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("edit_bibliography_add_keywords_modal")}
      </ModalHeader>
      <form onSubmit={(e) => addKeyword(e)}>
        <ModalBody>
          <Downshift
            onChange={(e) => {
              setKeywordName(e.name);
              setKeywordId(e.id);
            }}
            itemToString={(item) => (item ? item.name : "")}
          >
            {({
              selectedItem,
              getInputProps,
              getItemProps,
              highlightedIndex,
            }) => (
              <div>
                <div className="form-block row mb-0">
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      placeholder={
                        t(
                          "edit_bibliography_add_keywords_modal_keyword_input"
                        ) + " (650/A)"
                      }
                      {...getInputProps({
                        onChange: (e) => setKeywordName(e.target.value),
                      })}
                      value={keywordName}
                      className={
                        "form-input" + (error?.name ? " input-error" : "")
                      }
                      name="keyword"
                    />
                    {error?.name && (
                      <ValidationMessage message={error?.name[0]} />
                    )}
                  </div>
                </div>
                <ul className="data-list list-unstyled">
                  {listKeywords
                    .filter(
                      (item) =>
                        !keywordName ||
                        item.name
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")
                          .toLowerCase()
                          .includes(keywordName.toLowerCase())
                    )
                    .slice(0)
                    .map((item, index) => (
                      <li
                        className={`item_${item.id}`}
                        {...getItemProps({
                          key: index,
                          item,
                        })}
                        style={{
                          backgroundColor:
                            (selectedItem === item ||
                              highlightedIndex === index) &&
                            "#EBECF0",
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </Downshift>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              <button
                type="button"
                className="button-blue w-100"
                onClick={(e) => addKeyword(e)}
              >
                {t("edit_bibliography_add_keywords_modal_add_button")}
              </button>
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("edit_bibliography_add_keywords_modal_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default KeywordsModal;
