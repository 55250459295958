import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import SearchFilter from "../../../helpers/SearchFilter";
import * as Message from "../../../helpers/Messages";
import ListResponse from "../../../helpers/ListResponse";
import Select from "react-select";
import * as AppConfig from "../../../helpers/AppConfig";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import * as Tooltip from "../../../helpers/Tooltip";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";
import moment from "moment";

const SearchItem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();
  const [memberDetails, setMemberDetails] = useState("");
  const [role, setRole] = useState("");
  const [materialList, setMaterialList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listMaterials, setListMaterials] = useState([]);
  const [search_text, setSearch_Text] = useState("");
  const [type, setType] = useState("");
  const [material_types, setMaterial_Types] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [error, setError] = useState([]);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    memberDetail();
    if (searched) {
      searchItem();
    }
  }, [activePage, searched]);

  const memberDetail = () => {
    const memberID = param.id;

    let request = ApiUrl.searchMaterial + memberID + "/item";

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        Request.getRequest(ApiUrl.materialTypes).then((response) => {
          if (response.status === 200) {
            setListMaterials(response.data.material_types);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
        setMemberDetails(response.data.member);
        setRole(response.data.member.role);
        setLoading(false);
      } else if (response.status === 404) {
        navigate(Path.borrow);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const searchItem = (hasSearch) => {
    const memberID = param.id;

    let request = ApiUrl.searchMaterial + memberID + "/item";

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: search_text,
        type: type,
        material_type: material_types,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: search_text,
        type: type,
        material_type: material_types,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);
    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setMaterialList(response.data.results.data);
        setError([]);
        setLoading(false);
        setPagination(response.data.results);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const userGender = (param) => {
    switch (param) {
      case "M":
        return t("male");
      case "F":
        return t("female");
      default:
        return "-";
    }
  };

  const materials = listMaterials.map((item) => ({
    value: item.id,
    label: item.name_trans,
  }));

  return (
    <Fragment>
      <div id="show">
        <p className="partial-title mb-5">{t("search_item_member_details")}</p>

        <div className="member-data row mb-5">
          <div className="list-data col-12 col-md-4 d-flex">
            <ul>
              <li className="member-detail">
                {t("search_item_firstname_lastname_role")} :{" "}
                {(memberDetails.first_name ? memberDetails.first_name : "-") +
                  " " +
                  (memberDetails.last_name ? memberDetails.last_name : "-") +
                  " / " +
                  (memberDetails.role?.name_trans
                    ? memberDetails.role?.name_trans
                    : "-")}
              </li>
              <li>
                {t("search_item_email")} :{" "}
                {memberDetails.email ? memberDetails.email : "-"}
              </li>
              <li>
                {t("search_item_mobile")} :{" "}
                {memberDetails.phone ? memberDetails.phone : "-"}
              </li>
              <li>
                {t("search_item_birthday")} :{" "}
                {memberDetails.dob
                  ? moment(memberDetails.dob).format("DD-MM-yyyy")
                  : "-"}
              </li>
              <li>
                {t("search_item_gender")} :{" "}
                {memberDetails.gender ? userGender(memberDetails.gender) : "-"}
              </li>
              <li>
                {t("search_item_state_city")} :{" "}
                {(memberDetails.state ? memberDetails.state : "-") +
                  " / " +
                  (memberDetails.city ? memberDetails.city : "-")}
              </li>
              <li>
                {t("search_item_address_postal_code")} :{" "}
                {(memberDetails.address ? memberDetails.address : "-") +
                  " / " +
                  (memberDetails.postal_code ? memberDetails.postal_code : "-")}
              </li>
              <li>
                {t("search_item_start_date_end_date")} :{" "}
                {(memberDetails.membership_start
                  ? memberDetails.membership_start
                  : "-") +
                  " / " +
                  (memberDetails.membership_end
                    ? memberDetails.membership_end
                    : "-")}
              </li>
            </ul>
          </div>

          <div className="data-btn col-12 col-md-8">
            <form onSubmit={(e) => searchItem(e)}>
              <div className="row mb-5">
                <div className="form-block col-md-8 pb-4">
                  <input
                    placeholder={t("search_item_search")}
                    className={
                      "form-input" + (error.search_text ? " input-error" : "")
                    }
                    name="search_text"
                    type="text"
                    onChange={(e) => setSearch_Text(e.target.value)}
                  />
                  {error.search_text && (
                    <ValidationMessage message={error.search_text[0]} />
                  )}
                </div>
                <div className="row w-100">
                  <div className="form-block col-md-4 me-3">
                    <Select
                      className={
                        "form-react-select" + (error.type ? " input-error" : "")
                      }
                      classNamePrefix="select_input"
                      name="type"
                      isSearchable={false}
                      isClearable={true}
                      onChange={(e) => (e ? setType(e.value) : setType(""))}
                      placeholder={t("search_item_borrowing_type")}
                      options={[
                        { value: "normal", label: t("normal") },
                        { value: "library", label: t("library") },
                      ]}
                    />
                    {error.type && (
                      <ValidationMessage message={error.type[0]} />
                    )}
                  </div>
                  <div className="form-block col-md-4">
                    <Select
                      name="material_type"
                      className={
                        "form-react-select" +
                        (error.material_type ? " input-error" : "")
                      }
                      isSearchable={false}
                      isClearable={true}
                      onChange={(e) =>
                        e ? setMaterial_Types(e.value) : setMaterial_Types("")
                      }
                      classNamePrefix="select_input"
                      placeholder={t("search_item_material_type")}
                      options={materials}
                    />
                    {error.material_type && (
                      <ValidationMessage message={error.material_type[0]} />
                    )}
                  </div>
                  <div className="col-12 col-lg-3 col-md-4">
                    {btnLoading === true ? (
                      <button
                        className="button-blue has-icon px-5"
                        type="button"
                      >
                        <i className="icon spinner-icon me-2" />
                        {t("search_item_searching")}
                      </button>
                    ) : (
                      <button
                        className="button-blue has-icon px-5"
                        type="submit"
                        onClick={() => {
                          setSearched(true);
                          setActivePage("1");
                        }}
                      >
                        <i className="icon search-icon me-2" />
                        {t("search_item_search")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {searched === true && (
        <div className="table-list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" width="20%" className="text-left">
                  {t("search_item_title")}
                </th>
                <th role="columnheader" width="10%" className="text-center">
                  {t("search_item_author")}
                </th>
                <th role="columnheader" width="15%" className=" text-center">
                  {t("search_item_isbn_issn")}
                </th>
                <th role="columnheader" width="20%" className="text-center">
                  {t("search_item_publisher")}
                </th>
                <th role="columnheader" width="10%" className=" text-center">
                  {t("search_item_number_of_available_copies")}
                </th>
                <th role="columnheader" width="20%" className="text-center">
                  {t("search_item_choose")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="6">
                    <Loading />
                  </td>
                </tr>
              ) : (
                materialList.map((item, index) => (
                  <tr key={index}>
                    <td
                      role="cell"
                      data-label={t("search_item_title")}
                      className=""
                    >
                      {Tooltip.tooltipDisabledMobile(index, item.biblio.title)}
                    </td>
                    <td
                      role="cell"
                      data-label={t("search_item_author")}
                      className="text-center "
                    >
                      {item.biblio.authors
                        .map((author) => author.name)
                        .join("; ")}
                    </td>
                    <td
                      role="cell"
                      data-label={t("search_item_isbn_issn")}
                      className="text-center "
                    >
                      <span className="isbn-issn">
                        {item.isbn || item.issn}
                      </span>
                    </td>
                    <td
                      role="cell"
                      data-label={t("search_item_publisher")}
                      className="text-center"
                    >
                      {!item.publisher ? "" : item.publisher.name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("search_item_number_of_available_copies")}
                      className="text-center"
                    >
                      {item.items_count} <br />
                    </td>
                    <td
                      role="cell"
                      data-label={t("search_item_choose")}
                      className="text-center"
                    >
                      {item.items_count > 0 ? (
                        <button
                          onClick={() =>
                            navigate(
                              Path.confirmBorrow +
                                param.id +
                                "/item/" +
                                item.items.map((freeitem) => freeitem.id)
                            )
                          }
                          className="button-blue-borrow"
                        >
                          {t("search_item_choose")}
                        </button>
                      ) : (
                        <button className="button-blue-borrow">
                          {t("search_item_choose")}
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={searched}
          />
        </div>
      )}
      <div className="d-flex justify-content-center pagination-block">
        <CustomPagination
          data={pagination}
          setActivePage={(e) => setActivePage(e)}
        />
      </div>
    </Fragment>
  );
};

export default SearchItem;
