import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckAuthors } from "../../../../../helpers/CheckValue";
import { Bibliography } from "../../../../../store/Catalog/CreateBibliography/CreateBibliography";
import { Biblio } from "../../../../../store/Catalog/CreateBibliography/CreateBiblio";

import Downshift from "downshift";
import ReactSelect from "react-select";
import AuthorModal from "./Modals/AuthorModal";
import KeywordsModal from "./Modals/KeywordsModal";
import GeoTermsModal from "./Modals/GeoTermsModal";
import ValidationMessage from "../../../../../helpers/ValidationMessage";
import Loading from "../../../../../helpers/Loading";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api//ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as Path from "../../../../../routes/GeneralPaths";

const CreateBiblio = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  const resetBibliography = Bibliography((state) => state.reset);
  const addBibliographyData = Bibliography(
    (state) => state.addBibliographyData
  );
  const bibliographyData = Bibliography((state) => state.bibliographyData);

  const reset = Biblio((state) => state.reset);
  const addBiblioData = Biblio((state) => state.addBiblioData);
  const addBiblioDataUpdate = Biblio((state) => state.addBiblioDataUpdate);
  const biblioData = Biblio((state) => state.biblioData);

  const [loading, setLoading] = useState(true);

  const [searchedBiblios, setSearchedBiblios] = useState([]);
  const [oldBiblioData] = useState(biblioData);
  const [selectedNewBiblio, setSelectedNewBiblio] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);

  // Author modal
  const [authorModal, setAuthorModal] = useState(false);

  // Keyword modal
  const [keywordModal, setKeywordModal] = useState(false);

  // Geoterm modal
  const [geotermModal, setGeotermModal] = useState(false);

  useEffect(() => {
    resetBibliography();
    reset();
    getBiblioData();
  }, []);

  const getBiblioData = () => {
    let request = ApiUrl.createBiblio;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        addBibliographyData(response);
        setLoading(false);
      } else {
        Message.errorMsg();
      }
    });
  };

  const filterTitleList = (value) => {
    addBiblioDataUpdate({ ...biblioData, title: value });
    if (value.length > 2) {
      setSearchedBiblios(
        bibliographyData?.biblios.filter((biblio) =>
          biblio.title
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(
              value
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            )
        )
      );
    }
  };

  const downshiftOnChange = (biblioId) => {
    let request = ApiUrl.selectedBiblio + biblioId;
    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        addBiblioDataUpdate({
          ...biblioData,
          biblio_id: response?.data?.biblio?.id ? response.data.biblio.id : "",
          title: response?.data?.biblio?.title
            ? response.data.biblio.title
            : "",
          authors: response?.data?.biblio?.authors
            ? response.data.biblio.authors
            : [],
          medium: response?.data?.biblio?.medium
            ? response.data.biblio.medium
            : "",
          subtitle: response?.data?.biblio?.subtitle
            ? response.data.biblio.subtitle
            : "",
          materialtype_id: response?.data?.biblio?.materialtype
            ? response.data.biblio.materialtype
            : "",
          part_name: response?.data?.biblio?.part_name
            ? response.data.biblio.part_name
            : "",
          part_number: response?.data?.biblio?.part_number
            ? response.data.biblio.part_number
            : "",
          biblio_language_id: response?.data?.biblio?.language
            ? response.data.biblio.language
            : "",
          agerestriction: response?.data?.biblio?.agerestriction
            ? response.data.biblio.agerestriction
            : "",
          year_original_publication: response?.data?.biblio
            ?.year_original_publication
            ? response.data.biblio.year_original_publication
            : "",
          mediatype_id: response?.data?.biblio?.mediatype
            ? response.data.biblio.mediatype
            : "",
          keywords: response?.data?.biblio?.keywords
            ? response.data.biblio.keywords
            : [],
          geoterms: response?.data?.biblio?.geographicalterms
            ? response.data.biblio.geographicalterms
            : [],
          notes: response?.data?.biblio?.notes
            ? response.data.biblio.notes
            : "",
          abstract: response?.data?.biblio?.abstract
            ? response.data.biblio.abstract
            : "",
        });

        setSelectedNewBiblio(true);
      }
    });
  };

  const clearTitle = () => {
    addBiblioData(oldBiblioData);
    setSelectedNewBiblio(false);
  };

  const removeAuthor = (author) => {
    const removeIndex = biblioData?.authors.findIndex(
      (item) => item.id === author.id
    );
    biblioData?.authors.splice(removeIndex, 1);
    addBiblioDataUpdate({
      ...biblioData,
      authors: biblioData?.authors,
    });
  };

  const removeKeyword = (keyword) => {
    const removeIndex = biblioData?.keywords.findIndex(
      (item) => item.id === keyword.id
    );
    biblioData?.keywords.splice(removeIndex, 1);
    addBiblioDataUpdate({
      ...biblioData,
      keywords: biblioData?.keywords,
    });
  };

  const removeGeoterm = (geoterm) => {
    const removeIndex = biblioData?.geoterms.findIndex(
      (item) => item.id === geoterm.id
    );
    biblioData?.geoterms.splice(removeIndex, 1);
    addBiblioDataUpdate({
      ...biblioData,
      geoterms: biblioData?.geoterms,
    });
  };

  const createBiblio = () => {
    setBtnLoading(true);
    var authorArr = [];
    var geoTermsArr = [];
    var keywordsArr = [];

    biblioData.authors.forEach((author) => {
      authorArr.push({
        id: author.id,
        name: author.name,
        type: author.pivot.type,
      });
    });

    biblioData.geoterms.forEach((geoterm) => {
      geoTermsArr.push(geoterm.id);
    });

    biblioData.keywords.forEach((keyword) => {
      keywordsArr.push(keyword.id);
    });

    var formData = {
      biblio_id: biblioData.biblio_id,
      materialtype_id: biblioData.materialtype_id
        ? biblioData.materialtype_id.id
        : "",
      mediatype_id: biblioData.mediatype_id ? biblioData.mediatype_id.id : "",
      language_id: biblioData.language_id ? biblioData.language_id.id : "",
      lanugage: biblioData.language_id ? biblioData.language_id.id : "",
      title: biblioData.title,
      medium: biblioData.medium,
      subtitle: biblioData.subtitle,
      part_number: biblioData.part_number,
      part_name: biblioData.part_name,
      notes: biblioData.notes,
      abstract: biblioData.abstract,
      agerestriction: biblioData.agerestriction,
      year_original_publication: biblioData.year_original_publication,
      key_words: keywordsArr,
      geographical_terms: geoTermsArr,
      authors: authorArr,
    };

    Request.postRequest(ApiUrl.storeBiblio, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        setErrorList([]);
        navigate(Path.createBiblioItem + response.data.biblio_id);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setErrorList(response.data);
        if (response.data.error) {
          Message.errorMsg(response.data.error);
        }
      }
    });
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between gap-2 mb-5">
        <p className="partial-title">
          {t("create_bibliography_create_biblio_title")}
        </p>
        <div className="navigation-bibliography">
          <span className="left-arrow disabled">
            <i className="icon left-arrow-icon" />
          </span>
          <span className="current-step">1/3</span>
          <span
            className="right-arrow"
            onClick={(e) => (!btnLoading ? createBiblio() : e.preventDefault())}
          >
            <i className="icon right-arrow-icon" />
          </span>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Downshift
            onChange={(selection) => {
              downshiftOnChange(selection.id);
            }}
            itemToString={(item) => item}
          >
            {({
              getInputProps,
              getItemProps,
              getMenuProps,
              isOpen,
              selectedItem,
            }) => (
              <div className="form-block">
                <label>
                  {t("create_bibliography_create_biblio_title_label")}
                  <span className="biblioitem-tag">(245/A)</span>
                </label>
                <div className="clear-title">
                  <input
                    {...getInputProps({
                      value: biblioData.title,
                      onChange: (e) => {
                        filterTitleList(e.target.value);
                      },
                    })}
                    className={
                      "form-input" + (errorList?.title ? " input-error" : "")
                    }
                    placeholder={t(
                      "create_bibliography_create_biblio_title_placeholder"
                    )}
                  />
                  {selectedNewBiblio && (
                    <i
                      className="icon close-red-icon"
                      onClick={() => clearTitle()}
                    />
                  )}
                </div>
                <div {...getMenuProps()}>
                  {isOpen &&
                  biblioData.title.length > 2 &&
                  searchedBiblios.length > 0 ? (
                    <div className="downshift-list">
                      {searchedBiblios.slice(0, 5).map((item, index) => (
                        <div
                          {...getItemProps({
                            key: index,
                            index,
                            item,
                            className:
                              selectedItem?.id === item?.id
                                ? "downshift-item selected"
                                : "downshift-item",
                          })}
                        >
                          {item.title}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                {errorList?.title && (
                  <ValidationMessage message={errorList?.title[0]} />
                )}
              </div>
            )}
          </Downshift>
          <div className="form-block">
            <div className="d-flex align-items-center">
              <button
                className="button-gray-blue"
                onClick={() => setAuthorModal(true)}
                type="button"
              >
                <i className="icon plus-icon me-2" />
                {t("create_bibliography_create_biblio_add_author")}
              </button>
              <label className="mb-0">
                <span className="biblioitem-tag">(100/A)</span>
              </label>
            </div>
            <div className="form-input tags-container w-100 p-3 mt-3">
              {biblioData?.authors?.length > 0 ? (
                biblioData.authors.map((author, index) => (
                  <span key={index} className="tag">
                    {`${author.name} / ${CheckAuthors(author.pivot.type)}`}
                    <button
                      className="button-unstyled icon close-red-icon"
                      type="button"
                      onClick={() => removeAuthor(author)}
                    />
                  </span>
                ))
              ) : (
                <span className="empty-span">
                  {t(
                    "create_bibliography_create_biblio_add_author_placeholder"
                  )}
                </span>
              )}
            </div>
            {authorModal && (
              <AuthorModal
                onShowModal={authorModal}
                selectedAuthors={biblioData?.authors}
                setAuthorVisible={(e) => setAuthorModal(e)}
              />
            )}
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_biblio_medium")}
                <span className="biblioitem-tag">(245/H)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_create_biblio_medium_placeholder"
                )}
                className={
                  "form-input" + (errorList?.medium ? " input-error" : "")
                }
                name="medium"
                value={biblioData.medium}
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    medium: e.target.value,
                  })
                }
              />
              {errorList?.medium && (
                <ValidationMessage message={errorList?.medium[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_biblio_subtitle")}
                <span className="biblioitem-tag">(245/B)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_create_biblio_subtitle_placeholder"
                )}
                className={
                  "form-input" + (errorList?.subtitle ? " input-error" : "")
                }
                name="subtitle"
                value={biblioData.subtitle}
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    subtitle: e.target.value,
                  })
                }
              />
              {errorList?.subtitle && (
                <ValidationMessage message={errorList?.subtitle[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_biblio_material_type")}
              </label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList?.materialtype_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="material_type"
                placeholder={t(
                  "create_bibliography_create_biblio_material_type_placeholder"
                )}
                isClearable={true}
                value={
                  biblioData?.materialtype_id
                    ? {
                        value: biblioData.materialtype_id.id,
                        label: biblioData.materialtype_id.name,
                      }
                    : ""
                }
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    materialtype_id: { id: e.value, name: e.label },
                  })
                }
                isSearchable={false}
                options={bibliographyData?.materialTypes.map((item) => ({
                  value: item.id,
                  label: item.name_trans,
                }))}
              />
              {errorList?.materialtype_id && (
                <ValidationMessage message={errorList?.materialtype_id[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_biblio_part_name")}
                <span className="biblioitem-tag">(245/N)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_create_biblio_part_name_placeholder"
                )}
                className={
                  "form-input" + (errorList?.part_name ? " input-error" : "")
                }
                name="part_name"
                value={biblioData?.part_name}
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    part_name: e.target.value,
                  })
                }
              />
              {errorList?.part_name && (
                <ValidationMessage message={errorList?.part_name[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_biblio_part_number")}
                <span className="biblioitem-tag">(245/P)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_create_biblio_part_number_placeholder"
                )}
                className={
                  "form-input" + (errorList?.part_number ? " input-error" : "")
                }
                name="part_number"
                value={biblioData?.part_number}
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    part_number: e.target.value,
                  })
                }
              />
              {errorList?.part_number && (
                <ValidationMessage message={errorList?.part_number[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_biblio_original_lanugage")}
                <span className="biblioitem-tag">(041/A)</span>
              </label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList?.language_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="language_id"
                placeholder={t(
                  "create_bibliography_create_biblio_original_lanugage_placeholder"
                )}
                isClearable={true}
                isSearchable={false}
                value={
                  biblioData?.language_id
                    ? {
                        value: biblioData.language_id.id,
                        label: biblioData.language_id.name,
                      }
                    : ""
                }
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    language_id: { id: e.value, name: e.label },
                  })
                }
                options={bibliographyData?.languages.map((item) => ({
                  value: item.id,
                  label: item.name_trans,
                }))}
              />
              {errorList?.language_id && (
                <ValidationMessage message={errorList?.language_id[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_create_biblio_agerestriction")}
                <span className="biblioitem-tag">(521/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_create_biblio_agerestriction_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList?.agerestriction ? " input-error" : "")
                }
                name="agerestriction"
                value={biblioData?.agerestriction}
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    agerestriction: e.target.value,
                  })
                }
              />
              {errorList?.agerestriction && (
                <ValidationMessage message={errorList?.agerestriction[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t(
                  "create_bibliography_create_biblio_year_original_publication"
                )}
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_create_biblio_year_original_publication_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList?.year_original_publication ? " input-error" : "")
                }
                name="year_original_publication"
                value={biblioData?.year_original_publication}
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    year_original_publication: e.target.value,
                  })
                }
              />
              {errorList?.year_original_publication && (
                <ValidationMessage
                  message={errorList?.year_original_publication[0]}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>{t("create_bibliography_create_biblio_media_type")}</label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList?.mediatype_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="media_type"
                placeholder={t(
                  "create_bibliography_create_biblio_media_type_placeholder"
                )}
                isClearable={true}
                isSearchable={false}
                value={
                  biblioData?.mediatype_id
                    ? {
                        value: biblioData.mediatype_id.id,
                        label: biblioData.mediatype_id.name,
                      }
                    : ""
                }
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    mediatype_id: { id: e.value, name: e.label },
                  })
                }
                options={bibliographyData?.mediaTypes.map((item) => ({
                  value: item.id,
                  label: item.name_trans,
                }))}
              />
              {errorList?.mediatype_id && (
                <ValidationMessage message={errorList?.mediatype_id[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <div className="d-flex align-items-center">
                <button
                  className="button-gray-blue"
                  onClick={() => setKeywordModal(true)}
                  type="button"
                >
                  <i className="icon plus-icon me-2" />
                  {t("create_bibliography_create_biblio_add_keywords")}
                </button>
                <label className="mb-0">
                  <span className="biblioitem-tag">(650/A)</span>
                </label>
              </div>
              <div className="form-input tags-container w-100 p-3 mt-3">
                {biblioData?.keywords.length > 0 ? (
                  biblioData.keywords.map((keyword, index) => (
                    <span key={index} className="tag">
                      {keyword.name}
                      <button
                        className="button-unstyled icon close-red-icon"
                        onClick={() => removeKeyword(keyword)}
                        type="button"
                      />
                    </span>
                  ))
                ) : (
                  <span className="empty-span">
                    {t(
                      "create_bibliography_create_biblio_add_keywords_placeholder"
                    )}
                  </span>
                )}
              </div>
              {keywordModal && (
                <KeywordsModal
                  onShowModal={keywordModal}
                  selectedKeywords={biblioData?.keywords}
                  setKeywordVisible={(e) => setKeywordModal(e)}
                />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <div className="d-flex align-items-center">
                <button
                  className="button-gray-blue"
                  onClick={() => setGeotermModal(true)}
                  type="button"
                >
                  <i className="icon plus-icon me-2" />
                  {t("create_bibliography_create_biblio_geographical_terms")}
                </button>
                <label className="mb-0">
                  <span className="biblioitem-tag">(651/A)</span>
                </label>
              </div>
              <div className="form-input tags-container w-100 p-3 mt-3">
                {biblioData?.geoterms.length > 0 ? (
                  biblioData.geoterms.map((geoterm, index) => (
                    <span key={index} className="tag">
                      {geoterm.name}
                      <button
                        className="button-unstyled icon close-red-icon"
                        onClick={() => removeGeoterm(geoterm)}
                        type="button"
                      />
                    </span>
                  ))
                ) : (
                  <span className="empty-span">
                    {t(
                      "create_bibliography_create_biblio_geographical_terms_placeholder"
                    )}
                  </span>
                )}
                {geotermModal && (
                  <GeoTermsModal
                    onShowModal={geotermModal}
                    selectedGeoterms={biblioData?.geoterms}
                    setGeotermVisible={(e) => setGeotermModal(e)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <label>{t("create_bibliography_create_biblio_abstract")}</label>
              <textarea
                placeholder={t(
                  "create_bibliography_create_biblio_abstract_placeholder"
                )}
                className={
                  "form-input" + (errorList?.abstract ? " input-error" : "")
                }
                rows="3"
                name="abstract"
                value={biblioData?.abstract}
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    abstract: e.target.value,
                  })
                }
              />
              {errorList?.abstract && (
                <ValidationMessage message={errorList?.abstract[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label>{t("create_bibliography_create_biblio_notes")}</label>
              <textarea
                placeholder={t(
                  "create_bibliography_create_biblio_notes_placeholder"
                )}
                className={
                  "form-input" + (errorList?.notes ? " input-error" : "")
                }
                rows="3"
                name="notes"
                value={biblioData?.notes}
                onChange={(e) =>
                  addBiblioDataUpdate({
                    ...biblioData,
                    notes: e.target.value,
                  })
                }
              />
              {errorList?.notes && (
                <ValidationMessage message={errorList?.notes[0]} />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {btnLoading ? (
              <button className="button-blue has-icon" type="button">
                <i className="icon spinner-icon me-2" />
                {t("create_bibliography_create_biblio_saving_button")}
              </button>
            ) : (
              <button className="button-blue" onClick={() => createBiblio()}>
                {t("create_bibliography_create_biblio_save_button")}
              </button>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CreateBiblio;
