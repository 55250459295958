import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Pages404 from "../../components/utility/Pages404";

const Page404View = () => {
  const { t } = useTranslation("");
  return (
    <Fragment>
      <Helmet>
        <title>{t("page_not_found_tab")}</title>
      </Helmet>
      <div style={{ width: 100 + "%" }}>
        <Pages404 />
      </div>
    </Fragment>
  );
};

export default Page404View;
