import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckSelectedDate } from "../../../helpers/CheckValue";
import moment from "moment";
import sq from "date-fns/locale/sq";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";

const CreateStaff = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [staffRoles, setStaffRoles] = useState([]);
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [role_id, setRole_Id] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postal_code, setPostal_Code] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    staffRole();
  }, []);

  const staffRole = () => {
    Request.getRequest(ApiUrl.staffRoles).then((response) => {
      if (response.status === 200) {
        setStaffRoles(response.data.roles);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const inviteStaff = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    var formData = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      dob: dob ? moment(dob).format("DD-MM-yyyy") : "",
      role_id: role_id,
      state: state,
      city: city,
      address: address,
      postal_code: postal_code,
      phone: phone,
      gender: gender,
    };

    Request.postRequest(ApiUrl.staffInvite, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        navigate(Path.listStaff);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const roles = staffRoles.map((item) => ({
    value: item.id,
    label: item.name_trans,
  }));

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("create_staff_add_staff")}</p>
      <form onSubmit={(e) => inviteStaff(e)}>
        <div className="row">
          <div className="col-12 col-md-4 flex-column col-form">
            <div className="form-block position-relative">
              <label>{t("create_staff_name")}</label>
              <input
                placeholder={t("create_staff_add_name")}
                className={
                  "form-input" + (error.first_name ? " input-error" : "")
                }
                name="first_name"
                type="text"
                id="first_name"
                onChange={(e) => setFirst_Name(e.target.value)}
              />
              {error.first_name && (
                <ValidationMessage message={error.first_name[0]} />
              )}
            </div>
            <div className="form-block position-relative">
              <label>{t("create_staff_lastname")}</label>
              <input
                placeholder={t("create_staff_add_lastname")}
                className={
                  "form-input" + (error.last_name ? " input-error" : "")
                }
                name="last_name"
                type="text"
                id="last_name"
                onChange={(e) => setLast_Name(e.target.value)}
              />
              {error.last_name && (
                <ValidationMessage message={error.last_name[0]} />
              )}
            </div>
            <div className="form-block position-relative">
              <label>{t("create_staff_email")}</label>
              <input
                placeholder={t("create_staff_add_email")}
                className={"form-input" + (error.email ? " input-error" : "")}
                name="email"
                type="text"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {error.email && <ValidationMessage message={error.email[0]} />}
            </div>
            <div className="form-block position-relative">
              <label>{t("create_staff_birthday")}</label>
              <DatePicker
                placeholderText={t("create_staff_choose_date")}
                name="dob"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={sq}
                dateFormat="dd-MM-yyyy"
                autoComplete="off"
                selected={dob ? CheckSelectedDate(dob) : null}
                className={"date_input" + (error.dob ? " input-error" : "")}
                onChange={(e) => setDob(e)}
              />
              {error.dob && <ValidationMessage message={error.dob[0]} />}
            </div>
            <div className="form-block position-relative">
              <label>{t("create_staff_role")}</label>
              <Select
                className={
                  "form-react-select" + (error.role_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="role_id"
                isClearable={true}
                placeholder={t("create_staff_choose")}
                onChange={(e) => (e ? setRole_Id(e.value) : setRole_Id(""))}
                isSearchable={false}
                options={roles}
              />
              {error.role_id && (
                <ValidationMessage message={error.role_id[0]} />
              )}
            </div>
          </div>
          <div className="col-12 col-md-4 flex-column col-form">
            <div className="form-block position-relative">
              <label htmlFor="state">{t("create_staff_state")}</label>
              <input
                placeholder={t("create_staff_add_state")}
                className={"form-input" + (error.state ? " input-error" : "")}
                name="state"
                type="text"
                id="state"
                onChange={(e) => setState(e.target.value)}
              />
              {error.state && <ValidationMessage message={error.state[0]} />}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="city">{t("create_staff_city")}</label>
              <input
                placeholder={t("create_staff_add_city")}
                className={"form-input" + (error.city ? " input-error" : "")}
                name="city"
                type="text"
                id="city"
                onChange={(e) => setCity(e.target.value)}
              />
              {error.city && <ValidationMessage message={error.city[0]} />}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="address">{t("create_staff_address")}</label>
              <input
                placeholder={t("create_staff_add_address")}
                className={"form-input" + (error.address ? " input-error" : "")}
                name="address"
                type="text"
                id="address"
                onChange={(e) => setAddress(e.target.value)}
              />
              {error.address && (
                <ValidationMessage message={error.address[0]} />
              )}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="postal_code">
                {t("create_staff_postal_code")}
              </label>
              <input
                placeholder={t("create_staff_add_postal_code")}
                className={
                  "form-input" + (error.postal_code ? " input-error" : "")
                }
                name="postal_code"
                type="number"
                id="postal_code"
                onChange={(e) => setPostal_Code(e.target.value)}
              />
              {error.postal_code && (
                <ValidationMessage message={error.postal_code[0]} />
              )}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="phone">{t("ceate_staff_phone")}</label>
              <input
                placeholder={t("create_staff_add_phone_number")}
                className={"form-input" + (error.phone ? " input-error" : "")}
                name="phone"
                type="number"
                onChange={(e) => setPhone(e.target.value)}
              />
              {error.phone && <ValidationMessage message={error.phone[0]} />}
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column col-form">
            <div className="d-block form-block">
              <label className="main-label mb-2">
                {t("create_staff_gender")}
              </label>
              <div className="radio-buttons d-flex">
                <div className="radio-button mr-2 me-3">
                  <label htmlFor="mashkull">{t("create_staff_male")}</label>
                  <input
                    className="form-check-input"
                    name="gender"
                    type="radio"
                    value="M"
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                <div className="radio-button ml-3">
                  <label htmlFor="femer">{t("create_staff_female")}</label>
                  <input
                    className="form-check-input"
                    name="gender"
                    type="radio"
                    value="F"
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
              </div>
              {error.gender && <ValidationMessage message={error.gender[0]} />}
            </div>
            <div className="form-block mt-auto">
              {btnLoading ? (
                <button type="button" className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("create_staff_saving")}
                </button>
              ) : (
                <button type="submit" className="button-blue">
                  {t("create_staff_save")}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default CreateStaff;
