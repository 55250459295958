import create from "zustand";

const biblioitemData = {
  biblio_id: "",
  barcode: "",
  cover: "",
  coverValue: "",
  deleteCover: 0,
  currency: "",
  dimensions: "",
  ean: "",
  isbn: "",
  issn: "",
  kdu: "",
  language_id: "",
  lccn: "",
  national_control_number: "",
  pages: "",
  physical_details: "",
  place: "",
  price: "",
  publication_number: "",
  publication_year: "",
  publisher_id: "",
  replacement_price: "",
  url: "",
  urlNew: "",
  deleteUrl: 0,
  contributors: [],
};

export const Biblioitem = create((set) => ({
  biblioitemData,
  addBiblioitemData: (data) =>
    set(() => ({
      biblioitemData: {
        biblio_id: data?.biblio_id ? data.biblio_id : "",
        barcode: data?.biblio?.title ? data.biblio.title : "",
        cover: data?.cover ? data.cover : "",
        coverValue: "",
        deleteCover: 0,
        currency: data?.currency ? data.currency : "",
        dimensions: data?.dimensions ? data.dimensions : "",
        ean: data?.ean ? data.ean : "",
        isbn: data?.isbn ? data.isbn : "",
        issn: data?.issn ? data.issn : "",
        kdu: data?.kdu ? data.kdu : "",
        language_id: data?.language ? data.language : "",
        lccn: data?.lccn ? data.lccn : "",
        national_control_number: data?.national_control_number
          ? data.national_control_number
          : "",
        pages: data?.pages ? data.pages : "",
        physical_details: data?.physical_details ? data.physical_details : "",
        place: data?.place ? data.place : "",
        price: data?.price ? data.price : "",
        publication_number: data?.publication_number
          ? data.publication_number
          : "",
        publication_year: data?.publication_year ? data.publication_year : "",
        publisher_id: data?.publisher ? data.publisher : "",
        replacement_price: data?.replacement_price
          ? data.replacement_price
          : "",
        url: data?.url ? data.url : "",
        urlNew: "",
        deleteUrl: 0,
        contributors: data?.contributors ? data.contributors : [],
      },
    })),

  addBiblioitemUpdate: (data) => set(() => ({ biblioitemData: data })),
  reset: () => set(() => ({ biblioitemData : {
    biblio_id: "",
    barcode: "",
    cover: "",
    coverValue: "",
    deleteCover: 0,
    currency: "",
    dimensions: "",
    ean: "",
    isbn: "",
    issn: "",
    kdu: "",
    language_id: "",
    lccn: "",
    national_control_number: "",
    pages: "",
    physical_details: "",
    place: "",
    price: "",
    publication_number: "",
    publication_year: "",
    publisher_id: "",
    replacement_price: "",
    url: "",
    urlNew: "",
    deleteUrl: 0,
    contributors: [],
  }})),
}));
