import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../../helpers/ValidationMessage";

import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Request from "../../../../../api/Request";
import * as Message from "../../../../../helpers/Messages";

const EditServer = ({
  onShowModal,
  setServerVisible,
  setModified,
  serverId,
  serverName,
  serverUrl,
  serverPort,
  serverDatabase,
}) => {
  const { t } = useTranslation("");

  const [editServerName, setEditServerName] = useState(serverName);
  const [editServerUrl, setEditServerUrl] = useState(serverUrl);
  const [editServerPort, setEditServerPort] = useState(serverPort);
  const [editServerDatabase, setEditServerDatabase] = useState(serverDatabase);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState([]);

  //The function that closes the modal
  const onClose = () => {
    setServerVisible(false);
    setError([]);
  };

  const updateServer = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    Request.patchRequest(ApiUrl.updateServer + serverId, {
      name: editServerName,
      url: editServerUrl,
      port_number: editServerPort,
      database_name: editServerDatabase,
    }).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        onClose();
        setModified(true);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(response.data.error);
      }
    });
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("servers_list_edit_server_popup_title")}
      </ModalHeader>
      <form onSubmit={(e) => updateServer(e)} className="text-center">
        <ModalBody>
          <div className="form-block row mb-0">
            <div className="col-12 text-start mb-4">
              <label>{t("servers_list_edit_server_name_popup_label")}</label>
              <input
                type="text"
                name="name"
                className={"form-input" + (error?.name ? " input-error" : "")}
                onChange={(e) => setEditServerName(e.target.value)}
                value={editServerName}
                placeholder={t(
                  "servers_list_edit_server_name_popup_placeholder"
                )}
              />
              {error?.name && <ValidationMessage message={error?.name} />}
            </div>
            <div className="col-12 text-start mb-4">
              <label>{t("servers_list_edit_server_url_popup_label")}</label>
              <input
                type="text"
                name="url"
                className={"form-input" + (error?.url ? " input-error" : "")}
                onChange={(e) => setEditServerUrl(e.target.value)}
                value={editServerUrl}
                placeholder={t(
                  "servers_list_edit_server_url_popup_placeholder"
                )}
              />
              {error?.url && <ValidationMessage message={error?.url} />}
            </div>
            <div className="col-12 text-start mb-4">
              <label>{t("servers_list_edit_server_port_popup_label")}</label>
              <input
                type="number"
                name="port_number"
                className={
                  "form-input" + (error?.port_number ? " input-error" : "")
                }
                onChange={(e) => setEditServerPort(e.target.value)}
                value={editServerPort}
                placeholder={t(
                  "servers_list_edit_server_port_popup_placeholder"
                )}
              />
              {error?.port_number && (
                <ValidationMessage message={error?.port_number} />
              )}
            </div>
            <div className="col-12 text-start mb-4">
              <label>
                {t("servers_list_edit_server_database_popup_label")}
              </label>
              <input
                type="text"
                name="database_name"
                className={
                  "form-input" + (error?.database_name ? " input-error" : "")
                }
                onChange={(e) => setEditServerDatabase(e.target.value)}
                value={editServerDatabase}
                placeholder={t(
                  "servers_list_edit_server_database_popup_placeholder"
                )}
              />
              {error?.database_name && (
                <ValidationMessage message={error?.database_name} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button className="button-blue w-100 has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("servers_list_edit_server_editing_button")}
                </button>
              ) : (
                <button type="submit" className="button-blue w-100">
                  {t("servers_list_edit_server_edit_button")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("servers_list_edit_server_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditServer;
