import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import sq from "date-fns/locale/sq";
import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ValidationMessage from "../../../helpers/ValidationMessage";
import FormData from "form-data";
import { CheckSelectedDate } from "../../../helpers/CheckValue";

const EditAcquisition = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();
  const [copies, setCopies] = useState("");
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [seller_donor, setSeller_donor] = useState("");
  const [expected_on, setExpected_on] = useState(null);
  const [comments, setComments] = useState("");
  const [status, setStatus] = useState("");
  const [status_trans, setStatus_Trans] = useState("");
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    editAcquisition();
  }, []);

  const editAcquisition = () => {
    const id = param.id;

    Request.getRequest(ApiUrl.editAcquisition + id).then((response) => {
      if (response.status === 200) {
        setTitle(response.data.acquisition.title);
        setAuthor(response.data.acquisition.author);
        setCopies(response.data.acquisition.copies);
        setSeller_donor(response.data.acquisition.seller_donor);
        setExpected_on(response.data.acquisition.expected_on);
        setComments(response.data.acquisition.comments);
        setStatus(response.data.acquisition.status);
        setStatus_Trans(response.data.acquisition.status_trans);
      } else if (response.status === 404) {
        navigate(-1);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const updateAcquisition = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const id = param.id;
    var formData = {
      status: status,
      title: title,
      author: author,
      copies: copies,
      seller_donor: seller_donor,
      expected_on: expected_on ? moment(expected_on).format("DD-MM-yyyy") : "",
      comments: comments,
    };

    Request.patchRequest(ApiUrl.updateAcquisition + id, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          navigate(Path.listAcquisition);
          Message.successMsg(response.data.success);
          setTitle("");
          setAuthor("");
          setCopies("");
          setSeller_donor("");
          setExpected_on("");
          setComments("");
          setStatus("");
          setStatus_Trans("");
          setError([]);
        } else if (response.status === 404) {
          navigate(Path.listAcquisition);
          Message.errorMsg(response.data.error);
        } else {
          setError(response.data);
        }
      }
    );
  };

  return (
    <Fragment>
      <p className="partial-title big-divide-bottom">
        {t("edit_acquisition_change_acquisition")}
      </p>
      <form onSubmit={(e) => updateAcquisition(e)}>
        <div className="row">
          <div className="col-12 col-sm-6 flex-column col-form">
            <div className="form-block position-relative">
              <label htmlFor="title">{t("edit_acquisition_title")}</label>
              <input
                placeholder={t("edit_acquisition_add_title")}
                className={"form-input " + (error.title ? " input-error" : "")}
                name="title"
                type="text"
                value={title !== null ? title : ""}
                onChange={(e) => setTitle(e.target.value)}
              />
              {error.title && <ValidationMessage message={error.title[0]} />}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="author">{t("edit_acquisition_author")}</label>
              <input
                placeholder={t("edit_acquisition_add_author")}
                className={"form-input " + (error.author ? " input-error" : "")}
                name="author"
                type="text"
                value={author !== null ? author : ""}
                onChange={(e) => setAuthor(e.target.value)}
              />
              {error.author && <ValidationMessage message={error.author[0]} />}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="copies">
                {t("edit_acquisition_number_of_copies")}
              </label>
              <input
                placeholder={t("edit_acquisition_add_number_of_copies")}
                className={"form-input " + (error.copies ? " input-error" : "")}
                name="copies"
                type="number"
                value={copies !== null ? copies : ""}
                onChange={(e) => setCopies(e.target.value)}
              />
              {error.copies && <ValidationMessage message={error.copies[0]} />}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="seller_donor">
                {t("edit_acquisition_donor_seller")}
              </label>
              <input
                placeholder={t("edit_acquisition_add_seller_donor")}
                className={
                  "form-input " + (error.seller_donor ? " input-error" : "")
                }
                name="seller_donor"
                type="text"
                value={seller_donor !== null ? seller_donor : ""}
                onChange={(e) => setSeller_donor(e.target.value)}
              />
              {error.seller_donor && (
                <ValidationMessage message={error.seller_donor[0]} />
              )}
            </div>
          </div>
          <div className="col-12 col-sm-6 flex-column col-form ">
            <div className="form-block position-relative">
              <label htmlFor="expected_on">
                {t("edit_acquisition_date_of_arrival")}
              </label>
              <DatePicker
                placeholderText={t("edit_acquisition_choose_date")}
                name="from"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={sq}
                dateFormat="dd-MM-yyyy"
                selected={expected_on ? CheckSelectedDate(expected_on) : null}
                onChange={(e) => setExpected_on(e)}
                autoComplete="off"
                className={
                  "date_input" + (error.expected_on ? " input-error" : "")
                }
              />
              {error.expected_on && (
                <ValidationMessage message={error.expected_on[0]} />
              )}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="status-button">
                {t("edit_acquisition_status")}
              </label>
              <Select
                className={
                  "form-react-select" + (error.status ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="status"
                isSearchable={false}
                placeholder={t("edit_acquisition_choose")}
                onChange={(e) =>
                  e
                    ? (setStatus(e.value), setStatus_Trans(e.label))
                    : (setStatus(""), setStatus_Trans(""))
                }
                value={
                  status
                    ? {
                        value: status,
                        label: status_trans,
                      }
                    : ""
                }
                options={[
                  { value: "registered", label: t("status_registred") },
                  { value: "approved", label: t("status_approved") },
                  { value: "cancelled", label: t("status_cancelled") },
                ]}
              />
              {error.status && <ValidationMessage message={error.status[0]} />}
            </div>
            <div className="form-block position-relative">
              <label htmlFor="comments">{t("edit_acquisition_comments")}</label>
              <textarea
                placeholder={t("edit_acquisition_add_comments")}
                className={
                  "form-input tage" + (error.comments ? " input-error" : "")
                }
                name="comments"
                cols="50"
                rows="2"
                onChange={(e) => setComments(e.target.value)}
                value={comments !== null ? comments : ""}
              />
              {error.comments && (
                <ValidationMessage message={error.comments[0]} />
              )}
            </div>
            <div className="form-block mt-auto position-submit-acq-button offset-7">
              {btnLoading ? (
                <button className="button-blue has-icon w-100">
                  <i className="icon spinner-icon me-2" />
                  {t("edit_acquisition_changing")}
                </button>
              ) : (
                <button className="button-blue has-icon w-100">
                  {t("edit_acquisition_change")}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default EditAcquisition;
