import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import Select from "react-select";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";
import * as Tooltip from "../../../helpers/Tooltip";

const BorrowingList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [borrowingList, setBorrowingList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search_text, setSearch_Text] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listBorrowings();
  }, [activePage, searched]);

  const listBorrowings = (e) => {
    let request = ApiUrl.listBorrowings;

    var searchParams = {};

    if (e) {
      e.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: search_text,
        type: type,
        status: status,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: search_text,
        type: type,
        status: status,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);
    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setBorrowingList(response.data.borrowings.data);
        setLoading(false);
        setError({});
        setPagination(response.data.borrowings);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("borrowing_list_borrowing")}</p>
      <div className="search-item">
        <form onSubmit={(e) => listBorrowings(e)}>
          <div className="row mb-5">
            <div className="form-block col-12 col-sm-6 col-md-4">
              <input
                placeholder={t("borrowing_list_write")}
                className={
                  "form-input" + (error.search_text ? " input-error" : "")
                }
                name="search_text"
                type="text"
                onChange={(e) => setSearch_Text(e.target.value)}
              />
              {error.search_text && (
                <ValidationMessage message={error.search_text[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-3">
              <Select
                className={
                  "form-react-select" + (error.type ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="type"
                isSearchable={false}
                isClearable={true}
                onChange={(e) => (e ? setType(e.value) : setType(""))}
                placeholder={t("borrowing_list_choose")}
                options={[
                  { value: "member", label: t("member") },
                  { value: "nr_inventory", label: t("codification") },
                  { value: "title", label: t("title") },
                ]}
              />
              {error.type && <ValidationMessage message={error.type[0]} />}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-3">
              <Select
                className={
                  "form-react-select" + (error.status ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="status"
                isSearchable={false}
                isClearable={true}
                onChange={(e) => (e ? setStatus(e.value) : setStatus(""))}
                placeholder={t("borrowing_list_status")}
                options={[
                  { value: "active", label: t("active") },
                  { value: "notactive", label: t("inactive") },
                ]}
              />
              {error.status && <ValidationMessage message={error.status[0]} />}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-2">
              {btnLoading ? (
                <button className="button-blue has-icon" type={"button"}>
                  <i className="icon spinner-icon me-2" />
                  {t("borrowing_searching")}
                </button>
              ) : (
                <button
                  className="button-blue has-icon"
                  type={"submit"}
                  onClick={() => {
                    setActivePage("1");
                    setSearched(true);
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("borrowing_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list">
        <table className="w-100" role="table">
          <thead>
            <tr role="row">
              <th className="text-left" width="20%" role="columnheader">
                {t("borrowing_member_")}
              </th>
              <th className="text-center" width="20%" role="columnheader">
                {t("borrowing_title_author")}
              </th>
              <th className="text-center" width="10%" role="columnheader">
                {t("borrowing_type")}
              </th>
              <th className="text-center" width="20%" role="columnheader">
                {t("borrowing_codification_inventary_number")}
              </th>
              <th className="text-center" width="20%" role="columnheader">
                {t("borrowing_status")}
              </th>
              <th className="text-center" width="10%" role="columnheader">
                {t("borrowing_watch")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="6">
                  <Loading />
                </td>
              </tr>
            ) : (
              borrowingList.map((borrowing, index) => (
                <tr
                  key={index}
                  className="form-summary-table-row h-auto"
                  role="row"
                >
                  <td data-label={t("borrowing_member_")}>
                    {borrowing.user.first_name + " " + borrowing.user.last_name}
                  </td>
                  <td
                    className="text-center"
                    data-label={t("borrowing_title_author")}
                  >
                    <div className="text-md-center d-inline-flex">
                      {Tooltip.tooltipDisabledMobile(
                        "title" + index,
                        borrowing.item.biblioitem.biblio.title
                      )}
                    </div>
                    <div>
                      {borrowing.item.biblioitem.biblio.authors
                        .map((author) => author.name)
                        .join("; ")}
                    </div>
                  </td>
                  <td className="text-center" data-label={t("borrowing_type")}>
                    {borrowing.item.borrowing_type_trans}
                  </td>
                  <td
                    className="text-center"
                    data-label={t("borrowing_codification_inventary_number")}
                  >
                    {borrowing.item.inventory_number +
                      " / " +
                      borrowing.item.id}
                  </td>
                  <td
                    className="text-center status"
                    data-label={t("borrowing_status")}
                  >
                    <span
                      className={
                        borrowing.status === "delivered_late"
                          ? "delivered-late"
                          : borrowing.status === "to_be_delivered"
                          ? "tobedelivered"
                          : borrowing.status === "delivered_on_time"
                          ? "delivered"
                          : borrowing.status === "undelivered"
                          ? "undelivered"
                          : ""
                      }
                    >
                      <i className="dot me-2 mx-1"></i>
                      {borrowing.status_trans}
                    </span>
                  </td>
                  <td className="text-center" data-label={t("borrowing_watch")}>
                    <button
                      className="button-unstyled-icon"
                      onClick={() =>
                        navigate(Path.borrowingDetails + borrowing.id)
                      }
                    >
                      <i className="icon show-icon" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(error).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default BorrowingList;
