import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CheckSelectedDate } from "../../../helpers/CheckValue";
import moment from "moment";
import sq from "date-fns/locale/sq";
import DatePicker from "react-datepicker";
import Select from "react-select";
import FormData from "form-data";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";

const EditStaff = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();

  const [role_id, setRole_Id] = useState("");
  const [role_name, setRole_Name] = useState("");
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postal_code, setPostal_Code] = useState("");
  const [phone, setPhone] = useState("");
  const [membership_start, setMembership_Start] = useState(null);
  const [membership_end, setMembership_End] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    ListOfStaff();
  }, []);

  const ListOfStaff = () => {
    const id = param.id;

    Request.getRequest(ApiUrl.userEdit + id).then((response) => {
      if (response.status === 200) {
        var userData = response.data.user;
        setRole_Id(userData.role.id ? userData.role.id : "");
        setRole_Name(userData.role.name_trans ? userData.role.name_trans : "");
        setEmail(userData.email ? userData.email : "");
        setFirst_Name(userData.first_name ? userData.first_name : "");
        setLast_Name(userData.last_name ? userData.last_name : "");
        setDob(userData.dob ? userData.dob : "");
        setGender(userData.gender ? userData.gender : "");
        setPhone(userData.phone ? userData.phone : "");
        setAddress(userData.address ? userData.address : "");
        setCity(userData.city ? userData.city : "");
        setState(userData.state ? userData.state : "");
        setPostal_Code(userData.postal_code ? userData.postal_code : "");
        setMembership_Start(userData.membership_start);
        setMembership_End(response.data.months);
        setRoleList(response.data.roles);
      } else if (response.status === 404) {
        navigate(-1);
        Message.errorMsg(response.data.error);
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const id = param.id;
    const formData = new FormData();
    formData.append("role_id", role_id);
    formData.append("role_name", role_name);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("dob", dob ? moment(dob).format("DD-MM-yyyy") : "");
    formData.append("gender", gender);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("address", address);
    formData.append("postal_code", postal_code);
    formData.append("phone", phone);
    formData.append(
      "membership_start",
      membership_start ? moment(membership_start).format("DD-MM-yyyy") : ""
    );
    formData.append("membership_end", membership_end);
    formData.append("_method", "PATCH");

    Request.patchRequest(ApiUrl.updateUser + id, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        navigate(Path.listStaff);
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        navigate(Path.listStaff);
        Message.errorMsg(response.data.error);
      } else {
        setError(response.data);
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("edit_staff_change_staff")}</p>
      <form onSubmit={(e) => update(e)}>
        <div className="row">
          <div className="col-12 col-md-4 flex-column col-form">
            <div className="form-block position-relative">
              <label>{t("edit_staff_name")}:</label>
              <input
                placeholder={t("edit_staff_add_name")}
                className={
                  "form-input" + (error.first_name ? " input-error" : "")
                }
                name="first_name"
                type="text"
                value={first_name}
                id="first_name"
                onChange={(e) => setFirst_Name(e.target.value)}
              />
              {error.first_name && (
                <ValidationMessage message={error.first_name[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("edit_staff_lastname")}:</label>
              <input
                placeholder={t("edit_staff_add_lastname")}
                className={
                  "form-input" + (error.last_name ? " input-error" : "")
                }
                name="last_name"
                type="text"
                value={last_name}
                id="last_name"
                onChange={(e) => setLast_Name(e.target.value)}
              />
              {error.last_name && (
                <ValidationMessage message={error.last_name[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("edit_staff_email")}:</label>
              <input
                placeholder={t("edit_staff_email")}
                className={"form-input" + (error.email ? " input-error" : "")}
                name="email"
                type="text"
                value={email}
                readOnly
                id="email"
              />
              {error.email && <ValidationMessage message={error.email[0]} />}
            </div>
            <div className="form-block">
              <label>{t("edit_staff_birthday")}:</label>
              <DatePicker
                placeholderText={t("edit_staff_choose_date")}
                name="dob"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={sq}
                dateFormat="dd-MM-yyyy"
                selected={dob ? CheckSelectedDate(dob) : null}
                onChange={(e) => setDob(e)}
                autoComplete="off"
                className={"date_input" + (error.dob ? " input-error" : "")}
              />
              {error.dob && <ValidationMessage message={error.dob[0]} />}
            </div>
            <div className="form-block">
              <label>{t("edit_staff_role")}:</label>
              <Select
                className={
                  "form-react-select" + (error.role_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="role_id"
                isClearable={true}
                isSearchable={false}
                placeholder={t("edit_staff_choose")}
                value={[
                  {
                    value: role_id,
                    label: role_name,
                  },
                ]}
                options={roleList.map((item) => ({
                  value: item.id,
                  label: item.name_trans,
                }))}
                onChange={(e) =>
                  e
                    ? (setRole_Id(e.value), setRole_Name(e.label))
                    : (setRole_Id(""), setRole_Name(""))
                }
              />
              {error.role_id && (
                <ValidationMessage message={error.role_id[0]} />
              )}
            </div>
          </div>
          <div className="col-12 col-md-4 flex-column col-form">
            <div className="form-block">
              <label>{t("edit_staff_state")}:</label>
              <input
                placeholder={t("edit_staff_add_state")}
                className={"form-input" + (error.state ? " input-error" : "")}
                name="state"
                type="text"
                value={state}
                id="state"
                onChange={(e) => setState(e.target.value)}
              />
              {error.state && <ValidationMessage message={error.state[0]} />}
            </div>
            <div className="form-block">
              <label>{t("edit_staff_city")}:</label>
              <input
                placeholder={t("edit_staff_add_city")}
                className={"form-input" + (error.city ? " input-error" : "")}
                name="city"
                type="text"
                value={city}
                id="city"
                onChange={(e) => setCity(e.target.value)}
              />
              {error.city && <ValidationMessage message={error.city[0]} />}
            </div>
            <div className="form-block">
              <label>{t("edit_staff_address")}:</label>
              <input
                placeholder={t("edit_staff_add_address")}
                className={"form-input" + (error.address ? " input-error" : "")}
                name="address"
                type="text"
                value={address}
                id="address"
                onChange={(e) => setAddress(e.target.value)}
              />
              {error.address && (
                <ValidationMessage message={error.address[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("edit_staff_postal_code")}:</label>
              <input
                placeholder={t("edit_staff_add_postal_code")}
                className={
                  "form-input" + (error.postal_code ? " input-error" : "")
                }
                name="postal_code"
                type="number"
                value={postal_code}
                onChange={(e) => setPostal_Code(e.target.value)}
              />
              {error.postal_code && (
                <ValidationMessage message={error.postal_code[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("edit_staff_phone")}:</label>
              <input
                placeholder={t("edit_staff_add_phone")}
                className={"form-input" + (error.phone ? " input-error" : "")}
                name="phone"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              {error.phone && <ValidationMessage message={error.phone[0]} />}
            </div>
          </div>
          <div className="col-12 col-md-4 d-flex flex-column col-form">
            <div className="d-block form-block">
              <label>{t("edit_staff_gender")}:</label>
              <div className="radio-buttons d-flex">
                <div className="radio-button mr-2 me-3">
                  <label htmlFor="mashkull">{t("edit_staff_male")}</label>
                  <input
                    className="form-check-input"
                    name="gender"
                    type="radio"
                    value="M"
                    checked={gender === "M"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                <div className="radio-button ml-3">
                  <label htmlFor="femer">{t("edit_staff_female")}</label>
                  <input
                    className="form-check-input"
                    name="gender"
                    type="radio"
                    value="F"
                    checked={gender === "F"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                {error.gender && (
                  <ValidationMessage message={error.gender[0]} />
                )}
              </div>
            </div>
            <div className="form-block mt-auto">
              {btnLoading ? (
                <button className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("edit_staff_changing")}
                </button>
              ) : (
                <button className="button-blue has-icon">
                  {t("edit_staff_change")}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default EditStaff;
