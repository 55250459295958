import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import Select from "react-select";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const MembersList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [memberRoles, setMemberRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listOfMembers, setListOfMembers] = useState([]);
  const [search_text, setSearch_Text] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listMembers();
  }, [activePage, searched]);

  const listMembers = (hasSearch) => {
    let request = ApiUrl.listMembers;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        search_text: search_text,
        role: role,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        search_text: search_text,
        role: role,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        memberRole();
        setListOfMembers(response.data.members.data);
        setLoading(false);
        setError({});
        setPagination(response.data.members);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const memberRole = () => {
    Request.getRequest(ApiUrl.memberRoles).then((response) => {
      if (response.status === 200) {
        setMemberRoles(response.data.memberRoles);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const deleteMember = (e, id, name) => {
    ConfirmPopup.deletePopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteMember + id).then((response) => {
          if (response.status === 200) {
            listMembers();
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <div id="list-of-members">
        <p className="partial-title mb-5">{t("member_list_list_of_members")}</p>
        <div className="input-items">
          <form onSubmit={(e) => listMembers(e)}>
            <div className="row mb-5">
              <div className="form-block col-md-4">
                <input
                  placeholder={t("member_list_search_name_or_email")}
                  className="form-input"
                  name="search_text"
                  type="text"
                  onChange={(e) => setSearch_Text(e.target.value)}
                />
              </div>
              <div className="form-block col-md-4">
                <Select
                  className={
                    "form-react-select" + (error.role ? " input-error" : "")
                  }
                  isClearable={true}
                  classNamePrefix="select_input"
                  name="borrowing_type"
                  placeholder={t("member_list_choose")}
                  onChange={(e) => (e ? setRole(e.value) : setRole(""))}
                  isSearchable={false}
                  options={memberRoles.map((item) => ({
                    value: item.id,
                    label: item.name_trans,
                  }))}
                />
                {error.role && <ValidationMessage message={error.role[0]} />}
              </div>
              <div className="form-block col-12 col-sm-6 col-md-4">
                {btnLoading ? (
                  <button type="button" className="button-blue has-icon">
                    <i className="icon spinner-icon me-2" />
                    {t("member_list_searching")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="button-blue has-icon"
                    onClick={() => {
                      setSearched(true);
                      setActivePage("1");
                    }}
                  >
                    <i className="icon search-icon me-2" />
                    {t("member_list_search")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="table-list mb-5">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" className="text-left">
                  {t("member_list_name")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("member_list_lastname")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("member_list_email")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("member_list_role")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("member_list_status")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("member_list_action")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="6">
                    <Loading />
                  </td>
                </tr>
              ) : (
                listOfMembers.map((membersList, index) => (
                  <tr key={membersList.id} role="row">
                    <td role="cell" data-label={t("member_list_name")}>
                      {membersList.first_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("member_list_lastname")}
                      className="text-center"
                    >
                      {membersList.last_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("member_list_email")}
                      className="text-center"
                    >
                      {membersList.email}
                    </td>
                    <td
                      role="cell"
                      data-label={t("member_list_role")}
                      className="text-center"
                    >
                      {membersList.role_id === 4
                        ? t("member_list_student")
                        : ""}
                      {membersList.role_id === 5
                        ? t("member_list_academic_staff")
                        : ""}
                      {membersList.role_id === 6
                        ? t("member_list_personality")
                        : ""}
                    </td>
                    <td
                      role="cell"
                      data-label={t("member_list_status")}
                      className="text-center"
                    >
                      {membersList.token !== null
                        ? t("member_list_not_confirmed")
                        : t("member_list_confirmed")}
                    </td>
                    <td
                      role="cell"
                      data-label={t("member_list_delete")}
                      className="text-center"
                    >
                      <div className="row justify-content-around mr-auto ml-auto action-icons">
                        <div className="col-3 show">
                          <button
                            className="button-unstyled-icon"
                            onClick={() =>
                              navigate(Path.showMember + membersList.id)
                            }
                          >
                            <i className="icon show-icon" />
                          </button>
                        </div>
                        <div className="col-3 edit">
                          <button
                            className="button-unstyled-icon"
                            onClick={() =>
                              navigate(Path.editMember + membersList.id)
                            }
                          >
                            <i className="icon edit-icon" />
                          </button>
                        </div>
                        <div className="col-3 delete">
                          <button
                            type="submit"
                            className="button-unstyled-icon"
                            onClick={(e) =>
                              deleteMember(
                                e,
                                membersList.id,
                                membersList.first_name +
                                  " " +
                                  membersList.last_name
                              )
                            }
                          >
                            <i className="icon delete-icon" />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={searched}
          />
        </div>
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(error).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default MembersList;
