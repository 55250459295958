import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { CheckSelectedDate } from "../../../helpers/CheckValue";
import moment from "moment";
import Select from "react-select";
import sq from "date-fns/locale/sq";
import DatePicker from "react-datepicker";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";

const EditMember = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();
  const [memberRoles, setMemberRoles] = useState([]);
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [role_name, setRole_name] = useState("");
  const [dob, setDob] = useState(null);
  const [role_id, setRole_Id] = useState("");
  const [membership_start, setMembership_start] = useState(null);
  const [membership_end, setMembership_end] = useState("");
  const [membership_status, setMembership_status] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnChangeLoading, setBtnChangeLoading] = useState(false);

  useEffect(() => {
    EditingMember();
  }, []);

  const EditingMember = () => {
    const id = param.id;
    Request.getRequest(ApiUrl.userEdit + id).then((response) => {
      if (response.status === 200) {
        setRole_Id(response.data.user.role.id);
        setRole_name(response.data.user.role.name_trans);
        setEmail(response.data.user.email);
        setFirst_Name(response.data.user.first_name);
        setLast_Name(response.data.user.last_name);
        setDob(response.data.user.dob);
        setGender(response.data.user.gender);
        setPhone(response.data.user.phone);
        setAddress(response.data.user.address);
        setCity(response.data.user.city);
        setState(response.data.user.state);
        setPostal_code(response.data.user.postal_code);
        setMembership_start(response.data.user.membership_start);
        setMembership_end(response.data.months);
        setMembership_status(response.data.membership_status);
        setMemberRoles(response.data.roles);
      } else if (response.status === 404) {
        navigate(Path.listMembers);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const update = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const id = param.id;
    var formData = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      role_id: role_id,
      role_name: role_name,
      dob: dob ? moment(dob).format("DD-MM-yyyy") : "",
      membership_start: membership_start
        ? moment(membership_start).format("DD-MM-yyyy")
        : "",
      membership_end: membership_end,
      state: state,
      city: city,
      address: address,
      postal_code: postal_code,
      phone: phone,
      gender: gender,
    };

    Request.patchRequest(ApiUrl.updateUser + id, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        navigate(Path.listMembers);
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        navigate(Path.listMembers);
        Message.errorMsg(response.data.error);
      } else {
        setError(response.data);
      }
    });
  };

  const changeMembership = (e) => {
    e.preventDefault();
    setBtnChangeLoading(true);
    const id = param.id;

    var formData = {
      id: id,
      membership_end: membership_end,
    };

    Request.postRequest(ApiUrl.changeMembership, formData).then((response) => {
      setBtnChangeLoading(false);
      if (response.status === 200) {
        navigate(Path.listMembers);
        Message.successMsg(response.data.success);
      } else if (response.status === 404) {
        navigate(Path.listMembers);
        Message.errorMsg(response.data.error);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("edit_member_change_details")}</p>
      <form onSubmit={(e) => update(e)}>
        <div className="row">
          <div className="col-12 col-md-4 flex-column col-form">
            <div className="form-block">
              <label htmlFor="first_name">{t("edit_member_name")}:</label>
              <input
                placeholder={t("edit_member_add_name")}
                className={
                  "form-input" + (error.first_name ? " input-error" : "")
                }
                name="first_name"
                type="text"
                value={first_name}
                id="first_name"
                onChange={(e) => setFirst_Name(e.target.value)}
              />
              {error.first_name && (
                <ValidationMessage message={error.first_name[0]} />
              )}
            </div>
            <div className="form-block">
              <label htmlFor="last_name">{t("edit_member_lastname")}:</label>
              <input
                placeholder={t("edit_member_add_lastname")}
                className={
                  "form-input" + (error.last_name ? " input-error" : "")
                }
                name="last_name"
                type="text"
                value={last_name}
                id="last_name"
                onChange={(e) => setLast_Name(e.target.value)}
              />
              {error.last_name && (
                <ValidationMessage message={error.last_name[0]} />
              )}
            </div>
            <div className="form-block">
              <label htmlFor="email">{t("edit_member_email")}:</label>
              <input
                placeholder={t("edit_member_email")}
                className={"form-input" + (error.email ? " input-error" : "")}
                name="email"
                type="text"
                value={email}
                readOnly
                id="email"
              />
              {error.email && <ValidationMessage message={error.email[0]} />}
            </div>
            <div className="form-block">
              <label htmlFor="dob">{t("edit_member_birthday")}:</label>
              <DatePicker
                placeholderText={t("edit_member_choose_date")}
                name="dob"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={sq}
                dateFormat="dd-MM-yyyy"
                selected={dob ? CheckSelectedDate(dob) : null}
                onChange={(e) => setDob(e)}
                autoComplete="off"
                className={"date_input" + (error.dob ? " input-error" : "")}
              />
              {error.dob && <ValidationMessage message={error.dob[0]} />}
            </div>
            <div className="form-block">
              <label>{t("edit_member_role")}:</label>
              <Select
                className={
                  "form-react-select" + (error.role_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="role_id"
                isClearable={true}
                isSearchable={false}
                placeholder={t("edit_member_choose")}
                value={[
                  {
                    value: role_id,
                    label: role_name,
                  },
                ]}
                options={memberRoles.map((item) => ({
                  value: item.id,
                  label: item.name_trans,
                }))}
                onChange={(e) =>
                  e
                    ? (setRole_Id(e.value), setRole_name(e.label))
                    : (setRole_Id(""), setRole_name(""))
                }
              />
              {error.role_id && (
                <ValidationMessage message={error.role_id[0]} />
              )}
            </div>
          </div>
          <div className="col-12 col-md-4 flex-column col-form">
            {membership_start && (
              <Fragment>
                <div className="form-block">
                  <label htmlFor="membership_start">
                    {t("edit_member_start_date")}:
                  </label>
                  <DatePicker
                    placeholderText={t("edit_member_choose_date")}
                    name="from"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={sq}
                    dateFormat="dd-MM-yyyy"
                    selected={
                      membership_start
                        ? CheckSelectedDate(membership_start)
                        : null
                    }
                    readOnly
                    autoComplete="off"
                    className="date_input"
                  />
                </div>
                <div className="form-block">
                  <label htmlFor="membership_end-button">
                    {t("edit_member_end_date")}:
                  </label>
                  <input
                    placeholder={t("edit_member_timetable")}
                    className="form-input"
                    name="email"
                    type="text"
                    value={membership_end + " " + t("months")}
                    readOnly
                    id="email"
                  />
                </div>
              </Fragment>
            )}
            <div className="form-block">
              <label>{t("edit_member_state")}:</label>
              <input
                placeholder={t("edit_member_add_state")}
                className={"form-input" + (error.state ? " input-error" : "")}
                name="state"
                type="text"
                value={state}
                id="state"
                onChange={(e) => setState(e.target.value)}
              />
              {error.state && <ValidationMessage message={error.state[0]} />}
            </div>
            <div className="form-block">
              <label>{t("edit_member_city")}:</label>
              <input
                placeholder={t("edit_member_add_city")}
                className={"form-input" + (error.city ? " input-error" : "")}
                name="city"
                type="text"
                value={city}
                id="city"
                onChange={(e) => setCity(e.target.value)}
              />
              {error.city && <ValidationMessage message={error.city[0]} />}
            </div>
            <div className="form-block">
              <label>{t("edit_member_address")}:</label>
              <input
                placeholder={t("edit_member_add_address")}
                className={"form-input" + (error.address ? " input-error" : "")}
                name="address"
                type="text"
                value={address}
                id="address"
                onChange={(e) => setAddress(e.target.value)}
              />
              {error.address && (
                <ValidationMessage message={error.address[0]} />
              )}
            </div>
            {!membership_start && (
              <Fragment>
                <div className="form-block">
                  <label htmlFor="postal_code">
                    {t("edit_member_postal_code")}:
                  </label>
                  <input
                    placeholder={t("edit_member_add_postal_code")}
                    className={
                      "form-input" + (error.postal_code ? " input-error" : "")
                    }
                    name="postal_code"
                    type="number"
                    value={postal_code}
                    onChange={(e) => setPostal_code(e.target.value)}
                  />
                  {error.postal_code && (
                    <ValidationMessage message={error.postal_code[0]} />
                  )}
                </div>
                <div className="form-block">
                  <label htmlFor="phone">{t("edit_member_phone")}:</label>
                  <input
                    placeholder={t("edit_member_add_phone_number")}
                    className={
                      "form-input" + (error.phone ? " input-error" : "")
                    }
                    name="phone"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {error.phone && (
                    <ValidationMessage message={error.phone[0]} />
                  )}
                </div>
              </Fragment>
            )}
          </div>
          <div className="col-12 col-md-4 d-flex flex-column col-form">
            {membership_start && (
              <Fragment>
                <div className="form-block">
                  <label htmlFor="postal_code">
                    {t("edit_member_postal_code")}:
                  </label>
                  <input
                    placeholder={t("edit_member_add_postal_code")}
                    className={
                      "form-input" + (error.postal_code ? " input-error" : "")
                    }
                    name="postal_code"
                    type="number"
                    value={postal_code}
                    onChange={(e) => setPostal_code(e.target.value)}
                  />
                  {error.postal_code && (
                    <ValidationMessage message={error.postal_code[0]} />
                  )}
                </div>
                <div className="form-block">
                  <label htmlFor="phone">{t("edit_member_phone")}:</label>
                  <input
                    placeholder={t("edit_member_add_phone_number")}
                    className={
                      "form-input" + (error.phone ? " input-error" : "")
                    }
                    name="phone"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {error.phone && (
                    <ValidationMessage message={error.phone[0]} />
                  )}
                </div>
              </Fragment>
            )}
            <div className="form-block d-block">
              <label htmlFor="gender" className="main-label mb-2">
                {t("edit_member_gender")}:
              </label>
              <div className="radio-buttons d-flex">
                <div className="radio-button mr-2 me-3">
                  <label htmlFor="mashkull">{t("edit_member_male")}</label>
                  <input
                    className="form-check-input"
                    id="mashkull"
                    name="gender"
                    type="radio"
                    value="M"
                    checked={gender === "M"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                <div className="radio-button ml-3">
                  <label htmlFor="femer">{t("edit_member_female")}</label>
                  <input
                    className="form-check-input"
                    id="femer"
                    name="gender"
                    type="radio"
                    value="F"
                    checked={gender === "F"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                {error.gender && (
                  <ValidationMessage message={error.gender[0]} />
                )}
              </div>
            </div>
            <div className="form-block mt-auto">
              {btnLoading ? (
                <button className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("edit_member_changing")}
                </button>
              ) : (
                <button className="button-blue has-icon">
                  {t("edit_member_change")}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
      <br />
      <br />
      {membership_start && (
        <Fragment>
          <div
            style={{ borderTop: 2 + "px solid #1f54c5" }}
            className="invite-member"
          >
            <p className="partial-title mb-4 mt-4 pt-4">
              {t("edit_member_postpone_reservation")}
            </p>

            <form onSubmit={(e) => changeMembership(e)}>
              {membership_status === 1 && (
                <span className="ml-3">
                  {t("edit_member_membership_message")}
                </span>
              )}
              <div className="col-12 col-form d-flex align-items-end mt-3">
                <div className="form-block d-block">
                  <label className="main-label mb-3">
                    {t("edit_member_end_date")}:
                  </label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button me-4">
                      <label htmlFor="three_months">
                        {t("edit_member_three_months")}
                      </label>
                      <input
                        className="form-check-input"
                        name="membership_end"
                        type="radio"
                        onChange={(e) => setMembership_end(e.target.value)}
                        value="3"
                        id="three_months"
                      />
                    </div>
                    <div className="radio-button me-4 ms-4">
                      <label htmlFor="six_months">
                        {t("edit_member_six_months")}
                      </label>
                      <input
                        className="form-check-input"
                        name="membership_end"
                        type="radio"
                        onChange={(e) => setMembership_end(e.target.value)}
                        value="6"
                        id="six_months"
                      />
                    </div>
                    <div className="radio-button me-4 ms-4">
                      <label htmlFor="nine_months">
                        {t("edit_member_nine_months")}
                      </label>
                      <input
                        className="form-check-input"
                        name="membership_end"
                        type="radio"
                        onChange={(e) => setMembership_end(e.target.value)}
                        value="9"
                        id="nine_months"
                      />
                    </div>
                    <div className="radio-button me-4 ms-4">
                      <label htmlFor="twelve_months">
                        {t("edit_member_twelve_months")}
                      </label>
                      <input
                        className="form-check-input"
                        name="membership_end"
                        type="radio"
                        onChange={(e) => setMembership_end(e.target.value)}
                        value="12"
                        id="twelve_months"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-block ms-5">
                  {btnChangeLoading ? (
                    <button type="button" className="button-blue has-icon">
                      <i className="icon spinner-icon me-2" />
                      {t("edit_member_approving")}
                    </button>
                  ) : (
                    <button type="submit" className="button-blue has-icon">
                      {t("edit_member_postpone_membership")}
                    </button>
                  )}
                </div>
                {error.membership_end && (
                  <ValidationMessage message={error.membership_end[0]} />
                )}
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditMember;
