import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import sq from "date-fns/locale/sq";
import moment from "moment";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import Loading from "../../../helpers/Loading";
import ValidationMessage from "../../../helpers/ValidationMessage";
import CustomPagination from "../../../helpers/CustomPagination";
import ReactSelect from "react-select";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";

const IndexLogs = () => {
  const { t } = useTranslation();

  const [listLogs, setListLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [subject, setSubject] = useState("");
  const [action, setAction] = useState("");
  const [object, setObject] = useState("");
  const [value, setValue] = useState("");
  const [object_id, setObject_id] = useState("");
  const [from, setFrom] = useState({ date: "", value: "" });
  const [to, setTo] = useState({ date: "", value: "" });
  const [error, setError] = useState({});
  const [activePage, setActivePage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);

  const [actionOptions, setActionOptions] = useState([]);
  const [objectOptions, setObjectOptions] = useState([]);

  var date = new Date();
  date.setFullYear(date.getFullYear());

  const handleChangeDate = (e, date_type) => {
    if (date_type === "to") {
      setTo({
        date: e ? moment(e).format("DD-MM-yyyy") : "",
        value: e,
      });
    } else if (date_type === "from") {
      setFrom({
        date: e ? moment(e).format("DD-MM-yyyy") : "",
        value: e,
      });
    }
  };

  const listsLogs = (hasSearch) => {
    let request = ApiUrl.listlogs;
    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        subject: subject,
        action: action,
        object: object,
        value: value,
        object_id: object_id,
        from: from.date,
        to: to.date,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        subject: subject,
        action: action,
        object: object,
        value: value,
        object_id: object_id,
        from: from.date,
        to: to.date,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((result) => {
      setBtnLoading(false);
      if (result.status === 200) {
        setPagination(result.data.logs);
        setListLogs(result.data.logs.data);
        setLoading(false);
        setError({});
      } else if (result.status === 422) {
        setError(result.data);
      } else {
        setListLogs([]);
        setLoading(true);
      }
    });
  };

  useEffect(() => {
    listsLogs();
  }, [activePage, searched]);

  const actionsList = () => {
    Request.getRequest(ApiUrl.logActionsObjects).then((response) => {
      if (response.status === 200) {
        setActionOptions(response.data.log_actions);
        setObjectOptions(response.data.log_objects);
      }
    });
  };

  useEffect(() => {
    actionsList();
  }, []);

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("index_logs_title")}</p>
      <form onSubmit={(e) => listsLogs(e)}>
        <div className="row">
          <div className="form-block col-md-3">
            <label>{t("index_logs_subject")}</label>
            <input
              placeholder={t("index_logs_write_the_subject")}
              name="subject"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              type="text"
              className={"form-input" + (error.subject ? " input-error" : "")}
            />
            {error.subject && <ValidationMessage message={error.subject} />}
          </div>
          <div className="form-block col-md-3">
            <label>{t("index_logs_action")}</label>
            <ReactSelect
              className={
                "form-react-select" + (error.action ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="action"
              placeholder={t("index_logs_write_the_action")}
              isClearable={true}
              onChange={(e) => setAction(e ? e.value : "")}
              isSearchable={false}
              options={actionOptions.map((item) => ({
                value: item.name,
                label: item.name_trans,
              }))}
            />
            {error.action && <ValidationMessage message={error.action[0]} />}
          </div>
          <div className="form-block col-md-3">
            <label>{t("index_logs_object")}</label>
            <ReactSelect
              className={
                "form-react-select" + (error.object ? " input-error" : "")
              }
              classNamePrefix="select_input"
              name="object"
              placeholder={t("index_logs_write_the_action")}
              isClearable={true}
              onChange={(e) => setObject(e ? e.value : "")}
              isSearchable={false}
              options={objectOptions.map((item) => ({
                value: item.name,
                label: item.name_trans,
              }))}
            />
            {error.object && <ValidationMessage message={error.object} />}
          </div>
          <div className="form-block col-md-3">
            <label>{t("index_logs_value")}</label>
            <input
              placeholder={t("index_logs_write_the_value")}
              name="value"
              onChange={(e) => setValue(e.target.value)}
              type="text"
              value={value}
              className={"form-input" + (error.value ? " input-error" : "")}
            />
            {error.value && <ValidationMessage message={error.value} />}
          </div>
          <div className="form-block col-md-4">
            <label>{t("index_logs_object_id")}</label>
            <input
              type="number"
              placeholder={t("index_logs_write_the_objectId")}
              name="object_id"
              onChange={(e) => setObject_id(e.target.value)}
              value={object_id}
              className={"form-input" + (error.object_id ? " input-error" : "")}
            />
            {error.object_id && <ValidationMessage message={error.object_id} />}
          </div>
          <div className="form-block col-md-4">
            <label>{t("index_logs_date_from")}</label>
            <DatePicker
              placeholderText={t("index_logs_choose_date")}
              name="from"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale={sq}
              dateFormat="dd-MM-yyyy"
              selected={from.value}
              onChange={(e) => handleChangeDate(e, "from")}
              autoComplete="off"
              className={"date_input" + (error.from ? " input-error" : "")}
            />

            {error.from && <ValidationMessage message={error.from} />}
          </div>
          <div className="form-block col-md-4">
            <label>{t("index_logs_date_to")}</label>
            <DatePicker
              placeholderText={t("index_logs_choose_date")}
              name="to"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale={sq}
              dateFormat="dd-MM-yyyy"
              selected={to.value}
              onChange={(e) => handleChangeDate(e, "to")}
              autoComplete="off"
              className={"date_input" + (error.to ? " input-error" : "")}
            />
            {error.to && <ValidationMessage message={error.to} />}
          </div>
        </div>
        <div className="form-block col-12 col-md-6 col-lg-2 p-0">
          {btnLoading ? (
            <button type="button" className="button-blue has-icon">
              <i className="icon spinner-icon me-2" />
              {t("index_logs_filtering")}
            </button>
          ) : (
            <button
              type="submit"
              className="button-blue has-icon"
              onClick={() => {
                setSearched(true);
                setActivePage("1");
              }}
            >
              <i className="icon search-icon me-2" />
              {t("index_logs_filter")}
            </button>
          )}
        </div>
      </form>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th width="20%" role="columnheader" className="text-left">
                {t("index_logs_subject")}
              </th>
              <th width="10%" role="columnheader" className="text-center">
                {t("index_logs_action")}
              </th>
              <th width="10%" role="columnheader" className="text-center">
                {t("index_logs_object")}
              </th>
              <th width="35%" role="columnheader" className="text-center">
                {t("index_logs_value")}
              </th>
              <th width="10%" role="columnheader" className="text-center">
                {t("index_logs_object_id")}
              </th>
              <th width="15%" role="columnheader" className="text-center">
                {t("index_logs_date")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="6">
                  <Loading />
                </td>
              </tr>
            ) : (
              listLogs.map((log, index) => (
                <tr key={index}>
                  <td
                    role="cell"
                    className="text-left"
                    data-label={t("index_logs_subject")}
                  >
                    {log.subject}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("index_logs_action")}
                  >
                    {log.action_trans}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("index_logs_object")}
                  >
                    {log.object_trans}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("index_logs_value")}
                  >
                    {log.value}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("index_logs_object_id")}
                  >
                    {log.object_id}
                  </td>
                  <td
                    role="cell"
                    className="text-center"
                    data-label={t("index_logs_date")}
                  >
                    {moment(log.created_at).format("DD MMM, YYYY")}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(error).length > 0 && setSearched(false);
        }}
      />
    </Fragment>
  );
};

export default IndexLogs;
