import { t } from "i18next";
import Swal from "sweetalert2";

import confirmDeleteIcon from "../assets/images/icons/confirm_delete_icon.svg";

export const deletePopup = (itemDesc) => {
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    text: `${itemDesc ? itemDesc : ""}`,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};
