import { Navigate } from "react-router-dom";

import ActivateAccountView from "../views/authentication/ActivateAccountView";
import ForgotPasswordView from "../views/authentication/ForgotPasswordView";
import LoginView from "../views/authentication/LoginView";
import ResetPasswordView from "../views/authentication/ResetPasswordView";
import AcquisitionListView from "../views/panel/acquisition/AcquisitionListView";
import AddAcquisitionView from "../views/panel/acquisition/AddAcquisitionView";
import EditAcquisitionView from "../views/panel/acquisition/EditAcquisitionView";
import ShowAcquisitionView from "../views/panel/acquisition/ShowAcquisitionView";
import BackupView from "../views/panel/backup/BackupView";
import LogsView from "../views/panel/backup/LogsView";
import CreatePostView from "../views/panel/blog/CreatePostView";
import EditPostView from "../views/panel/blog/EditPostView";
import ListPostsView from "../views/panel/blog/ListPostsView";
import BorrowingListView from "../views/panel/borrowing/BorrowingListView";
import BorrowView from "../views/panel/borrowing/BorrowView";
import ConfirmationItemView from "../views/panel/borrowing/ConfirmationItemView";
import BorrowDetailsView from "../views/panel/borrowing/DetailsView";
import ListPendingView from "../views/panel/borrowing/ListPendingView";
import ReservationsView from "../views/panel/borrowing/ReservationsView";
import SearchItemView from "../views/panel/borrowing/SearchItemView";
import SearchMemberView from "../views/panel/borrowing/SearchMemberView";
import BiblioitemsView from "../views/panel/catalog/bibliography/BiblioitemsView";
import AuthorView from "../views/panel/catalog/author/AuthorView";
import CreateBiblioitemView from "../views/panel/catalog/bibliography/create/CreateBiblioitemView";
import CreateBiblioView from "../views/panel/catalog/bibliography/create/CreateBiblioView";
import GeographicalTermView from "../views/panel/catalog/geographical_term/GeographicalTermView";
import CreateItemView from "../views/panel/catalog/bibliography/create/CreateItemView";
import KeywordView from "../views/panel/catalog/keyword/KeywordView";
import PublisherView from "../views/panel/catalog/publisher/PublisherView";
import EditBiblioitemView from "../views/panel/catalog/bibliography/create/EditBiblioitemView";
import EditBibliographyView from "../views/panel/catalog/bibliography/EditBibliographyView";
import EditBiblioView from "../views/panel/catalog/bibliography/create/EditBiblioView";
import EditItemsView from "../views/panel/catalog/bibliography/create/EditItemsView";
import ConfirmMarcView from "../views/panel/catalog/marc/ConfirmMarcView";
import ExportMarcView from "../views/panel/catalog/marc/ExportView";
import ImportMarcView from "../views/panel/catalog/marc/ImportView";
import ShowBiblioitemView from "../views/panel/catalog/bibliography/ShowBiblioitemView";
import ConfirmZ3950View from "../views/panel/catalog/z3950/ConfirmZ3950View";
import ServersListView from "../views/panel/catalog/z3950/ServersListView";
import ServicesListView from "../views/panel/catalog/z3950/ServicesListView";
import CreateMemberView from "../views/panel/member/CreateMemberView";
import EditMemberView from "../views/panel/member/EditMemberView";
import ListMemberView from "../views/panel/member/ListMemberView";
import ShowMemberView from "../views/panel/member/ShowMemberView";
import ReportBorrowingView from "../views/panel/report/BorrowingView";
import ReportCatalogView from "../views/panel/report/CatalogView";
import ReportStatsView from "../views/panel/report/StatsView";
import CreateSerialView from "../views/panel/serial/CreateSerialView";
import EditSerialView from "../views/panel/serial/EditSerialView";
import FrequenceView from "../views/panel/serial/FrequenceView";
import ListSerialView from "../views/panel/serial/ListSerialView";
import ShowSerialView from "../views/panel/serial/ShowSerialView";
import AddStaffView from "../views/panel/staff/CreateStaffView";
import EditStaffView from "../views/panel/staff/EditStaffView";
import ListStaffView from "../views/panel/staff/ListStaffView";
import ShowStaffView from "../views/panel/staff/ShowStaffView";
import ChangePasswordView from "../views/panel/password/ChangePasswordView";

import * as Path from "./GeneralPaths";
import Page404View from "../views/utility/Page404View";
import ProtectedRoute from "./ProtectedRoute";
import { isLogged } from "../helpers/AppConfig";

const privateRoutes = (role) => {
  return [
    {
      path: Path.dashboard,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute
          roles={[
            "administrator",
            "operator",
            "student",
            "academic_staff",
            "personality",
            "it",
            "librarian",
          ]}
        >
          {role === "administrator" && <Navigate to={Path.listBiblioitems} />}
          {role === "operator" && <Navigate to={Path.listBiblioitems} />}
          {role === "student" && window.location.replace(Path.nextProfile)}
          {role === "academic_staff" &&
            window.location.replace(Path.nextProfile)}
          {role === "personality" && window.location.replace(Path.nextProfile)}
          {role === "it" && <Navigate to={Path.listBackup} />}
          {role === "librarian" && <Navigate to={Path.reportStats} />}
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listBiblioitems,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <BiblioitemsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.showBiblioitemID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ShowBiblioitemView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editBiblioitemID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <EditBibliographyView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createBiblio,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <CreateBiblioView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createBiblioItemID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <CreateBiblioitemView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createItemID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <CreateItemView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editBiblioID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <EditBiblioView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editBiblioIdBiblioitem,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <EditBiblioView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editBiblioitemonlyID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <EditBiblioitemView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editItemsID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <EditItemsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.importMarc,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator"]}>
          <ImportMarcView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.confirmMarc,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator"]}>
          <ConfirmMarcView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.exportMarc,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator"]}>
          <ExportMarcView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.z3950Server,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "it"]}>
          <ServersListView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.confirmZ3950,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator", "librarian"]}>
          <ConfirmZ3950View />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.z3950Service,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator", "librarian"]}>
          <ServicesListView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createAuthor,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <AuthorView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createPublisher,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <PublisherView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createKeyWords,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <KeywordView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createGeogreaphicalTerm,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <GeographicalTermView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.borrow,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <BorrowView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.searchMemberID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <SearchMemberView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.searchMaterialID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <SearchItemView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.confirmBorrowId,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ConfirmationItemView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listBorrowings,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <BorrowingListView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listPending,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ListPendingView />
        </ProtectedRoute>
      ),
    },

    {
      path: Path.reservations,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ReservationsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.borrowingDetailsID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <BorrowDetailsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createSerial,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <CreateSerialView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listSerials,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ListSerialView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.showSerialID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ShowSerialView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.frequence,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <FrequenceView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editSerialID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <EditSerialView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listMembers,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ListMemberView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.addMember,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <CreateMemberView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.showMemberID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ShowMemberView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editMemberID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <EditMemberView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listStaff,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator"]}>
          <ListStaffView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.addStaff,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator"]}>
          <AddStaffView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.showStaffID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator"]}>
          <ShowStaffView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editStaffID,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator"]}>
          <EditStaffView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listAcquisition,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator"]}>
          <AcquisitionListView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createAcquisition,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <AddAcquisitionView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.showAcquisitionId,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <ShowAcquisitionView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editAcquisitionId,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "operator"]}>
          <EditAcquisitionView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.reportStats,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "librarian"]}>
          <ReportStatsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.reportCatalog,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "librarian"]}>
          <ReportCatalogView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.reportBorrowing,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "librarian"]}>
          <ReportBorrowingView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listBackup,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "it"]}>
          <BackupView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.logs,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "it"]}>
          <LogsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.listBlogPosts,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "it", "operator"]}>
          <ListPostsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.editBlogPostId,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "it", "operator"]}>
          <EditPostView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.createBlogPost,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "it", "operator"]}>
          <CreatePostView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.changePassword,
      element: !isLogged() ? (
        <Navigate to={Path.login} />
      ) : (
        <ProtectedRoute roles={["administrator", "it", "operator"]}>
          <ChangePasswordView />
        </ProtectedRoute>
      ),
    },
  ];
};

const publicRoutes = () => {
  var privateRoutes = [];
  privateRoutes.splice(0, 5);

  let publicArr = [
    {
      path: Path.login,
      element: isLogged() ? <Navigate to={Path.dashboard} /> : <LoginView />,
    },
    {
      path: Path.forgotPassword,
      element: isLogged() ? (
        <Navigate to={Path.dashboard} />
      ) : (
        <ForgotPasswordView />
      ),
    },
    {
      path: Path.resetPasswordParam,
      element: isLogged() ? (
        <Navigate to={Path.dashboard} />
      ) : (
        <ResetPasswordView />
      ),
    },
    {
      path: Path.ativateAccountParam,
      element: isLogged() ? (
        <Navigate to={Path.dashboard} />
      ) : (
        <ActivateAccountView />
      ),
    },
  ];

  var publicRoutes = publicArr.map((item) => ({
    path: item.path,
    element: item.element,
  }));

  let allRoutesArr = [...privateRoutes, ...publicRoutes];
  return allRoutesArr;
};

const helperRoutes = [{ path: Path.pageError404, element: <Page404View /> }];

export { privateRoutes, publicRoutes, helperRoutes };
