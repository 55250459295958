import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Sidebar from "../../../../components/layouts/sidebar/Sidebar";
import GeographicalTermsList from "../../../../components/panel/catalog/geographical_term/GeographicalTermsList";

const GeographicalTermView = () => {
  const { t } = useTranslation("");
  return (
    <Fragment>
      <Helmet>
        <title>{t("geographicalterm_list_tab")}</title>
      </Helmet>
      <Sidebar>
        <GeographicalTermsList />
      </Sidebar>
    </Fragment>
  );
};

export default GeographicalTermView;
