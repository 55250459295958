import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import StaffList from "../../../components/panel/staff/StaffList";

const ListStaffView = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>{t("list_of_staff_views")}</title>
      </Helmet>
      <Sidebar>
        <StaffList />
      </Sidebar>
    </Fragment>
  );
};

export default ListStaffView;
