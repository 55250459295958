import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SearchFilter from "../../../../helpers/SearchFilter";
import ListResponse from "../../../../helpers/ListResponse";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Tooltip from "../../../../helpers/Tooltip";

const Borrowings = () => {
  const { t } = useTranslation();

  const [listBiblioitem, setListBiblioitem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnAddLoading, setBtnAddLoading] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listBiblioitems();
  }, [activePage]);

  const listBiblioitems = () => {
    let request = ApiUrl.reportBorrowingNow;
    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
      request = SearchFilter(searchParams, request);
    }

    Request.getRequest(request).then((result) => {
      if (result.status === 200) {
        setListBiblioitem(result.data.items.data);
        setLoading(false);
        setPagination(result.data.items);
      } else {
        setListBiblioitem([]);
        setLoading(true);
      }
    });
  };

  const download = (e) => {
    e.preventDefault();
    setBtnAddLoading(true);
    let request = ApiUrl.reportExportBorrowingNow;
    const link = document.createElement("a");
    Request.getRequestForDownloadExcel(request).then((response) => {
      setBtnAddLoading(false);
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", "Report_Borrowings_Now.xlsx");
      link.click();
    });
  };

  return (
    <Fragment>
      <div className="ml-1 pt-3 mt-4 mb-4 pb-1">
        {btnAddLoading === true ? (
          <button className="button-blue has-icon mt-0 mb-3 mr-5" type="button">
            <i className="icon spinner-icon me-2" />
            {t("borrowing_exporting")}
          </button>
        ) : (
          <button
            className="button-blue has-icon ml-0 mt-0 mb-3 mr-5"
            type="button"
            onClick={(e) => download(e)}
          >
            <i className="icon download-icon me-2" />
            {t("borrowing_export")}
          </button>
        )}
      </div>
      <div className="w-100 mb-5">
        <div className="table-list">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th width="25%" role="columnheader" className="text-left">
                  {t("borrowing_title")}
                </th>
                <th width="20%" role="columnheader" className="text-center">
                  {t("borrowing_author")}
                </th>
                <th width="15%" role="columnheader" className="text-center">
                  {t("borrowing_member")}
                </th>
                <th width="15%" role="columnheader" className="text-center">
                  {t("borrowing_inventary_number")}
                </th>
                <th width="10%" role="columnheader" className="text-center">
                  {t("borrowing_late")}
                </th>
                <th width="15%" role="columnheader" className="text-center">
                  {t("borrowing_day_of_return")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="6">
                    <Loading />
                  </td>
                </tr>
              ) : (
                listBiblioitem.map((item, index) => (
                  <tr key={item.id}>
                    <td
                      role="cell"
                      data-label={t("borrowing_title")}
                      className="title-borrowing"
                    >
                      {Tooltip.tooltipDisabledMobile(
                        "-title-" + index,
                        item.biblioitem.biblio.title
                      )}
                    </td>
                    <td
                      role="cell"
                      data-label={t("borrowing_author")}
                      className="text-center"
                    >
                      {item.biblioitem.biblio["authors"].map(
                        (item, index, arr) => {
                          if (index === arr.length - 1) {
                            return item["name"];
                          } else {
                            return item["name"] + "; ";
                          }
                        }
                      )}
                    </td>
                    <td
                      role="cell"
                      data-label={t("borrowing_member")}
                      className="text-center"
                    >
                      {item.borrowings[0].user_with_trashed.first_name}{" "}
                      {item.borrowings[0].user_with_trashed.last_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("borrowing_inventary_number")}
                      className=" text-center"
                    >
                      {item.inventory_number + " / " + item.id}
                    </td>
                    <td
                      role="cell"
                      data-label={t("borrowing_late")}
                      className="text-center"
                    >
                      {new Date() >=
                      new Date(item.borrowings[0].should_return_on)
                        ? t("yes")
                        : t("no")}
                    </td>
                    <td
                      role="cell"
                      data-label={t("borrowing_day_of_return")}
                      className="text-center"
                    >
                      {moment(item.borrowings[0].should_return_on).format(
                        "DD-MM-yyyy"
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={false}
          />
        </div>
        <CustomPagination
          data={pagination}
          setActivePage={(e) => setActivePage(e)}
        />
      </div>
    </Fragment>
  );
};

export default Borrowings;
