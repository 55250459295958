import create from "zustand";

const bibliographyData = {};

export const Bibliography = create((set) => ({
  bibliographyData,
  addBibliographyData: (data) =>
    set(() => ({
      bibliographyData: {
        title: data?.biblio?.title ? data.biblio.title : "",
        authors: data?.biblio?.authors ? data.biblio.authors : [],
        medium: data?.biblio?.medium ? data.biblio.medium : "",
        subtitle: data?.biblio?.subtitle ? data.biblio.subtitle : "",
        materialtype_id: data?.biblio?.materialtype
          ? data.biblio.materialtype
          : "",
        part_name: data?.biblio?.part_name ? data.biblio.part_name : "",
        part_number: data?.biblio?.part_number ? data.biblio.part_number : "",
        biblio_language_id: data?.biblio?.language ? data.biblio.language : "",
        agerestriction: data?.biblio?.agerestriction
          ? data.biblio.agerestriction
          : "",
        year_original_publication: data?.biblio?.year_original_publication
          ? data.biblio.year_original_publication
          : "",
        mediatype_id: data?.biblio?.mediatype ? data.biblio.mediatype : "",
        keywords: data?.biblio?.keywords ? data.biblio.keywords : [],
        geoterms: data?.biblio?.geographicalterms
          ? data.biblio.geographicalterms
          : [],
        notes: data?.biblio?.notes ? data.biblio.notes : "",
        abstract: data?.biblio?.abstract ? data.biblio.abstract : "",

        isbn: data?.isbn ? data.isbn : "",
        issn: data?.issn ? data.issn : "",
        publication_year: data?.publication_year ? data.publication_year : "",
        place: data?.place ? data.place : "",
        publisher_id: data?.publisher ? data.publisher : "",
        publication_number: data?.publication_number
          ? data.publication_number
          : "",
        contributors: data?.contributors ? data?.contributors : [],
        physical_details: data?.physical_details ? data.physical_details : "",
        pages: data?.pages ? data.pages : "",
        dimensions: data?.dimensions ? data.dimensions : "",
        lccn: data?.lccn ? data.lccn : "",
        url: data?.url ? data.url : "",
        urlNew: "",
        deleteUrl: 0,
        kdu: data?.kdu ? data.kdu : "",
        biblioitem_language_id: data?.language ? data.language : "",
        national_control_number: data?.national_control_number
          ? data.national_control_number
          : "",
        barcode: data?.barcode ? data.barcode : "",
        price: data?.price ? data.price : "",
        replacement_price: data?.replacement_price
          ? data.replacement_price
          : "",
        cover: data?.cover ? data.cover : "",
        coverValue: "",
        deleteCover: 0,
        currency: data?.currency ? data.currency : "",
        ean: data?.ean ? data.ean : "",
        biblio_id: data?.biblio?.id ? data.biblio?.id : "",
        items: data?.items ? data.items : [],
        newItems: [],
        deletedItems: [],
      },
    })),
  addBibliographyUpdate: (data) => set(() => ({ bibliographyData: data })),
  reset: () => set(() => ({  bibliographyData : {} })),
}));
