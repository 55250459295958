import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as AppConfig from "../../../helpers/AppConfig";
import moment from "moment";
import * as Message from "../../../helpers/Messages";

const ConfirmationItem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = useParams();
  const [member, setMember] = useState("");
  const [role, setRole] = useState("");
  const [material, setMaterial] = useState("");
  const [biblio, setBiblio] = useState("");
  const [today, setToday] = useState("");
  const [afterToday, setAfterToday] = useState("");
  const [itemType, setItemType] = useState("");
  const [user_id, setUser_Id] = useState("");
  const [item_id, setItem_Id] = useState("");
  const [should_return_on, setShould_return_on] = useState("");
  const [pending, setPending] = useState("1");

  useEffect(() => {
    details();
  }, []);

  const details = () => {
    const member_id = param.member_id;
    const item_id = param.item_id;

    Request.getRequest(
      ApiUrl.confirmBorrowing + member_id + "/item/" + item_id
    ).then((response) => {
      if (response.status === 200) {
        setMember(response.data.member);
        setRole(response.data.member.role);
        setMaterial(response.data.item);
        setBiblio(response.data.item.biblioitem.biblio);
        setToday(response.data.today);
        setAfterToday(response.data.afterToday);
        setItemType(response.data.item_type);
        setUser_Id(response.data.member.id);
        setItem_Id(response.data.item.id);
        setShould_return_on(response.data.afterToday);
        setPending("1");
      } else if (response.status === 404) {
        navigate(Path.listBorrowings);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const saveBorrowing = (e) => {
    e.preventDefault();
    var formData = {
      pending: pending,
      user_id: user_id,
      item_id: item_id,
      should_return_on: afterToday,
    };

    Request.postRequest(ApiUrl.saveBorrowing, formData).then((response) => {
      if (response.status === 200) {
        if (pending == 0) {
          navigate(Path.listBorrowings);
          Message.successMsg(response.data.success);
        } else if (pending == 1) {
          navigate(Path.listPending);
          Message.successMsg(response.data.success);
        }
      } else if (response.status === 405) {
        navigate(Path.listBorrowings);
        Message.infoMsg(response.data.info);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const memberGender = (param) => {
    switch (param) {
      case "M":
        return t("male");
      case "F":
        return t("female");
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <div id="show">
        <form method="POST" onSubmit={(e) => saveBorrowing(e)}>
          <p className="partial-title mb-5">{t("confirmation_item_borrow")}</p>
          <div className="row m-0 member-data member-details-bg mb-4">
            <div className="list-data col-12 col-lg-6 col-sm-12 d-flex">
              <ul>
                <li className="member-detail">
                  {t("confirmation_item_firstname_lastname_role")} :{" "}
                  {(member.first_name ? member.first_name : "-") +
                    " " +
                    (member.last_name ? member.last_name : "-") +
                    " / " +
                    (role.name ? role.name : "-")}
                </li>
                <li>
                  {t("confirmation_item_email")} : &nbsp;{" "}
                  {member.email ? member.email : "-"}
                </li>
                <li>
                  {t("confirmation_item_mobile")} : &nbsp;{" "}
                  {member.phone ? member.phone : "-"}
                </li>
                <li>
                  {t("confirmation_item_birthday")} : &nbsp;{" "}
                  {member.dob ? member.dob : "-"}
                </li>
                <li>
                  {t("confirmation_item_gender")} : &nbsp;{" "}
                  {member.gender ? memberGender(member.gender) : "-"}
                </li>
                <li>
                  {t("confirmation_item_identification_number")} :{" "}
                  {member.nid ? member.nid : "-"}
                </li>
                <li>
                  {t("confirmation_item_state_city")} : &nbsp;{" "}
                  {(member.state ? member.state : "-") +
                    " / " +
                    (member.city ? member.city : "-")}
                </li>
                <li>
                  {t("confirmation_item_address_postal_code")} : &nbsp;{" "}
                  {(member.address ? member.address : "-") +
                    " / " +
                    (member.postal_code ? member.postal_code : "-")}
                </li>
                <li>
                  {t("confirmation_item_start_date_end_date")} : &nbsp;{" "}
                  {(member.membership_start ? member.membership_start : "-") +
                    " / " +
                    (member.membership_end ? member.membership_end : "-")}
                </li>
                {member.role_id === 4 && (
                  <Fragment>
                    <li>
                      {t("confirmation_item_faculty")} :{" "}
                      {member.faculty ? member.faculty : "-"}
                    </li>
                    <li>
                      {t("confirmation_item_department")} :{" "}
                      {member.department ? member.department : "-"}
                    </li>
                    <li>
                      {t("confirmation_item_program")} :{" "}
                      {member.program ? member.program : "-"}
                    </li>
                    <li>
                      {t("confirmation_item_education_level")} :{" "}
                      {member.edulevel ? member.edulevel : "-"}
                    </li>
                    <li>
                      {t("confirmation_study_time")} :{" "}
                      {member.fullorparttime
                        ? member.fullorparttime === "fulltime"
                          ? t("confirmation_item_fulltime")
                          : t("confirmation_item_part_time")
                        : "-"}
                    </li>
                    <li>
                      {t("confirmation_item_year")} :{" "}
                      {member.year ? member.year : "-"}
                    </li>
                  </Fragment>
                )}
              </ul>
            </div>
            <div className="list-data col-12 col-md-6 d-flex">
              <ul>
                <li className="member-detail">
                  {t("confirmation_item_title")} : &nbsp;{" "}
                  {biblio.title ? biblio.title : "-"}
                </li>
                <li>
                  {t("confirmation_item_codification")} : &nbsp;{" "}
                  {material.inventory_number ? material.inventory_number : "-"}
                </li>
                <li>
                  {t("confirmation_item_inventary_number")} : &nbsp;{" "}
                  {material.id ? material.id : "-"}
                </li>
              </ul>
            </div>
          </div>
          <div className=" pt-5 huazo">
            <div className="row align-items-end">
              <div className="col-12 col-md-6 col-xl-3 order-xl-2 mb-4">
                <label htmlFor="created_at">
                  {t("confirmation_item_date_today")}
                </label>
                <div className="calendar-block">
                  <input
                    placeholder={t("confirmation_item_today_date")}
                    id="date-created"
                    className="textpicker form-input disable-input "
                    name="created_at"
                    type="text"
                    value={moment(today).format("d-mm-yyyy h:MM:ss")}
                    readOnly
                  />
                  <i className="input-container" />
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3 order-xl-3 mb-4">
                <label htmlFor="should_return_on">
                  {t("confirmation_item_delivery_date")}
                </label>
                <div className="calendar-block">
                  <input
                    placeholder={t("confirmation_item_date_of_delivery")}
                    id="date-return"
                    className="textpicker form-input disable-input"
                    name="should_return_on"
                    type="text"
                    value={
                      itemType === "library"
                        ? moment(afterToday).format("d-mm-yyyy")
                        : moment(afterToday).format("d-mm-yyyy h:MM:ss")
                    }
                    readOnly
                  />
                  <i className="input-container" />
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3 order-xl-1 mb-4">
                <input type="hidden" name="user_id" value="10" />
                <input type="hidden" name="item_id" value="1" />
                <div className="radio-buttons borrow flex-column">
                  <div className="radio-button mr-2 mb-2">
                    <input
                      className="form-check-input"
                      id="pending"
                      name="pending"
                      type="radio"
                      value="1"
                      defaultChecked
                      onChange={(e) => setPending(e.target.value)}
                    />
                    <label htmlFor="pending">
                      {t("confirmation_item_borrow_later")}
                    </label>
                  </div>
                  <div className="radio-button">
                    <input
                      className="form-check-input"
                      id="paid_now"
                      name="pending"
                      type="radio"
                      value="0"
                      onChange={(e) => setPending(e.target.value)}
                    />
                    <label htmlFor="paid_now">
                      {t("confirmation_item_borrow_now")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-3 order-xl-4 m-auto">
                <input
                  className="button-blue-borrow"
                  name="hua"
                  type="submit"
                  value={t("confirmation_item_give_borrowing")}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default ConfirmationItem;
