import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Bibliography } from "../../../../../store/Catalog/CreateBibliography/CreateBibliography";
import { Biblioitem } from "../../../../../store/Catalog/CreateBibliography/CreateBiblioitem";
import { CheckContributorsType } from "../../../../../helpers/CheckValue";
import { biblioCoversPath } from "../../../../../helpers/AppConfig";

import ReactSelect from "react-select";
import ValidationMessage from "../../../../../helpers/ValidationMessage";
import ContributorsModal from "./Modals/ContributorsModal";
import Loading from "../../../../../helpers/Loading";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api//ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as Path from "../../../../../routes/GeneralPaths";

const EditBiblioitem = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const { biblio_id, id } = useParams();

  const resetBibliography = Bibliography((state) => state.reset);
  const addBibliographyUpdate = Bibliography(
    (state) => state.addBibliographyUpdate
  );
  const bibliographyData = Bibliography((state) => state.bibliographyData);

  const resetBiblioitem = Biblioitem((state) => state.reset);
  const addBiblioitemUpdate = Biblioitem((state) => state.addBiblioitemUpdate);
  const addBiblioitemData = Biblioitem((state) => state.addBiblioitemData);
  const biblioitemData = Biblioitem((state) => state.biblioitemData);

  // Geoterm modal
  const [contributorModal, setContributorModal] = useState(false);

  const [errorList, setErrorList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    resetBibliography();
    resetBiblioitem();
    getBiblioitemData();
  }, []);

  const getBiblioitemData = () => {
    Request.getRequest(ApiUrl.editBiblioitemOnly + biblio_id + "/" + id).then(
      (response) => {
        if (response.status === 200) {
          addBiblioitemData(response?.data?.biblioitem);
          addBibliographyUpdate({
            ...bibliographyData,
            languages: response.data.languages,
            contributors: response.data.contributors,
            publishers: response.data.publishers,
          });
          setLoading(false);
        } else if (response.status === 404) {
          navigate(-1);
          Message.errorMsg(response.data.error);
        }
      }
    );
  };

  const removeContributor = (contributor) => {
    const removeIndex = biblioitemData?.contributors.findIndex(
      (item) => item.id === contributor.id
    );
    biblioitemData?.contributors.splice(removeIndex, 1);
    addBiblioitemUpdate({
      ...biblioitemData,
      contributors: biblioitemData?.contributors,
    });
  };

  const createBiblioitem = (e, nav) => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();

    formData.append("biblio_id", biblio_id);
    formData.append(nav, 1);
    formData.append("barcode", biblioitemData.barcode);
    formData.append("cover", biblioitemData.coverValue);
    if (biblioitemData.deleteCover === 1) {
      formData.append("delete_cover", 1);
    }
    formData.append("currency", biblioitemData.currency);
    formData.append("dimensions", biblioitemData.dimensions);
    formData.append("ean", biblioitemData.ean);
    formData.append("isbn", biblioitemData.isbn);
    formData.append("issn", biblioitemData.issn);
    formData.append("kdu", biblioitemData.kdu);
    formData.append(
      "biblioitem_language_id",
      biblioitemData?.language_id ? biblioitemData.language_id.id : ""
    );
    formData.append("lccn", biblioitemData.lccn);
    formData.append(
      "national_control_number",
      biblioitemData.national_control_number
    );
    formData.append("pages", biblioitemData.pages);
    formData.append("physical_details", biblioitemData.physical_details);
    formData.append("place", biblioitemData.place);
    formData.append("price", biblioitemData.price);
    formData.append("publication_number", biblioitemData.publication_number);
    formData.append("publication_year", biblioitemData.publication_year);
    formData.append(
      "publisher_id",
      biblioitemData?.publisher_id ? biblioitemData.publisher_id.id : ""
    );
    formData.append("replacement_price", biblioitemData.replacement_price);
    formData.append("url", biblioitemData.urlNew);
    if (biblioitemData.deleteUrl === 1) {
      formData.append("delete_url", 1);
    }
    biblioitemData.contributors.forEach((contributor, index) => {
      formData.append("contributors[" + index + "][author_id]", contributor.id);
      formData.append(
        "contributors[" + index + "][type]",
        contributor.pivot.type
      );
    });

    formData.append("_method", "PATCH");

    Request.postRequest(ApiUrl.updateBiblioitemonly + id, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          setErrorList([]);
          if (nav === "back") {
            navigate(Path.editBiblio + biblio_id + "/" + id);
            Message.successMsg(response.data.success);
          } else {
            if (response.data.nr_of_items === 0) {
              navigate(Path.createItem + id);
              Message.successMsg(response.data.success);
            } else {
              navigate(Path.editItems + id);
              Message.successMsg(response.data.success);
            }
          }
        } else if (response.status === 404) {
          navigate(-1);
          Message.errorMsg(response.data.error);
        } else if (response.status === 422) {
          setErrorList(response.data);
          if (response.data.error) {
            Message.errorMsg(response.data.error);
          }
        }
      }
    );
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between gap-2 mb-5">
        <p className="partial-title">
          {t("create_bibliography_edit_biblioitem_title")}
        </p>
        <div className="navigation-bibliography">
          <span
            className="left-arrow"
            onClick={(e) =>
              !btnLoading ? createBiblioitem(e, "back") : e.preventDefault()
            }
          >
            <i className="icon left-arrow-icon" />
          </span>
          <span className="current-step">2/3</span>
          <span
            className="right-arrow"
            onClick={(e) =>
              !btnLoading ? createBiblioitem(e, "forward") : e.preventDefault()
            }
          >
            <i className="icon right-arrow-icon" />
          </span>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="row">
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_biblioitem_isbn")}
                <span className="biblioitem-tag">(020/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_isbn_placeholder"
                )}
                className={
                  "form-input" + (errorList.isbn ? " input-error" : "")
                }
                name="isbn"
                value={biblioitemData.isbn}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    isbn: e.target.value,
                  })
                }
              />
              {errorList.isbn && (
                <ValidationMessage message={errorList.isbn[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_biblioitem_issn")}
                <span className="biblioitem-tag">(022/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_issn_placeholder"
                )}
                className={
                  "form-input" + (errorList.isbn ? " input-error" : "")
                }
                name="issn"
                value={biblioitemData.issn}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    issn: e.target.value,
                  })
                }
              />
              {errorList.issn && (
                <ValidationMessage message={errorList.issn[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_biblioitem_publication_year")}
                <span className="biblioitem-tag">(260/C)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_publication_year_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList.publication_year ? " input-error" : "")
                }
                name="publication_year"
                value={biblioitemData.publication_year}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    publication_year: e.target.value,
                  })
                }
              />
              {errorList.publication_year && (
                <ValidationMessage message={errorList.publication_year[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_biblioitem_publication_place")}
                <span className="biblioitem-tag">(260/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_publication_place_placeholder"
                )}
                className={
                  "form-input" + (errorList.place ? " input-error" : "")
                }
                name="place"
                value={biblioitemData.place}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    place: e.target.value,
                  })
                }
              />
              {errorList.place && (
                <ValidationMessage message={errorList.place[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_biblioitem_publisher")}
                <span className="biblioitem-tag">(260/B)</span>
              </label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList.publisher_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="publisher"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_publisher_placeholder"
                )}
                isClearable={true}
                isSearchable={false}
                options={bibliographyData.publishers.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={
                  biblioitemData?.publisher_id
                    ? {
                        value: biblioitemData.publisher_id.id,
                        label: biblioitemData.publisher_id.name,
                      }
                    : ""
                }
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    publisher_id: { id: e.value, name: e.label },
                  })
                }
              />
              {errorList.publisher_id && (
                <ValidationMessage message={errorList.publisher_id[0]} />
              )}
            </div>
            <div className="form-block col-12 col-md-4">
              <label>
                {t("create_bibliography_edit_biblioitem_publication_number")}
                <span className="biblioitem-tag">(250/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_publication_number_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList.publication_number ? " input-error" : "")
                }
                name="publication_number"
                value={biblioitemData.publication_number}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    publication_number: e.target.value,
                  })
                }
              />
              {errorList.publication_number && (
                <ValidationMessage message={errorList.publication_number[0]} />
              )}
            </div>
          </div>
          <div className="form-block">
            <div className="d-flex align-items-center">
              <button
                className="button-gray-blue"
                type="button"
                onClick={() => setContributorModal(true)}
              >
                <i className="icon plus-icon me-2" />
                {t(
                  "create_bibliography_edit_biblioitem_add_contributors_button"
                )}
              </button>
              <label className="mb-0">
                <span className="biblioitem-tag">(700/A)</span>
              </label>
            </div>
            <div className="form-input tags-container w-100 p-3 mt-3">
              {biblioitemData?.contributors?.length > 0 ? (
                biblioitemData.contributors.map((contributor, index) => (
                  <span key={index} className="tag">
                    {`${contributor.name} / ${CheckContributorsType(
                      contributor.pivot.type
                    )}`}
                    <button
                      className="button-unstyled icon close-red-icon"
                      type="button"
                      onClick={() => removeContributor(contributor)}
                    />
                  </span>
                ))
              ) : (
                <span className="empty-span">
                  {t(
                    "create_bibliography_edit_biblioitem_add_contributors_placeholder"
                  )}
                </span>
              )}
            </div>
            {contributorModal && (
              <ContributorsModal
                onShowModal={contributorModal}
                selectedContributors={biblioitemData?.contributors}
                setContributorVisible={(e) => setContributorModal(e)}
              />
            )}
          </div>
          <div className="row">
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t("create_bibliography_edit_biblioitem_ilustrations")}
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_ilustrations_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList.physical_details ? " input-error" : "")
                }
                name="physical_details"
                value={biblioitemData.physical_details}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    physical_details: e.target.value,
                  })
                }
              />
              {errorList.physical_details && (
                <ValidationMessage message={errorList.physical_details[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t("create_bibliography_edit_biblioitem_page_number")}
                <span className="biblioitem-tag">(300/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_page_number_placeholder"
                )}
                className={
                  "form-input" + (errorList.pages ? " input-error" : "")
                }
                name="pages"
                value={biblioitemData.pages}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    pages: e.target.value,
                  })
                }
              />
              {errorList.pages && (
                <ValidationMessage message={errorList.pages[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t("create_bibliography_edit_biblioitem_dimension")}
                <span className="biblioitem-tag">(300/C)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_dimension_placeholder"
                )}
                className={
                  "form-input" + (errorList.dimensions ? " input-error" : "")
                }
                name="dimensions"
                value={biblioitemData.dimensions}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    dimensions: e.target.value,
                  })
                }
              />
              {errorList.dimensions && (
                <ValidationMessage message={errorList.dimensions[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t("create_bibliography_edit_biblioitem_lccn")}
                <span className="biblioitem-tag">(010/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_lccn_placeholder"
                )}
                className={
                  "form-input" + (errorList.lccn ? " input-error" : "")
                }
                name="lccn"
                value={biblioitemData.lccn}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    lccn: e.target.value,
                  })
                }
              />
              {errorList.lccn && (
                <ValidationMessage message={errorList.lccn[0]} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>{t("create_bibliography_edit_biblioitem_url")}</label>
              <div className="upload-file-box">
                <div
                  className={
                    "form-input" + (errorList.url ? " input-error" : "")
                  }
                >
                  {biblioitemData.url === "" && biblioitemData.urlNew === "" ? (
                    <Fragment>
                      <input
                        className="upload-input"
                        onChange={(e) => {
                          addBiblioitemUpdate({
                            ...biblioitemData,
                            url: e.target.files[0].name,
                            urlNew: e.target.files[0],
                          });
                        }}
                        accept="application/pdf"
                        name="url"
                        type="file"
                      />
                      <div className="upload-input-placeholder">
                        <span>
                          {t(
                            "create_bibliography_edit_biblioitem_url_placeholder"
                          )}
                        </span>
                        <i className="icon upload-icon" />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="upload-input-placeholder">
                        <span>{biblioitemData.url}</span>
                        <i
                          className="icon close-red-icon"
                          onClick={() =>
                            addBiblioitemUpdate({
                              ...biblioitemData,
                              url: "",
                              urlNew: "",
                            })
                          }
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
                {errorList.url && (
                  <ValidationMessage message={errorList.url[0]} />
                )}
              </div>
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t("create_bibliography_edit_biblioitem_kdu")}
                <span className="biblioitem-tag">(080/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_kdu_placeholder"
                )}
                className={"form-input" + (errorList.kdu ? " input-error" : "")}
                name="kdu"
                value={biblioitemData.kdu}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    kdu: e.target.value,
                  })
                }
              />
              {errorList.kdu && (
                <ValidationMessage message={errorList.kdu[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t("create_bibliography_edit_biblioitem_lanugage")}
                <span className="biblioitem-tag">(041/A)</span>
              </label>
              <ReactSelect
                className={
                  "form-react-select" +
                  (errorList.language_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="language"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_lanugage_placeholder"
                )}
                isClearable={true}
                isSearchable={false}
                options={bibliographyData.languages.map((item) => ({
                  value: item.id,
                  label: item.name_trans,
                }))}
                value={
                  biblioitemData?.language_id
                    ? {
                        value: biblioitemData.language_id.id,
                        label: biblioitemData.language_id.name_trans,
                      }
                    : ""
                }
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    language_id: { id: e.value, name_trans: e.label },
                  })
                }
              />
              {errorList.language_id && (
                <ValidationMessage
                  message={errorList.language_id[0]}
                />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t(
                  "create_bibliography_edit_biblioitem_national_control_number"
                )}
                <span className="biblioitem-tag">(016/A)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_national_control_number_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList.national_control_number ? " input-error" : "")
                }
                name="national_control_number"
                value={biblioitemData.national_control_number}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    national_control_number: e.target.value,
                  })
                }
              />
              {errorList.national_control_number && (
                <ValidationMessage
                  message={errorList.national_control_number[0]}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>{t("create_bibliography_edit_biblioitem_barcode")}</label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_barcode_placeholder"
                )}
                className={
                  "form-input" + (errorList.barcode ? " input-error" : "")
                }
                name="barcode"
                value={biblioitemData.barcode}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    barcode: e.target.value,
                  })
                }
              />
              {errorList.barcode && (
                <ValidationMessage message={errorList.barcode[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t("create_bibliography_edit_biblioitem_price")}
                <span className="biblioitem-tag">(365/B)</span>
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_price_placeholder"
                )}
                className={
                  "form-input" + (errorList.price ? " input-error" : "")
                }
                name="price"
                value={biblioitemData.price}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    price: e.target.value,
                  })
                }
              />
              {errorList.price && (
                <ValidationMessage message={errorList.price[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>
                {t("create_bibliography_edit_biblioitem_replacement_price")}
              </label>
              <input
                type="text"
                placeholder={t(
                  "create_bibliography_edit_biblioitem_replacement_price_placeholder"
                )}
                className={
                  "form-input" +
                  (errorList.replacement_price ? " input-error" : "")
                }
                name="replacement_price"
                value={biblioitemData.replacement_price}
                onChange={(e) =>
                  addBiblioitemUpdate({
                    ...biblioitemData,
                    replacement_price: e.target.value,
                  })
                }
              />
              {errorList.replacement_price && (
                <ValidationMessage message={errorList.replacement_price[0]} />
              )}
            </div>
            <div className="form-block col-12 col-sm-6 col-lg-3">
              <label>{t("create_bibliography_edit_biblioitem_cover")}</label>
              <div className="upload-file-box">
                <div
                  className={
                    "form-input" + (errorList.cover ? " input-error" : "")
                  }
                >
                  {biblioitemData.cover === "" ? (
                    <Fragment>
                      <input
                        className="upload-input"
                        onChange={(e) =>
                          addBiblioitemUpdate({
                            ...biblioitemData,
                            cover: e.target.files[0].name,
                            coverValue: e.target.files[0],
                            deleteCover: 0,
                          })
                        }
                        accept="image/*"
                        name="cover"
                        type="file"
                      />
                      <div className="upload-input-placeholder">
                        <span>
                          {t(
                            "create_bibliography_edit_biblioitem_cover_placeholder"
                          )}
                        </span>
                        <i className="icon upload-icon" />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="upload-input-placeholder">
                        <span>{biblioitemData.cover}</span>
                        <i
                          className="icon close-red-icon"
                          onClick={() =>
                            addBiblioitemUpdate({
                              ...biblioitemData,
                              cover: "",
                              coverValue: "",
                              deleteCover: 1,
                            })
                          }
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
                {biblioitemData.cover !== "" && (
                  <div className="uploaded-image">
                    <div className="image-div">
                      {biblioitemData.cover !== "" &&
                      biblioitemData.coverValue !== "" ? (
                        <img
                          src={URL.createObjectURL(biblioitemData.coverValue)}
                          alt="Cover"
                        />
                      ) : (
                        <img
                          src={biblioCoversPath + biblioitemData.cover}
                          alt="Cover"
                        />
                      )}
                    </div>
                  </div>
                )}
                {errorList.cover && (
                  <ValidationMessage message={errorList.cover[0]} />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {btnLoading ? (
              <button className="button-blue has-icon" type="button">
                <i className="icon spinner-icon me-2" />
                {t("create_bibliography_edit_biblioitem_saving_button")}
              </button>
            ) : (
              <button
                className="button-blue"
                onClick={(e) => createBiblioitem(e, "forward")}
              >
                {t("create_bibliography_edit_biblioitem_save_button")}
              </button>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditBiblioitem;
