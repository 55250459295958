import { useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Path from "../../../../routes/GeneralPaths";

const ImportMarc = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const uploadMarc = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("marc_file", name);

    Request.postRequestExport(ApiUrl.saveMarcFile, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 201) {
          setName("");
          setErrors([]);
          navigate(Path.confirmMarc, {
            state: { file_name: response.data.marc_name },
          });
        } else if (response.status === 422) {
          setErrors(response.data.marc_file);
        } else if (response.status === 405) {
          setErrors(response.data.error);
        }
      }
    );
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("import_marc_title")}</p>
      <div>
        <form onSubmit={(e) => uploadMarc(e)}>
          <div className="row m-0 position-relative">
            <div className="form-block col-12 col-sm-6 col-xl-4 p-0">
              <label htmlFor="ngarko">
                {t("import_marc_upload_file_label")}
              </label>
              <div className="upload-file-box">
                <div
                  className={
                    "form-input" + (errors.length > 0 ? " input-error" : "")
                  }
                >
                  {name === "" ? (
                    <Fragment>
                      <input
                        className="upload-input"
                        onChange={(e) => setName(e.target.files[0])}
                        name="marc_file"
                        type="file"
                      />
                      <div className="upload-input-placeholder">
                        <span>{t("import_marc_upload_file_placeholder")}</span>
                        <i className="icon upload-icon" />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="upload-input-placeholder">
                        <span>{name.name}</span>
                        <i
                          className="icon close-red-icon"
                          onClick={() => setName("")}
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              {errors.length > 0 && <ValidationMessage message={errors} />}
            </div>
            <div className="col-12 p-0">
              {btnLoading ? (
                <button type="button" className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("import_marc_upload_file_uploading_button")}
                </button>
              ) : (
                <button type="submit" className="button-blue">
                  {t("import_marc_upload_file_upload_button")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default ImportMarc;
