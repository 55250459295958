import { useState } from "react";
import { useTranslation } from "react-i18next";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import { useEffect } from "react";
import { Fragment } from "react";
import ReactSelect from "react-select";
import { tooltipDisabledMobile } from "../../../../helpers/Tooltip";
import ListResponse from "../../../../helpers/ListResponse";
import CustomPagination from "../../../../helpers/CustomPagination";
import SearchFilter from "../../../../helpers/SearchFilter";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import Loading from "../../../../helpers/Loading";

const ExportMarc = () => {
  const { t } = useTranslation();

  //   Search states
  const [text, setText] = useState("");
  const [specification, setSpecification] = useState("");
  const [searched, setSearched] = useState(false);
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  //   Marc list
  const [marcData, setMarcData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [activePage, setActivePage] = useState("");
  const [btnAddLoading, setAddBtnLoading] = useState(false);

  //   Export marc
  const [checkedRecords, setCheckedRecords] = useState({});

  useEffect(() => {
    marcList();
  }, [activePage, searched]);

  const marcList = (e) => {
    let urlRequest = ApiUrl.selectMarcRecords;
    var searchParams = {};

    // If the filters button is clicked
    if (e) {
      setBtnLoading(true);
      e.preventDefault();
    }

    // Change page
    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      // If the filter button is clicked, but we are in the first page
      searchParams = {
        text: text,
        specification: specification,
      };
    } else if (searched && activePage > 1) {
      // If the filter button is clicked, but we are not in the first page
      searchParams = {
        text: text,
        specification: specification,
        page: activePage,
      };
    }

    // Url of the request with filter parameters
    urlRequest = SearchFilter(searchParams, urlRequest);

    Request.getRequest(urlRequest).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setMarcData(response.data.biblioitems.data);
        setPagination(response.data.biblioitems);
        setLoading(false);
        setErrors({});
      } else if (response.status === 422) {
        setErrors(response.data);
      }
    });
  };

  const checkItem = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      const checkedValues = {
        [`value_${value}`]: value,
      };
      let checkedInputs = {
        ...checkedRecords,
      };
      Object.assign(checkedInputs, checkedValues);
      setCheckedRecords(checkedInputs);
    } else {
      var checkedList = checkedRecords;
      delete checkedList[`value_${value}`];
      var uncheckedInputs = {
        ...checkedRecords,
      };
      setCheckedRecords(uncheckedInputs);
    }
  };

  const checkAllItems = (e) => {
    var form = document.querySelector("#choose-form");
    var inputs = form.querySelectorAll("input");

    var checkedValues = {};
    for (let input of inputs) {
      checkedValues = {
        ...checkedValues,
        [`value_${input.value}`]: input.value,
      };
      if (e.target.checked) {
        input.checked = true;
        let checkedInputs = {
          ...checkedValues,
          ...checkedRecords,
        };
        Object.assign(checkedInputs, checkedValues);
        setCheckedRecords(checkedInputs);
      } else {
        input.checked = false;
        delete checkedRecords[`value_${input.value}`];
        let checkedInputs = {
          ...checkedRecords,
        };
        setCheckedRecords(checkedInputs);
      }
    }
  };

  const exportMarc = (e) => {
    e.preventDefault();
    setAddBtnLoading(true);

    const checkedItems = Object.values(checkedRecords);

    const formData = new FormData();
    for (let i = 0; i < checkedItems.length; i++) {
      checkedItems[i] !== "on" &&
        formData.append("biblioitems[" + i + "]", checkedItems[i]);
    }

    Request.postRequestExport(ApiUrl.saveMarcRecords, formData).then(
      (response) => {
        setAddBtnLoading(false);
        var checkboxs = document.querySelectorAll('input[type="checkbox"]');

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "marc.mrc");
          document.body.appendChild(link);
          link.click();
          setCheckedRecords({});
          for (var i = 0; i < checkboxs.length; i++) {
            checkboxs[i].checked = false;
          }
          Message.successMsg(t("export_marc_export_success_message"));
        } else if (response.status === 404) {
          Message.warningMsg(response.data.error);
        } else {
          Message.errorMsg(response.data.error);
        }
      }
    );
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("export_marc_title")}</p>
      <div className="input-items mb-5">
        <form onSubmit={(e) => marcList(e)}>
          <div className="row form-block mb-5">
            <div className="col-12 col-md-4 mb-4">
              <input
                placeholder={t("export_marc_search_input_placeholder")}
                className={"form-input" + (errors.text ? " input-error" : "")}
                name="text"
                type="text"
                onChange={(e) => setText(e.target.value)}
              />
              {errors.text && <ValidationMessage message={errors.text[0]} />}
            </div>
            <div className="col-12 col-md-4 mb-4">
              <ReactSelect
                className={
                  "form-react-select" +
                  (errors.specification ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="specification"
                placeholder={t("export_marc_search_select_placeholder")}
                onChange={(e) => setSpecification(e ? e.value : "")}
                isSearchable={false}
                options={[
                  {
                    value: "title",
                    label: t("export_marc_select_option_title"),
                  },
                  {
                    value: "author",
                    label: t("export_marc_select_option_author"),
                  },
                  {
                    value: "isbn/issn",
                    label: t("export_marc_select_option_isbn_issn"),
                  },
                  {
                    value: "contributors",
                    label: t("export_marc_select_option_contributor"),
                  },
                  {
                    value: "publisher",
                    label: t("export_marc_select_option_publisher"),
                  },
                ]}
              />
              {errors.specification && (
                <ValidationMessage message={errors.specification[0]} />
              )}
            </div>
            <div className="col-12 col-md-4">
              {btnLoading === true ? (
                <button type="button" className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("export_marc_search_searching_button")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-blue has-icon"
                  onClick={() => setSearched(true)}
                >
                  <i className="icon search-icon me-2" />
                  {t("export_marc_search_search_button")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <form onSubmit={(e) => exportMarc(e)} id="choose-form">
        <div className="form-group d-md-none">
          {t("export_marc_choose_all_label")}
          <input
            type="checkbox"
            id="choose_all_m"
            onChange={(e) => checkAllItems(e)}
            className="form-input-checkbox"
          />
          <label className="float-end" htmlFor="choose_all_m"></label>
        </div>
        <div className="table-list mb-4">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" width="20%" className="text-left">
                  {t("export_marc_table_title")}
                </th>
                <th role="columnheader" width="20%" className="text-center">
                  {t("export_marc_table_isbn_issn")}
                </th>
                <th role="columnheader" width="20%" className="text-center">
                  {t("export_marc_table_publisher")}
                </th>
                <th role="columnheader" width="20%" className="text-center">
                  {t("export_marc_table_author")}
                </th>
                <th role="columnheader" width="20%" className="form-group">
                  {t("export_marc_table_choose_all")}
                  <input
                    type="checkbox"
                    id="choose_all"
                    className="form-input-checkbox"
                    onChange={(e) => checkAllItems(e)}
                  />
                  <label className="float-end" htmlFor="choose_all"></label>
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <Loading />
                  </td>
                </tr>
              ) : (
                marcData.map((marcItem) => (
                  <tr key={marcItem.id}>
                    <td role="cell" data-label={t("export_marc_table_title")}>
                      {tooltipDisabledMobile(
                        marcItem.id,
                        marcItem.biblio.title
                      )}
                    </td>
                    <td
                      role="cell"
                      data-label={t("export_marc_table_isbn_issn")}
                      className="text-center"
                    >
                      {marcItem.isbn ? marcItem.isbn : ""}{" "}
                      {marcItem.issn && "/"} {marcItem.issn}
                    </td>
                    <td
                      role="cell"
                      data-label={t("export_marc_table_publisher")}
                      className="text-center"
                    >
                      {marcItem.publisher && marcItem.publisher.name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("export_marc_table_author")}
                      className="text-center"
                    >
                      {marcItem.biblio.authors
                        .map((author) => author.name)
                        .join("; ")}
                    </td>
                    <td
                      role="cell"
                      data-label={t("export_marc_table_choose_all_mobile")}
                      className="form-group"
                    >
                      <input
                        type="checkbox"
                        name="biblioitems[]"
                        value={marcItem.id}
                        className="form-input-checkbox"
                        id={marcItem.id}
                        onChange={(e) => checkItem(e)}
                        defaultChecked={
                          checkedRecords[`value_${marcItem.id}`] ===
                          `${marcItem.id}`
                        }
                      />
                      <label
                        className="float-end"
                        htmlFor={marcItem.id}
                      ></label>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            <ListResponse
              totalItems={pagination && pagination.total}
              loading={loading}
              searched={searched}
            />
          </table>
        </div>
        <div className="col-12 d-flex justify-content-start justify-content-lg-end">
          {btnAddLoading === true ? (
            <button className="button-blue has-icon" type="button">
              <i className="icon spinner-icon me-2" />
              {t("export_marc_export_exporting_button")}
            </button>
          ) : (
            <button className="button-blue" type="submit">
              {t("export_marc_export_export_button")}
            </button>
          )}
        </div>
        <CustomPagination
          data={pagination}
          setActivePage={(e) => {
            setActivePage(e);
            Object.keys(errors).length > 0 && setSearched(false);
          }}
        />
      </form>
    </Fragment>
  );
};

export default ExportMarc;
