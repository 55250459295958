import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { authRole } from "../../../../helpers/AppConfig";
import {
  CheckBorrowingType,
  CheckSelectedDate,
} from "../../../../helpers/CheckValue";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";

const AddItems = ({ biblioitemId, serieId, setUpdateItems }) => {
  const { t } = useTranslation();

  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const [index, setIndex] = useState(0);

  const [newItems, setNewItems] = useState([]);

  const addNewItem = () => {
    var items = newItems;
    var newItemData = {
      borrowing_type: "",
      inventory_number: "",
      place_number: "",
      damaged: 0,
      damaged_date: "",
      renewal_date: "",
      id: index,
    };
    items.push(newItemData);
    setNewItems([...items]);
    setIndex(index + 1);
  };

  useEffect(() => {
    addNewItem();
  }, []);

  const removeItem = (id) => {
    var items = newItems;
    items.forEach((item, index) => {
      if (item.id === id) {
        newItems.splice(index, 1);
      }
    });

    setNewItems([...items]);
  };

  const handleChangeSelect = (e, name, i) => {
    var items = newItems;
    items[i][name] = e ? e.value : "";
    setNewItems([...items]);
  };

  const handleChange = (e, name, i) => {
    var items = newItems;
    items[i][name] = e.target.value;
    setNewItems([...items]);
  };

  const handleChangeDate = (e, name, i) => {
    var items = newItems;
    items[i][name] = e ? moment(e).format("yyyy-MM-DD") : null;
    setNewItems([...items]);
  };

  const updateGeneratedSerie = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var formData = new FormData();

    newItems.forEach((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        key !== "id" && formData.append(`items[${index}][${key}]`, value);
      }
      formData.append(`items[${index}][biblioitem_id]`, biblioitemId);
      formData.append(`items[${index}][serie_id]`, serieId);
    });

    Request.postRequest(ApiUrl.storeSerieItems, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        setErrorList([]);
        setNewItems([]);
        setNewItems([
          {
            borrowing_type: "",
            inventory_number: "",
            place_number: "",
            damaged: 0,
            damaged_date: "",
            renewal_date: "",
            id: index,
          },
        ]);
        setIndex(index + 1);
        setUpdateItems(true);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setErrorList(response.data);
      }
    });
  };

  return (
    <Fragment>
      <form onSubmit={(e) => updateGeneratedSerie(e)}>
        <div className="row m-0">
          <div className="col-12 col-md-10 p-0">
            {newItems.map((item, index) => (
              <Fragment key={item.id}>
                <div className="row card-item-bg mt-3">
                  <div className="form-block col-12 col-md-4 mb-4">
                    <label>
                      {t(
                        "edit_serial_serie_list_add_item_borrowing_type_label"
                      )}
                    </label>
                    <ReactSelect
                      className={
                        "form-react-select" +
                        (errorList[`items.${index}.borrowing_type`]
                          ? " input-error"
                          : "")
                      }
                      classNamePrefix="select_input"
                      name={"items[" + item.id + "][borrowing_type]"}
                      placeholder={t(
                        "edit_serial_serie_list_add_item_borrowing_type_placeholder"
                      )}
                      defaultValue={
                        item.borrowing_type
                          ? CheckBorrowingType(item.borrowing_type)
                          : null
                      }
                      isSearchable={false}
                      isClearable={true}
                      menuPlacement="auto"
                      options={[
                        {
                          value: "library",
                          label: t("check_borrowing_type_library"),
                        },
                        {
                          value: "normal",
                          label: t("check_borrowing_type_normal"),
                        },
                        {
                          value: "unavailable",
                          label: t("check_borrowing_type_unavailable"),
                        },
                      ]}
                      onChange={(e) =>
                        handleChangeSelect(e, `borrowing_type`, index)
                      }
                    />
                    {errorList[`items.${index}.borrowing_type`] && (
                      <ValidationMessage
                        message={errorList[`items.${index}.borrowing_type`]}
                      />
                    )}
                  </div>
                  <div className="form-block col-12 col-md-4 mb-4">
                    <label>
                      {t("edit_serial_serie_list_add_item_codification_label")}
                    </label>
                    <input
                      placeholder={t(
                        "edit_serial_serie_list_add_item_codification_placeholder"
                      )}
                      className={
                        "form-input" +
                        (errorList[`items.${index}.inventory_number`]
                          ? " input-error"
                          : "")
                      }
                      name={"items[" + item.id + "][inventory_number]"}
                      onChange={(e) =>
                        handleChange(e, `inventory_number`, index)
                      }
                      type="text"
                      defaultValue={
                        item.inventory_number ? item.inventory_number : ""
                      }
                    />
                    {errorList[`items.${index}.inventory_number`] && (
                      <ValidationMessage
                        message={errorList[`items.${index}.inventory_number`]}
                      />
                    )}
                  </div>
                  <div className="form-block col-12 col-md-4 mb-4">
                    <label>
                      {t("edit_serial_serie_list_add_item_place_number_label")}
                    </label>
                    <input
                      placeholder={t(
                        "edit_serial_serie_list_add_item_place_number_placeholder"
                      )}
                      className={
                        "form-input" +
                        (errorList[`items.${index}.place_number`]
                          ? " input-error"
                          : "")
                      }
                      name={"items[" + item.id + "][place_number]"}
                      onChange={(e) => handleChange(e, `place_number`, index)}
                      type="text"
                      defaultValue={item.place_number ? item.place_number : ""}
                    />
                    {errorList[`items.${index}.place_number`] && (
                      <ValidationMessage
                        message={errorList[`items.${index}.place_number`]}
                      />
                    )}
                  </div>
                  {authRole() === "administrator" && (
                    <div className="form-block row col-12 m-0 p-0">
                      <div className="col-12 col-md-4 mb-4">
                        <label>
                          {t("edit_serial_serie_list_add_item_damaged_label")}
                        </label>
                        <div className="radio-buttons d-flex">
                          <div className="radio-button me-4">
                            <label htmlFor={`damaged_${index}`}>
                              {t(
                                "edit_serial_serie_list_add_item_damaged_yes_label"
                              )}
                            </label>
                            <input
                              className="form-check-input"
                              name={"items[" + item.id + "][damaged]"}
                              onChange={(e) =>
                                handleChange(e, `damaged`, index)
                              }
                              type="radio"
                              value="1"
                              id={`damaged_${index}`}
                              defaultChecked={item.damaged === 1}
                            />
                          </div>
                          <div className="radio-button ms-4">
                            <label htmlFor={`notdamaged_${index}`}>
                              {t(
                                "edit_serial_serie_list_add_item_damaged_no_label"
                              )}
                            </label>
                            <input
                              className="form-check-input"
                              id={`notdamaged_${index}`}
                              name={"items[" + item.id + "][damaged]"}
                              onChange={(e) =>
                                handleChange(e, `damaged`, index)
                              }
                              type="radio"
                              value="0"
                              defaultChecked={item.damaged === 0}
                            />
                          </div>
                        </div>
                        {errorList[`items.${index}.damaged`] && (
                          <ValidationMessage
                            message={errorList[`items.${index}.damaged`]}
                          />
                        )}
                      </div>

                      {(item.damaged === 1 || item.damaged === "1") && (
                        <Fragment>
                          <div className="col-12 col-md-4 mb-4">
                            <label>
                              {t(
                                "edit_serial_serie_list_add_item_damaged_date_label"
                              )}
                            </label>
                            <ReactDatePicker
                              placeholderText={t(
                                "edit_serial_serie_list_add_item_damaged_date_placeholder"
                              )}
                              name={"items[" + item.id + "][damaged_date]"}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              // locale={sq}
                              dateFormat="dd-MM-yyyy"
                              selected={
                                item.damaged_date
                                  ? CheckSelectedDate(item.damaged_date)
                                  : null
                              }
                              onChange={(e) =>
                                handleChangeDate(e, `damaged_date`, index)
                              }
                              autoComplete="off"
                              className={
                                "date_input" +
                                (errorList[`items.${index}.damaged_date`]
                                  ? " input-error"
                                  : "")
                              }
                            />
                            {errorList[`items.${index}.damaged_date`] && (
                              <ValidationMessage
                                message={
                                  errorList[`items.${index}.damaged_date`]
                                }
                              />
                            )}
                          </div>
                          <div className="col-12 col-md-4 mb-4">
                            <label>
                              {t(
                                "edit_serial_serie_list_add_item_renewal_date_label"
                              )}
                            </label>
                            <ReactDatePicker
                              placeholderText={t(
                                "edit_serial_serie_list_add_item_renewal_date_placeholder"
                              )}
                              name={"items[" + item.id + "][renewal_date]"}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              // locale={sq}
                              dateFormat="dd-MM-yyyy"
                              selected={
                                item.renewal_date
                                  ? CheckSelectedDate(item.renewal_date)
                                  : null
                              }
                              onChange={(e) =>
                                handleChangeDate(e, `renewal_date`, index)
                              }
                              autoComplete="off"
                              className={
                                "date_input" +
                                (errorList[`items.${index}.renewal_date`]
                                  ? " input-error"
                                  : "")
                              }
                            />
                            {errorList[`items.${index}.renewal_date`] && (
                              <ValidationMessage
                                message={
                                  errorList[`items.${index}.renewal_date`]
                                }
                              />
                            )}
                          </div>
                        </Fragment>
                      )}
                    </div>
                  )}
                  <div className="add-remove-buttons">
                    {index + 1 === newItems.length && (
                      <button
                        className="button-blue-border-item"
                        type="button"
                        onClick={() => addNewItem()}
                      >
                        +
                      </button>
                    )}
                    {newItems.length !== 1 && (
                      <button
                        type="button"
                        className="button-blue-item"
                        onClick={() => removeItem(item.id)}
                      >
                        -
                      </button>
                    )}
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
          <div className="col-12 col-md-2 ps-0 ps-md-3 mt-3 align-self-end">
            {btnLoading ? (
              <button className="button-blue has-icon" type="button">
                <i className="icon spinner-icon me-2" />
                {t("edit_serial_serie_list_add_item_saving_button")}
              </button>
            ) : (
              <button className="button-blue">
                {t("edit_serial_serie_list_add_item_save_button")}
              </button>
            )}
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default AddItems;
