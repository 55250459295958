import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CheckSelectedDate } from "../../../helpers/CheckValue";
import moment from "moment";
import Select from "react-select";
import sq from "date-fns/locale/sq";
import DatePicker from "react-datepicker";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";

const InviteMember = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [memberRoles, setMemberRoles] = useState([]);
  const [first_name, setFirst_Name] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState(null);
  const [role_id, setRole_Id] = useState("");
  const [membership_start, setMembership_start] = useState(null);
  const [membership_end, setMembership_end] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [nid, setNid] = useState("");
  const [faculty, setFaculty] = useState("");
  const [department, setDepartment] = useState("");
  const [program, setProgram] = useState("");
  const [edulevel, setEdulevel] = useState("");
  const [fullorparttime, setFullorparttime] = useState("");
  const [year, setYear] = useState("");
  const [error, setError] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    memberRole();
  }, []);

  var date = new Date();
  date.setFullYear(date.getFullYear());

  const memberRole = () => {
    Request.getRequest(ApiUrl.memberRoles).then((response) => {
      if (response.status === 200) {
        setMemberRoles(response.data.memberRoles);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const inviteMember = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var studentData = {
      faculty: faculty,
      department: department,
      program: program,
      edulevel: edulevel,
      fullorparttime: fullorparttime,
      year: year,
    };

    var formData = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      role_id: role_id,
      dob: dob ? moment(dob).format("DD-MM-yyyy") : "",
      membership_start: membership_start
        ? moment(membership_start).format("DD-MM-yyyy")
        : "",
      membership_end: membership_end,
      state: state,
      city: city,
      address: address,
      postal_code: postal_code,
      phone: phone,
      gender: gender,
      nid: nid,
      ...studentData,
    };

    Request.postRequest(ApiUrl.storeMember, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        navigate(Path.listMembers);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Fragment>
      <p className="partial-title mb-5">{t("invite_member_add_member")}</p>
      <form onSubmit={(e) => inviteMember(e)}>
        <div className="row">
          <div className="col-12 col-md-4 flex-column col-form">
            <div className="form-block">
              <label>{t("invite_member_name")}</label>
              <input
                type="text"
                name="first_name"
                placeholder={t("invite_member_add_name")}
                className={
                  "form-input" + (error.first_name ? " input-error" : "")
                }
                onChange={(e) => setFirst_Name(e.target.value)}
              />
              {error.first_name && (
                <ValidationMessage message={error.first_name[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("invite_member_lastname")}</label>
              <input
                type="text"
                name="last_name"
                placeholder={t("invite_member_add_lastname")}
                className={
                  "form-input" + (error.last_name ? " input-error" : "")
                }
                onChange={(e) => setLast_Name(e.target.value)}
              />
              {error.last_name && (
                <ValidationMessage message={error.last_name[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("invite_member_email")}</label>
              <input
                type="text"
                name="email"
                placeholder={t("invite_member_email")}
                className={"form-input" + (error.email ? " input-error" : "")}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error.email && <ValidationMessage message={error.email[0]} />}
            </div>
            <div className="form-block">
              <label>{t("invite_member_birthday")}</label>
              <DatePicker
                placeholderText={t("invite_member_choose_date")}
                name="dob"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={sq}
                dateFormat="dd-MM-yyyy"
                selected={dob ? CheckSelectedDate(dob) : null}
                onChange={(e) => setDob(e)}
                autoComplete="off"
                className={"date_input" + (error.dob ? " input-error" : "")}
              />
              {error.dob && <ValidationMessage message={error.dob[0]} />}
            </div>
            <div className="form-block">
              <label>{t("invite_member_identification_number")}</label>
              <input
                type="text"
                name="nid"
                placeholder={t("invite_member_identification_number_nid")}
                className={"form-input" + (error.nid ? " input-error" : "")}
                onChange={(e) => setNid(e.target.value)}
              />
              {error.nid && <ValidationMessage message={error.nid[0]} />}
            </div>
            {role_id === 4 && (
              <Fragment>
                <div className="form-block">
                  <label>{t("invite_member_year")}</label>
                  <input
                    type="number"
                    name="year"
                    placeholder={t("invite_member_year")}
                    className={
                      "form-input" + (error.year ? " input-error" : "")
                    }
                    onChange={(e) => setYear(e.target.value)}
                  />
                  {error.year && <ValidationMessage message={error.year[0]} />}
                </div>
                <div className="form-block">
                  <label>{t("invite_member_faculty")}</label>
                  <input
                    type="text"
                    name="faculty"
                    placeholder={t("invite_member_faculty")}
                    className={
                      "form-input" + (error.faculty ? " input-error" : "")
                    }
                    onChange={(e) => setFaculty(e.target.value)}
                  />
                  {error.faculty && (
                    <ValidationMessage message={error.faculty[0]} />
                  )}
                </div>
              </Fragment>
            )}
          </div>

          <div className="col-12 col-md-4 flex-column col-form">
            <div className="form-block">
              <label>{t("invite_member_start_date")}</label>
              <input
                type="text"
                defaultValue={moment().format("DD-MM-yyyy")}
                name="membership_start"
                placeholder={t("invite_member_start_date")}
                className={
                  "form-input" + (error.membership_start ? " input-error" : "")
                }
                readOnly="readonly"
              />
              {error.membership_start && (
                <ValidationMessage message={error.membership_start[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("invite_member_end_date")}</label>
              <Select
                className={
                  "form-react-select" +
                  (error.membership_end ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="membership_end"
                placeholder={t("invite_member_end_date")}
                isClearable={true}
                onChange={(e) =>
                  e ? setMembership_end(e.value) : setMembership_end("")
                }
                isSearchable={false}
                options={[
                  { value: "3", label: t("three_months") },
                  { value: "6", label: t("six_months") },
                  { value: "9", label: t("nine_months") },
                  { value: "12", label: t("twelve_months") },
                ]}
              />
              {error.membership_end && (
                <ValidationMessage message={error.membership_end[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("invite_member_state")}</label>
              <input
                type="text"
                name="state"
                placeholder={t("invite_member_add_state")}
                className={"form-input" + (error.state ? " input-error" : "")}
                onChange={(e) => setState(e.target.value)}
              />
              {error.state && <ValidationMessage message={error.state[0]} />}
            </div>
            <div className="form-block">
              <label>{t("invite_member_city")}</label>
              <input
                type="text"
                name="city"
                placeholder={t("invite_member_add_city")}
                className={"form-input" + (error.city ? " input-error" : "")}
                onChange={(e) => setCity(e.target.value)}
              />
              {error.city && <ValidationMessage message={error.city[0]} />}
            </div>
            <div className="form-block">
              <label>{t("invite_member_address")}</label>
              <input
                type="text"
                name="address"
                placeholder={t("invite_member_add_address")}
                className={"form-input" + (error.address ? " input-error" : "")}
                onChange={(e) => setAddress(e.target.value)}
              />
              {error.address && (
                <ValidationMessage message={error.address[0]} />
              )}
            </div>
            {role_id === 4 && (
              <div className="form-block">
                <label>{t("invite_member_department")}</label>
                <input
                  type="text"
                  name="department"
                  placeholder={t("invite_member_department")}
                  className={
                    "form-input" + (error.department ? " input-error" : "")
                  }
                  onChange={(e) => setDepartment(e.target.value)}
                />
                {error.department && (
                  <ValidationMessage message={error.department[0]} />
                )}
              </div>
            )}
          </div>
          <div className="col-12 col-md-4 d-flex flex-column col-form">
            <div className="form-block">
              <label>{t("invite_member_postal_code")}</label>
              <input
                type="number"
                name="postal_code"
                placeholder={t("invite_member_add_postal_code")}
                className={
                  "form-input" + (error.postal_code ? " input-error" : "")
                }
                onChange={(e) => setPostal_code(e.target.value)}
              />
              {error.postal_code && (
                <ValidationMessage message={error.postal_code[0]} />
              )}
            </div>
            <div className="form-block">
              <label>{t("invite_member_phone")}</label>
              <input
                type="number"
                name="phone"
                placeholder={t("invite_member_add_phone")}
                className={"form-input" + (error.phone ? " input-error" : "")}
                onChange={(e) => setPhone(e.target.value)}
              />
              {error.phone && <ValidationMessage message={error.phone[0]} />}
            </div>
            <div className="form-block">
              <label>{t("invite_member_role")}</label>
              <Select
                className={
                  "form-react-select" + (error.role_id ? " input-error" : "")
                }
                classNamePrefix="select_input"
                name="role_id"
                isClearable={true}
                placeholder={t("invite_member_choose")}
                onChange={(e) => (e ? setRole_Id(e.value) : setRole_Id(""))}
                isSearchable={false}
                options={memberRoles.map((item) => ({
                  value: item.id,
                  label: item.name_trans,
                }))}
              />
              {error.role_id && (
                <ValidationMessage message={error.role_id[0]} />
              )}
            </div>
            {role_id === 4 && (
              <Fragment>
                <div className="form-block">
                  <label>{t("invite_member_program")}</label>
                  <input
                    type="text"
                    name="program"
                    placeholder={t("invite_member_program")}
                    className={
                      "form-input" + (error.program ? " input-error" : "")
                    }
                    onChange={(e) => setProgram(e.target.value)}
                  />
                  {error.program && (
                    <ValidationMessage message={error.program[0]} />
                  )}
                </div>
                <div className="form-block">
                  <label>{t("invite_member_education_level")}</label>
                  <input
                    type="text"
                    name="edulevel"
                    placeholder={t("invite_member_education_level")}
                    className={
                      "form-input" + (error.edulevel ? " input-error" : "")
                    }
                    onChange={(e) => setEdulevel(e.target.value)}
                  />
                  {error.edulevel && (
                    <ValidationMessage message={error.edulevel[0]} />
                  )}
                </div>
                <div className="form-block d-block">
                  <label className="mb-2">
                    {t("invite_member_study_time")}
                  </label>
                  <div className="radio-buttons d-flex">
                    <div className="radio-button mr-3 me-3">
                      <label htmlFor="fulltime">
                        {t("invite_member_full_time")}
                      </label>
                      <input
                        className={"form-check-input"}
                        id="fulltime"
                        name="fullorparttime"
                        type="radio"
                        value="fulltime"
                        onChange={(e) => setFullorparttime(e.target.value)}
                      />
                    </div>
                    <div className="radio-button">
                      <label htmlFor="parttime">
                        {t("invite_member_part_time")}
                      </label>
                      <input
                        className="form-check-input"
                        id="parttime"
                        name="fullorparttime"
                        type="radio"
                        value="parttime"
                        onChange={(e) => setFullorparttime(e.target.value)}
                      />
                    </div>
                    {error.fullorparttime && (
                      <ValidationMessage message={error.fullorparttime[0]} />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            <div className="form-block d-block">
              <label className="mb-2">{t("invite_member_gender")}</label>
              <div className="radio-buttons d-flex">
                <div className="radio-button mr-3 me-3">
                  <label htmlFor="man">{t("invite_member_male")}</label>
                  <input
                    className="form-check-input"
                    id="man"
                    name="gender"
                    type="radio"
                    value="M"
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                <div className="radio-button">
                  <label htmlFor="woman">{t("invite_member_female")}</label>
                  <input
                    className="form-check-input"
                    id="woman"
                    name="gender"
                    type="radio"
                    value="F"
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
                {error.gender && (
                  <ValidationMessage message={error.gender[0]} />
                )}
              </div>
            </div>
            <div className="form-block mt-auto">
              {btnLoading ? (
                <button type="button" className="button-blue has-icon mt-auto">
                  <i className="icon spinner-icon me-2" />
                  {t("invite_member_saving")}
                </button>
              ) : (
                <button type="submit" className="button-blue has-icon mt-auto">
                  {t("invite_member_save")}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default InviteMember;
