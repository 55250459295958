import i18next from "i18next";
import Cookies from "js-cookie";

// Api key from backend
export const apiKey = process.env.REACT_APP_API_KEY;

// Api base url
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// Base url
export const baseUrl = process.env.REACT_APP_BASE_URL;

// Set access token and role
export const setAccessToken = (access_token, role) => {
  Cookies.set("role", `${role}`, {
    expires: new Date(new Date().getTime() + 240 * 60 * 1000),
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  Cookies.set("access_token", `${access_token}`, {
    expires: new Date(new Date().getTime() + 240 * 60 * 1000),
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};

// Delete access token and role
export const deleteAccessToken = () => {
  Cookies.remove("access_token", {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
  Cookies.remove("role", {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};

// Get access token
export const token = () => {
  if (Cookies.get("access_token")) {
    return Cookies.get("access_token");
  } else {
    return false;
  }
};

// Get role
export const authRole = () => {
  if (Cookies.get("role")) {
    return Cookies.get("role");
  } else {
    return false;
  }
};

// Check if is logged in
export const isLogged = () => {
  if (token() && authRole()) {
    return true;
  } else {
    return false;
  }
};

// Set access token and role
export const setLanguage = (language) => {
  Cookies.set("i18nextLng", `${language}`, {
    expires: 365,
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};

// Get language
export const language = () => {
  if (Cookies.get("i18nextLng")) {
    return Cookies.get("i18nextLng");
  } else {
    return false;
  }
};

//Storage path
export const blogPath = baseUrl + "storage/blog/images/";
export const marcPath = baseUrl + "storage/marc/";
export const biblioCoversPath = baseUrl + "storage/covers/";
export const serieCoversPath = baseUrl + "storage/seriecovers/";
export const bookCovers = baseUrl + "storage/images/covers/";

export const apiConfigParams = () => {
  return {
    headers: {
      Authorization: `Bearer ${token()}`,
      "api-key": apiKey,
      "content-type": "application/json",
      "Accept-Language": i18next.language,
    },
  };
};

export const apiConfigParamsLogin = () => {
  return {
    headers: {
      "api-key": apiKey,
      "content-type": "application/json",
      "Accept-Language": i18next.language,
    },
  };
};

export const apiConfigParamsUrlencoded = () => {
  return {
    headers: {
      Authorization: `Bearer ${token()}`,
      "api-key": apiKey,
      "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Accept-Language": i18next.language,
    },
  };
};

export const apiConfigParamsForDownload = () => {
  return {
    headers: {
      Authorization: `Bearer ${token()}`,
      "api-key": apiKey,
      responseType: "blob",
      "Accept-Language": i18next.language,
    },
  };
};

export const apiConfigParamsForDownloadExcel = () => {
  return {
    headers: {
      Authorization: `Bearer ${token()}`,
      "api-key": apiKey,
      "Content-Disposition": "attachment; filename=template.xlsx",
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "Accept-Language": i18next.language,
    },
    responseType: "arraybuffer",
  };
};

export const apiConfigParamsForExport = () => {
  return {
    headers: {
      Authorization: `Bearer ${token()}`,
      "api-key": apiKey,
      "Content-Type": "multipart/form-data",
      "Accept-Language": i18next.language,
    },
  };
};
